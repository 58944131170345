import * as React from "react"
import { Router } from "@reach/router"

import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { ScrollToPlugin } from "gsap/all"
import { Draggable } from "gsap/Draggable"

import { NavSession } from "../components/utils/nav-session"
import { withPrefix } from "../components/utils/with-prefix"
import ApolloWrapper from "../components/utils/apollo-wrapper"
import FlavorFinderDebugTool from "../components/utils/debbuggin-tool/debbuging-tool"
import PieceRoute from "../components/utils/piece-route"

import FillAndFollowPage from "../components/utils/fillAndFollowPage/fillAndFollowPage"
import ForgotPasswordPage from "../components/utils/forgotPasswordPage/forgotPasswordPage"
import QuizPage from "../components/utils/quizPage/quizPage"
import ResetPasswordPage from "../components/utils/resetPasswordPage/resetPasswordPage"
import ResultPage from "../components/utils/resultPage/resultPage"
import SignInPage from "../components/utils/signInPage/signInPage"
import SignUpPage from "../components/utils/signUpPage/signUpPage"
import UserProfilePage from "../components/utils/userProfile/userProfilePage"

import { CognitoSessionProvider } from "../context/CognitoSessionContext"
import { ModalProvider } from "../context/ModalContext"
import { QuizProvider } from "../context/QuizContext"

const IndexPage = () => {
  // const isFlavorFinderEnabled =
  //   typeof process.env.GATSBY_ENABLE_FLAVORFINDER != undefined
  //     ? process.env.GATSBY_ENABLE_FLAVORFINDER != "0"
  //     : true
  const isFlavorFinderEnabled = false;
  const isFlavorFinderDebugEnabled =
    typeof process.env.GATSBY_ENABLE_FLAVORFINDER_DEBUG_TOOL != undefined
      ? process.env.GATSBY_ENABLE_FLAVORFINDER_DEBUG_TOOL != "0"
      : true

  React.useEffect(() => {
    gsap.registerPlugin(ScrollTrigger)
    gsap.registerPlugin(ScrollToPlugin)
    gsap.registerPlugin(Draggable)
  }, [])

  return (
    <CognitoSessionProvider>
      <ApolloWrapper>
        <QuizProvider>
          <ModalProvider>
            <NavSession>
              <Router basepath={withPrefix("/")}>
                <PieceRoute default />
                <SignInPage path="/sign-in" />
                <SignUpPage path="/sign-up" />
                <ForgotPasswordPage path="/forgot-password" />
                <ResetPasswordPage path="/reset-password" />
                <UserProfilePage path="/profile" />
                <FillAndFollowPage path="/fill-and-follow" />
                {isFlavorFinderEnabled && (
                  <>
                    <QuizPage path="/whiskey-finder/quiz" />
                    <ResultPage path="/whiskey-finder/results" />
                  </>
                )}
                {isFlavorFinderDebugEnabled && (
                  <FlavorFinderDebugTool path="/debugging-tool" />
                )}
              </Router>
            </NavSession>
          </ModalProvider>
        </QuizProvider>
      </ApolloWrapper>
    </CognitoSessionProvider>
  )
}

export default IndexPage
