import React from "react"
import PropTypes from "prop-types"
import * as variable from "./imageSwitcher.module.scss"

const ImageSwitcher = ({ img, smallImg, alt, breakpoint }) => {
  const screenWidthBreak =
    breakpoint === "sm"
      ? variable.screenSmMin
      : breakpoint === "md"
      ? variable.screenMdMin
      : breakpoint === "lg"
      ? variable.screenLgMin
      : null

  return (
    <picture>
      <source
        srcSet={smallImg.url}
        media={`(max-width: ${screenWidthBreak})`}
      />
      <img src={img.url} alt={img.alt} />
    </picture>
  )
}

ImageSwitcher.propTypes = {
  img: PropTypes.object,
  smallImg: PropTypes.object,
  alt: PropTypes.string,
  breakpoint: PropTypes.oneOf(["sm", "md", "lg"]),
}

ImageSwitcher.defaultProps = {
  img: ``,
  smallImg: ``,
  alt: ``,
  breakpoint: `md`,
}

export default ImageSwitcher
