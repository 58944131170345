import React, { useState } from "react"
import PropTypes from "prop-types"
import classNames from "classnames/bind"
import { useLocation } from "@reach/router"

import ShareButton from "../../molecules/ShareButton/ShareButton"
import SaveButton from "../../molecules/SaveButton/SaveButton"

import BreadCrumbs from "../../atoms/BreadCrumbs/BreadCrumbs"

import * as styles from "./articleHero.module.scss"

const ProgressBar = ({ steps, currentStep }) => {
  const cx = classNames.bind(styles)
  const progress = ((currentStep - 1) * 100) / steps
  const width = 100 / steps

  return (
    <div className={cx("progressbar")}>
      <div style={{ marginLeft: `${progress}%`, width: `${width}%` }} />
    </div>
  )
}

const CarouselItem = ({ img, isCurrent, total, currentStep, next }) => {
  const cx = classNames.bind(styles)

  return (
    <div className={cx("carouselItem", isCurrent ? "current" : "")}>
      <div className={cx("progressWrapper")}>
        <ProgressBar steps={total} currentStep={currentStep + 1} />
      </div>
      <img src={img.url} alt={img.alt} onClick={() => next()} />
    </div>
  )
}

const ArticleHero = ({ author, title, copy, image, breadcrumbs, pieceId }) => {
  const [counter, setCounter] = useState(0)

  const { href } = useLocation()
  const hasImage = image ? true : false

  const isCarousel = hasImage && image.length > 1

  const cx = classNames.bind(styles)

  const next = () => {
    const nextItem = counter + 1 < image.length ? counter + 1 : 0

    setCounter(nextItem)
  }

  const copyColumn = cx({
    ["colSix"]: image,
    ["colTwelve"]: !image,
  })

  return (
    <div className={cx("grid")}>
      {breadcrumbs && (
        <BreadCrumbs breadcrumbs={breadcrumbs} isWhiteFont={hasImage} />
      )}
      {image && (
        <div className={cx("colSix")}>
          {isCarousel ? (
            <div className={cx("carousel")}>
              {image.map((img, i) => {
                return (
                  <CarouselItem
                    key={i}
                    img={img}
                    isCurrent={counter === i}
                    currentStep={i}
                    total={image.length}
                    next={() => next()}
                  />
                )
              })}
            </div>
          ) : (
            <div>
              <img src={image[0].url} alt={image[0].alt} />
            </div>
          )}
        </div>
      )}
      <div className={copyColumn}>
        <div className={cx("container")}>
          <div className={cx("contentWrapper")}>
            {author && <h4>by {author}</h4>}
            <h2>{title}</h2>
            <p>{copy}</p>
          </div>
          <div className={cx("ctaWrapper")}>
            <SaveButton
              title="Add to favorites"
              savedTitle="Remove from favorites"
              pieceId={pieceId}
              contentData={{ title, type: "article" }}
            />
            <ShareButton title="Share" linkUrl={href} />
          </div>
        </div>
      </div>
    </div>
  )
}

ArticleHero.propTypes = {
  author: PropTypes.string,
  title: PropTypes.string,
  copy: PropTypes.string,
  image: PropTypes.array,
  pieceId: PropTypes.string,
  breadcrumbs: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
}

ArticleHero.defaultProps = {
  author: ``,
  title: ``,
  copy: ``,
  image: ``,
  breadcrumbs: ``,
}

export default ArticleHero
