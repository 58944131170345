// extracted by mini-css-extract-plugin
export var category = "searchCategories-module--category--1Bwu5";
export var selected = "searchCategories-module--selected--1UKqG";
export var filtersButton = "searchCategories-module--filtersButton--3fpBr";
export var overlay = "searchCategories-module--overlay--eR0dC";
export var filters = "searchCategories-module--filters--2IlVv";
export var controls = "searchCategories-module--controls--3Ve5n";
export var cross = "searchCategories-module--cross--7PVfC";
export var results = "searchCategories-module--results--1HVRa";
export var noResults = "searchCategories-module--noResults--308z0";
export var loadMore = "searchCategories-module--loadMore--1D8tC";