import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import classNames from "classnames/bind"
import DeviceInfo from "../../utils/device-info"
import * as styles from "./contentCard.module.scss"
// Atoms
import Button from "../../atoms/Button/Button"
import ImageSwitcher from "../../atoms/ImageSwitcher/ImageSwitcher"
import SaveButton from "../SaveButton/SaveButton"
import ShareButton from "../ShareButton/ShareButton"
import ShareLink from "../../atoms/ShareLink/ShareLink"
import { withPrefix } from "../../utils/with-prefix"

const ContentCard = ({
  deviceInfo,
  eyebrow,
  header,
  copy,
  hasShare,
  hasSave,
  hasShop,
  cta,
  image,
  mobileImage,
  type,
  url,
  info,
  features,
  variant,
  snapToColumn,
  onFocus,
  carousel,
  pieceId,
  key,
  ctaBottom,
  productId,
}) => {
  const [isMain, setIsMain] = useState(variant === "1UP")
  const [customStyles, setCustomStyles] = useState({})
  const cx = classNames.bind(styles)

  useEffect(() => {
    updateWidth()
  }, [carousel, deviceInfo.viewport, deviceInfo.screenSize])

  const updateWidth = () => {
    const newStyles = {}
    if (carousel) {
      let maxWidth = "381px"
      switch (deviceInfo.viewport) {
        case "small":
          maxWidth = `${window.innerWidth - 48}px`
          break
        case "medium":
          maxWidth = `${window.innerWidth - 120}px`
          break
        case "big":
          maxWidth = `${(window.innerWidth - 240 - 56) / 3}px`
          break
      }
      newStyles.width = maxWidth
    }
    setCustomStyles(newStyles)
  }

  const handleFocus = () => {
    onFocus()
  }

  const imgData = {
    img: image,
    smallImg: mobileImage ? mobileImage : image,
    alt: image.alt ? image.alt : header,
    breakpoint: "md",
  }

  return (
    <div
      className={cx("container", {
        ["colTwo"]: !isMain && variant === "2UP",
        ["colThree"]: !snapToColumn && variant === "3UP",
        ["main"]: isMain && type === "article",
        ["recipe"]: type === "recipe" || type === "bottle",
        ["bottle"]: type === "bottle",
        ["video"]: type === "video",
        ["carouselCard"]: carousel,
        ["related"]: type === "related",
      })}
      style={{ ...customStyles }}
      tabIndex={0}
      onFocus={handleFocus}
      key={key}
    >
      {type !== "recipe" && type !== "bottle" && (
        <div className={cx("background")}>
          <ImageSwitcher {...imgData} />
        </div>
      )}
      <div className={cx("content")}>
        {eyebrow && <h5 className={cx("eyebrow")}>{eyebrow}</h5>}
        <h2 className={cx("header")}>{header}</h2>
        {copy && <p className={cx("copy")}>{copy}</p>}
        {(type === "recipe" || type === "bottle") && (
          <>
            {info && (
              <div className={cx("info")}>
                {info.map((item, index) => {
                  return (
                    item.icon && (
                      <div className={cx("data")} key={index}>
                        <img src={item.icon} alt={item.text} />
                        <p>{item.text}</p>
                      </div>
                    )
                  )
                })}
              </div>
            )}
            {features && (
              <div className={cx("features")}>
                {features.map((item, index) => {
                  return (
                    item.value && (
                      <div className={cx("feature")} key={index}>
                        <p>{item.value}</p>
                        <h5>{item.feature}</h5>
                      </div>
                    )
                  )
                })}
              </div>
            )}
            <div className={cx("imageWrapper")}>
              <ImageSwitcher {...imgData} />
            </div>
          </>
        )}
        {(cta || hasSave || hasShare) && (
          <div
            className={cx("interaction", { ctaBottom: ctaBottom })}
            data-gtm-module-type={isMain ? "featured" : "card"}
            data-gtm-content-type={type}
            data-gtm-title={header}
          >
            {cta &&
              (!isMain ||
                (isMain )) && (
                <div className={cx("ctaWrapper")}>
                  <Button {...cta} />
                </div>
              )}
            {(hasShare || hasSave) && (
              <div className={cx("actions")}>
                {hasShare && (
                  <div className={cx("share")}>
                    <ShareButton linkUrl={window.location.host + url} />
                  </div>
                )}
                {hasShare &&
                  hasSave &&
                  (!isMain ||
                    (isMain &&
                      ["large", "big"].indexOf(deviceInfo.viewport) ===
                        -1)) && <span className={cx("separator")}></span>}
                {hasSave && (
                  <div className={cx("save")}>
                    <SaveButton
                      pieceId={pieceId}
                      contentData={{ title: header, type }}
                    />
                  </div>
                )}
              </div>
            )}
            {type === "video" && (
              <div className={cx("play")}>
                <a href={cta?.url ? cta.url : url} target="_self">
                  <i className={cx("playIcon")}></i>
                </a>
              </div>
            )}
          </div>
        )}
        {hasShop && (
          <div className={cx("variantActions")}>
            <bsi-locator-buy-button
              product={productId}
              language="EN_US"
              theme="jimbeam"
            >
              <ShareLink
                onClick={() => {}}
                type="shop"
                icon={true}
                iconAlignment="left"
              />
            </bsi-locator-buy-button>
            <SaveButton pieceId={pieceId} bottle={type === "bottle"} />
          </div>
        )}
      </div>
    </div>
  )
}

ContentCard.propTypes = {
  eyebrow: PropTypes.string,
  header: PropTypes.string,
  copy: PropTypes.string,
  hasShare: PropTypes.bool,
  hasSave: PropTypes.bool,
  cta: PropTypes.object,
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  mobileImage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  type: PropTypes.oneOf(["video", "recipe", "article", "related", "bottle"]),
  url: PropTypes.string,
  info: PropTypes.arrayOf(PropTypes.object),
  features: PropTypes.arrayOf(PropTypes.object),
  variant: PropTypes.oneOf(["1UP", "2UP", "3UP"]),
  snapToColumn: PropTypes.object,
  onFocus: PropTypes.func,
  carousel: PropTypes.bool,
  pieceId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  key: PropTypes.string,
  ctaBottom: PropTypes.bool,
}

ContentCard.defaultProps = {
  eyebrow: ``,
  header: ``,
  copy: ``,
  hasShare: false,
  hasSave: false,
  cta: null,
  image: ``,
  mobileImage: ``,
  type: null,
  url: "#",
  info: null,
  variant: "3UP",
  snapToColumn: null,
  onFocus: () => {},
  carousel: false,
  pieceId: null,
  key: "",
  ctaBottom: false,
}

const ContentCardWrapper = props => (
  <DeviceInfo>
    {deviceInfo => <ContentCard deviceInfo={deviceInfo} {...props} />}
  </DeviceInfo>
)

export default ContentCardWrapper
