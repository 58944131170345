import React from "react"
import classNames from "classnames/bind"
import FilterDropdown from "../../atoms/FilterDropdown/FilterDropdown"

import * as styles from "./categoryFilters.module.scss"

const CategoryFilters = ({
  filters,
  onChange,
  selected,
  hasSort = true,
  selectedOptions,
  updateState,
}) => {
  const cx = classNames.bind(styles)
  const sort = [
    {
      filter: "created",
      label: "Newest",
      order: "asc",
    },
    {
      filter: "created",
      label: "Oldest",
      order: "desc",
    },
    {
      filter: "title",
      label: "A-Z",
      order: "asc",
    },
    {
      filter: "title",
      label: "Z-A",
      order: "desc",
    },
  ]

  return (
    <div className={cx("categoryFilters")}>
      {filters &&
        filters.map((filter, idx) => {
          return (
            <FilterDropdown
              selected={selected}
              filters={filter.values}
              key={idx}
              label={filter.name}
              isShow={filter.name === "type" ? true : false}
              onChange={(value, order) => onChange(value, order, filter.name)}
              selectedOptions={selectedOptions}
              updateState={e => updateState(e)}
              filterName={filter.name}
            />
          )
        })}
      {hasSort && (
        <FilterDropdown
          selected={selected}
          filters={sort}
          label={"Sort"}
          isShow={false}
          onChange={(value, name) => onChange(value, name, "sort")}
          selectedOptions={selectedOptions}
          updateState={e => updateState(e)}
          filterName={"sort"}
        />
      )}
    </div>
  )
}

export default CategoryFilters
