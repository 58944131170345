// extracted by mini-css-extract-plugin
export var root = "articleDetail-module--root--2det7";
export var container = "articleDetail-module--container--38kOT";
export var copyContainer = "articleDetail-module--copyContainer--2oPlC";
export var fullBleed = "articleDetail-module--fullBleed--VcqV6";
export var imageWrapper = "articleDetail-module--imageWrapper--1AlBV";
export var captionWrapper = "articleDetail-module--captionWrapper--hhNe7";
export var grid = "articleDetail-module--grid--1U9Wm";
export var colSix = "articleDetail-module--colSix--2HUcD";
export var colTwelve = "articleDetail-module--colTwelve--2ENDE";
export var right = "articleDetail-module--right--1At2o";
export var left = "articleDetail-module--left--pQo4h";