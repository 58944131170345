import React, { useEffect, useRef, useState } from "react"
import PropTypes from "prop-types"
import classNames from "classnames/bind"
import { Draggable } from "gsap/Draggable"
import DeviceInfo from "../../utils/device-info"

import * as styles from "./sliderContainer.module.scss"

const SliderContainer = ({
  deviceInfo,
  children,
  className,
  currentSlide,
  scrollable,
  onUpdate,
  columnData,
  onDrag,
  offsetLeft,
}) => {
  const [dragStart, setDragStart] = useState({
    x: 0,
    y: 0,
  })
  const [dragEnd, setDragEnd] = useState({
    x: 0,
    y: 0,
  })
  const [draggin, setDraggin] = useState(false)
  const wrapperRef = useRef(null)
  const cx = classNames.bind(styles)

  useEffect(() => {
    goToCurrent()
  }, [currentSlide])

  useEffect(() => {
    goToCurrent()
  }, [columnData])

  useEffect(() => {
    onDrag(draggin)
  }, [draggin])

  useEffect(() => {
    const xDiff =
      dragStart.x > dragEnd.x
        ? dragStart.x - dragEnd.x
        : dragEnd.x - dragStart.x
    const yDiff =
      dragStart.y > dragEnd.y
        ? dragStart.y - dragEnd.y
        : dragEnd.y - dragStart.y
    // prevent vertical drag to trigger update
    if (yDiff < xDiff) {
      if (dragStart.x > dragEnd.x) {
        // move foward
        if (currentSlide + 1 < children.length) {
          onUpdate(currentSlide + 1)
        }
      } else {
        // move back
        if (currentSlide - 1 >= 0) {
          onUpdate(currentSlide - 1)
        }
      }
    }
    goToCurrent()
  }, [dragEnd])

  useEffect(() => {
    const wrapper = wrapperRef.current
    if (wrapper) {
      // update width to match scrollable content
      wrapper.style.width = `${wrapper.scrollWidth + 16}px`
      const slides = wrapper.children
      Draggable.create(wrapper, {
        type: "x",
        trigger: slides,
        bounds: {
          minX: getOffset(slides[0]),
          maxX: getOffset(slides[slides.length - 1]),
        },
        onDragStart: args => {
          setDragStart({
            x: args.clientX,
            y: args.clientY,
          })
          setDraggin(true)
        },
        onDragEnd: args => {
          setDragEnd({
            x: args.clientX,
            y: args.clientY,
          })
          setDraggin(false)
        },
      })
    }
  }, [wrapperRef.current])

  const goToCurrent = () => {
    const wrapper = wrapperRef.current
    wrapper.parentNode.scrollLeft = 0
    if (wrapper) {
      const slides = wrapper.children
      const current = slides[currentSlide]
      let scrollTo = getOffset(current)
      wrapper.style.transform = `translate3d(${
        scrollTo + (deviceInfo.viewport !== "small" ? offsetLeft : 0)
      }px, 0px, 0px)`
    }
  }

  const getOffset = slide => {
    const wrapper = wrapperRef.current
    const containerDiff = wrapper.parentNode.clientWidth - slide.clientWidth
    const offsetLeft =
      deviceInfo.isSafari && deviceInfo.viewport !== "small"
        ? slide.offsetLeft - wrapper.offsetLeft
        : slide.offsetLeft

    if (columnData) {
      const columnOffset =
        deviceInfo.viewport === "small" ? 24 : columnData.width + columnData.gap
      return offsetLeft * -1 + columnOffset
    }
    if (currentSlide === 0 && deviceInfo.viewport !== "small") {
      return containerDiff / 2 - offsetLeft
    }
    if (deviceInfo.viewport === "small") {
      const slideStyle = slide.currentStyle || window.getComputedStyle(slide)
      return offsetLeft * -1 + parseInt(slideStyle.marginLeft) / -2
    }
    return (offsetLeft - slide.clientWidth / 2) * -1
  }

  return (
    <div className={cx("container", !scrollable && "noScroll", className)}>
      <div className={cx("wrapper")} ref={wrapperRef}>
        {children}
      </div>
    </div>
  )
}

SliderContainer.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  currentSlide: PropTypes.number,
  scrollable: PropTypes.bool,
  onUpdate: PropTypes.func,
  columnData: PropTypes.object,
  onDrag: PropTypes.func,
  offsetLeft: PropTypes.number,
}

SliderContainer.defaultProps = {
  className: "",
  currentSlide: 0,
  scrollable: false,
  onUpdate: () => {},
  columnData: null,
  onDrag: () => {},
  offsetLeft: 0,
}

const SliderContainerWrapper = props => (
  <DeviceInfo>
    {deviceInfo => <SliderContainer deviceInfo={deviceInfo} {...props} />}
  </DeviceInfo>
)

export default SliderContainerWrapper
