import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames/bind"
import * as styles from "./socialMedia.module.scss"

const cx = classNames.bind(styles)

const VARIANTS = {
  facebook: {
    icon: "facebookIcon",
    title: "Facebook",
  },
  instagram: {
    icon: "instagramIcon",
    title: "Instagram",
  },
  twitter: {
    icon: "twitterIcon",
    title: "Twitter",
  },
  download: {
    icon: "arrowDownIcon",
    title: "Download",
  },
}

const SocialMedia = ({ variant }) => {
  const currentVariant = VARIANTS[variant]

  return (
    <div className={cx("container")}>
      <div className={cx("iconContainer")}>
        <i className={cx(currentVariant.icon)} />
      </div>
      <h5>{currentVariant.title}</h5>
    </div>
  )
}

SocialMedia.propTypes = {
  variant: PropTypes.oneOf(["facebook", "twitter", "instagram", "download"]),
}
SocialMedia.defaultProps = {}

export default SocialMedia
