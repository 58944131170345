import React, { useEffect, useState, useCallback } from "react"
import PropTypes from "prop-types"

import classNames from "classnames/bind"

import Question from "../../molecules/Question/Question"
import BrandLogo from "../../atoms/BrandLogo/BrandLogo"
import Button from "../../atoms/Button/Button"
import ProgressBar from "../../atoms/ProgressBar/ProgressBar"
import QuestionBreaker from "../../organisms/QuestionBreaker/QuestionBreaker"

import DeviceInfo from "../../utils/device-info"
import {
  getOptionGroupsFromQuestion,
  getOptionsFromGroups,
} from "../../utils/helpers"

import * as styles from "./quizContainer.module.scss"
import { EVENTS, reports } from "../../utils/reports"

const QuizContainer = ({ brandLogoImage, structure, sendResults }) => {
  const [currentQuestionId, setCurrentQuestionId] = useState()
  const [currentQuestion, setCurrentQuestion] = useState({})
  const [optionGroups, setOptionGroups] = useState([])
  const [currentOptions, setCurrentOptions] = useState([])
  const [currentQuestionData, setCurrrentQuestionData] = useState({})

  const [questionsHistory, setQuestionsHistory] = useState([])

  const [answers, setAnswers] = useState([])
  const [finalAnswer, setFinalAnswer] = useState(false)
  const [stepOptions, setStepOptions] = useState([])
  const [answersToReport, setAnswersToReport] = useState("")
  const [currentAnswers, setCurrentAnswers] = useState([])
  const [selectedAnswerId, setSelectedAnswerId] = useState()
  const [favorites, setFavorites] = useState([])
  const [selectedFavorite, setSelectedFavorite] = useState(null)
  const [breaker, setBreaker] = useState([])
  const [totalSteps, setTotalSteps] = useState()
  const [currentStep, setCurrentStep] = useState(0)
  const [prevStep, setPrevStep] = useState(0)

  const [isFinish, setIsFinish] = useState(false)
  const [nextEnabled, setNextEnabled] = useState(false)
  const [showBreaker, setShowBreaker] = useState(false)
  const [isBack, setIsBack] = useState(false)

  const questions = structure.flavorFinderQuestions || []
  const firstQuestionId = questions[0] && questions[0].entityId
  const url = currentQuestion.fieldImage?.entity?.fieldBeamBourbonuImage?.url
  const alt = currentQuestion.fieldImage?.entity?.fieldBeamBourbonuImage?.alt
  const currentQuestionImage = url ? { url, alt: alt } : null
  const questionActiveImage =
    currentQuestion.fieldActive?.entity?.fieldBeamBourbonuImage

  const reportInfo = {
    question: currentQuestion.title,
    number: currentQuestionId,
  }

  const isLastQuestion = current => {
    return (
      !Number.isInteger(current?.fieldNextQuestion?.targetId) &&
      !Number.isInteger(
        current.optionGroups?.[0]?.entity?.fieldBeamFlavorfinderOptions?.[0]
          ?.entity?.fieldBeamFlavorfinderNext?.targetId
      )
    )
  }

  useEffect(() => {
    if (!currentQuestionId) {
      setCurrentQuestionId(firstQuestionId)
      setQuestionsHistory([firstQuestionId])
      setCurrentStep(1)
    }
  }, [firstQuestionId])

  useEffect(() => {
    const question = questions.find(question => {
      return question.entityId === `${currentQuestionId}`
    })

    setTotalSteps(questions.length)

    if (!isBack) {
      setSelectedFavorite(null)
      setCurrrentQuestionData(question)
    }

    if (question?.fieldBreaker && !isBack) {
      setBreaker(question.fieldBreaker.entity)
      setShowBreaker(true)
    } else if (question) {
      loadNewQuestion(question)
      //remove current answers for back button
      if (isBack) {
        setCurrentAnswers([])
        setSelectedFavorite(null)
        setSelectedAnswerId("")
      }
    }
  }, [currentQuestionId])

  useEffect(() => {
    const optionGroups = getOptionsFromGroups(currentOptions)
    setOptionGroups(optionGroups)
  }, [currentOptions])

  useEffect(() => {
    const followUpQuestion = currentQuestion.type === "multiple_followup"
    if (followUpQuestion && currentQuestion.followUpData) {
      setNextEnabled(selectedFavorite !== null)
    } else {
      setNextEnabled(selectedAnswerId)
    }
    if (selectedFavorite) {
      addFavorite(selectedFavorite)
    }
  }, [selectedAnswerId, optionGroups, selectedFavorite, currentQuestion])

  useEffect(() => {
    //Go to next item on the flow
    if (currentAnswers.length > 0) {
      const nextId = currentAnswers[0]
      setCurrentQuestionId(nextId)
      addQuestionToHistory(nextId)
    }
  }, [currentAnswers])

  useEffect(() => {
    reportInfo.question &&
      reports({
        event: EVENTS.PAGEVIEW_QUIZ,
        ...reportInfo,
      })

    let isLast = isLastQuestion(currentQuestion)
    setIsFinish(isLast)
    if (isLast) {
      setCurrentStep(totalSteps - 1)
    }
  }, [currentQuestion])

  useEffect(() => {
    if (showBreaker) {
      window.scrollTo(0, 0)
    }
  }, [showBreaker])

  const addFavorite = newFavorite => {
    const index = questionsHistory.length - 1
    setFavorites(oldFavorites => {
      const newFavorites = [...oldFavorites]
      newFavorites.splice(index, 1, newFavorite)
      return newFavorites
    })
  }

  const onSelect = (id, answersToReport) => {
    setAnswersToReport(answersToReport)

    const newId = id.ids !== undefined ? id.ids : id

    if (newId != selectedAnswerId) {
      setSelectedAnswerId(newId)
    } else {
      if (!id?.favorite && currentQuestion.type != "single_scale") {
        setSelectedAnswerId("")
      }
    }

    let tempOptions = stepOptions

    if (id.ids) {
      if (id.favorite) {
        tempOptions[currentStep - 1] = {
          step: currentStep,
          options: id.favorite,
          questionId: currentQuestionId.toString(),
          favorite: true,
        }
      } else {
        tempOptions[currentStep - 1] = {
          step: currentStep,
          options: id.ids.toString(),
          questionId: currentQuestionId.toString(),
        }
      }
    } else {
      tempOptions[currentStep - 1] = {
        step: currentStep,
        options: id,
        questionId: currentQuestionId.toString(),
      }
    }

    id?.favorite && setSelectedFavorite(id?.favorite)

    setStepOptions(tempOptions)

    if (isFinish) {
      setCurrentStep(totalSteps)
    }
  }

  const addQuestionToHistory = questionId => {
    setQuestionsHistory(oldHistory => oldHistory.concat(`${questionId}`))
  }
  const removeAnswerFromQuestionId = questionId => {
    const removedQuestion = questions.find(
      question => question.entityId == questionId
    )
    const answersToRemove = removedQuestion?.optionGroups.reduce(
      (ids, current) => {
        current?.entity?.fieldBeamFlavorfinderOptions.forEach(option => {
          ids.push(option.entity.entityId)
        })
        return ids
      },
      []
    )
    setAnswers(prevList =>
      prevList.filter(answer => !answersToRemove.includes(answer))
    )
  }
  const removeQuestionFromHistory = useCallback(() => {
    const history = [...questionsHistory]
    const removedQuestionId = history.pop()
    const lastQuestionId = history[history.length - 1]
    setQuestionsHistory(history)
    removeAnswerFromQuestionId(removedQuestionId)
    return lastQuestionId
  }, [questionsHistory, currentAnswers])

  const loadNewQuestion = question => {
    if (!isBack) {
      setSelectedAnswerId("")
    }
    setSelectedFavorite(null)

    const newOptions = getOptionGroupsFromQuestion(question)
    setCurrentOptions(newOptions)
    setCurrentQuestion({ ...question })
  }

  const handleAnswers = useCallback(() => {
    if (selectedAnswerId?.length > 0) {
      const ids = selectedAnswerId?.split(",")
      const newIds = []
      const flow = []
      //get next question after pressing back button
      if (isBack && currentQuestion.type !== "multiple_preference") {
        const nextId = answers[answers.length - 1]
        const nextOption = optionGroups?.find(option => option.id === nextId)

        //filter if old answer is selected
        if (ids.includes(nextId)) {
          flow.push(nextOption.next.targetId)
        }
      }

      //Add answer to answer list
      ids.forEach(id => {
        if (!answers.find(element => element == id)) {
          newIds.push(id)
        }

        //Add valid options to flow
        let newOption = optionGroups?.find(option => option.id === id)

        if (newOption && newOption.next) {
          const nextId = newOption.next.targetId
          if (
            currentAnswers.indexOf(nextId) == -1 &&
            flow.indexOf(nextId) == -1
          ) {
            flow.push(newOption.next.targetId)
          }
        }
      })

      if (currentQuestion.fieldNextQuestion?.targetId) {
        if (
          currentAnswers.indexOf(currentQuestion.fieldNextQuestion.targetId) ==
          -1
        ) {
          flow.push(currentQuestion.fieldNextQuestion.targetId)
        }
      }

      const updatedAnswers =
        newIds.length > 0
          ? [...currentAnswers.slice(1), ...flow]
          : [...currentAnswers, ...flow]
      //remove old answers only if there is new one selected (press back and select new options)
      const answersToRemove =
        newIds.length > 0
          ? currentQuestion?.optionGroups.reduce((ids, current) => {
              current?.entity?.fieldBeamFlavorfinderOptions.forEach(option => {
                ids.push(option.entity.entityId)
              })
              return ids
            }, [])
          : []

      const finalAnswers = [
        ...answers.filter(answer => !answersToRemove.includes(answer)),
        ...newIds,
      ]

      setAnswers(finalAnswers)
      setCurrentAnswers(updatedAnswers)
      setPrevStep(currentStep)
      if (!isFinish) {
        setPrevStep(currentStep)
        setCurrentStep(prev => prev + 1)
      }
    }
  }, [
    answers,
    selectedAnswerId,
    currentAnswers,
    optionGroups,
    currentStep,
    isFinish,
  ])

  const handlerOnNextQuestion = () => {
    setIsBack(false)

    //Check if question has follow up
    const ids = selectedAnswerId?.split(",")
    if (
      currentQuestion.type === "multiple_followup" &&
      ids.length > 1 &&
      !currentQuestion.followUpData
    ) {
      // update question with new selection, text and max
      const updateCurrentQuestion = {
        ...currentQuestion,
        followUpData: {
          title: currentQuestion.fieldFollowupTitle,
          assist: currentQuestion.fieldFollowupAssist,
          max: 1,
          options: [],
          favorite: null,
        },
      }

      if (currentQuestion.optionGroups) {
        let currentFavorite = null
        const newOptionsGroup = []
        currentOptions.map(group => {
          newOptionsGroup.push({
            options: group.options.filter(option => {
              if (favorites.some(id => id === option.id)) {
                currentFavorite = option.id
              }
              return ids.some(id => id === option.id)
            }),
          })
        })

        updateCurrentQuestion.followUpData.options = newOptionsGroup
        updateCurrentQuestion.followUpData.favorite = currentFavorite
        setSelectedFavorite(currentFavorite)
      }

      setCurrentQuestion({ ...updateCurrentQuestion })
    } else {
      handleAnswers()
    }
  }

  const handlerOnPrevQuestion = useCallback(() => {
    setIsBack(true)

    if (isFinish) {
      //reset if got to last step
      setCurrentStep(prevStep)
    } else {
      setCurrentStep(prev => prev - 1)
    }

    if (
      currentQuestion.type === "multiple_followup" &&
      currentQuestion.followUpData
    ) {
      const updateCurrentQuestion = {
        ...currentQuestion,
        followUpData: null,
      }

      setCurrentQuestion({ ...updateCurrentQuestion })

      //Check previous options
      let stepInfo = stepOptions.find(
        element => element.questionId === currentQuestion.entityId
      )
      if (stepInfo && stepInfo.favorite) {
        setSelectedAnswerId(stepInfo[1])
      }
    } else {
      const lastQuestionId = removeQuestionFromHistory()
      setCurrentQuestionId(lastQuestionId)
    }
  }, [currentQuestion, isFinish, prevStep, removeQuestionFromHistory])

  const handlerOnFinish = () => {
    //update answers to include the last option selected before sending results
    handleAnswers()
    //update to trigger sendResults()
    setFinalAnswer(true)
  }

  useEffect(() => {
    if (finalAnswer) {
      const options = answers.reduce((options, answer) => {
        const answerOptions = answer.split(",")
        return options.concat(
          answerOptions.map(id => {
            const option = {
              optionId: id,
            }
            if (favorites.some(favorite => favorite === id)) {
              option.favorite = true
            }
            return option
          })
        )
      }, [])

      sendResults(options)
    }
  }, [finalAnswer])

  const breakerAnim = () => {
    loadNewQuestion(currentQuestionData)
    setShowBreaker(false)
  }

  const cx = classNames.bind(styles)
  return (
    <DeviceInfo>
      {deviceInfo => (
        <>
          {showBreaker && (
            <QuestionBreaker
              title={breaker.fieldBeamFlavorfinderLabel}
              secondaryCopy={breaker.fieldBeamFlavorfinderSecond}
              assistanceCopy={breaker.fieldBeamFlavorfinderAssist}
              imageCopy={breaker.fieldBeamFlavorfinderImage}
              start={true}
              action={breakerAnim}
            />
          )}
          <div className={cx("container")}>
            <div className={cx("brandContainer")}>
              <BrandLogo img={brandLogoImage} url="#" />
              {deviceInfo.viewport === "small" && (
                <Button
                  type="tertiary"
                  icon={true}
                  iconAlignment="left"
                  state={questionsHistory.length > 1 ? "active" : "disabled"}
                  onClick={handlerOnPrevQuestion}
                  trackingObjects={[
                    {
                      event: EVENTS.QUIZ_BACK_BUTTOM,
                      ...reportInfo,
                    },
                  ]}
                />
              )}
            </div>
            <div className={cx("questionContainer")}>
              {currentQuestion && (
                <Question
                  text={currentQuestion.title}
                  subCopy={currentQuestion.fieldAssist}
                  options={currentOptions}
                  image={currentQuestionImage}
                  selectedImage={questionActiveImage}
                  type={currentQuestion.type}
                  selectedAnswer={selectedAnswerId}
                  onSelect={onSelect}
                  min={parseInt(currentQuestion.fieldSelectionMin)}
                  max={parseInt(currentQuestion.fieldSelectionMax)}
                  followUpData={currentQuestion.followUpData}
                  selectedFavorite={selectedFavorite}
                />
              )}
              <div className={cx("progress")}>
                <ProgressBar steps={totalSteps} currentStep={currentStep} />
              </div>
            </div>
            <div className={cx("navigator")}>
              {deviceInfo.viewport !== "small" && (
                <Button
                  url=""
                  text="BACK"
                  type="tertiary"
                  icon={true}
                  iconAlignment="left"
                  state={questionsHistory.length > 1 ? "active" : "disabled"}
                  onClick={handlerOnPrevQuestion}
                  trackingObjects={[
                    {
                      event: EVENTS.QUIZ_BACK_BUTTOM,
                      ...reportInfo,
                    },
                  ]}
                />
              )}
              <Button
                url=""
                text={isFinish ? "FINISH" : "NEXT QUESTION"}
                type="tertiary"
                icon={true}
                iconAlignment="right"
                state={nextEnabled ? "active" : "disabled"}
                onClick={isFinish ? handlerOnFinish : handlerOnNextQuestion}
                trackingObjects={
                  isFinish
                    ? [
                        {
                          event: EVENTS.QUIZ_ANSWER,
                          ...reportInfo,
                          answers: answersToReport,
                        },
                      ]
                    : [
                        {
                          event: EVENTS.QUIZ_NEXT_BUTTOM,
                          ...reportInfo,
                        },
                        {
                          event: EVENTS.QUIZ_ANSWER,
                          ...reportInfo,
                          answers: answersToReport,
                        },
                      ]
                }
              />
            </div>
          </div>
        </>
      )}
    </DeviceInfo>
  )
}

QuizContainer.propTypes = {
  structure: PropTypes.object,
  brandLogoImage: PropTypes.string,
  sendResults: PropTypes.func,
}

QuizContainer.defaultProps = {
  structure: {},
  brandLogoImage: "",
  sendResults: () => {},
}

export default QuizContainer
