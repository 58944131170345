import React, { useEffect, useState, useCallback } from "react"
import classNames from "classnames/bind"
import Password from "../../atoms/Password/Password"
import Button from "../../atoms/Button/Button"
import * as styles from "./resetPasswordPage.module.scss"
import { navigate } from "@reach/router"
import { withPrefix } from "../with-prefix"
import useCognitoUserData from "../cognito-user-data"

const ResetPasswordPage = () => {
  const requiredFieldNames = ["password", "newPassword", "confirmPassword"]

  const [formValues, setFormValues] = useState({})
  const [formErrors, setFormErrors] = useState({})

  const {
    tempUserData,
    sessionChecked,
    isLoggedIn,
    checkSession,
    userAttributes,
    changePassword,
    firstChangePassword,
  } = useCognitoUserData()
  const [errorMsg, setErrorMsg] = useState("")
  const cx = classNames.bind(styles)

  const isEnabled = () => {
    let isEnabled = true
    requiredFieldNames.forEach(key => {
      if (!formValues[key]) {
        isEnabled = false
      }
    })
    for (const key in formErrors) {
      if (formErrors[key]) {
        isEnabled = false
      }
    }
    return isEnabled
  }

  const handleChange = (value, name) => {
    setFormValues({ ...formValues, [name]: value })
    if (errorMsg) {
      setErrorMsg("")
    }
    if (name === "newPassword" && formValues.confirmPassword) {
      if (value !== formValues.confirmPassword) {
        setFormErrors({
          ...formErrors,
          confirmPassword: "Passwords don't match.",
        })
      } else {
        setFormErrors({
          ...formErrors,
          confirmPassword: false,
          newPassword: false,
        })
      }
    }
  }

  const handleError = (value, name) => {
    setFormErrors({ ...formErrors, [name]: value })
  }

  useEffect(() => {
    //redirect not logged users
    if (sessionChecked && !isLoggedIn() && !tempUserData?.email) {
      navigate(withPrefix("/sign-in"))
    }
  }, [sessionChecked])

  const handleFirstChangePassword = () => {
    firstChangePassword(
      tempUserData["email"],
      formValues["password"],
      formValues["newPassword"]
    )
      .then(() => {
        checkSession()
        navigate(withPrefix("/profile"))
      })
      .catch(error => {
        console.error(error)
        setErrorMsg(error.message)
      })
  }

  const handleChangePassword = () => {
    changePassword(formValues["password"], formValues["newPassword"])
      .then(resp => {
        navigate(withPrefix("/profile"))
      })
      .catch(err => {
        if (err.code === "NotAuthorizedException") {
          return setErrorMsg("Incorrect old password.")
        }
        setErrorMsg(err.message)
      })
  }

  const handleSubmit = useCallback(
    e => {
      if (!isEnabled()) {
        return
      }
      e.preventDefault()
      setErrorMsg("")
      if (formValues["newPassword"] !== formValues["confirmPassword"]) {
        return setErrorMsg("Passwords don't match.")
      }
      const authEmail =
        userAttributes && userAttributes.find(elm => elm?.Name === "email")

      if (tempUserData?.["email"]) {
        handleFirstChangePassword()
      } else if (authEmail) {
        handleChangePassword()
      }
    },
    [tempUserData, userAttributes, formValues]
  )
  return (
    <div className={cx("wrapper")}>
      <div className={cx("container")}>
        <form onSubmit={e => handleSubmit(e)} className={cx("formContainer")}>
          <div className={cx("formHeader")}>
            <h1>Reset your password</h1>
          </div>
          <div className={cx("formFields")}>
            <Password
              name={"password"}
              label={"Old password"}
              placeholder={"Old Password"}
              maxLength={0}
              isRequired={false}
              onChange={e => handleChange(e, "password")}
              onError={e => handleError(e, "password")}
            />
            <Password
              name={"newPassword"}
              label={"New Password"}
              placeholder={"Password"}
              maxLength={0}
              isRequired={false}
              helperText={
                "Min. 8 characters. Mix of numbers, letters and upper and lower cases."
              }
              onChange={e => handleChange(e, "newPassword")}
              onError={e => handleError(e, "newPassword")}
            />
            <Password
              name={"confirmPassword"}
              label={"Confirm Password"}
              placeholder={"Password"}
              maxLength={0}
              isRequired={false}
              onChange={e => handleChange(e, "confirmPassword")}
              onError={e => handleError(e, "confirmPassword")}
              valueToConfirm={formValues.newPassword}
              defaultError={formErrors.confirmPassword}
            />
            {errorMsg && (
              <div>
                <p>{errorMsg}</p>
              </div>
            )}
          </div>
          <div className={cx("formFooter")}>
            <Button
              type={"primary"}
              text={"Reset password"}
              icon={false}
              state={isEnabled() ? "active" : "disabled"}
            />
          </div>
        </form>
      </div>
    </div>
  )
}

ResetPasswordPage.propTypes = {}

ResetPasswordPage.defaultProps = {}

export default ResetPasswordPage
