import React, { useContext } from "react"
import PropTypes from "prop-types"
import { ModalContext } from "../../../context/ModalContext"
import ShareLink from "../../atoms/ShareLink/ShareLink"
import ShareModal from "../../organisms/ShareModal/ShareModal"

const ShareButton = ({ title, linkUrl, onClick, isFull }) => {
  const { openModal } = useContext(ModalContext)

  const handlerOnClick = e => {
    if (onClick) {
      return onClick()
    }
    e.persist()
    if (isFull) {
      openModal({
        variant: "shareComplete",
        top: window.scrollY,
        content: <ShareModal />,
      })
    } else {
      openModal({
        title: "SHARE WITH FRIENDS",
        copy:
          "Start planning for your next whiskey night by sharing with friends over 21.",
        variant: "share",
        linkUrl,
        top: window.scrollY,
      })
    }
  }

  return (
    <ShareLink
      onClick={handlerOnClick}
      text={title}
      type="share"
      icon={true}
      iconAlignment="left"
      url={null}
    />
  )
}

ShareButton.propTypes = {
  title: PropTypes.string,
  linkUrl: PropTypes.string,
  isFull: PropTypes.bool,
}

ShareButton.defaultProps = {
  isFull: false,
}

export default ShareButton
