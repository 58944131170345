import React, { useState } from "react"
import PropTypes from "prop-types"
import classNames from "classnames/bind"
import { useLocation } from "@reach/router"

import DeviceInfo from "../../utils/device-info"

import * as styles from "./recipeHero.module.scss"
import ShareButton from "../ShareButton/ShareButton"
import SaveButton from "../SaveButton/SaveButton"

const RecipeHero = ({
  image,
  category,
  title,
  body,
  infoIcons,
  favoriteCta,
  shareCta,
}) => {
  const { href } = useLocation()

  const cx = classNames.bind(styles)

  return (
    <div className={cx("wrapper")}>
      <img src={image.src} alt={image.alt} />
      <div className={cx("container")}>
        <div className={cx("textColumn")}>
          {category && <h5>{category}</h5>}
          {title && <h2>{title}</h2>}
          {body && <p>{body}</p>}
          {infoIcons && (
            <div className={cx("infoContainer")}>
              {infoIcons.map((item, index) => {
                return (
                  item.icon &&
                  item.text && (
                    <div key={index}>
                      <img src={item.icon} alt={item.text} />
                      <p>{item.text}</p>
                    </div>
                  )
                )
              })}
            </div>
          )}
          <div className={cx("socialContainer")}>
            {favoriteCta && (
              <SaveButton
                title={favoriteCta?.text}
                pieceId={favoriteCta?.pieceId}
                contentData={{ title, type: "recipe" }}
              />
            )}
            {shareCta && <ShareButton title={shareCta?.text} linkUrl={href} />}
          </div>
        </div>
      </div>
    </div>
  )
}

RecipeHero.propTypes = {
  image: PropTypes.object,
  category: PropTypes.string,
  title: PropTypes.string,
  body: PropTypes.string,
  infoIcons: PropTypes.arrayOf(PropTypes.object),
  favoriteCta: PropTypes.object,
  shareCta: PropTypes.object,
}

RecipeHero.defaultProps = {
  image: {
    src: "",
    alt: "",
  },
  category: "",
  title: "",
  body: "",
  infoIcons: null,
  favoriteCta: null,
  shareCta: null,
}

const RecipeHeroWrapper = props => (
  <DeviceInfo>
    {deviceInfo => <RecipeHero deviceInfo={deviceInfo} {...props} />}
  </DeviceInfo>
)

export default RecipeHeroWrapper
