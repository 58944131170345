import React, { useEffect, useState } from "react"
import SearchCategories from "./SearchCategories"
import { useQuery } from "@apollo/client"
import { searchApiQuery } from "../../utils/fragments"
import { withPrefix } from "../../utils/with-prefix"

const index_id = "beam_bourbonu_piece_contents_index"
const resultLimit = 9
const facets = [
  { operator: "=", field: "level", limit: 0, min_count: 0, missing: false },
  { operator: "=", field: "kind", limit: 0, min_count: 0, missing: false },
  { operator: "=", field: "brand", limit: 0, min_count: 0, missing: false },
  { operator: "=", field: "type", limit: 0, min_count: 0, missing: false },
  {
    operator: "=",
    field: "category",
    limit: 0,
    min_count: 1,
    missing: false,
  },
]
const types = {
  BeamBourbonuPieceArticle: "article",
  BeamBourbonuPieceRecipe: "recipe",
  BeamBourbonuPieceVideo: "video",
}

const SearchApiLayout = ({ categories, userFavorites }) => {
  let path = ["/search"]
  const [pagination, setPagination] = useState()
  const [selectedCategory, setSeletedCategory] = useState(categories[0])
  const [selectedOptions, setSelectedOptions] = useState([])
  const [sort, setSort] = useState([{ field: "title", value: "asc" }])
  const [filters, setFilters] = useState()
  const [results, setResults] = useState()
  const [resultsCount, setResultsCount] = useState()
  const [limit, setLimit] = useState(resultLimit)
  let range = { offset: 0, limit: resultLimit }
  const [groups, setGroups] = useState([
    {
      conjunction: "AND",
      conditions: [
        { operator: "=", name: "category", value: selectedCategory.name },
      ],
    },
  ])

  useEffect(() => {
    if (window) {
      path = window.location.pathname.split("/")
      if (path[3] === "videos" || path[3] === "articles") {
        const type = path[3].slice(0, -1)
        setSeletedCategory({ name: "Whiskey Culture" })

        setGroups([
          {
            conjunction: "AND",
            conditions: [
              { operator: "=", name: "category", value: "Whiskey Culture" },
            ],
          },
          {
            conjunction: "OR",
            conditions: [{ operator: "=", name: "type", value: type }],
          },
        ])
      } else if (path[3] === "recipes") {
        setSeletedCategory({ name: "Recipes" })
        setGroups([
          {
            conjunction: "AND",
            conditions: [{ operator: "=", name: "category", value: "Recipes" }],
          },
        ])
      }
    }
  }, [])

  const { data, loading, fetchMore } = useQuery(searchApiQuery, {
    variables: { index_id, facets, sort, range, groups },
  })

  const handleOnLoadMore = () => {
    range = { offset: 0, limit: limit + resultLimit }
    setLimit(limit + resultLimit)
    return fetchMore({
      query: searchApiQuery,
      variables: { index_id, facets, sort, range, groups },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        setResults(fetchMoreResult.searchAPISearch?.documents)
        setResultsCount(fetchMoreResult?.searchAPISearch?.result_count)
      },
    })
  }

  const handleCategoryChange = category => {
    setSeletedCategory(category)
    setGroups([
      {
        conjunction: "AND",
        conditions: [{ operator: "=", name: "category", value: category.name }],
      },
    ])
  }

  const handleFiltersChange = (value, order, name) => {
    if (name === "sort") {
      setSort([{ field: value, value: order }])
    } else if (typeof value === "string") {
      setGroups([
        {
          conjunction: "AND",
          conditions: [
            { operator: "=", name: "category", value: selectedCategory.name },
          ],
        },
        {
          conjunction: "OR",
          conditions: [{ operator: "=", name: name, value: value }],
        },
      ])
    } else {
      const allConditions = value.map(val => {
        return { operator: "=", name: val.name, value: val.value }
      })
      setGroups([
        {
          conjunction: name === "type" ? "AND" : "OR",
          conditions:
            allConditions.length > 0
              ? allConditions
              : [
                  {
                    operator: "=",
                    name: "category",
                    value: selectedCategory.name,
                  },
                ],
        },
      ])
    }
  }

  const clearAllFilters = e => {
    e.preventDefault()
    setSelectedOptions([])
    setGroups([
      {
        conjunction: "AND",
        conditions: [
          { operator: "=", name: "category", value: selectedCategory.name },
        ],
      },
    ])
  }

  const updateState = value => {
    setSelectedOptions(value)
  }

  useEffect(() => {
    setFilters(data?.searchAPISearch?.facets)
    setResults(data?.searchAPISearch?.documents)
    setResultsCount(data?.searchAPISearch?.result_count)
  }, [data])

  useEffect(() => {
    setPagination(resultsCount > limit)
  }, [resultsCount, limit])

  const favorieteResults = userFavorites?.map(({ entity }) => ({
    type: types[entity?.__typename],
    pieceId: entity?.entityId,
    videofeatured:
      entity?.fieldBeamBourbonuImage?.entity.fieldBeamBourbonuImage.url,
    articlefeatured:
      entity?.fieldBeamBourbonuImages &&
      entity?.fieldBeamBourbonuImages[0]?.entity.image.url,
    url: withPrefix(entity?.entityUrl.path),
    eyebrow: "",
    title: entity?.title,
    description: entity?.description.value,
    iconlevel: "",
    level: "",
    iconduration: "",
    duration: "",
    iconkind: "",
    kind: "",
  }))

  return (
    <SearchCategories
      allFilters={filters}
      categories={categories}
      selected={selectedCategory}
      favoritePieces={favorieteResults}
      onChange={value => handleCategoryChange(value)}
      onChangeFilters={(value, name, order) =>
        handleFiltersChange(value, name, order)
      }
      results={results}
      showPagination={pagination}
      handleOnLoadMore={() => handleOnLoadMore()}
      loading={loading}
      selectedOptions={selectedOptions}
      updateState={e => updateState(e)}
      clearAllFilters={e => clearAllFilters(e)}
    />
  )
}

export default SearchApiLayout
