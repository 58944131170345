import React from "react"
import classNames from "classnames/bind"
import MultipleDropdown from "./../Dropdown/MultipleDropdown"

import * as styles from "./filterDropdown.module.scss"

const FilterDropdown = ({
  filters,
  label,
  onChange,
  isShow,
  selectedOptions,
  updateState,
  filterName,
}) => {
  const cx = classNames.bind(styles)

  return (
    <div
      className={cx(
        "filterDropdown",
        label === "Sort" ? "sort" : "",
        isShow ? "show" : ""
      )}
    >
      {!isShow && label !== "Sort" ? (
        <h4>Filter</h4>
      ) : isShow && label !== "Sort" ? (
        <h4>Show</h4>
      ) : (
        <h4>Sort</h4>
      )}
      <MultipleDropdown
        label={label}
        filters={filters}
        onSelect={(value, order) => onChange(value, order, label)}
        selectedOptions={selectedOptions}
        updateState={e => updateState(e)}
        filterName={filterName}
        multiple={!isShow && label !== "Sort" ? true : false}
      />
    </div>
  )
}

export default FilterDropdown
