import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import classNames from "classnames/bind"

import SaveModal from "./variants/SaveModal"
import ShareModal from "./variants/ShareModal"
import OptInModal from "./variants/OptInModal"

import * as styles from "./modal.module.scss"
import { useMutation } from "@apollo/client"
import { SignUpFfyb } from "../../utils/fragments"
import { useQuery } from "../../../hooks/useQuery"
import { navigate } from "gatsby-link"
import { withPrefix } from "../../utils/with-prefix"
import DeviceInfo from "../../utils/device-info"

const cx = classNames.bind(styles)

const COMPONENTS = {
  save: SaveModal,
  share: ShareModal,
  optIn: OptInModal,
}

const Modal = ({
  visible,
  onClose,
  secondaryCta,
  primaryCta,
  title,
  copy,
  variant,
  linkUrl,
  deviceInfo,
}) => {
  const [sendResult, { data }] = useMutation(SignUpFfyb)
  const locationId = useQuery("location")
  const [acceptTerms, setAcceptTerms] = useState(false)

  const sendOptIn = () => {
    sendResult({
      variables: {
        ffyb: {
          locationId,
          notificationsAccepted: acceptTerms,
        },
      },
    })
    .then(()=> {
      navigate(withPrefix("profile"))
    })
  }

  useEffect(() => {
    if (data) {
      navigate(withPrefix("profile"))
    }
  }, [data])

  useEffect(() => {
    if (visible) {
      //required for iphone safari
      if (deviceInfo.isSafari) {
        document.documentElement.style.overflow = "hidden"
      } else {
        //fix scroll during breaker animation
        document.body.style.overflow = "hidden"
      }
    }
    return () => {
      if (deviceInfo.isSafari) {
        document.documentElement.style.overflow = "unset"
      } else {
        document.body.style.overflow = "unset"
      }
    }
  }, [visible])

  const closeClassName = cx({
    close: true,
    big: variant === "share",
  })

  const modalClassName = cx({
    modal: true,
    [variant]: variant,
  })

  const handleClose = () => {
    if(variant === 'optIn') {
     sendOptIn()
    }
    onClose()
  }

  const ModalComponent = COMPONENTS[variant]

  return visible ? (
    <div className={cx("wrapper")}>
      <div className={cx("container")}>
        <button className={closeClassName} onClick={handleClose}>
          <div className={cx("cross")} />
          <div>Close</div>
        </button>
        <div className={modalClassName}>
          <div className={cx("bodyContainer")}>
            <ModalComponent
              title={title}
              copy={copy}
              linkUrl={linkUrl}
              secondaryCta={secondaryCta}
              primaryCta={primaryCta}
              handleClose={handleClose}
              acceptTerms={acceptTerms}
              setAcceptTerms={setAcceptTerms}
            />
          </div>
        </div>
      </div>
    </div>
  ) : null
}

Modal.propTypes = {
  title: PropTypes.string,
  copy: PropTypes.string,
  visible: PropTypes.bool,
  primaryCta: PropTypes.object,
  secondaryCta: PropTypes.object,
  variant: PropTypes.oneOf(["share", "save", "optIn"]),
}

Modal.defaultProps = {
  visible: false,
  children: null,
  title: "",
  copy: "",
  primaryCta: {},
  secondaryCta: {},
  variant: "share",
}

const ModalWrapper = props => (
  <DeviceInfo>
    {deviceInfo => <Modal deviceInfo={deviceInfo} {...props} />}
  </DeviceInfo>
)

export default ModalWrapper
