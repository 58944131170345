// extracted by mini-css-extract-plugin
export var link = "shareLink-module--link--3T2Ds";
export var iconOnly = "shareLink-module--iconOnly--2H-1b";
export var iconLeft = "shareLink-module--iconLeft--2VtmR";
export var iconRight = "shareLink-module--iconRight--1-kkU";
export var favoriteIcon = "shareLink-module--favoriteIcon--3sXaC";
export var selectedFavoriteIcon = "shareLink-module--selectedFavoriteIcon--1bzoI";
export var shareIcon = "shareLink-module--shareIcon--2lMvx";
export var shopIcon = "shareLink-module--shopIcon--1uzz7";
export var positive = "shareLink-module--positive--3_qVf";
export var accent = "shareLink-module--accent--1AkGg";
export var disabled = "shareLink-module--disabled--3OQtz";
export var golden = "shareLink-module--golden--1El1b";