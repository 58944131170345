import React, { useRef } from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import classNames from "classnames/bind"

import * as styles from "./shareLink.module.scss"

const ShareLink = ({
  type,
  variant,
  state,
  icon,
  text,
  url,
  target,
  iconAlignment,
  onClick,
  selected,
}) => {
  const linkRef = useRef(null)

  const cx = classNames.bind(styles)

  const linkClassName = cx({
    ["disabled"]: state === "disabled",
    ["positive"]: variant === "positive",
    ["accent"]: variant === "accent",
    ["golden"]: state === "loading" || state === "confirmed",
    ["iconOnly"]: !text,
    ["iconLeft"]: iconAlignment === "left",
    ["iconRight"]: iconAlignment === "right",
  })

  const iconClassName = cx({
    ["favoriteIcon"]: icon && type === "favorite" && !selected,
    ["selectedFavoriteIcon"]: icon && type === "favorite" && selected,
    ["shareIcon"]: icon && type === "share",
    ["shopIcon"]: icon && type === "shop",
  })

  let label = text

  const handlerOnClick = e => {
    onClick(e)
    linkRef.current.blur()
  }

  return (
    <div className={cx("link")} onClick={handlerOnClick}>
      {url ? (
        <Link
          data-gtm-action={selected ? "unfavorite" : type}
          ref={linkRef}
          className={linkClassName}
          to={url}
          target={target}
        >
          {icon && <i className={iconClassName} />}
          <h4>{label}</h4>
        </Link>
      ) : (
        <button
          data-gtm-action={selected ? "unfavorite" : type}
          ref={linkRef}
          className={linkClassName}
        >
          {icon && <i className={iconClassName} />}
          <h4>{label}</h4>
        </button>
      )}
    </div>
  )
}

ShareLink.propTypes = {
  type: PropTypes.oneOf(["favorite", "share", "shop"]),
  variant: PropTypes.oneOf(["positive", "accent"]),
  state: PropTypes.oneOf(["active", "disabled"]),
  icon: PropTypes.bool,
  iconAlignment: PropTypes.oneOf(["right", "left"]),
  text: PropTypes.string,
  url: PropTypes.string,
  target: PropTypes.oneOf(["_blank", "_self"]),
  onClick: PropTypes.func,
  selected: PropTypes.bool,
}

ShareLink.defaultProps = {
  type: "",
  variant: "positive",
  state: "active",
  icon: false,
  text: "",
  target: "_self",
  iconAlignment: "left",
  onClick: () => {},
  selected: false,
}

export default ShareLink
