import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames/bind"

import Button from "../../atoms/Button/Button"

import DeviceInfo from "../../utils/device-info"
import { EVENTS } from "../../utils/reports"

import * as styles from "./slideCard.module.scss"
import SaveButton from "../SaveButton/SaveButton"

const SlideCardTextColumn = ({
  bottleId,
  bottleName,
  bottleDescription,
  cta,
  proof,
  age,
  price,
  flavorNotes,
  slide,
  totalSlides,
  productId,
  deviceInfo,
}) => {
  const cx = classNames.bind(styles)
  return (
    <div className={cx("textCol")}>
      {!deviceInfo.isMobile && (
        <div className={cx("carouselNavigationController")}>
          <h4>Top pick</h4>
          <div>
            <h4>{`(${slide}/${totalSlides})`}</h4>
          </div>
        </div>
      )}
      <h2 className={cx("bottleName")}>{bottleName}</h2>
      <p>{bottleDescription}</p>
      {productId && (
        <div className={cx("ctasContainer")}>
          <bsi-locator-buy-button
            product={productId}
            language="EN_US"
            theme="jimbeam"
          >
            <Button
              {...cta}
              trackingObjects={[
                {
                  event: EVENTS.BUY_NOW_CLICK,
                  bottle: bottleName,
                },
              ]}
            />
          </bsi-locator-buy-button>
          <SaveButton
            pieceId={parseInt(bottleId)}
            text="Add to favorites"
            bottle={true}
          />
        </div>
      )}
      <div className={cx("keyFeatures")}>
        <h4>Key Features</h4>
        <ul>
          <li>
            <h3>{proof}</h3>
            <h5>Proof</h5>
          </li>
          <span />
          <li>
            <h3>{age}</h3>
            <h5>Aged</h5>
          </li>
          <span />
          <li>
            <h3>{price}</h3>
            <h5>Price</h5>
          </li>
        </ul>
      </div>
      <div className={cx("flavorNotes")}>
        <h4>Dominant Flavor notes</h4>
        {flavorNotes.map((flavorNote, index) => (
          <p key={`flavornote-${index}`}>{flavorNote}</p>
        ))}
      </div>
    </div>
  )
}

SlideCardTextColumn.propTypes = {
  bottleName: PropTypes.string,
  bottleDescription: PropTypes.string,
  cta: PropTypes.object,
  productId: PropTypes.string,
  proof: PropTypes.string,
  age: PropTypes.string,
  price: PropTypes.string,
  flavorNotes: PropTypes.arrayOf(PropTypes.string),
  deviceInfo: PropTypes.object,
  slide: PropTypes.string,
  totalSlides: PropTypes.string,
}

SlideCardTextColumn.defaultProps = {
  bottleName: "",
  bottleDescription: "",
  cta: {},
  proof: "",
  age: "",
  price: "",
  flavorNotes: [],
  onPrevClick: () => {},
  onNextClick: () => {},
  slide: "1",
  totalSlides: "1",
}

const SlideCardWrapper = props => (
  <DeviceInfo>
    {deviceInfo => <SlideCardTextColumn deviceInfo={deviceInfo} {...props} />}
  </DeviceInfo>
)

export default SlideCardWrapper
