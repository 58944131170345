import { useContext, useEffect, useState, useCallback } from "react"
import { CognitoSessionContext } from "../../context/CognitoSessionContext"
import * as AmazonCognitoIdentity from "amazon-cognito-identity-js"
import * as AWS from "aws-sdk/global"
import {
  lastQuizOptions,
  lastRegistrationSource,
} from "./session-variable";
import { GetUserInformation } from "./fragments";
import { useLazyQuery } from "@apollo/client"

function useCognitoUserData() {
  const {
    tempUserData,
    setTempUserData,
    userAttributes,
    setUserAttributes,
    setJwt,
    jwt,
    setSessionChecked,
    sessionChecked,
    updateSession,
    setUpdateSession,
  } = useContext(CognitoSessionContext)
  //check session

  const [getUserInformation, { data:userData, loading, error }] = useLazyQuery(
    GetUserInformation
  )

  const poolData = {
    UserPoolId: process.env.GATSBY_AWS_USER_POOL_ID,
    ClientId: process.env.GATSBY_AWS_CLIENT_ID,
  }

  const clearTempData = () => {
    setTempUserData(null)
  }

  const isLoggedIn = useCallback(() => {
    return !!userAttributes
  }, [userAttributes])

  const cleanSession = () => {
    setUserAttributes(false)
    setJwt("")
  }

  const checkSession = () => {
    setSessionChecked(false)
    const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData)
    const cognitoUser = userPool.getCurrentUser()
    if (cognitoUser != null) {
      cognitoUser.getSession(function (err, session) {
        if (err || !session.isValid()) {
          console.error(err.message || JSON.stringify(err))
          cleanSession()
          setSessionChecked(true)
          return
        }
        cognitoUser.getUserAttributes(function (err, attributes) {
          if (err) {
            console.error("getUserAttributes error: ", err)
            cleanSession()
          } else {
            setUserAttributes(attributes)
            clearTempData()
          }
          const jwt = session.getIdToken().getJwtToken()
          setJwt(jwt)
          setSessionChecked(true)
        })
        const loginData = {}
        loginData[
          `cognito-idp.${process.env.GATSBY_AWS_REGION}.amazonaws.com/${process.env.GATSBY_AWS_USER_POOL_ID}`
        ] = session.getIdToken().getJwtToken()
        AWS.config.region = process.env.GATSBY_AWS_REGION

        //aws credentials update
        AWS.config.credentials = new AWS.CognitoIdentityCredentials({
          Logins: loginData,
        })
        if (AWS.config.credentials.needsRefresh()) {
          const refresh_token = session.getRefreshToken()
          cognitoUser.refreshSession(refresh_token, (err, session) => {
            if (err) {
              clearTempData()
            } else {
              const jwt = session.getIdToken().getJwtToken()
              AWS.config.credentials.params.Logins[
                `cognito-idp.${process.env.GATSBY_AWS_REGION}.amazonaws.com/${process.env.GATSBY_AWS_USER_POOL_ID}`
              ] = jwt
              setJwt(jwt)
            }
          })
        }
      })
    } else {
      setSessionChecked(true)
    }
  }
  const firstChangePassword = (email, oldPassword, newPassword) => {
    return new Promise((resolve, reject) => {
      const authData = {
        Username: email,
        Password: oldPassword,
      }
      const authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails(
        authData
      )
      const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData)
      const userData = {
        Username: email,
        Pool: userPool,
      }
      const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData)

      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: function (resp) {
          setUpdateSession(true)
          resolve(resp)
        },
        newPasswordRequired: function (userAttributes) {
          cognitoUser.completeNewPasswordChallenge(
            newPassword,
            {},
            {
              onSuccess: user => {
                resolve(user)
              },
              onFailure: error => {
                reject(error)
              },
            }
          )
        },
        onFailure: function (err) {
          reject(err)
        },
      })
    })
  }
  const changePassword = (oldPassword, newPassword) => {
    return new Promise((res, rej) => {
      const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData)
      const cognitoUser = userPool.getCurrentUser()
      cognitoUser.getSession(function (err, session) {
        if (err || !session.isValid()) {
          cleanSession()
          return rej(err)
        }
        cognitoUser.changePassword(oldPassword, newPassword, (err, result) => {
          if (err) {
            return rej(err)
          }
          res(result)
        })
      })
    })
  }

  const signOut = () => {
    const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData)
    const cognitoUser = userPool.getCurrentUser()
    if (cognitoUser != null) {
      cognitoUser.signOut()
      setUpdateSession(true)
      cleanSession()

      if (window.beamFrontendCurrentUser) {
        window.beamFrontendCurrentUser.update(null)
      }
    }
    lastQuizOptions().clear()
    lastRegistrationSource().clear()
  }

  const signUp = (email, password) => {
    const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData)

    const attributeList = []

    const dataEmail = {
      Name: "email",
      Value: email,
    }

    const attributeEmail = new AmazonCognitoIdentity.CognitoUserAttribute(
      dataEmail
    )

    attributeList.push(attributeEmail)

    return new Promise((resolve, reject) => {
      userPool.signUp(
        email,
        password,
        attributeList,
        null,
        function (err, result) {
          if (err) {
            return reject(err)
          }
          resolve(result)
        }
      )
    })
  }

  const updateUser = email => {
    return new Promise((res, rej) => {
      const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData)
      const cognitoUser = userPool.getCurrentUser()
      cognitoUser.getSession(function (err, session) {
        if (err || !session.isValid()) {
          cleanSession()
          return rej(err)
        }

        const dataEmail = {
          Name: "email",
          Value: email,
        }

        const attributeEmail = new AmazonCognitoIdentity.CognitoUserAttribute(
          dataEmail
        )

        cognitoUser.updateAttributes([attributeEmail], (err, result) => {
          if (err) {
            return rej(err)
          }
          res(result)
        })
      })
    })
  }

  const forgotPassword = email => {
    const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData)
    const cognitoUser = new AmazonCognitoIdentity.CognitoUser({
      Username: email,
      Pool: userPool,
    })
    return new Promise((resolve, reject) => {
      cognitoUser.forgotPassword({
        onSuccess: function (data) {
          resolve(data)
        },
        onFailure: function (err) {
          reject(err)
        },
        inputVerificationCode: function (data) {
          resolve(data)
        },
      })
    })
  }
  const confirmResetPassword = (email, verificationCode, newPassword) => {
    const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData)
    const cognitoUser = new AmazonCognitoIdentity.CognitoUser({
      Username: email,
      Pool: userPool,
    })
    return new Promise((resolve, reject) => {
      cognitoUser.confirmPassword(verificationCode, newPassword, {
        onSuccess() {
          resolve()
        },
        onFailure(err) {
          reject(err)
        },
      })
    })
  }

  const signIn = (email, password, onPasswordRequired = () => {}) => {
    const authData = {
      Username: email,
      Password: password,
    }
    const authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails(
      authData
    )
    const poolData = {
      UserPoolId: process.env.GATSBY_AWS_USER_POOL_ID,
      ClientId: process.env.GATSBY_AWS_CLIENT_ID,
    }
    const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData)
    const userData = {
      Username: authData.Username,
      Pool: userPool,
    }
    const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData)
    return new Promise((resolve, reject) => {
      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: function () {
          getUserInformation();
          checkSession()
          setUpdateSession(true)
          resolve()
        },
        newPasswordRequired: onPasswordRequired,
        onFailure: function (err) {
          reject(err)
        },
      })
    })
  }
  useEffect(() => {
    if (poolData.UserPoolId && poolData.ClientId) {
      checkSession()
    } else {
      cleanSession()
    }
  }, [])

  return {
    sessionChecked,
    isLoggedIn,
    tempUserData,
    clearTempData,
    setTempUserData,
    userAttributes,
    setUserAttributes,
    signOut,
    checkSession,
    setJwt,
    jwt,
    changePassword,
    firstChangePassword,
    signUp,
    updateUser,
    setUpdateSession,
    updateSession,
    forgotPassword,
    confirmResetPassword,
    signIn,
    userData
  }
}

export default useCognitoUserData
