import React, { useState } from "react"
import PropTypes from "prop-types"
import classNames from "classnames/bind"

import * as styles from "./recipeMoreCocktails.module.scss"
import ContentCardsContainer from "../ContentCardsContainer/ContentCardsContainer"
import LPImageHero from "../LPImageHero/LPImageHero"
import DeviceInfo from "../../utils/device-info"

const RecipeMoreCocktails = ({ cards, imageHero, deviceInfo }) => {
  const [isClosed, setIsClosed] = useState(false)

  const cx = classNames.bind(styles)

  const iconClassname = cx({
    ["accordionOpened"]: !isClosed,
    ["accordionClosed"]: isClosed,
  })

  return (
    <div className={cx("container")}>
      <div className={cx("cardsContainer")}>
        <h4
          onClick={() => {
            deviceInfo.isMobile && setIsClosed(!isClosed)
          }}
        >
          other cocktails you might enjoy{" "}
          {deviceInfo.isMobile && <i className={iconClassname} />}
        </h4>
        {!isClosed && cards && <ContentCardsContainer cards={cards} />}
      </div>
      {!isClosed && imageHero && (
        <div className={cx("promoContainer")}>
          <LPImageHero {...imageHero} halfWidthColumn={true} />
        </div>
      )}
    </div>
  )
}

RecipeMoreCocktails.propTypes = {
  cards: PropTypes.array,
  imageHero: PropTypes.object,
}

RecipeMoreCocktails.defaultProps = {
  cards: null,
  imageHero: null,
}
const RecipeMoreCocktailsWrapper = props => (
  <DeviceInfo>
    {deviceInfo => <RecipeMoreCocktails deviceInfo={deviceInfo} {...props} />}
  </DeviceInfo>
)

export default RecipeMoreCocktailsWrapper
