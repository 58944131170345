import React, { createContext, useState } from "react"
import PropTypes from "prop-types"

import Modal from "../components/molecules/Modal/Modal"
import { withPrefix } from "../components/utils/with-prefix"

import LargeModal from "../components/molecules/LargeModal/LargeModal"

export const ModalContext = createContext()

export const ModalProvider = ({ children }) => {
  const [variant, setVariant] = useState("save")
  const [title, setTitle] = useState()
  const [copy, setCopy] = useState()
  const [linkUrl, setLinkUrl] = useState()
  const [visible, setVisible] = useState(false)
  const [top, setTop] = useState(0)
  const [content, setContent] = useState(null)
  const [shareData, setShareData] = useState(null)

  const openModal = ({
    variant,
    title,
    copy,
    linkUrl,
    top,
    content = null,
  }) => {
    setVariant(variant)
    setTitle(title)
    setCopy(copy)
    setLinkUrl(linkUrl)
    setTop(top)
    setContent(content)
    setVisible(true)
  }

  const closeModal = () => {
    setVisible(false)
  }

  const getShareCompleteData = () => {
    return shareData
  }
  const setShareCompleteData = newShareData => {
    setShareData(newShareData)
  }

  return (
    <ModalContext.Provider
      value={{
        openModal,
        closeModal,
        getShareCompleteData,
        setShareCompleteData,
        visible,
      }}
    >
      {(variant === "save" || variant === "share") && (
        <Modal
          variant={variant}
          title={title}
          copy={copy}
          linkUrl={linkUrl}
          visible={visible}
          onClose={closeModal}
          primaryCta={{
            text: "Create an account",
            url: withPrefix("/sign-up"),
            type: "primary",
            variant: "positive",
            icon: false,
            iconAlignment: "",
            onClick: closeModal,
          }}
          secondaryCta={{
            text: "Sign In",
            url: withPrefix("/sign-in"),
            type: "secondary",
            variant: "positive",
            onClick: closeModal,
          }}
        />
      )}
      {variant === "optIn" && (
        <Modal
          top={top}
          variant={variant}
          title={title}
          copy={copy}
          visible={visible}
          onClose={closeModal}
          primaryCta={{
            text: "submit",
            type: "primary",
            variant: "positive",
            icon: false,
            iconAlignment: "",
            onClick: closeModal,
          }}
        />
      )}
      {(variant === "delete" || variant === "shareComplete") && (
        <LargeModal
          onClose={closeModal}
          visible={visible}
          top={top}
          size={variant === "shareComplete" ? "medium" : "big"}
        >
          {content}
        </LargeModal>
      )}
      {children}
    </ModalContext.Provider>
  )
}

ModalProvider.propTypes = {
  children: PropTypes.node,
}
