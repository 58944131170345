import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames/bind"

import SocialMedia from "../../../atoms/SocialMedia/SocialMedia"
import CopyLink from "../../../atoms/CopyLink/CopyLink"

import * as styles from "../modal.module.scss"

const cx = classNames.bind(styles)

const ShareModal = ({ title, copy, linkUrl }) => {
  const handlePopup = (e, url) => {
    const height = 600 < window.innerHeight ? 600 : window.innerHeight
    const width = 600 < window.innerWidth ? 600 : window.innerWidth
    window.open(url, "popup", "height=" + height + ",width=" + width)
  }
  return (
    <>
      <h2>{title}</h2>
      <p>{copy}</p>
      <div className={cx("socialMedia")}>
        <a
          data-gtm-social-share="twitter"
          data-gtm-url={linkUrl}
          target="popup"
          href={`http://twitter.com/share?url=${linkUrl}`}
          onClick={e =>
            handlePopup(e, `http://twitter.com/share?url=${linkUrl}`)
          }
        >
          <SocialMedia variant="twitter" />
        </a>
        <a
          data-gtm-social-share="facebook"
          data-gtm-url={linkUrl}
          target="popup"
          href={`http://www.facebook.com/sharer.php?u=${linkUrl}`}
          onClick={e =>
            handlePopup(e, `http://www.facebook.com/sharer.php?u=${linkUrl}`)
          }
        >
          <SocialMedia variant="facebook" />
        </a>
      </div>
      <CopyLink url={linkUrl} />
    </>
  )
}

ShareModal.propTypes = {}
ShareModal.defaultProps = {}

export default ShareModal
