import React, { useEffect, useRef, useState } from "react"
import PropTypes, { element } from "prop-types"
import classNames from "classnames/bind"

import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { Draggable } from "gsap/Draggable"

import Button from "../../atoms/Button/Button"

import DeviceInfo from "../../utils/device-info"
import { EVENTS, reports } from "../../utils/reports"

import * as styles from "./parallaxLockupWithCarousel.module.scss"
import ContentCard from "../../molecules/ContentCard/ContentCard"

const ParallaxLockupWithCarousel = ({
  deviceInfo,
  title,
  eyebrow,
  subcopy,
  image,
  cta,
  slides,
  animation,
  carouselPosition,
  hasDots,
  clickableDots,
  variant,
}) => {
  const [width, setWidth] = useState(0)
  const [currentSlide, setCurrentSlide] = useState(0)
  const [dragStart, setDragStart] = useState(0)
  const [dragEnd, setDragEnd] = useState(0)
  const [animationTimeline, setAnimationTimeline] = useState(null)
  const containerRef = useRef(null)
  const contentRef = useRef(null)
  const carouselBlockRef = useRef(null)
  const carouselWrapperRef = useRef(null)
  const carouselBackgroundRef = useRef(null)
  const imagePreload = [];


  const cx = classNames.bind(styles)

  useEffect(() => {
    updateSlideSize()
    window.addEventListener("resize", updateSlideSize)
    updateSlideSize()
    return () => window.removeEventListener("resize", updateSlideSize)
  }, [])

  useEffect(() => {
    updateSlideSize()
    window.addEventListener("orientationchange", updateSlideSize)
    updateSlideSize()
    return () =>
      window.removeEventListener("orientationchange", updateSlideSize)
  }, [])

  useEffect(() => {
    updateSlideSize()
    prepareAnimationTimeline()
  }, [carouselBlockRef.current])

  useEffect(() => {
    if (dragStart > dragEnd) {
      // move foward
      if (currentSlide + 1 < slides.length) {
        goToSlide(currentSlide + 1)
        setCurrentSlide(currentSlide + 1)
      }
    } else {
      // move back
      if (currentSlide - 1 >= 0) {
        goToSlide(currentSlide - 1)
        setCurrentSlide(currentSlide - 1)
      }
    }
  }, [dragEnd])

  const prepareAnimationTimeline = () => {
    if (
      containerRef?.current &&
      carouselWrapperRef.current &&
      animationTimeline === null &&
      width !== 0
    ) {
      const pinDuration = slides.length * (animation === "fade-in" ? 500 : 300)
      const elementHeight = containerRef?.current.clientHeight
      const spacingTop =
        containerRef?.current.getBoundingClientRect().top > 0
          ? containerRef?.current.getBoundingClientRect().top -
          elementHeight / 2
          : 0

      let tl
      let slidesCount = 100 / slides.length

      if (!deviceInfo.isMobile) {
        tl = gsap.timeline({
          scrollTrigger: {
            trigger: containerRef?.current,
            toggleActions: "play none none pause",
            start: variant === "card-slide" ? `top+=10 top` : "bottom bottom",
            scrub: true,
            end: "110%",
            pin: true,
            pinSpacing: "margin",
            //markers: true,
            onUpdate: () => updateCurrent(tl.progress()),
          },
        })
      } else {
        tl = gsap.timeline()
      }

      const wrapper = carouselWrapperRef.current
      const children = wrapper.children
      if (animation === "vertical-slide") {
        if (slides[0] && slides[0].background) {
          const initilBackground = `url("${slides[0].background?.url || slides[0].background
            }")`

          carouselBackgroundRef.current.style.backgroundImage = initilBackground
          //carouselBlockRef.current.style.backgroundImage = initilBackground

        }
      }

      const shadow =
        deviceInfo.viewport === "small"
          ? "0px 0px 95.1965px rgba(181, 140, 88, 0.3)"
          : "0px 0px 170.597px rgba(181, 140, 88, 0.3)"
      if (variant === "carousel-C" && animation === "switch") {
        tl.set(children[0], { boxShadow: shadow })
      }



      slides.map((slide, index) => {
        switch (animation) {
          case "vertical-slide":
            if (slide.background) {
              if (imagePreload[index] == null) {
                let img = new Image();
                img.src = slide.background?.url || slide.background
                imagePreload[index] = img;
              }
              // set current slide's background to back background
              if (imagePreload[index]) {
                tl.set(carouselBackgroundRef.current, {
                  backgroundImage: `url("${imagePreload[index].src
                    }")`,
                })
              }
            }

            let offsetTop = children[index].offsetTop
            let wrapperHeight = wrapper.clientHeight
           

            if (
              children[index].clientHeight <
              carouselBlockRef.current.clientHeight
            ) {
              offsetTop = carouselBlockRef.current.clientHeight * index
            }

            if (deviceInfo.viewport === "small") {
              offsetTop = 357 * index
            }

            if(index === 0 && !hasDots) {
              tl.fromTo(
                wrapper, 
                {
                  top: '100%'
                },              
                {
                  top: `${offsetTop}px`,
                  duration: 1
                },
                `0%`
              )
              tl.fromTo(
                carouselBackgroundRef.current,
                {
                  opacity: 0
                },
                {
                  opacity: 1,
                  duration: 0.5
                }
              )
            }

            tl.set(carouselBackgroundRef.current, {
              backgroundImage: `url("${imagePreload[index].src
                }")`,
            },`${slidesCount * index}%`)

            tl.to(
              wrapper,
              {
                top: `${offsetTop * -1}px`,
                duration: 1,
               
              },
              `${slidesCount * index}%`
            )


            if(index === slides.length -1 && !hasDots) {
              tl.to(
                wrapper, {
                  top: `${offsetTop*-1}px`,
                  duration: 1
                },
                `98%`
              )
            }

            break
          case "horizontal-slide":
            tl.fromTo(
              children[index],
              {
                transform: `translateX(0px)`,
              },
              {
                transform: `translateX(${children[index].clientWidth * index * -1
                  }px)`,
                duration: 1 * index + 0.5,
              }
            )
            break
          case "fade-in":
            tl.from(children[index], { boxShadow: "none", duration: 0 })
            tl.to(children[index], { opacity: 1 })
            break
          case "switch":
            if (variant === "carousel-C") {
              tl.set(children, { boxShadow: "none" })
              tl.set(children[0], { boxShadow: shadow })
            }
            tl.fromTo(
              children[index],

              { zIndex: -1 * index },
              { zIndex: 1 + index, duration: 1 * index + 0.5 }
            )
            break
          default:
            tl.to(children[index], 1 * index + 0.5, { opacity: 1 })
            break
        }
        tl.addLabel(`slide-${index}`)
        if (deviceInfo.isMobile) {
          tl.pause(0)
          if(animation === "vertical-slide") {
            tl.repeatDelay(1)
            tl.repeat(-1)
            tl.play()
          }
        } 
        //tl.scrollTrigger.refresh()
      })

      setAnimationTimeline(tl)
      if (animation !== "vertical-slide") {
        // add Drag handler to swipe through carousel
        Draggable.create(carouselWrapperRef.current, {
          type: "x",
          edgeResistance: 0.5,
          dragResistance: 0.95,
          bounds: {
            minX: 0,
            maxX: 0,
          },
          lockAxis: true,
          onDragStart: args => {
            setDragStart(args.clientX)
          },
          onDragEnd: args => {
            setDragEnd(args.clientX)
          },
        })
      } 
    }
  }

  const updateCurrent = progress => {
    const snapVal = 1 / slides.length
    const current = Math.round(progress / snapVal)
    if (current < slides.length) {
      setCurrentSlide(current)
    }
  }

  const updateSlideSize = () => {
    const blockRef = carouselBlockRef.current
    if (blockRef) {
      const newWidth = blockRef.clientWidth
      const smallViewport = deviceInfo.viewport === "small"
      if (variant !== "carousel-C") {
        // set default height
        let newHeight = smallViewport ? 397 : 620
        if (variant === "carousel-A") {
          if (smallViewport) {
            newHeight = 364
          }
        }
        if (hasDots) {
          // to prevent sub-scroll
          newHeight -= 40
        }
        gsap.to(blockRef, {
          height: `${newHeight}px`,
          maxHeight: smallViewport ? `${newHeight}px` : `80vh`,
        })
      } else {
        const container = containerRef?.current
        const minHeight =
          container.clientHeight < 850
            ? `${container.clientHeight * 1.125}px`
            : "100vh"
        const newHeight = smallViewport
          ? `${container.scrollHeight}px`
          : minHeight
        gsap.to(container, {
          minHeight: newHeight,
        })
      }
      setWidth(newWidth)
      if (animation === "horizontal-slide" && carouselWrapperRef.current) {
        carouselWrapperRef.current.style.width = `${newWidth * slides.length}px`
      }
    }
    if (animationTimeline && animationTimeline.scrollTrigger) {
      animationTimeline.scrollTrigger.refresh()
    }
  }

  const goToSlide = slide => {
    if (!deviceInfo.isMobile) {
      // if (animation === "horizontal-slide") {
      /**
       * force scroll left to 0 to prevent container from scrolling
       * on auto scrollintoview on tab action
       */
      carouselWrapperRef.current.parentNode.scrollLeft = 0
      // }
      // get pin duration
      const pinDuration =
        animationTimeline.scrollTrigger.end -
        animationTimeline.scrollTrigger.start
      // get last slide time
      const time = animationTimeline.labels[`slide-${slides.length - 1}`]
      // get stepDuration
      const stepDuration =
        pinDuration * ((animationTimeline.labels[`slide-${slide}`] * 1) / time)
      // move scroll to current slide
      gsap.to(window, {
        duration: 1,
        scrollTo: animationTimeline.scrollTrigger.start + stepDuration - 1,
        ease: "linear",
        onComplete: () => setCurrentSlide(slide),
      })
    } else {
      if (slide === 0) {
        animationTimeline.tweenFromTo(
          animationTimeline.duration() * 0.7,
          animationTimeline.duration()
        )
        setCurrentSlide(1)
      } else {
        animationTimeline.tweenFromTo(
          animationTimeline.duration(),
          animationTimeline.duration() * 0.7
        )
        setCurrentSlide(0)
      }
    }
  }

  return (
    <div
      className={cx("container", {
        carouselOnLeft: carouselPosition === "left",
        pieceCarousel: variant === "content-card",
      })}
      ref={containerRef}
    >
      <div className={cx("content")} ref={contentRef}>
        <div className={cx("grid")}>
          <div className={cx("textBlock")}>
            {eyebrow && <span className={cx("eyebrowTag")}>{eyebrow}</span>}
            <h6 className={cx("titleTag")}>{title}</h6>
            {subcopy && <p className={cx("copyTag")}>{subcopy}</p>}
            {cta && (
              <Button
                {...cta}
                trackingObjects={[
                  {
                    event: EVENTS.QUIZ_RESTART,
                  },
                ]}
              />
            )}
          </div>
          <div
            className={cx("carouselBlock", {
              ["square"]: variant === "carousel-C",
              ["piece"]: variant === "content-card",
            })}
            ref={carouselBlockRef}
          >
            <div
              className={cx("carouselContainer", {
                ["circular"]: variant === "carousel-C",
              })}
            >
              <div
                className={cx("carouselBackground")}
                ref={carouselBackgroundRef}
              />
              <div
                className={cx("carouselWrapper", {
                  ["vertical"]: animation === "vertical-slide",
                  ["horizontal"]: animation === "horizontal-slide",
                  ["fadein"]: animation === "fade-in",
                  ["swap"]: animation === "switch",
                })}
                ref={carouselWrapperRef}
              >
                {slides &&
                  slides.map((slide, index) => {
                    const slideType =
                      variant === "card-slide"
                        ? "card"
                        : variant === "carousel-A"
                          ? "shadow"
                          : "square"
                    const updateStyle = {}
                    if (slideType !== "square") {
                      updateStyle.width = `${width}px`
                    }
                    return (
                      <div
                        key={`slide-${index}`}
                        className={cx("slide", slideType)}
                        style={{ ...updateStyle }}
                        tabIndex={0}
                        onFocus={() => goToSlide(index)}
                        onClick={() => {
                          reports({
                            event: EVENTS.IMAGE_CLICK,
                            type:
                              slideType === "card"
                                ? "flavor_note"
                                : slideType === "shadow"
                                  ? "features"
                                  : "other_buckets",
                            title:
                              slideType === "card"
                                ? slide.title
                                : slideType === "shadow"
                                  ? `${slide.eyebrow} ${slide.title}`
                                  : `Bucket ${index + 1}`,
                          })
                        }}
                      >
                        {slideType !== "card" && slide.image && (
                          <img
                            src={slide.image.url}
                            alt={
                              slide.image.alt ? slide.image.alt : slide.title
                            }
                          />
                        )}

                        {slideType !== "square" && (
                          <div className={cx("content")}>
                            {slide.eyebrow && (
                              <span className={cx("eyebrowTag")}>
                                {slide.eyebrow}
                              </span>
                            )}
                            <h2 className={cx("titleTag")}>{slide.title}</h2>
                            {slide.subcopy && (
                              <p className={cx("copyTag")}>{slide.subcopy}</p>
                            )}
                            {slideType === "card" && slide.image && (
                              <img
                                src={slide.image.url}
                                alt={
                                  slide.image.alt
                                    ? slide.image.alt
                                    : slide.title
                                }
                              />
                            )}
                          </div>
                        )}
                      </div>
                    )
                  })}
              </div>
            </div>
            {hasDots && (
              <div className={cx("dotsWrapper")}>
                {slides &&
                  slides.map((slide, index) => {
                    return (
                      <button
                        key={`dot-${index}`}
                        className={cx("dot", {
                          ["active"]: currentSlide === index,
                          ["clickable"]: clickableDots,
                        })}
                        onClick={() => goToSlide(index)}
                      >
                        {index}
                      </button>
                    )
                  })}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

ParallaxLockupWithCarousel.propTypes = {
  title: PropTypes.string,
  eyebrow: PropTypes.string,
  subcopy: PropTypes.string,
  image: PropTypes.object,
  cta: PropTypes.object,
  slides: PropTypes.array,
  animation: PropTypes.oneOf([
    "vertical-slide",
    "horizontal-slide",
    "fade-in",
    "switch",
  ]),
  variant: PropTypes.oneOf(["card-slide", "carousel-A", "carousel-C"]),
  carouselPosition: PropTypes.oneOf(["left", "right"]),
  hasDots: PropTypes.bool,
  clickableDots: PropTypes.bool,
}

ParallaxLockupWithCarousel.defaultProps = {
  carouselPosition: "right",
  image: null,
  hasDots: false,
  variant: "card-slide",
  animation: "vertical-slide",
}

const ParallaxLockupWithCarouselWrapper = props => (
  <DeviceInfo>
    {deviceInfo => (
      <ParallaxLockupWithCarousel deviceInfo={deviceInfo} {...props} />
    )}
  </DeviceInfo>
)

export default ParallaxLockupWithCarouselWrapper
