// extracted by mini-css-extract-plugin
export var button = "button-module--button--1w9UC";
export var iconOnly = "button-module--iconOnly--3_ef6";
export var iconLeft = "button-module--iconLeft--3gn71";
export var iconRight = "button-module--iconRight--3eB5F";
export var iconBag = "button-module--iconBag--TtNkn";
export var iconCheckmark = "button-module--iconCheckmark--3lUIA";
export var iconLoading = "button-module--iconLoading--1F82N";
export var iconArrowRight = "button-module--iconArrowRight--36rWS";
export var iconArrowLeft = "button-module--iconArrowLeft--1hVkI";
export var iconTrash = "button-module--iconTrash--1ib65";
export var iconFav = "button-module--iconFav--Oe8HS";
export var primary = "button-module--primary--4-FG5";
export var positive = "button-module--positive--32fnZ";
export var accent = "button-module--accent--2_LOu";
export var disabled = "button-module--disabled--9Mzfy";
export var golden = "button-module--golden--1Cu_N";
export var secondary = "button-module--secondary--9nq1t";
export var tertiary = "button-module--tertiary--3SUV2";