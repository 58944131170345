import React, { useState, useEffect, useRef } from "react"
import PropTypes from "prop-types"
import classNames from "classnames/bind"
import OptionGroup from "../../molecules/OptionGroup/OptionGroup"
import SliderContainer from "../../molecules/SliderContainer/SliderContainer"

import * as styles from "./multipleSelectSliderContainer.module.scss"
import DeviceInfo from "../../utils/device-info"

const MultipleSelectSliderContainer = ({
  deviceInfo,
  options,
  onSelect,
  selectedOption,
  offset,
}) => {
  const defaultSelection = () => {
    const baseSelection = selectedOption ? selectedOption.split(",") : []
    return baseSelection
  }

  const [currentOption, setCurrentOption] = useState(0)
  const [lastOptionValue, setLastOptionValue] = useState(0)
  const [selection, setSelection] = useState(defaultSelection())
  const [columnData, setColumnData] = useState(null)
  const [circleStyles, setCircleStyles] = useState({})
  const [preferenceStyles, setPreferenceStyles] = useState({})
  const [isDraggin, setIsDraggin] = useState(false)
  const [resizeTriggered, setResizeTriggered] = useState(false)

  const wrapperRef = useRef(null)
  const circleRef = useRef(null)
  const preferenceRef = useRef(null)
  const cx = classNames.bind(styles)

  useEffect(() => {
    updateColumnData()
    window.addEventListener("resize", () => {
      setResizeTriggered(true)
      updateColumnData()
    })
    return () =>
      window.removeEventListener("resize", () => {
        setResizeTriggered(true)
        updateColumnData()
      })
  }, [])

  useEffect(() => {
    updateColumnData()
  }, [offset])

  const updateColumnData = () => {
    if (wrapperRef && wrapperRef.current && !isDraggin) {
      const wrapper = wrapperRef.current
      const wrapperWidth =
        deviceInfo.viewport === "small"
          ? window.innerWidth - 48
          : wrapper.clientWidth - offset.widthOffset
      const gap =
        deviceInfo.viewport === "small"
          ? 21
          : deviceInfo.viewport === "medium"
          ? 24
          : 28
      const span = deviceInfo.viewport === "small" ? 6 : 4
      const newColumnData = {
        width: (wrapperWidth - gap * 5) / 6,
        gap: gap,
        span: span,
      }

      const actionWrapperSize = span * newColumnData.width + (span - 1) * gap
      const actionOffset =
        deviceInfo.viewport === "small"
          ? 24
          : offset.offsetLeft + gap + newColumnData.width
      const sliderHeight = wrapper.children[0].clientHeight
      const offsetHeight = wrapper.clientHeight - sliderHeight
      const hasOffsetTop = wrapper.offsetTop !== 0

      const elementOffset =
        hasOffsetTop && !resizeTriggered
          ? deviceInfo.viewport === "small"
            ? 0
            : wrapper.offsetTop - 16
          : offsetHeight / 2 + 16

      setCircleStyles({
        width: `${
          actionWrapperSize - (deviceInfo.viewport === "small" ? 32 : 0)
        }px`,
        height: `${
          actionWrapperSize - (deviceInfo.viewport === "small" ? 32 : 0)
        }px`,
        transform: `translateX(${
          actionOffset +
          (deviceInfo.viewport === "small"
            ? 16
            : actionWrapperSize > 378
            ? (actionWrapperSize - 378) / 2
            : 0)
        }px)`,
        top: `${elementOffset < 24 ? 24 : elementOffset}px`,
      })
      setPreferenceStyles({
        width: `${actionWrapperSize}px`,
        transform: `translateX(${actionOffset}px)`,
        bottom: `${elementOffset < 24 ? 24 : elementOffset}px`,
      })
      setColumnData(newColumnData)
    }
  }

  const handleDrag = draggin => {
    setIsDraggin(draggin)
  }

  const handlePreferenceClick = (optionId, preferenceValue) => {
    const updateSelection = selection
    updateSelection[optionId] = preferenceValue
    setSelection(updateSelection)
    parseSelection(updateSelection)
    const gotToNextGroup = options.length - 1 >= currentOption + 1
    if (gotToNextGroup) {
      handleEnableOption(currentOption + 1)
    } else {
      setLastOptionValue(preferenceValue)
    }
  }

  const parseSelection = data => {
    if (data.length === options.length) {
      onSelect(data.join(","))
    }
  }

  const handleEnableOption = (optionIndex, back = false) => {
    setCurrentOption(optionIndex)
    handlePreferenceFocus(optionIndex, true, back)
  }

  const handlePreferenceFocus = (option, delay = false, back = false) => {
    if (preferenceRef.current) {
      const currentPreferences = preferenceRef.current.children[option]
      if (currentPreferences) {
        preferenceRef.current.style.opacity = 0
        setTimeout(
          () => {
            const prefTo = back
              ? currentPreferences.children.length - 2
              : option === 0
              ? 0
              : 1
            currentPreferences.children[prefTo].focus()
            preferenceRef.current.style.opacity = 1
          },
          delay ? 450 : 0
        )
      }
    }
  }

  const handlePreferenceBlur = e => {
    if (currentOption + 1 <= options.length - 1) {
      handleEnableOption(currentOption + 1)
    }
  }

  const handlePreferenceBlurBack = e => {
    if (currentOption - 1 >= 0) {
      handleEnableOption(currentOption - 1, true)
    }
  }

  const handleBlur = ({ relatedTarget }) => {
    if (relatedTarget && !relatedTarget.classList.contains(cx("preference"))) {
      wrapperRef.current.focus()
    }
  }

  return (
    <div className={cx("container")} ref={wrapperRef} tabIndex={0}>
      <SliderContainer
        currentSlide={currentOption}
        onUpdate={handleEnableOption}
        columnData={columnData}
        onDrag={handleDrag}
        offsetLeft={offset.offsetLeft}
      >
        {options &&
          options.map((option, index) => {
            const key = `option-${index}`
            const active = currentOption === index
            const optionData = {
              ...option,
              active: active,
              overlaps: true,
              defaultImage: option?.image,
              options: option.options,
              snapToColumn: columnData,
              value:
                index === options.length - 1
                  ? lastOptionValue
                  : selection[index],
              id: index,
              onFocus: () => handleEnableOption(index),
              onSelect: handlePreferenceClick,
              onBlur: handlePreferenceFocus,
              selection: selection,
              onlyOption: true,
            }
            return <OptionGroup key={key} {...optionData} active={active} />
          })}
      </SliderContainer>
      <div className={cx("circle")} style={circleStyles} ref={circleRef}></div>
      <div
        className={cx("preferenceContainer")}
        style={preferenceStyles}
        ref={preferenceRef}
      >
        {options &&
          options.map((option, index) => {
            const key = `option-${index}`
            const active = currentOption === index
            const currentPreference =
              selection[index] !== undefined ? selection[index] : false
            return (
              <div
                className={cx("preferenceWrapper", {
                  ["active"]: active,
                })}
                key={key}
              >
                {index > 0 && (
                  <button
                    className={cx("hidden")}
                    onFocus={() => handlePreferenceBlurBack()}
                  ></button>
                )}
                {option.options.map((preference, prefIndex) => {
                  const key = `preference-${prefIndex}`
                  return (
                    <button
                      key={key}
                      onClick={e => handlePreferenceClick(index, preference.id)}
                      onBlur={
                        prefIndex === 0 && prefIndex === index
                          ? e => handleBlur(e)
                          : () => {}
                      }
                      className={cx("preference", {
                        ["selected"]: currentPreference === preference.id,
                      })}
                    >
                      {preference.title}
                    </button>
                  )
                })}
                {index < options.length - 1 && (
                  <button
                    className={cx("hidden")}
                    onFocus={() => handlePreferenceBlur()}
                  ></button>
                )}
              </div>
            )
          })}
      </div>
    </div>
  )
}

MultipleSelectSliderContainer.propTypes = {
  options: PropTypes.arrayOf(PropTypes.object),
  onSelect: PropTypes.func,
  selectedOption: PropTypes.string,
  offset: PropTypes.object,
}

MultipleSelectSliderContainer.defaultProps = {
  options: [],
  onSelect: () => {},
  selectedOption: "",
  offset: {
    widthOffset: 0,
    offsetLeft: 0,
  },
}

const MultipleSelectSliderContainerWrapper = props => (
  <DeviceInfo>
    {deviceInfo => (
      <MultipleSelectSliderContainer deviceInfo={deviceInfo} {...props} />
    )}
  </DeviceInfo>
)

export default MultipleSelectSliderContainerWrapper
