import { useContext, useEffect } from "react"
import { useMutation } from "@apollo/client"

import { QuizContext } from "../context/QuizContext"

import { SendResults } from "../components/utils/fragments"

export function useQuizResult() {
  const { result, setResult } = useContext(QuizContext)

  const [sendResult, { data, loading, error }] = useMutation(SendResults)

  useEffect(() => {
    if (data) setResult(data)
  }, [data])

  return {
    result,
    sendResult,
    loading,
    error,
  }
}
