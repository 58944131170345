import React, { useState, useRef, useEffect } from "react"
import PropTypes from "prop-types"
import classNames from "classnames/bind"

import Button from "../../atoms/Button/Button"

import { gsap } from "gsap"

import DeviceInfo from "../../utils/device-info"
import { EVENTS } from "../../utils/reports"

import * as styles from "./reactiveImageLockup.module.scss"

const baseHeight = (DeviceInfo.viewport === "small" ? 327 : 586) + "px"

const ReactiveImageLockup = ({
  deviceInfo,
  // text block
  title,
  copy,
  eyebrow,
  cta,
  // image block
  imageTitle,
  imageCopy,
  imageEyebrow,
  slideImage1,
  slideImage2,
  foregroundImage,
  backgroundImage,
  // configuration
  imageOnRight,
  isFullsize,
  motionType,
  showExpanded,
  slideImage1Position,
  slideImage2Position,
  info,
}) => {
  const [isExpanded, setIsExpanded] = useState(showExpanded)
  const [expandedHeight, setExpandedHeight] = useState(baseHeight)
  const [contentHeight, setContentHeight] = useState(baseHeight)
  const containerRef = useRef(null)
  const topLayerRef = useRef(null)
  const cx = classNames.bind(styles)

  useEffect(() => {
    if (
      (deviceInfo.isMobileBrowser && motionType !== "static") ||
      motionType === "scroll"
    ) {
      const current = containerRef.current
      gsap.to(current, {
        scrollTrigger: {
          trigger: current,
          start: "-30% top",
          end: "bottom bottom",
          toggleActions: "play none none reverse",
          scrub: true,
          onEnter: () => {
            setIsExpanded(true)
          },
          onLeaveBack: ({ direction }) => {
            if (direction === -1) {
              setIsExpanded(false)
            }
          },
        },
      })
    }
  }, [containerRef, motionType, deviceInfo])

  useEffect(() => {
    updateExpandedHeight()
    window.addEventListener("resize", updateExpandedHeight)
    return () => window.removeEventListener("resize", updateExpandedHeight)
  }, [])

  const updateExpandedHeight = () => {
    const topLayer = topLayerRef.current
    if (topLayer) {
      const parent = topLayer.parentNode
      let newHeight =
        deviceInfo.viewport === "small"
          ? topLayer.clientHeight + 40
          : topLayer.clientHeight * 0.9
      setContentHeight(
        newHeight > parseInt(baseHeight) ? `${newHeight}px` : baseHeight
      )
      if (!isExpanded) {
        newHeight = parent.clientWidth
        if (deviceInfo.viewport === "small") {
          newHeight = (topLayer.clientHeight * 80) / 90
        }
      }
      setExpandedHeight(`${newHeight}px`)
    }
  }

  useEffect(() => {
    updateExpandedHeight()
  }, [isExpanded])

  const handleHover = e => {
    if (!deviceInfo.isMobileBrowser) {
      if (motionType === "hover") {
        setIsExpanded(true)
      }
    }
  }

  const handleLeave = e => {
    if (!deviceInfo.isMobileBrowser) {
      if (motionType === "hover") {
        setIsExpanded(false)
      }
    }
  }

  return (
    <div className={cx("container")} ref={containerRef}>
      <div className={cx("grid", imageOnRight ? "rightAligned" : "")}>
        <div
          className={cx("textBlock", isFullsize ? "fullsize" : "")}
          style={{
            height: deviceInfo.viewport === "small" ? "auto" : contentHeight,
          }}
        >
          {eyebrow && <span className={cx("eyebrowTag")}>{eyebrow}</span>}
          <h2 className={cx("titleTag")}>{title}</h2>
          {copy && <p className={cx("copyTag")}>{copy}</p>}
          {cta && (
            <Button
              {...cta}
              trackingObjects={[
                {
                  event: EVENTS.QUIZ_START_BUTTOM,
                  header: title,
                },
              ]}
            />
          )}
        </div>
        {backgroundImage && (
          <div className={cx("imageBlock", isExpanded ? "expanded" : "")}>
            {slideImage1 && (
              <img
                alt={imageTitle}
                src={slideImage1.url}
                className={cx("fadeinLayer", slideImage1Position)}
              />
            )}
            {slideImage2 && (
              <img
                alt={imageTitle}
                src={slideImage2.url}
                className={cx("fadeinLayer", slideImage2Position)}
              />
            )}
            <div
              className={cx("motionBlock")}
              style={{
                height: isExpanded
                  ? deviceInfo.viewport !== "small"
                    ? "calc(100% + 80px)"
                    : expandedHeight
                  : "auto",
              }}
            >
              <img
                alt={imageTitle}
                src={backgroundImage.url}
                className={cx("backLayer")}
              />
              <div
                className={cx("topLayer")}
                ref={topLayerRef}
                onMouseOver={e => handleHover(e)}
                onMouseLeave={e => handleLeave(e)}
                role={"banner"}
              >
                {imageEyebrow && (
                  <span className={cx("eyebrowTag")}>{imageEyebrow}</span>
                )}
                {imageTitle && <h2 className={cx("titleTag")}>{imageTitle}</h2>}
                {imageCopy && <p className={cx("copyTag")}>{imageCopy}</p>}
                {info && (
                  <div className={cx("info")}>
                    {info.map((item, index) => {
                      return (
                        item.icon && (
                          <div className={cx("data")} key={index}>
                            <img src={item.icon} alt={item.text} />
                            <p>{item.text}</p>
                          </div>
                        )
                      )
                    })}
                  </div>
                )}
                {foregroundImage && (
                  <div className={cx("imageContainer")}>
                    <img alt={imageTitle} src={foregroundImage.url} />
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

ReactiveImageLockup.propTypes = {
  // content column
  title: PropTypes.string.isRequired,
  copy: PropTypes.string,
  eyebrow: PropTypes.string,
  cta: PropTypes.objectOf(PropTypes.string),
  // image column
  imageTitle: PropTypes.string,
  imageCopy: PropTypes.string,
  imageEyebrow: PropTypes.string,
  slideImage1: PropTypes.object,
  slideImage2: PropTypes.object,
  foregroundImage: PropTypes.object,
  backgroundImage: PropTypes.object,
  // layout options
  imageOnRight: PropTypes.bool,
  isFullsize: PropTypes.bool,
  motionType: PropTypes.oneOf(["hover", "scroll", "static"]),
  showExpanded: PropTypes.bool,
  slideImage1Position: PropTypes.oneOf([
    "topLeft",
    "topRight",
    "bottomLeft",
    "bottomRight",
  ]),
  slideImage2Position: PropTypes.oneOf([
    "topLeft",
    "topRight",
    "bottomLeft",
    "bottomRight",
  ]),
  info: PropTypes.arrayOf(PropTypes.object),
}

ReactiveImageLockup.defaultProps = {
  // content column
  title: `Your Whiskey Palate`,
  copy: ``,
  eyebrow: ``,
  cta: null,
  // image column
  imageTitle: ``,
  imageCopy: ``,
  imageEyebrow: ``,
  slideImage1: ``,
  slideImage2: ``,
  foregroundImage: ``,
  backgroundImage: ``,
  // layout options
  imageOnRight: false,
  isFullsize: false,
  motionType: `hover`,
  showExpanded: false,
  slideImage1Position: `topLeft`,
  slideImage2Position: `bottomRight`,
  info: null,
}

const ReactiveImageLockupWrapper = props => (
  <DeviceInfo>
    {deviceInfo => <ReactiveImageLockup deviceInfo={deviceInfo} {...props} />}
  </DeviceInfo>
)

export default ReactiveImageLockupWrapper
