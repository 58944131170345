import React, { useState } from "react"
import { useQuery } from "@apollo/react-hooks"
import gql from "graphql-tag"
import Categories from "./components/Categories"
import Results from "./components/Results"

import {
  grid,
  colTwo,
  colTen,
  colTwelve,
  gold,
  limestone,
} from "./debbuging-tool.module.scss"

const Debbugging = () => {
  const { loading, error, data } = useQuery(GET_CATS)
  const [value, setValue] = useState([])

  if (loading) {
    return <h1>"Loading..."</h1>
  }

  if (error) {
    return <h1>{error.message}</h1>
  }

  return (
    <div>
      <div className={`${grid} ${gold}`}>
        <div className={colTwelve}>
          <p>Flavor Finder Debbuging Tool</p>
        </div>
      </div>

      <div className={grid}>
        <div className={`${colTwo} ${limestone}`}>
          <p>Categories:</p>
          <Categories data={data} value={value} onChange={setValue} />
        </div>

        <div className={colTen}>
          <p>Result:</p>
          <HandleResults categories={value} />
        </div>
      </div>
    </div>
  )
}

export default Debbugging

const GET_CATS = gql`
  query {
    flavorFinderCategories {
      entityId
      entityLabel
    }
  }
`

const HandleResults = ({ categories }) => {
  const { loading, error, data } = useQuery(GET_BUCKETS, {
    variables: { categories },
  })

  if (loading) {
    return <p>Loading...</p>
  }

  if (error) {
    return <p>{error.message}</p>
  }

  return <Results data={data} />
}

const GET_BUCKETS = gql`
  query($categories: [BeamFlavorFinderCategoryScore]!) {
    flavorFinderTestCategories(categories: $categories) {
      bucket {
        tid
        name
      }
    }
  }
`
