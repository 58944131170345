import * as React from "react"
import classNames from "classnames/bind"

import Layout from "../../atoms/layout/Layout"
import * as styles from "../../atoms/layout/layout.module.scss"

const cx = classNames.bind(styles)

const ErrorPage = () => (
  <Layout includeHeaderOffset>
    <div className={cx('fullHeight')}>The page could not be loaded.<br />Please try again later.</div>
  </Layout>
)

export default ErrorPage
