import * as React from "react"
import classNames from "classnames/bind"

import Layout from "../../atoms/layout/Layout"
import * as styles from "../../atoms/layout/layout.module.scss"

const cx = classNames.bind(styles)

const LoadingPage = () => (
  <Layout includeHeaderOffset>
    <div className={cx('fullHeight')}>Loading…</div>
  </Layout>
)

export default LoadingPage
