// extracted by mini-css-extract-plugin
export var wrapper = "ParallaxLockup-module--wrapper--3kYfq";
export var next = "ParallaxLockup-module--next--35eVD";
export var prev = "ParallaxLockup-module--prev--2ZcYh";
export var container = "ParallaxLockup-module--container--1cCsq";
export var slideContainer = "ParallaxLockup-module--slideContainer--2JIYR";
export var sliderTab = "ParallaxLockup-module--sliderTab--2hlOR";
export var grid = "ParallaxLockup-module--grid--332YX";
export var slidesContainer = "ParallaxLockup-module--slidesContainer--1uHPn";
export var leftCol = "ParallaxLockup-module--leftCol--3bh3t";
export var carouselNavigationController = "ParallaxLockup-module--carouselNavigationController--p-zU5";
export var iconPrev = "ParallaxLockup-module--iconPrev--2rryt";
export var iconNext = "ParallaxLockup-module--iconNext--3A70_";
export var rightCol = "ParallaxLockup-module--rightCol--17f2R";