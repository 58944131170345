import React, { useEffect, useContext } from "react"
import classNames from "classnames/bind"

import { navigate } from "@reach/router"
import { useQuery } from "@apollo/client"

import { GetUserInformation } from "../fragments"

import TabContainer from "../../organisms/TabContainer/TabContainer"
import LPImageHero from "../../organisms/LPImageHero/LPImageHero"
import ShareModule from "../../organisms/ShareModule/ShareModule"
import FavoriteBottle from "../../organisms/FavoriteBottle/FavoriteBottle"

import TabItem from "../../molecules/TabItem/TabItem"
import ContentFillAndFollow from "../../molecules/ContentFillAndFollow/ContentFillAndFollow"
import { brands } from "../../molecules/ContentFillAndFollow/ContentFillAndFollow";
import ContentAnyRoadTicketing from "../../molecules/ContentAnyRoadTicketing/ContentAnyRoadTicketing"

import AccountInformationForm from "./AccountInformationForm"

import { withPrefix } from "../with-prefix"
import useCognitoUserData from "../cognito-user-data"

import ProfileImage from "../../../images/account-profile-whiskey-finder-hero.png"
import { ModalContext } from "../../../context/ModalContext"

import * as styles from "./userProfilePage.module.scss"
import DeviceInfo from "../device-info"

const cx = classNames.bind(styles)

const UserProfilePage = ({ location, deviceInfo }) => {
  const { openModal, visible } = useContext(ModalContext)
  const params = new URLSearchParams(location?.search)
  const ffybLocation = params.get("location")
  const isFfybLocationValid = brands.hasOwnProperty(ffybLocation);

  const { data, loading, error, refetch } = useQuery(GetUserInformation, {
    fetchPolicy: "network-only",
  })
  const { sessionChecked, isLoggedIn, signOut } = useCognitoUserData()

  const bucket = data?.currentUser?.lastFlavorFinderResult?.bucket
  const {
    fieldBeamBourbonuCopy: shareableCopy,
    fieldBeamBourbonuThreeimgs,
    fieldBeamBourbonuArchive: shareableArchive,
  } = bucket?.fieldBeamBourbonuRpShareable?.entity || {}
  const shareableThreeimgs = fieldBeamBourbonuThreeimgs
    ? fieldBeamBourbonuThreeimgs.map(img => img?.entity?.fieldBeamBourbonuImage)
    : []
  const bottles = data?.currentUser?.lastFlavorFinderResult?.bottles
  const bottleImage =
    bottles?.[0]?.fieldBeamBourbonuShareImage?.entity?.fieldBeamBourbonuImage
  if (bottleImage) {
    shareableThreeimgs.push(bottleImage)
  }

  const shareModuleTab = {
    images: [
      {
        url: "https://via.placeholder.com/586x620",
        alt: "background",
      },
    ],
    titles: [
      "Compare Your Palate",
      "Show Off Your Knowledge",
      "Share your bottle",
    ],
    subCopy:
      "Kick off a conversation about whiskey with friends over 21 and start exploring together.",
    linkUrl: "https://www.beamdistillg.com/whiskey",
    ctasTitle: "",
    primaryCta: {
      text: "See Results",
      url: withPrefix("/whiskey-finder/results"),
      type: "primary",
      variant: "positive",
      icon: false,
      iconAlignment: "",
    },
    secondaryCta: {
      text: "Retake Quiz",
      url: withPrefix("/whiskey-finder/quiz"),
      type: "secondary",
      variant: "positive",
    },
  }

  const LPImageTab = {
    title: "Start Your Whiskey Journey Now",
    copy:
      "Welcome to Whiskey Finder, where we get to know you and recommend some whiskeys based on your palate. While we’d love to sit down for a chat, we figure a quiz is the next best thing.",
    cta: {
      text: "Take the quiz",
      url: withPrefix("/whiskey-finder/quiz"),
      type: "primary",
      variant: "positive",
      icon: false,
      iconAlignment: "",
    },
    image: {
      url: ProfileImage,
      alt: "background",
    },
    mobileImage: {
      url: ProfileImage,
      alt: "background",
    },
  }

  const fillAndFollowTimeUTC = data?.currentUser?.ffyb?.[0]?.startDate

  const fillAndFollowTime = fillAndFollowTimeUTC
    ? new Date(fillAndFollowTimeUTC)
    : null
  const fillAndFollowLocation = data?.currentUser?.ffyb?.[0]?.location

  const fillfollowTabData = data?.currentUser?.anyroadTicketDetails?.filter(
    details =>
      details.tab === "fillfollow" &&
      details.locationId === fillAndFollowLocation
  )

  const fillAndFollowTab = {
    eyebrow: fillfollowTabData ? fillfollowTabData[0]?.eyebrow : null,
    header: fillfollowTabData ? fillfollowTabData[0]?.title : null,
    body: fillfollowTabData ? fillfollowTabData[0]?.description : null,
    time:
      fillAndFollowTime &&
      fillAndFollowTime.toLocaleDateString(undefined, {
        hour: "numeric",
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        hour12: true,
      }),
    location: fillAndFollowLocation,
    image: {
      url: fillfollowTabData ? fillfollowTabData[0]?.image : null,
      alt: "alternative",
    },
    mobileImage: {
      url: fillfollowTabData ? fillfollowTabData[0]?.image : null,
      alt: "alternative",
    },
  }

  const anyroadBookingId = data?.currentUser?.latestBooking?.bookingId
  const anyroadExperienceName = data?.currentUser?.latestBooking?.experienceName
  const anyroadExperienceUTCDate = data?.currentUser?.latestBooking?.experienceDate?.slice(
    0,
    -5
  )
  const anyroadExperienceDate = anyroadExperienceUTCDate
    ? new Date(anyroadExperienceUTCDate)
    : null
  const anyroadNumberGuestsBooked =
    data?.currentUser?.latestBooking?.numberGuestsBooked

  const bookingsTab = data?.currentUser?.anyroadTicketDetails?.filter(
    details => details.tab === "bookings"
  )
  const noBookingsTab = data?.currentUser?.anyroadTicketDetails?.filter(
    details => details.tab === "nobookings"
  )

  const anyRoadTab = anyroadBookingId
    ? {
        eyebrow: bookingsTab ? bookingsTab[0]?.eyebrow : null,
        title: anyroadExperienceName,
        body: bookingsTab ? bookingsTab[0]?.description : null,
        id: anyroadBookingId,
        time:
          anyroadExperienceDate &&
          anyroadExperienceDate.toLocaleDateString(undefined, {
            hour: "numeric",
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
            hour12: true,
          }),
        guests: anyroadNumberGuestsBooked,
        image: {
          url: bookingsTab ? bookingsTab[0]?.image : null,
          alt: "alternative",
        },
        mobileImage: {
          url: bookingsTab ? bookingsTab[0]?.image : null,
          alt: "alternative",
        },
      }
    : {
        title: noBookingsTab ? noBookingsTab[0]?.title : null,
        body: noBookingsTab ? noBookingsTab[0]?.description : null,
        cta: {
          text: "book a trip",
          type: "primary",
          icon: false,
          variant: "positive",
          url: "https://app.anyroad.com/tours/beam-made-bourbon/s-c61686fc",
          target: "_blank",
        },
        image: {
          url: noBookingsTab ? noBookingsTab[0]?.image : null,
          alt: "alternative",
        },
        mobileImage: {
          url: noBookingsTab ? noBookingsTab[0]?.image : null,
          alt: "alternative",
        },
      }

  const reletedArgs = {
    eyebrow: "Your Favorite Bottles",
    cards: data?.currentUser?.fieldUserprofileWishlist.map(({ entity }) => ({
      pieceId: entity?.id,
      productId: entity?.fieldBeamBourbonuEpid,
      header: entity?.title,
      image: entity?.fieldBeamBourbonuImage?.entity?.fieldBeamBourbonuImage,
      mobileImage:
        entity?.fieldBeamBourbonuImage?.entity?.fieldBeamBourbonuImage,
      type: "bottle",
      variant: "3UP",
      features: [
        {
          value:
            entity?.fieldBeamBourbonuFeatures?.entity?.fieldBourbonuBfProof,
          feature: "Proof",
        },
        {
          value: entity?.fieldBeamBourbonuFeatures?.entity?.fieldBourbonuBfAge,
          feature: "Aged",
        },
        {
          value:
            entity?.fieldBeamBourbonuFeatures?.entity?.fieldBourbonuBfPrice,
          feature: "Price",
        },
      ],
      hasShop: true,
    })),
  }

  const getShareUrl = path => {
    return window.location.href.replace("/profile", path)
  }

  const currentUrl =
    typeof window !== "undefined" ? getShareUrl(bucket?.path?.alias) : ""

  useEffect(() => {
    if (!loading && sessionChecked && !isLoggedIn()) {
      signOut()
      navigate(withPrefix("/sign-in"))
    }
  }, [data, sessionChecked, loading, isLoggedIn])
  useEffect(() => {
    if (data) {
      if (sessionChecked && isLoggedIn() && ffybLocation && isFfybLocationValid) {
        if (
          !data.currentUser?.ffyb ||
          !data.currentUser.ffyb.some(ffyb => ffyb.location == ffybLocation)
        ) {
          openModal({
            variant: "optIn",
            title: "SIGN UP TO FOLLOW YOUR BARREL'S JOURNEY OVER TIME",
            copy:
              "From filling distillate in a new charred American Oak barrel, as you did today, to resting in our Clermont rackhouses and maturing through the changing Kentucky seasons, discover how your liquid transforms into bourbon.",
          })
        }
      }
    }
  }, [sessionChecked, data])

  //to prevent this issue https://github.com/apollographql/react-apollo/issues/3644
  useEffect(() => {
    const id = setInterval(() => {
      if (error) {
        refetch()
      }
    }, 15 * 1000)
    return () => clearInterval(id)
  }, [refetch, error])

  //update user information once FFYB modal is closed
  useEffect(() => {
    const timeout = setTimeout(() => {
      refetch()
    }, 500)
    return () => clearTimeout(timeout)
  }, [visible])

  return (
    !loading && (
      <div className={cx("mainContainer")}>
        <header>
          <div>
            <h1>Welcome Back</h1>
          </div>
        </header>
        <TabContainer>
          {/* <TabItem name={"Your whiskey palate"}>
            {data?.currentUser?.lastFlavorFinderResult?.bucket ? (
              <div style={{ position: "relative" }}>
                <ShareModule
                  {...shareModuleTab}
                  images={shareableThreeimgs}
                  linkUrl={currentUrl}
                />
              </div>
            ) : (
              <div className={cx("tabContainer")}>
                <LPImageHero {...LPImageTab} isTab={true} />
              </div>
            )}

            {!deviceInfo.isMobile && <FavoriteBottle {...reletedArgs} />}
          </TabItem> */}
          <TabItem name="YOUR DISTILLERY TOUR BOOKING">
            <div className={cx("tabContainer")}>
              <ContentAnyRoadTicketing {...anyRoadTab} />
            </div>
          </TabItem>
          {fillAndFollowTime && (
            <TabItem name="Fill & Follow your barrel">
              <div className={cx("tabContainer")}>
                <ContentFillAndFollow {...fillAndFollowTab} />
              </div>
            </TabItem>
          )}
          <TabItem name={"Your account information"}>
            <div className={cx("tabContainer")}>
              <AccountInformationForm values={data?.currentUser} />
            </div>
          </TabItem>

          {deviceInfo.isMobile && (
            <TabItem name={"Your Favorite Bottles"}>
              <div className={cx("tabContainer")}>
                <FavoriteBottle {...reletedArgs} />
              </div>
            </TabItem>
          )}
        </TabContainer>
      </div>
    )
  )
}

const UserProfilePageWrapper = props => (
  <DeviceInfo>
    {deviceInfo => <UserProfilePage deviceInfo={deviceInfo} {...props} />}
  </DeviceInfo>
)
export default UserProfilePageWrapper
