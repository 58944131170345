const Facts = [
  {
    title: "Whiskey Fact #87",
    subtitle: "Rackhouse Rules",
    copy:
      "The nine-storey rackhouse is where our bourbon is aged in charred American Oak barrels. Famously, Booker favored the middle three floors, known as “The Center Cut”, which yield the most consistent, balanced flavor of wood and baking spices. ",
    image: "",
  },
  {
    title: "Whiskey Fact #35",
    subtitle: "Our Kentucky Seasons",
    copy:
      "As the seasons change, the Kentucky climate expands and contracts the barrel wood. This allows the bourbon to “breathe” in and out of the barrel, extracting caramelized sugars, an oaky flavor and a beautiful, golden-brown color from the charred wood.",
    image: "",
  },
  {
    title: "Whiskey Fact #13 ",
    subtitle: "Master the Kentucky Chew",
    copy:
      "We’ll let you in on our little secret: The Kentucky Chew. When you sip your whiskey, swish it all around your mouth and smack your lips together after you swallow to get the full taste of every flavor note. Now that’s some damn good bourbon.",
    image: "",
  },
  {
    title: "Whiskey Fact #26",
    subtitle: "Alligator Skin",
    copy:
      "We call our level-4 char American White Oak barrels “alligator char” because, after being fired, the inside of the barrel takes on the scaly, bumpy look of a gator’s skin.",
    image: "",
  },
  {
    title: "Whiskey Fact #42",
    subtitle: "Our Kentucky Water",
    copy:
      "To make our whiskey, we use our world-famous, limestone-rich Kentucky water, and absolutely nothing else. This helps to craft the slightly sweet, golden brown bourbon we all know and love.",
    image: "",
  },
  {
    title: "Whiskey Fact #68",
    subtitle: "Devil’s Cut & Angel’s Share",
    copy:
      "You see, while a ‘standard’ barrel holds 53 gallons, during the aging process a fair portion of that escapes through evaporation – known as the “angel’s share.” Then there’s the liquid we lose because it’s trapped deep inside the wood, called the “devil’s cut.”",
    image: "",
  },
]

const randomFact = factsArray => {
  if (factsArray && factsArray.length > 0) {
    const index = Math.round(Math.random() * (factsArray.length - 1))
    const whiskeyFact = factsArray[index]
    return {
      title: whiskeyFact.title,
      subtitle: whiskeyFact.fieldBeamBourbonuSubcopy,
      copy: whiskeyFact.description.value,
      image:
        whiskeyFact.fieldBeamBourbonuImage?.entity?.fieldBeamBourbonuImage?.url,
    }
  }
  const index = Math.round(Math.random() * (Facts.length - 1))
  return Facts[index]
}

const FactsData = factsArray => {
  return {
    title: ["Nicely Done.", "That’s it."],
    subtitle:
      "We've got a good sense of your unique whiskey palate. Give us a sec as we track down a few bottles you'll appreciate.",
    cta: {
      text: "Skip to results",
      url: null,
      type: "tertiary",
      variant: "positive",
      icon: true,
      iconAlignment: "right",
    },
    fact: randomFact(factsArray),
  }
}

export default FactsData
