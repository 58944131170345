import React, { useEffect } from "react"
import PropTypes from "prop-types"
import classNames from "classnames/bind"

import * as styles from "./largeModal.module.scss"

const cx = classNames.bind(styles)

const LargeModal = ({ visible, onClose, children, top, size }) => {
  useEffect(() => {
    if (visible) {
      //fix scroll during modal open
      document.body.style.overflow = "hidden"
      //required for iphone safari
      document.documentElement.style.overflow = "hidden"
    }
    return () => {
      document.documentElement.style.overflow = "unset"
      document.body.style.overflow = "unset"
    }
  }, [visible])

  const classnameClose = cx({
    close: true,
    [size]: true,
  })

  const classnameModal = cx({
    modal: true,
    [size]: true,
  })

  return visible ? (
    <div className={cx("wrapper")} style={{ top }}>
      <div className={cx("container")}>
        <div className={classnameClose} onClick={() => onClose()}>
          <div className={cx("cross")} />
          <h5>Close</h5>
        </div>
        <div className={classnameModal}>{children}</div>
      </div>
    </div>
  ) : null
}

LargeModal.propTypes = {
  title: PropTypes.string,
  copy: PropTypes.string,
  primaryCta: PropTypes.object,
  secondaryCta: PropTypes.object,
  visible: PropTypes.bool,
  children: PropTypes.node,
  size: PropTypes.oneOf(["big", "medium"]),
}

LargeModal.defaultProps = {
  visible: false,
  children: null,
  size: "big",
}

export default LargeModal
