// extracted by mini-css-extract-plugin
export var container = "parallaxLockupC-module--container--1a2ql";
export var grid = "parallaxLockupC-module--grid--20hmC";
export var titleTag = "parallaxLockupC-module--titleTag--fpZUn";
export var copyTag = "parallaxLockupC-module--copyTag--1CWZe";
export var eyebrowTag = "parallaxLockupC-module--eyebrowTag--3CKZO";
export var textBlock = "parallaxLockupC-module--textBlock--19-iO";
export var carouselBlock = "parallaxLockupC-module--carouselBlock--gftFw";
export var square = "parallaxLockupC-module--square--3ETD5";
export var carouselContainer = "parallaxLockupC-module--carouselContainer--3f7Xt";
export var circular = "parallaxLockupC-module--circular--17Yu4";
export var carouselWrapper = "parallaxLockupC-module--carouselWrapper--3xvAB";
export var swap = "parallaxLockupC-module--swap--1AEBC";
export var slide = "parallaxLockupC-module--slide--2EDKS";
export var carouselBackground = "parallaxLockupC-module--carouselBackground--2YJLv";
export var card = "parallaxLockupC-module--card--3-Su_";
export var content = "parallaxLockupC-module--content--38dgK";