import React, { useState, useCallback } from "react"
import classNames from "classnames/bind"
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes"

import * as styles from "./multipleDropdown.module.scss"

const MultipleDropdown = ({
  label,
  filters,
  onSelect,
  selectedOptions,
  updateState,
  filterName,
  multiple,
}) => {
  const cx = classNames.bind(styles)
  const [isActive, setIsActive] = useState(false)
  const [scopeOptions, setScopeOptions] = useState([])
  const options = filters.map(filter => {
    if (filterName === "sort") {
      return {
        value: filter.filter,
        label: filter.label,
        name: filterName,
        order: filter.order,
      }
    } else {
      return { value: filter.filter, label: filter.filter, name: filterName }
    }
  })

  if (multiple) {
    options.push({ label: "APPLY", value: "apply" })
  }

  const dropdownLabel = ({ placeholderButtonLabel }) => {
    if (multiple) {
      return `${placeholderButtonLabel}`
    } else {
      return `${scopeOptions.length > 0 ? scopeOptions : options[0].label}`
    }
  }

  const onChange = useCallback(
    (value, event) => {
      if (event.action === "deselect-option" && event.option.value === "*") {
        updateState([])
        setScopeOptions([])
      } else if (event.action === "deselect-option") {
        updateState(value.filter(o => o.value !== "*"))
        setScopeOptions(value.filter(o => o.value !== "*"))
      } else if (event.option.value === "apply" && multiple) {
        sendSelectedValues()
      } else if (!multiple) {
        if (filterName === "sort") {
          const sort = value.filter(val => val.name === "sort")
          onSelect(sort[0].value, sort[0].order && sort[0].order)
          setScopeOptions(sort[0].label)
        } else {
          onSelect(value[0].value)
        }
      } else {
        updateState(value, ...selectedOptions.filter(o => o.name !== "sort"))
        setScopeOptions([value[value.length - 1], ...scopeOptions])
      }
    },
    [selectedOptions]
  )

  const onMenuOpen = () => {
    setIsActive(!isActive)
  }

  const sendSelectedValues = useCallback(() => {
    if (selectedOptions.length != 0) {
      onSelect(selectedOptions)
    } else {
      onSelect([])
    }
  }, [selectedOptions])

  return (
    <div
      className={cx(
        "multipleDropdown",
        isActive ? "active" : "",
        multiple ? "" : "hideCheckbox"
      )}
      onClick={() => onMenuOpen()}
    >
      <ReactMultiSelectCheckboxes
        options={options}
        placeholderButtonLabel={label}
        getDropdownButtonLabel={dropdownLabel}
        onChange={onChange}
        value={selectedOptions}
        setState={setScopeOptions}
        hideSearch={true}
      />
    </div>
  )
}

export default MultipleDropdown
