import React, { useEffect, useRef, useState } from "react"
import PropTypes from "prop-types"
import classNames from "classnames/bind"
import { gsap } from "gsap"
import Button from "../../atoms/Button/Button"
import DeviceInfo from "../../utils/device-info"
import { EVENTS, reports } from "../../utils/reports"
import * as styles from "./parallaxLockupC.module.scss"

const ParallaxLockupC = ({
  title,
  eyebrow,
  subcopy,
  image,
  cta,
  slides,
  deviceInfo,
}) => {
  const [width, setWidth] = useState(0)
  const containerRef = useRef(null)
  const carouselBlockRef = useRef(null)
  const carouselWrapperRef = useRef(null)
  const carouselBackgroundRef = useRef(null)

  const cx = classNames.bind(styles)

  useEffect(() => {
    updateSlideSize()
    window.addEventListener("resize", updateSlideSize)
    updateSlideSize()
    return () => window.removeEventListener("resize", updateSlideSize)
  }, [])

  useEffect(() => {
    updateSlideSize()
    window.addEventListener("orientationchange", updateSlideSize)
    updateSlideSize()
    return () =>
      window.removeEventListener("orientationchange", updateSlideSize)
  }, [])

  useEffect(() => {
    updateSlideSize()
    prepareAnimation()
  }, [carouselBlockRef.current])

  const prepareAnimation = () => {
    if (containerRef.current && carouselWrapperRef.current && width !== 0) {
      if (containerRef.current && carouselWrapperRef.current) {
        const imagesWrapper = carouselWrapperRef.current
        const children = imagesWrapper.children
        const transitionDuration = 0.8
        const timeLines = []
        slides.map((slide, index) => {
          const tl = gsap.timeline()

          tl.to(children[index], {
            duration: 0,
            ease: "sine.out",
            opacity: 0,
            zIndex: 0,
            yPercent: 60,
          })
          tl.to(children[index], {
            duration: transitionDuration,
            ease: "sine.out",
            opacity: 1,
            zIndex: 1,
            yPercent: 0,
          })
          tl.to(children[index], {
            duration: transitionDuration,
            ease: "sine.out",
            opacity: 0.2,
            zIndex: 0,
            yPercent: -80,
            delay: transitionDuration * 2,
          })
          tl.to(children[index], {
            duration: transitionDuration * ((slides.length - 1) * 3),
            ease: "sine.out",
            opacity: 0,
            zIndex: 0,
            yPercent: -80,
          })
          tl.repeat(-1)
          tl.pause(0)
          timeLines.push(tl)
        })
        timeLines.map((timeLine, index) => {
          timeLine.play((timeLine.duration() / timeLines.length) * index)
        })
      }
    }
  }

  const updateSlideSize = () => {
    const blockRef = carouselBlockRef.current
    if (blockRef) {
      if (deviceInfo.viewport === "big") {
        blockRef.style.height = blockRef.offsetWidth + "px"
      }
      const newWidth = blockRef.clientWidth
      setWidth(newWidth)
    }
  }

  return (
    <div className={cx("container")} ref={containerRef}>
      <div className={cx("grid")}>
        <div className={cx("textBlock")}>
          {eyebrow && <span className={cx("eyebrowTag")}>{eyebrow}</span>}
          <h6 className={cx("titleTag")}>{title}</h6>
          {subcopy && <p className={cx("copyTag")}>{subcopy}</p>}
          {cta && (
            <Button
              {...cta}
              trackingObjects={[
                {
                  event: EVENTS.QUIZ_RESTART,
                },
              ]}
            />
          )}
        </div>
        <div
          className={cx("carouselBlock", {
            ["square"]: true,
          })}
          ref={carouselBlockRef}
        >
          <div
            className={cx("carouselContainer", {
              ["circular"]: true,
            })}
          >
            <div
              className={cx("carouselBackground")}
              ref={carouselBackgroundRef}
              style={
                image && {
                  backgroundImage: `url("${image.url}")`,
                }
              }
            />
            <div
              className={cx("carouselWrapper", {
                ["swap"]: true,
              })}
              ref={carouselWrapperRef}
            >
              {slides &&
                slides.map((slide, index) => {
                  const slideType = "square"
                  return (
                    <div
                      key={`slide-${index}`}
                      className={cx("slide", slideType)}
                      tabIndex={0}
                      onClick={() => {
                        reports({
                          event: EVENTS.IMAGE_CLICK,
                          type: "other_buckets",
                          title: `Bucket ${index + 1}`,
                        })
                      }}
                    >
                      {slideType !== "card" && slide.image && (
                        <img
                          src={slide.image.url}
                          alt={slide.image.alt ? slide.image.alt : slide.title}
                        />
                      )}
                    </div>
                  )
                })}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

ParallaxLockupC.propTypes = {
  title: PropTypes.string,
  eyebrow: PropTypes.string,
  subcopy: PropTypes.string,
  image: PropTypes.object,
  cta: PropTypes.object,
  slides: PropTypes.array,
}

ParallaxLockupC.defaultProps = {
  image: null,
}

const ParallaxLockupWithCarouselWrapper = props => (
  <DeviceInfo>
    {deviceInfo => <ParallaxLockupC deviceInfo={deviceInfo} {...props} />}
  </DeviceInfo>
)

export default ParallaxLockupWithCarouselWrapper
