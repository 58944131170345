import React, { createContext, useState } from "react"
import PropTypes from "prop-types"

export const QuizContext = createContext()

export const QuizProvider = ({ children }) => {
  const [questions, setQuestions] = useState({})
  const [result, setResult] = useState()
  const [facts, setFacts] = useState()

  return (
    <QuizContext.Provider
      value={{
        questions,
        setQuestions,
        result,
        setResult,
        facts,
        setFacts,
      }}
    >
      {children}
    </QuizContext.Provider>
  )
}

QuizProvider.propTypes = {
  children: PropTypes.node,
}
