import React, { useState, useEffect, useRef } from "react"
import PropTypes from "prop-types"
import classNames from "classnames/bind"

import * as styles from "./password.module.scss"
import { validatePassword } from "../../utils/helpers"

const Password = ({
  name,
  label,
  isRequired,
  maxLength,
  placeholder,
  helperText,
  defaultValue,
  isDisabled,
  autocomplete,
  defaultError,
  onChange,
  onError,
  valueToConfirm,
  isFromSignIn,
}) => {
  const [value, setValue] = useState(defaultValue)
  const [active, setActive] = useState(false)
  const [error, setError] = useState(defaultError)
  const [showPassword, toggleShowPassword] = useState(false)
  const [eyeClicked, toggleEyeClicked] = useState(false)

  const inputRef = useRef(null)

  const cx = classNames.bind(styles)

  useEffect(() => {
    setError(defaultError)
  }, [defaultError])

  useEffect(() => {
    inputRef.current.addEventListener("blur", () => {
      eyeClicked && inputRef.current.focus()
      eyeClicked && toggleEyeClicked(false)
    })
    return inputRef.current.removeEventListener("blur", () => {
      eyeClicked && inputRef.current.focus()
      eyeClicked && toggleEyeClicked(false)
    })
  }, [])

  const formInputClassName = cx({
    ["formInput"]: true,
    ["disabled"]: isDisabled,
  })

  const inputContainerClassName = cx({
    ["inputContainer"]: true,
    ["focused"]: active,
    ["filled"]: value && !error,
    ["hasError"]: error,
  })

  const helperContainerClassName = cx({
    ["helperContainer"]: true,
    ["hasError"]: error,
  })

  const handleMouseDown = e => {
    e.preventDefault()
    toggleShowPassword(!showPassword)
    toggleEyeClicked(true)
  }

  const handleOnChange = e => {
    if (e.target.value.length >= maxLength && maxLength !== 0) {
      setError("Character limit reached")
      onError(true)
    } else {
      setError(null)
      onError(false)
    }
    setValue(e.target.value)
    onChange(e.target.value)
  }

  const showDetails = () => {
    if (
      isDisabled ||
      (value && !active && !error) ||
      (!helperText && maxLength === 0 && !error)
    ) {
      return false
    }
    if (!isDisabled && (helperText || maxLength !== 0 || error)) {
      return true
    }
    return true
  }

  const handleOnBlur = () => {
    setActive(false)
    if (valueToConfirm) {
      if (valueToConfirm !== value) {
        setError("Passwords don't match.")
        onError("Passwords don't match.")
      }
    }
    if (value) {
      if (!validatePassword(value)) {
        if (isFromSignIn) {
          setError("Password incorrect")
          onError("Password incorrect")
        } else if (!valueToConfirm) {
          setError(
            "Password must contain min. 8 characters, mix of numbers, letters and upper and lower cases."
          )
          onError(
            "Password must contain min. 8 characters, mix of numbers, letters and upper and lower cases."
          )
        }
      } else if (name === "password" || name === "newPassword") {
        setError(null)
        onError(false)
      }
    } else {
      setError("This field is required")
      onError("This field is required")
    }
  }

  return (
    <div className={formInputClassName}>
      <label htmlFor={name}>
        {label}
        {isRequired && <span aria-hidden="true">*</span>}
      </label>
      <div className={inputContainerClassName} key={"input container"}>
        <input
          type={showPassword ? "text" : "password"}
          id={name}
          name={name}
          autoComplete={autocomplete}
          required={isRequired}
          placeholder={!active ? placeholder : ""}
          onChange={e => handleOnChange(e)}
          onFocus={() => setActive(true)}
          onBlur={() => handleOnBlur()}
          value={value}
          ref={inputRef}
          aria-describedby={`${name}-description`}
        />
        {value.length > 0 && active && (
          <i
            className={!showPassword ? cx("iconEyeReveal") : cx("iconEyeHide")}
            onMouseDown={e => handleMouseDown(e)}
          />
        )}
      </div>
      {showDetails() && (
        <div className={helperContainerClassName}>
          {(helperText || error) && (
            <span id={`${name}-description`}>{error || helperText}</span>
          )}
          {maxLength !== 0 && (
            <span className={cx("counter")}>{`${
              maxLength - value.length
            }/${maxLength}`}</span>
          )}
        </div>
      )}
    </div>
  )
}

Password.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  isRequired: PropTypes.bool,
  maxLength: PropTypes.number.isRequired,
  placeholder: PropTypes.string.isRequired,
  helperText: PropTypes.string,
  isDisabled: PropTypes.bool,
  autocomplete: PropTypes.oneOf(["new-password", "current-password"]),
  defaultError: PropTypes.oneOf([PropTypes.string, PropTypes.bool]),
  valueToConfirm: PropTypes.string,
  onChange: PropTypes.func,
  onError: PropTypes.func,
  isFromSignIn: PropTypes.bool,
}

Password.defaultProps = {
  name: "",
  label: "",
  isRequired: true,
  maxLength: 100,
  placeholder: "",
  helperText: "",
  defaultValue: "",
  autocomplete: "new-password",
  defaultError: "",
  valueToConfirm: "",
  isFromSignIn: false,
  onChange: () => {},
  onError: () => {},
}

export default Password
