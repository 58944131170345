import React, { useState, useEffect, useRef } from "react"
import PropTypes from "prop-types"
import classNames from "classnames/bind"

import * as styles from "./dropdown.module.scss"

const Dropdown = ({
  name,
  label,
  isRequired,
  placeholder,
  defaultValue,
  isDisabled,
  listItems,
  autocomplete,
  onSelect,
}) => {
  const [value, setValue] = useState(defaultValue)
  const [active, setActive] = useState(false)
  const [clicked, toggleClicked] = useState(false)

  const selectRef = useRef(null)

  const cx = classNames.bind(styles)

  let click = false
  const onMouseDown = () => {
    click = true
    setActive(false)
  }

  const onFocus = () => {
    setActive(!click)
  }

  const onBlur = () => {
    click = false
    setActive(false)
  }

  const handleFocusAndBlur = () => {
    selectRef.current.addEventListener("mousedown", onMouseDown)
    selectRef.current.addEventListener("focus", onFocus)
    selectRef.current.addEventListener("blur", onBlur)
  }

  const removeFocusAndBlur = () => {
    selectRef.current.removeEventListener("mousedown", onMouseDown)
    selectRef.current.removeEventListener("focus", onFocus)
    selectRef.current.removeEventListener("blur", onBlur)
  }

  useEffect(() => {
    handleFocusAndBlur()
    return removeFocusAndBlur()
  }, [])

  const formSelectClassName = cx({
    formDropdown: true,
    disabled: isDisabled,
    focused: active,
  })

  const selectContainerClassName = cx({
    selectContainer: true,
    filled: value,
  })

  const handleOnSelect = e => {
    setValue(e)
    onSelect(e)
  }

  return (
    <div className={formSelectClassName}>
      <label htmlFor={name}>
        {label}
        {isRequired && <span aria-hidden="true">*</span>}
      </label>
      <div className={selectContainerClassName}>
        <select
          key={name}
          id={name}
          name={name}
          autoComplete={autocomplete}
          required={isRequired}
          onChange={e => handleOnSelect(e.target.value)}
          value={value}
          ref={selectRef}
          disabled={isDisabled}
        >
          {placeholder && <option value={"all"}>{placeholder}</option>}
          {listItems.map((item, index) => {
            const value = item.value ? item.value : item.filter
            const label = item.label ? item.label : item.filter
            return (
              <option value={value} key={index}>
                {label}
              </option>
            )
          })}
        </select>
        <i className={cx("iconDown")} />
        <span className={cx("line")} />
      </div>
    </div>
  )
}

Dropdown.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  isRequired: PropTypes.bool,
  placeholder: PropTypes.string,
  isDisabled: PropTypes.bool,
  listItems: PropTypes.arrayOf(PropTypes.object).isRequired,
  autocomplete: PropTypes.string,
  onSelect: PropTypes.func,
}

Dropdown.defaultProps = {
  name: "",
  label: "",
  isRequired: true,
  placeholder: "",
  defaultValue: 0,
  listItems: [],
  autocomplete: "off",
  onSelect: () => {},
}

export default Dropdown
