import React, { useEffect, useState, useRef } from "react"
import PropTypes from "prop-types"
import DeviceInfo from "../../utils/device-info"
import QuestionOption from "../../atoms/QuestionOption/QuestionOption"
import ScaleOptionContainer from "../../molecules/ScaleOptionContainer/ScaleOptionContainer"
import classNames from "classnames/bind"

import * as styles from "./singleSelectContainer.module.scss"

const SingleSelectContainer = ({
  deviceInfo,
  image,
  selectedImage,
  options,
  selectedOption,
  onSelect,
  isScale,
  isMultiSingle,
  isMultiSingleNoPic,
}) => {
  const cx = classNames.bind(styles)
  const singleSelectContainer = useRef()
  const optionRef = useRef(null)
  const [currentOption, setCurrentOption] = useState()
  const [currentOptionImg, setCurrentOptionImg] = useState({})
  const [clicked, setClicked] = useState(false)
  const isMultiple = options[0] && options[0].options.length > 2 ? true : false
  const hasMainImage = image ? true : false
  const hasOptionImage =
    options[0] && options[0].options.some(option => option.image)

  const wrapperStyles = cx({
    grid: true,
    twoOptions: !isMultiple,
    multiOption: isMultiple,
    withOptionImage: hasOptionImage,
    withMainImage: hasMainImage,
  })

  useEffect(() => {
    setCurrentOption(selectedOption)

    if (hasOptionImage && isScale) {
      const currentSubOpt = options[0].options.filter(
        optn => optn.id === selectedOption
      )

      if (currentSubOpt && currentSubOpt[0]) {
        setCurrentOptionImg(currentSubOpt[0].image)
      }
    }
  }, [selectedOption, options, isScale])

  useEffect(() => {
    updateStyles(hasOptionImage)
  }, [hasOptionImage, isMultiSingleNoPic])

  const updateStyles = hasOptionImage => {
    const bool =
      hasOptionImage &&
      deviceInfo.viewport !== "small" &&
      options[0].options.length > 4 &&
      !isMultiSingle

    const container = singleSelectContainer.current
    const overFlowState = bool || isMultiSingleNoPic ? "hidden auto" : "unset"
    // set initial overflow
    container.style.overflow = overFlowState

    container.style.maxHeight =
      hasOptionImage && deviceInfo.viewport !== "small" ? `100%` : "none"

    // add breathing space between options and scrollbar to prevent overlapping
    if (
      deviceInfo.viewport !== "small" &&
      container.clientHeight < container.scrollHeight &&
      isMultiple
    ) {
      container.style.width = `${container.clientWidth + 24}px`
      container.style.paddingRight = "24px"
      container.style.marginRight = "-24px"
      // always show scrollbar when scroll is posible
      container.style.overflow = "hidden scroll"
    } else {
      container.style.width = ""
      container.style.paddingRight = ""
      container.style.marginRight = ""
      container.style.overflow = overFlowState
    }
  }
  const handleSelect = optionId => {
    setCurrentOption(optionId)
    onSelect(optionId)
  }

  return (
    <div
      className={cx("singleOptionWrapper", isMultiSingle && "fullHeight")}
      ref={singleSelectContainer}
    >
      {image ? (
        <div className={cx("imageBlock")}>
          {!selectedOption ? (
            <img src={image.url} alt={image.alt} />
          ) : (
            <img src={selectedImage.url} alt={selectedImage.alt} />
          )}
        </div>
      ) : isScale ? (
        <div className={cx("imageBlock", "backgroundHalo")}>
          <img src={currentOptionImg.url} alt={currentOptionImg.alt} />
        </div>
      ) : null}

      <div className={cx("selectOptionBlock")}>
        {isScale ? (
          <ScaleOptionContainer
            options={options[0].options}
            onSelect={id => handleSelect(id)}
            selectedOption={
              currentOption ? currentOption : options[0].options[0].id
            }
          />
        ) : (
          <div className={wrapperStyles}>
            <div className={cx("separation")}>
              <hr />
              <p>or</p>
              <hr />
            </div>
            {options[0] &&
              options[0].options.map(option => {
                const isDefault = image ? true : false
                const isSelected = option.id === currentOption

                const styles = cx({
                  colSix: true,
                  selected: isSelected,
                  noSelected: !isSelected,
                  idle: !currentOption,
                  noImageState: !isDefault,
                  withMainImage: hasMainImage,
                  noOptionImage: !hasOptionImage,
                  noImage: !option.image,
                })

                return (
                  <div
                    key={option.id}
                    className={styles}
                    ref={optionRef}
                    aria-hidden="true"
                  >
                    {isMultiSingle && <div className={cx("circle")}></div>}
                    <QuestionOption
                      title={option.title}
                      subtitle={option.subtitle}
                      id={option.id}
                      image={option.image}
                      selectedImage={option.selectedImage}
                      isSelected={isSelected}
                      onClick={id => {
                        handleSelect(id)
                      }}
                      isIdle={!currentOption}
                    />
                  </div>
                )
              })}
          </div>
        )}
      </div>
    </div>
  )
}

SingleSelectContainer.propTypes = {
  image: PropTypes.object,
  selectedImage: PropTypes.object,
  options: PropTypes.array,
  selectedOption: PropTypes.string,
  onSelect: PropTypes.func,
  isScale: PropTypes.bool,
}

SingleSelectContainer.defaultProps = {
  image: null,
  selectedImage: null,
  options: [[]],
  isScale: false,
}

const SingleSelectContainerWrapper = props => (
  <DeviceInfo>
    {deviceInfo => <SingleSelectContainer deviceInfo={deviceInfo} {...props} />}
  </DeviceInfo>
)

export default SingleSelectContainerWrapper
