import React, { useContext, useRef, useState } from "react"
import PropTypes from "prop-types"
import classNames from "classnames/bind"

import CopyLink from "../../atoms/CopyLink/CopyLink"
import SocialMedia from "../../atoms/SocialMedia/SocialMedia"

import { EVENTS, reports } from "../../utils/reports"
import DeviceInfo from "../../utils/device-info"

import * as styles from "./shareModal.module.scss"
import { ModalContext } from "../../../context/ModalContext"

const cx = classNames.bind(styles)

const handlePopup = (e, url) => {
  const height = 600 < window.innerHeight ? 600 : window.innerHeight
  const width = 600 < window.innerWidth ? 600 : window.innerWidth
  window.open(url, "popup", "height=" + height + ",width=" + width)
}

const ShareModal = ({ deviceInfo, clickableDots }) => {
  const [currentSlide, setCurrentSlide] = useState(0)

  const { getShareCompleteData } = useContext(ModalContext)

  const { linkUrl, shareImages, shareableCopy } = getShareCompleteData()

  const goToSlide = slide => {
    setCurrentSlide(slide)
  }

  return (
    <div className={cx("container")}>
      {deviceInfo.isMobile && (
        <div className={cx("mobileHeader")}>
          <h2>Share your test</h2>
          <p>Sharing is restricted to 21+ only.</p>
        </div>
      )}
      <div className={cx("imgCol")}>
        {shareImages.map((image, index) => {
          if (index === currentSlide) {
            return <img className={cx("img")} src={image.url} />
          } else {
            return null
          }
        })}
        <div className={cx("dotsWrapper")}>
          {shareImages.map((slide, index) => {
            return (
              <button
                key={`dot-${index}`}
                className={cx("dot", {
                  ["active"]: currentSlide === index,
                  ["clickable"]: clickableDots,
                })}
                onClick={() => goToSlide(index)}
              >
                {index}
              </button>
            )
          })}
        </div>
      </div>
      <div className={cx("textCol")}>
        {!deviceInfo.isMobile && (
          <>
            <h2>Share your test</h2>
            <p>Sharing is restricted to 21+ only.</p>
          </>
        )}
        <div className={cx("media")}>
          <div className={cx("socialMedia")}>
            <a
              data-gtm-social-share="twitter"
              data-gtm-url={linkUrl}
              target="popup"
              href={`http://twitter.com/share?url=${linkUrl}&text=${shareableCopy}`}
              onClick={e => {
                reports({
                  event: EVENTS.QUIZ_SHARE,
                  method: "Twitter",
                })
                return handlePopup(
                  e,
                  `http://twitter.com/share?url=${linkUrl}&text=${shareableCopy}`
                )
              }}
            >
              <SocialMedia variant="twitter" />
            </a>
            <a
              data-gtm-social-share="facebook"
              data-gtm-url={linkUrl}
              target="popup"
              href={`http://www.facebook.com/sharer.php?u=${linkUrl}&display=popup`}
              onClick={e => {
                reports({
                  event: EVENTS.QUIZ_SHARE,
                  method: "Facebook",
                })
                return handlePopup(
                  e,
                  `http://www.facebook.com/sharer.php?u=${linkUrl}&display=popup`
                )
              }}
            >
              <SocialMedia variant="facebook" />
            </a>
            <a
              data-gtm-social-share="instagram"
              data-gtm-url={linkUrl}
              download="share"
              href={shareImages ? shareImages[0]?.url : ""}
              target="_blank"
              onClick={() => {
                reports({
                  event: EVENTS.QUIZ_SHARE,
                  method: "Instagram",
                })
              }}
            >
              <SocialMedia variant="instagram" />
            </a>
            <a
              data-gtm-social-share="url link"
              data-gtm-url={linkUrl}
              download="share"
              href={shareImages ? shareImages[0]?.url : ""}
              target="_blank"
              onClick={() => {
                reports({
                  event: EVENTS.QUIZ_SHARE,
                  method: "Download",
                })
              }}
            >
              <SocialMedia variant="download" />
            </a>
          </div>
          <CopyLink url={linkUrl} />
        </div>
      </div>
    </div>
  )
}

const ShareModalWrapper = props => (
  <DeviceInfo>
    {deviceInfo => <ShareModal deviceInfo={deviceInfo} {...props} />}
  </DeviceInfo>
)

ShareModal.propTypes = {
  title: PropTypes.string,
  subCopy: PropTypes.string,
  linkUrl: PropTypes.string,
  ctasTitle: PropTypes.string,
  images: PropTypes.array,
  primaryCta: PropTypes.object,
  secondaryCta: PropTypes.object,
  animation: PropTypes.string,
  clickableDots: PropTypes.bool,
  shareableCopy: PropTypes.string,
  hideRetakeCta: PropTypes.bool,
}

ShareModal.defaultProps = {
  animation: "horizontal-slide",
  clickableDots: true,
  hideRetakeCta: false,
}

ShareModal.defaultProps = {}

export default ShareModalWrapper
