export const EVENTS = {
  LOGIN: "login",
  REGISTER: "register",
  QUIZ_START_BUTTOM: "quizStart",
  QUIZ_NEXT_BUTTOM: "quizNextButtom",
  QUIZ_BACK_BUTTOM: "quizBack",
  QUIZ_ANSWER: "quizAnswer",
  QUIZ_COMPLETE: "quizComplete",
  QUIZ_RESTART: "quizRestart",
  QUIZ_SHARE: "quizShare",
  QUIZ_SAVE: "quizSave",
  ADD_TO_FAVORITE: "addToFavorite",
  REMOVE_FROM_FAVORITE: "removeFromFavorite",
  CAROUSEL_INTERACTION: "carouselInteraction",
  IMAGE_CLICK: "imageClick",
  BUY_NOW_CLICK: "buyNowClick",
  PAGEVIEW_QUIZ: "pageviewQuiz",
  PAGEVIEW_RESULTS: "pageviewResults",
  PAGEVIEW_INTERSTITIAL: "pageviewInterstitial",
  NEWSLETTER_SIGN_UP: "newsLetterSignUp"
}
const METHODS = {
  // Data Layer Events
  login: ({userFirstName: first_name , userLastName: last_name , userEmail: email , userBirthDate: birthdate , userCity: city , userState: state , userCountry: country }) => ({
    event: "login",
    user:{
      login_status: 'true',
      user_id: 'undefined',
      email,
      first_name,
      last_name,
      birthdate,
      address: 'undefined',
      zip: 'undefined',
      state,
      city,
      country
      }
  }),
  register: ({ method: loginMethod, login_status: logged_in_user , userFirstName: first_name , userLastName: last_name , userEmail: email , userBirthDate: birthdate , userCity: city , userState: state , userCountry: country }) => ({
    event: "e_accountRegister",
    loginMethod,
    user:{
      login_status:logged_in_user,
      user_id: 'undefined',
      email,
      first_name,
      last_name,
      birthdate,
      address: 'undefined',
      zip: 'undefined',
      state,
      city,
      country
    }
  }),
  newsLetterSignUp :({ login_status: logged_in_user, email: email, first_name: first_name, last_name: last_name, birthdate: birthdate, state: state, city: city, country: country})=>({
    event: "newsletter_sign_up",
    user: {
      logged_in_user,
      email,
      first_name,
      last_name,
      birthdate,
      address: 'undefined',
      zip: 'undefined',
      state,
      city,
      country
    }
  }),
  quizStart: ({ header: header_name }) => ({
    event: "quiz_start",
    quiz_name: "flavor finder",
    header_name,
  }),
  quizNextButtom: ({
    question: quiz_question,
    number: quiz_question_number,
  }) => ({
    event: "quiz_next_button",
    quiz_name: "flavor finder",
    quiz_question,
    quiz_question_number,
  }),
  quizAnswer: ({
    question: quiz_question,
    number: quiz_question_number,
    answers: quiz_answers,
  }) => ({
    event: "quiz_answer",
    quiz_name: "flavor finder",
    quiz_question,
    quiz_question_number,
    quiz_answers,
  }),
  quizBack: ({ question: quiz_question, number: quiz_question_number }) => ({
    event: "quiz_back",
    quiz_name: "flavor finder",
    quiz_question,
    quiz_question_number,
  }),
  quizComplete: ({ profile: flavor_finder_profile }) => ({
    event: "quiz_complete",
    quiz_name: "flavor finder",
    flavor_finder_profile,
  }),
  quizRestart: () => ({
    event: "quiz_restart",
    quiz_name: "flavor finder",
  }),
  quizShare: ({ method }) => ({
    event: "quiz_share",
    quiz_name: "flavor finder",
    method,
  }),
  quizSave: () => ({
    event: "quiz_save",
    quiz_name: "flavor finder",
  }),
  carouselInteraction: ({ direction, title: carousel_title }) => ({
    event: "carousel_scroll",
    direction,
    carousel_title,
  }),
  imageClick: ({ title: image_title, type: content_type }) => ({
    event: "image_click",
    content_type,
    image_title,
  }),
  buyNowClick: ({ bottle: bottle_name }) => ({
    event: "buy_now_click",
    bottle_name,
  }),
  addToFavorite: ({ title, type: content_type }) => ({
    event: "add_to_favorite",
    content_type,
    title,
  }),
  removeFromFavorite: ({ title, type: content_type }) => ({
    event: "remove_from_favorite",
    content_type,
    title,
  }),
  // Virtual Pageviews
  pageviewQuiz: ({
    question: quiz_question,
    number: quiz_question_number,
  }) => ({
    event: "pageview",
    component: "quiz",
    quiz_name: "flavor finder",
    quiz_page_type: "question",
    quiz_question,
    quiz_question_number,
  }),
  pageviewInterstitial: ({ interstitial: quiz_interstitial }) => ({
    event: "pageview",
    component: "quiz",
    quiz_name: "flavor finder",
    quiz_page_type: "interstitial",
    quiz_interstitial,
  }),
  pageviewResults: () => ({
    event: "pageview",
    component: "quiz",
    quiz_name: "flavor finder",
    quiz_page_type: "results",
  }),
}

export const reports = ({ event, ...values }) => {
  const report = METHODS[event](values)
  if (window.dataLayer) {
    window.dataLayer.push(report)
  }
}
