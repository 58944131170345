// extracted by mini-css-extract-plugin
export var wrapper = "modal-module--wrapper--15LVd";
export var container = "modal-module--container--3AcPv";
export var close = "modal-module--close--7DMlG";
export var big = "modal-module--big--1ZXTs";
export var cross = "modal-module--cross--2BoOb";
export var modal = "modal-module--modal--3chbH";
export var save = "modal-module--save--2A1Gu";
export var bodyContainer = "modal-module--bodyContainer--1nkuy";
export var optIn = "modal-module--optIn--XEHVT";
export var share = "modal-module--share--3enG_";
export var socialMedia = "modal-module--socialMedia--1TJkE";