import React, { useState } from "react"
import PropTypes from "prop-types"
import classNames from "classnames/bind"
import DeviceInfo from "../../utils/device-info"

import * as styles from "./recipeInstruction.module.scss"

import ContentCard from "../ContentCard/ContentCard"

const RecipeInstruction = ({
  ingredients,
  instructions,
  productCard,
  deviceInfo,
}) => {
  const [isClosed, setIsClosed] = useState(false)

  const cx = classNames.bind(styles)

  const iconClassname = cx({
    ["accordionOpened"]: !isClosed,
    ["accordionClosed"]: isClosed,
  })

  return (
    <div className={cx("container")}>
      <div className={cx("textColumn")}>
        <h4
          onClick={() => {
            deviceInfo.isMobile && setIsClosed(!isClosed)
          }}
        >
          ingredients and how to craft{" "}
          {deviceInfo.isMobile && <i className={iconClassname} />}
        </h4>
        {!isClosed && (
          <>
            <div className={cx("ingredients")}>
              <h3>What you need</h3>
              <ul>
                {ingredients.map((ingredient, index) => {
                  return (
                    <li key={index}>
                      <p>{ingredient}</p>{" "}
                    </li>
                  )
                })}
              </ul>
            </div>
            <div className={cx("instructions")}>
              <h3>How to craft</h3>
              <p>{instructions}</p>
            </div>
          </>
        )}
      </div>
      {!isClosed && productCard?.image?.url && (
        <div className={cx("cardColumn")}>
          <h4>featured product</h4>
          <div className={cx("cardContainer")}>
            <ContentCard
              header={productCard.header}
              image={productCard.image}
              mobileImage={productCard.mobileImage}
              type={productCard.type}
            />
          </div>
        </div>
      )}
    </div>
  )
}

RecipeInstruction.propTypes = {
  ingredients: PropTypes.arrayOf(PropTypes.string),
  instructions: PropTypes.string,
  productCard: PropTypes.object,
}

RecipeInstruction.defaultProps = {
  ingredients: [],
  instructions: "",
  productCard: null,
}

const RecipeHeroWrapper = props => (
  <DeviceInfo>
    {deviceInfo => <RecipeInstruction deviceInfo={deviceInfo} {...props} />}
  </DeviceInfo>
)

export default RecipeHeroWrapper
