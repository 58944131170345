import React from "react"

import { navigate } from "@reach/router"
import { withPrefix } from "../with-prefix"

import QuizContainer from "../../organisms/QuizContainer/QuizContainer"
import ErrorPage from "../errorPage/errorPage"
import LoadingPage from "../loadingPage/loadingPage"

import { useQuizQuestions } from "../../../hooks/useQuizQuestions"
import { useQuizResult } from "../../../hooks/useQuizResults"
import { useWhiskeyFact } from "../../../hooks/useWhiskeyFacts"
import { lastQuizOptions, lastRegistrationSource } from "../session-variable"
import { EVENTS, reports } from "../reports"
import QuestionBreaker from "../../organisms/QuestionBreaker/QuestionBreaker"

const QuizPage = () => {
  const {
    questions: structure,
    loading: queryLoading,
    error: queryError,
  } = useQuizQuestions()

  const {
    result,
    sendResult,
    loading: resultLoading,
    error: resultError,
  } = useQuizResult()

  const {
    facts,
    setFacts,
    loading: factLoading,
    error: factError,
  } = useWhiskeyFact()

  const [showBreaker, setShowBreaker] = React.useState(false)

  React.useEffect(() => {
    if (resultLoading) {
      setShowBreaker(true)
    }
  }, [resultLoading])

  const onSendResults = options => {
    lastQuizOptions().set(options)
    lastRegistrationSource().set("FRONTEND_WHISKYFINDER")
    return sendResult({ variables: { options } })
  }

  const handleShowResults = () => {
    if (result) {
      reports({
        event: EVENTS.QUIZ_COMPLETE,
        profile: result.flavorFinderSubmit.bucket.name,
      })
      navigate(withPrefix("/whiskey-finder/results"))
    }
  }

  if (queryLoading) {
    return <LoadingPage />
  }

  if (queryError || resultError) {
    return <ErrorPage />
  }

  if (showBreaker) {
    return (
      <QuestionBreaker
        start={true}
        isFact={true}
        percent={100}
        action={handleShowResults}
        facts={facts}
      />
    )
  }

  return (
    <QuizContainer
      structure={structure}
      sendResults={onSendResults}
      brandLogoImage="https://via.placeholder.com/102x28"
    />
  )
}

export default QuizPage
