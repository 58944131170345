import * as React from "react"
import classNames from "classnames/bind"

import * as styles from "./videoPage.module.scss"
import VideoCopyLockup from "../../atoms/VideoCopyLockup/VideoCopyLockup"
import VideoPlayer from "../../atoms/VideoPlayer/VideoPlayer"
import ArticleDetail from "../../organisms/ArticleDetail/ArticleDetail"
import RelatedContent from "../../organisms/RelatedContent/RelatedContent"
import BreadCrumbs from "../../atoms/BreadCrumbs/BreadCrumbs"
import { withPrefix } from "../../utils/with-prefix"

const cx = classNames.bind(styles);

const VideoPage = ({ piece }) => {
  const {
    title,
    description,
    fieldBeamBourbonuVideo,
    fieldBeamBourbonuCategory,
    fieldBeamBourbonuContent,
    fieldBeamBourbonuRelPieces,
    fieldBeamBourbonuBreadcrumbs,
    entityId,
    relatedContent,
  } = piece
  let relatedCardsProps = { cards: null }

  const videoPlayerArgs = {
    sourceUrl:
      fieldBeamBourbonuVideo?.entity?.fieldBeamBourbonuFile?.entity?.url,
    linkUrl: fieldBeamBourbonuVideo?.entity?.fieldBeamBourbonuVideoEmbed,
  }

  const videoCopyLockupArgs = {
    category: fieldBeamBourbonuCategory?.entity?.name,
    title,
    description: description.value,
    pieceId: entityId,
  }

  const articles = fieldBeamBourbonuContent.map(({ entity }) => ({
    copy: entity?.fieldBeamBourbonuCopy?.value,
    image: entity?.fieldBeamBourbonuImage?.entity?.fieldBeamBourbonuImage
      ?.url && {
      url: entity?.fieldBeamBourbonuImage?.entity?.fieldBeamBourbonuImage?.url,
    },
    isFullBleed: entity?.fieldBeamBourbonuImgPosition === "fullbleed",
  }))

  const relatedContentArgs = {
    eyebrow: "more to explore",
    cards: fieldBeamBourbonuRelPieces.map(({ entity }) => ({
      eyebrow: "our story",
      header: entity?.title,
      entityId: entity?.entityId,
      entityBundle: entity?.entityBundle,
      copy: entity?.description?.value,
      image: entity?.fieldBeamBourbonuVideo
        ? { url: entity.fieldBeamBourbonuVideo.entity.thumbnail.url }
        : entity?.fieldBeamBourbonuImage && {
            url: entity.fieldBeamBourbonuImage.entity.thumbnail.url,
          },
      mobileImage: entity?.fieldBeamBourbonuVideo
        ? { url: entity.fieldBeamBourbonuVideo.entity.thumbnail.url }
        : entity?.fieldBeamBourbonuImage && {
            url: entity.fieldBeamBourbonuImage.entity.thumbnail.url,
          },
      type: "related",
      url: withPrefix(entity?.entityUrl?.path),
      variant: "2UP",
      hasSave: false,
      hasShare: false,
      cta: {
        text: "Read Now",
        url: withPrefix(entity?.entityUrl?.path),
        type: "tertiary",
        variant: "positive",
        icon: true,
        iconAlignment: "right",
      },
    })),
  }

  relatedCardsProps.eyebrow = "related"
  relatedContent?.forEach((piece, index) => {
    const card = {
      eyebrow: "our story",
      header: piece?.title,
      entityId: piece?.entityId,
      entityBundle: piece?.entityBundle,
      copy: piece?.description?.value,
      image: {
        url: piece?.fieldBeamBourbonuImages
          ? piece?.fieldBeamBourbonuImages[0]?.entity?.thumbnail?.url
          : piece?.fieldBeamBourbonuImage?.entity?.fieldBeamBourbonuImage.url,
        alt: "Related content image",
      },
      mobileImage: {
        url: piece?.fieldBeamBourbonuImages
          ? piece?.fieldBeamBourbonuImages[1]?.entity?.thumbnail?.url
          : piece?.fieldBeamBourbonuImage?.entity?.fieldBeamBourbonuImage.url,
        alt: "Related content image",
      },
      type: "related",
      url: withPrefix(piece?.entityUrl?.path),
      variant: "2UP",
      hasSave: false,
      hasShare: false,
      cta: {
        text: "Read Now",
        url: withPrefix(piece?.entityUrl?.path),
        type: "tertiary",
        variant: "positive",
        icon: true,
        iconAlignment: "right",
      },
    }
    relatedCardsProps.cards
      ? relatedCardsProps.cards.push(card)
      : (relatedCardsProps.cards = [card])
  })

  if (fieldBeamBourbonuRelPieces.length <= 1 && relatedCardsProps.cards) {
    relatedContentArgs.cards = [...relatedCardsProps.cards].filter(
      elem => elem.entityId !== entityId && elem.entityBundle !== "recipe"
    )
  }

  const breadcrumbs =
    fieldBeamBourbonuBreadcrumbs &&
    fieldBeamBourbonuBreadcrumbs
      .map(breadcrumb => {
        return {
          url: breadcrumb.entity?.path?.alias,
          label: breadcrumb.entity?.title,
        }
      })
      .concat({ label: title })

  return (
    <div className={cx('videoPageContainer')}>
      {breadcrumbs && (
        <BreadCrumbs
          breadcrumbs={breadcrumbs}
          isWhiteFont={false}
          video={true}
        />
      )}
      <VideoPlayer {...videoPlayerArgs} />
      <VideoCopyLockup {...videoCopyLockupArgs} />
      {articles.map(args => (
        <ArticleDetail {...args} />
      ))}
      {relatedContentArgs?.cards?.length > 0 && (
        <RelatedContent {...relatedContentArgs} />
      )}
    </div>
  )
}

export default VideoPage
