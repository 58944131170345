import React, { useState, useEffect, useRef } from "react"
import PropTypes from "prop-types"
import classNames from "classnames/bind"

import * as styles from "./input.module.scss"
import { validateBirth, validateEmail } from "../../utils/helpers"

const Input = ({
  name,
  label,
  isRequired,
  maxLength,
  placeholder,
  helperText,
  defaultValue,
  isDisabled,
  type,
  autocomplete,
  defaultError,
  onChange,
  onError,
}) => {
  const [value, setValue] = useState(defaultValue)
  const [active, setActive] = useState(false)
  const [error, setError] = useState(defaultError)
  const [crossClicked, toggleCrossClicked] = useState(false)

  useEffect(() => {
    setError(defaultError)
  }, [defaultError])

  const inputRef = useRef(null)

  const cx = classNames.bind(styles)

  useEffect(() => {
    inputRef.current.addEventListener("blur", () => {
      crossClicked && inputRef.current.focus()
      crossClicked && toggleCrossClicked(false)
    })
    return inputRef.current.removeEventListener("blur", () => {
      crossClicked && inputRef.current.focus()
      crossClicked && toggleCrossClicked(false)
    })
  }, [])

  const formInputClassName = cx({
    formInput: true,
    disabled: isDisabled,
  })

  const inputContainerClassName = cx({
    inputContainer: true,
    focused: active,
    filled: value && !error,
    hasError: error,
  })

  const helperContainerClassName = cx({
    helperContainer: true,
    hasError: error,
  })

  const handleMouseDown = e => {
    e.preventDefault()
    toggleCrossClicked(true)
    setValue("")
    onChange("")
  }

  const handleOnChange = e => {
    if (e.target.value.length >= maxLength && maxLength !== 0) {
      setError("Character limit reached")
      onError(true)
    } else if (!defaultError) {
      setError(null)
      onError(false)
    }
    setValue(e.target.value)
    onChange(e.target.value)
  }

  const handleOnBlur = () => {
    setActive(false)
    if (type === "date" && !value) {
      setValue("yyyy-mm-dd")
      setError(null)
      onError(false)
    }
    if (type === "date" && value) {
      if (!validateBirth(value, true)) {
        setError("You don’t meet legal drinking age requirements.")
        onError(true)
      }
    }
    if (type === "email" && value) {
      if (!validateEmail(value)) {
        setError("Not valid email")
        onError(true)
      }
    }
    if (!value) {
      setError("This field is required")
      onError("This field is required")
    }
  }

  return (
    <div className={formInputClassName}>
      <label id={`${name}-label`} htmlFor={name}>
        {label}
        {isRequired && type !== "date" && <span aria-hidden="true">*</span>}
      </label>
      <div className={inputContainerClassName}>
        <input
          type={type}
          id={name}
          name={name}
          autoComplete={autocomplete}
          required={isRequired}
          placeholder={!active ? placeholder : ""}
          onChange={e => handleOnChange(e)}
          onFocus={() => setActive(true)}
          onBlur={() => handleOnBlur()}
          value={value}
          ref={inputRef}
          key={name}
          aria-describedby={`${name}-description ${name}-label`}
        />
        {value && active && (
          <i
            className={cx("iconClose")}
            onMouseDown={e => handleMouseDown(e)}
            id={"cross"}
          />
        )}
      </div>
      {!isDisabled && (helperText || maxLength !== 0 || error) && (
        <div className={helperContainerClassName}>
          {(helperText || error) && (
            <span id={`${name}-description`}>{error || helperText}</span>
          )}
          {maxLength !== 0 && (
            <span className={cx("counter")}>{`${
              maxLength - value.length
            }/${maxLength}`}</span>
          )}
        </div>
      )}
    </div>
  )
}

Input.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  isRequired: PropTypes.bool,
  maxLength: PropTypes.number.isRequired,
  placeholder: PropTypes.string.isRequired,
  helperText: PropTypes.string,
  isDisabled: PropTypes.bool,
  type: PropTypes.oneOf(["text", "number", "email", "date"]),
  autocomplete: PropTypes.string,
  defaultError: PropTypes.string,
  onChange: PropTypes.func,
  onError: PropTypes.func,
}

Input.defaultProps = {
  name: "",
  label: "",
  isRequired: true,
  maxLength: 100,
  placeholder: "",
  helperText: "",
  defaultValue: "",
  type: "text",
  autocomplete: "off",
  defaultError: "",
  onChange: () => {},
  onError: () => {},
}

export default Input
