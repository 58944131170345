// extracted by mini-css-extract-plugin
export var container = "contentCard-module--container--r2uvs";
export var colTwo = "contentCard-module--colTwo--1gS9q";
export var content = "contentCard-module--content--1ISjK";
export var colThree = "contentCard-module--colThree--31wiZ";
export var recipe = "contentCard-module--recipe--2kibV";
export var main = "contentCard-module--main--9zoZH";
export var interaction = "contentCard-module--interaction--2qMaj";
export var ctaWrapper = "contentCard-module--ctaWrapper--nWOmM";
export var eyebrow = "contentCard-module--eyebrow--2ddc9";
export var header = "contentCard-module--header--1z6Jn";
export var copy = "contentCard-module--copy--3ReW1";
export var video = "contentCard-module--video--27sXj";
export var related = "contentCard-module--related--1HV9H";
export var ctaBottom = "contentCard-module--ctaBottom--TE7GL";
export var carouselCard = "contentCard-module--carouselCard--29BOL";
export var background = "contentCard-module--background--3AQwd";
export var imageWrapper = "contentCard-module--imageWrapper--1J1HG";
export var info = "contentCard-module--info--3CJ6r";
export var data = "contentCard-module--data--2vYUW";
export var features = "contentCard-module--features--3uMUN";
export var feature = "contentCard-module--feature--2VwPN";
export var actions = "contentCard-module--actions--3CtnF";
export var share = "contentCard-module--share--I5msi";
export var save = "contentCard-module--save--1kKf0";
export var separator = "contentCard-module--separator--J-U9d";
export var play = "contentCard-module--play--3Ntyz";
export var playIcon = "contentCard-module--playIcon--1Tr3g";
export var variantActions = "contentCard-module--variantActions--3y0lQ";