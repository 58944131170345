// extracted by mini-css-extract-plugin
export var container = "questionBreaker-module--container--1FRXl";
export var hasFact = "questionBreaker-module--hasFact--2GWW8";
export var copyContainer = "questionBreaker-module--copyContainer--1csYn";
export var factWrapper = "questionBreaker-module--factWrapper--37buY";
export var factTitle = "questionBreaker-module--factTitle--2U6jR";
export var hasImage = "questionBreaker-module--hasImage--x-4fj";
export var factSubTitle = "questionBreaker-module--factSubTitle--2WAAz";
export var factCopy = "questionBreaker-module--factCopy--1a4mY";
export var factHeader = "questionBreaker-module--factHeader--JN8fO";
export var factSubHeader = "questionBreaker-module--factSubHeader--3KKsF";