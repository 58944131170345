import React, { useEffect, useRef, useState } from "react"
import PropTypes from "prop-types"
import classNames from "classnames/bind"

import { gsap } from "gsap"

import SlideCardTextColumn from "../../molecules/SlideCard/SlideCardTextColumn"
import SlideCardImageColumn from "../../molecules/SlideCard/SlideCardImageColumn"
import { Draggable } from "gsap/Draggable"

import DeviceInfo from "../../utils/device-info"
import { EVENTS, reports } from "../../utils/reports"

import * as styles from "./ParallaxLockup.module.scss"

const ParallaxLockup = ({ deviceInfo, slides }) => {
  const [currentSlide, setCurrentSlide] = useState(0)

  const cx = classNames.bind(styles)
  const imageColumnsRef = useRef([])
  const containerRef = useRef(null)
  const textColumnsRef = useRef([])
  const [dragStart, setDragStart] = useState(0)
  const [dragEnd, setDragEnd] = useState(0)

  useEffect(() => {
    if (!deviceInfo.isMobile && slides.length > 0) {
      document.addEventListener("keyup", handleKeyUp)
      return document.addEventListener("keyup", handleKeyUp)
    } else {
      setDraggableAnimation()
      updateSize()
    }
  }, [])

  const handleKeyUp = e => {
    if (e.target.name === "navigation-tab") {
      if (e.key === "Shift" || e.keyCode === 16) {
        e.target.blur()
        if (e.target.id - 1 >= 0) {
          handleNavigation(parseInt(e.target.id - 1))
        }
      }
      if (e.key === "Tab" || e.keyCode === 9) {
        e.target.blur()
        handleNavigation(parseInt(e.target.id))
      }
    }
  }

  useEffect(() => {
    if (dragStart > dragEnd) {
      // move foward
      if (currentSlide + 1 < slides.length) {
        handleNavigation(currentSlide + 1)
        setCurrentSlide(currentSlide + 1)
      } else {
        handleNavigation(0)
        setCurrentSlide(0)
      }
    } else if (dragStart < dragEnd) {
      // move back
      if (currentSlide - 1 >= 0) {
        handleNavigation(currentSlide - 1)
        setCurrentSlide(currentSlide - 1)
      } else {
        handleNavigation(slides.length - 1)
        setCurrentSlide(slides.length - 1)
      }
    }
  }, [dragEnd])

  const handleNavigation = slide => {
    if (slides.length > 1) {
      setCurrentSlide(slide)
      setAnimation(slide)
      if (deviceInfo.isMobile) {
        updateSize(slide)
      }
    }
  }

  const setDraggableAnimation = () => {
    if (deviceInfo.isMobile && !Draggable.get(imageColumnsRef.current[0])) {
      imageColumnsRef.current.forEach((imageColumn, index) => {
        Draggable.create(imageColumn, {
          type: "x",
          edgeResistance: 0.5,
          dragResistance: 0.95,
          bounds: {
            minX: 0,
            maxX: 0,
          },
          lockAxis: true,
          onDragStart: args => {
            setDragStart(args.clientX)
          },
          onDragEnd: args => {
            setDragEnd(args.clientX)
          },
        })
      })
    } else {
      imageColumnsRef.current.forEach(imageColumn => {
        Draggable.get(imageColumn)?.kill()
      })
    }
  }

  const updateSize = (slide = currentSlide) => {
    textColumnsRef.current.forEach((textColumn, index) => {
      if (index === slide) {
        textColumn.offsetParent.style.height =
          textColumn.children[0].offsetHeight + 66 + "px"
      }
    })
    imageColumnsRef.current.forEach((imageColumn, index) => {
      if (index === slide) {
        imageColumn.offsetParent.style.height =
          imageColumn.children[0].offsetHeight + "px"
      }
    })
  }

  const onClickImage = title => {
    reports({
      event: EVENTS.IMAGE_CLICK,
      type: "bottles",
      title,
    })
  }

  const setAnimation = slide => {
    const transitionDuration = 0.5
    textColumnsRef.current.forEach((textColumn, index) => {
      gsap.to(textColumn, {
        duration: transitionDuration,
        ease: "sine.out",
        opacity: 0,
        zIndex: 0,
      })
      if (slide === index) {
        gsap.to(textColumn, {
          duration: transitionDuration,
          ease: "sine.in",
          opacity: 1,
          zIndex: 10,
          delay: transitionDuration,
        })
      }
    })

    imageColumnsRef.current.forEach((imageColumn, index) => {
      gsap.to(imageColumn, {
        duration: transitionDuration,
        ease: "sine.out",
        opacity: 0,
        zIndex: 0,
        yPercent: -10,
      })

      if (slide === index) {
        gsap.to(imageColumn, {
          duration: transitionDuration / 10,
          ease: "none",
          opacity: 0,
          zIndex: 0,
          delay: transitionDuration,
          yPercent: 10,
        })
        gsap.to(imageColumn, {
          duration: transitionDuration - transitionDuration / 10,
          ease: "sine.in",
          opacity: 1,
          zIndex: 10,
          delay: transitionDuration + transitionDuration / 10,
          yPercent: 0,
        })
      }
    })
  }

  return !deviceInfo.isMobile || deviceInfo.viewport == 'large' || deviceInfo.viewport == 'big'  ? (
    <div className={cx("wrapper")} ref={containerRef}>
      {slides.length > 1 && (
        <div className={cx("prev")}>
          <span
            onClick={() =>
              handleNavigation(
                currentSlide === 0 ? slides.length - 1 : currentSlide - 1
              )
            }
          />
        </div>
      )}
      <div className={cx("grid")}>
        <div className={cx("leftCol")}>
          <div className={cx("slidesContainer")}>
            {slides.map((slide, index) => {
              return (
                <div
                  ref={slide => (textColumnsRef.current[index] = slide)}
                  id={index}
                  key={index}
                >
                  {!deviceInfo.isMobile || deviceInfo.viewport == 'large' || deviceInfo.viewport == 'big'  && (
                    <button
                      className={cx("sliderTab")}
                      id={index}
                      name={"navigation-tab"}
                      aria-label={`You are on the slide ${index + 1} of ${slides.length
                        }`}
                    />
                  )}
                  <SlideCardTextColumn
                    {...slide}
                    slide={(index + 1).toString()}
                    totalSlides={slides.length.toString()}
                  />
                </div>
              )
            })}
          </div>
        </div>
        <div className={cx("rightCol")}>
          <div className={cx("slidesContainer")}>
            {slides.map((slide, index) => {
              return (
                <div
                  ref={slide => (imageColumnsRef.current[index] = slide)}
                  id={index}
                  key={index}
                >
                  <SlideCardImageColumn
                    image={slide.image}
                    onImageClick={() => onClickImage(slide.bottleName)}
                  />
                </div>
              )
            })}
          </div>
        </div>
      </div>
      {slides.length > 1 && (
        <div className={cx("next")}>
          <span
            onClick={() =>
              handleNavigation(
                currentSlide + 1 === slides.length ? 0 : currentSlide + 1
              )
            }
          />
        </div>
      )}
    </div>
  ) : (
    <div className={cx("wrapper")}>
      <div className={cx("grid")}>
        <div className={cx("leftCol")}>
          <div className={cx("carouselNavigationController")}>
            <h4>Top pick</h4>
            <div>
              <h4>{`(${currentSlide + 1}/${slides.length})`}</h4>
              {deviceInfo.isMobile && deviceInfo.viewport == 'small' || deviceInfo.viewport == 'medium' && (
                <span
                  className={cx("iconPrev")}
                  onClick={() =>
                    handleNavigation(
                      currentSlide === 0 ? slides.length - 1 : currentSlide - 1
                    )
                  }
                />
              )}
              {deviceInfo.isMobile && deviceInfo.viewport == 'small' || deviceInfo.viewport == 'medium' && (
                <span
                  className={cx("iconNext")}
                  onClick={() =>
                    handleNavigation(
                      currentSlide + 1 === slides.length ? 0 : currentSlide + 1
                    )
                  }
                />
              )}
            </div>
          </div>
          <div className={cx('slidesContainer')}>
            {slides.map((slide, index) => {
              return (
                <div
                  ref={slide => (textColumnsRef.current[index] = slide)}
                  id={index}
                  key={index}
                >
                  {!deviceInfo.isMobile || deviceInfo.viewport == 'large' || deviceInfo.viewport == 'big' && (
                    <button
                      className={cx("sliderTab")}
                      id={index}
                      name={"navigation-tab"}
                      aria-label={`You are on the slide ${index + 1} of ${slides.length
                        }`}
                    />
                  )}
                  <SlideCardTextColumn
                    {...slide}
                    onPrevClick={() =>
                      handleNavigation(
                        currentSlide === 0 ? slides.length - 1 : currentSlide - 1
                      )
                    }
                    onNextClick={() =>
                      handleNavigation(
                        currentSlide + 1 === slides.length ? 0 : currentSlide + 1
                      )
                    }
                    slide={(index + 1).toString()}
                    totalSlides={slides.length.toString()}
                  />
                </div>
              )
            })}
          </div>
        </div>
        <div className={cx("rightCol")}>
          <div className={cx("slidesContainer")}>
            {slides.map((slide, index) => {
              return (
                <div
                  ref={slide => (imageColumnsRef.current[index] = slide)}
                  id={index}
                  key={index}
                >
                  <SlideCardImageColumn
                    image={slide.image}
                    onImageClick={() => onClickImage(slide.bottleName)}
                  />
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

ParallaxLockup.propTypes = {
  slides: PropTypes.arrayOf(PropTypes.object),
}

ParallaxLockup.defaultProps = {
  slides: [],
}

const ParallaxLockupWrapper = props => (
  <DeviceInfo>
    {deviceInfo => <ParallaxLockup deviceInfo={deviceInfo} {...props} />}
  </DeviceInfo>
)

export default ParallaxLockupWrapper
