import * as React from "react"
import Layout from "../atoms/layout/Layout"
import VideoPage from "../utils/videoPage/videoPage"

const VideoPiece = ({ piece }) => {
  return (
    <Layout includeHeaderOffset>
      <VideoPage piece={piece} />
    </Layout>
  )
}

export default VideoPiece
