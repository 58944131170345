import React, { useContext, useEffect, useState, useCallback } from "react"
import PropTypes from "prop-types"
import classNames from "classnames/bind"

import Input from "../../atoms/Input/Input"
import Checkbox from "../../atoms/Checkbox/Checkbox"
import Button from "../../atoms/Button/Button"
import Dropdown from "../../atoms/Dropdown/Dropdown"

import * as styles from "./accountInformationForm.module.scss"
import { ModalContext } from "../../../context/ModalContext"
import { countries, states } from "../locations"
import useCognitoUserData from "../cognito-user-data"
import { navigate } from "@reach/router"
import { withPrefix } from "../with-prefix"
import { validateOnlyLetters } from "../helpers"
import { useMutation } from "@apollo/client"
import { UpdateCurrentUser, UpdateFfyb } from "../fragments"

const AccountInformationForm = ({ values }) => {
  const { openModal, closeModal } = useContext(ModalContext)
  const { signOut, updateUser } = useCognitoUserData()
  const [sending, setSending] = useState(false)
  const [success, setSuccess] = useState(false)
  const [isPristine, setIsPristine] = useState(true)
  const [errorMsg, setErrorMsg] = useState("")
  const [updateFfyb] = useMutation(UpdateFfyb)
  const [updateCurrentUser] = useMutation(UpdateCurrentUser)
  const [formErrors, setFormErrors] = useState({})
  const [formValues, setFormValues] = useState({
    ...values,
    ...(values.birthdate &&
      values.birthdate.length > 10 && {
        birthdate: values.birthdate.substring(0, 10),
      }),
  })
  const [initialValues, setInitialValues] = useState(formValues)

  const handleChange = (value, name) => {
    setFormValues({ ...formValues, [name]: value })
    if (
      name === "city" ||
      name === "state" ||
      name === "firstname" ||
      name === "lastname"
    ) {
      if (!validateOnlyLetters(value)) {
        setFormErrors({ ...formErrors, [name]: "Only letters are allowed" })
      } else {
        setFormErrors({ ...formErrors, [name]: false })
      }
    }
  }

  const handleError = (value, name) => {
    setFormErrors({ ...formErrors, [name]: value })
  }

  const handleDelete = () => {
    //todo add delete account functionality
  }

  const showDeleteModal = () => {
    openModal({
      variant: "delete",
      top: window.scrollY,
      content: (
        <div className={cx("deleteAccountWrapper")}>
          <h2>Hold Up, ARE you Sure?</h2>
          <p>
            Like that last sip in your glass, once you hit delete your account
            will be gone forever. That includes any saved content, like your
            Whiskey Finder results and any Bourbon U content.
          </p>
          <Button
            text={"Delete Account"}
            variant={"accent"}
            type={"primary"}
            icon={false}
            onClick={e => handleDelete(e)}
            url="https://privacyportal.onetrust.com/webform/cd746ada-e4dd-4132-9de8-6bf8fd08206b/draft/c38a7b35-d4e0-4ad3-83d9-6a85c64d6a03"
            target="_blank"
          />
          <Button
            text={"Cancel"}
            variant={"positive"}
            type={"secondary"}
            icon={false}
            onClick={() => closeModal()}
          />
        </div>
      ),
    })
  }

  const handleSubmit = e => {
    e.preventDefault()
  }

  const doSubmit = useCallback(() => {
    setSending(true)
    if (!formValues.email) {
      return
    }

    const data = {
      firstname: formValues.firstname,
      lastname: formValues.lastname,
      email: formValues.email,
      birthdate: formValues.birthday,
      country: formValues.country,
      state: formValues.state,
      city: formValues.city,
      globalPolicyAccepted: formValues.globalPolicyAccepted,
    }

    updateUser(data.email)
      .then(() => {
        updateCurrentUser({ variables: { profile: data } })
        if (
          formValues.ffyb?.[0]?.location &&
          formValues.notificationsAccepted !== "undefined"
        ) {
          updateFfyb({
            variables: {
              ffyb: {
                notificationsAccepted: formValues.notificationsAccepted
                  ? true
                  : false,
              },
            },
          })
        }
        setSuccess(true)
        setSending(false)
      })
      .catch(err => {
        setErrorMsg(err.message)
      })
  }, [formValues])

  const handleLogout = e => {
    signOut()
    navigate(withPrefix("/sign-in"))
  }

  useEffect(() => {
    setIsPristine(initialValues === formValues)
  }, [formValues])

  const cx = classNames.bind(styles)
  return (
    <div className={cx("userProfileWrapper")}>
      <div className={cx("container")}>
        <form onSubmit={e => handleSubmit(e)} className={cx("formContainer")}>
          <div className={cx("formHeader")}>
            <h2>Account information</h2>
          </div>
          <div className={cx("formFields")}>
            <Input
              type={"text"}
              name={"firstName"}
              label={"First Name"}
              placeholder={"First Name"}
              maxLength={0}
              isRequired={false}
              onChange={e => handleChange(e, "firstname")}
              onError={e => handleError(e, "firstname")}
              defaultValue={formValues.firstname}
              defaultError={formErrors.firstname}
            />
            <Input
              type={"text"}
              name={"lastname"}
              label={"Last Name"}
              placeholder={"Last Name"}
              maxLength={0}
              isRequired={false}
              onChange={e => handleChange(e, "lastname")}
              onError={e => handleError(e, "lastname")}
              defaultValue={formValues.lastname}
              defaultError={formErrors.lastname}
            />
            <Input
              type={"email"}
              name={"email"}
              label={"Email"}
              placeholder={"Email"}
              maxLength={320}
              helperText={"Max length"}
              isRequired={false}
              onChange={e => handleChange(e, "email")}
              onError={e => handleError(e, "email")}
              defaultValue={formValues.email}
            />
            <Input
              type={"date"}
              name={"birthdate"}
              label={"Date of birth"}
              placeholder={"DD/MM/YYYY"}
              maxLength={0}
              isRequired={true}
              onChange={e => handleChange(e, "birthdate")}
              onError={e => handleError(e, "birthdate")}
              defaultValue={formValues.birthdate}
            />
            <Dropdown
              name={"country"}
              label={"Country"}
              placeholder={"Choose Country"}
              listItems={countries.map(country => {
                return { label: country.Name, value: country.Code }
              })}
              isRequired={false}
              onSelect={e => handleChange(e, "country")}
              defaultValue={formValues.country}
            />
            {formValues.country === "US" || formValues.country === "CA" ? (
              <Dropdown
                name={"state"}
                label={"State"}
                placeholder={"Choose State"}
                listItems={states
                  .find(state => state.abbreviation === formValues.country)
                  ?.states.map(state => {
                    return {
                      label: state.name,
                      value: state.abbreviation,
                    }
                  })}
                isRequired={false}
                onSelect={e => handleChange(e, "state")}
                defaultValue={formValues.state}
              />
            ) : (
              <Input
                type={"text"}
                name={"state"}
                label={"State"}
                placeholder={"State"}
                maxLength={0}
                isRequired={false}
                onChange={e => handleChange(e, "state")}
                onError={e => handleError(e, "state")}
                defaultValue={formValues.state}
                defaultError={formErrors.state}
              />
            )}
            <Input
              type={"text"}
              name={"city"}
              label={"City"}
              placeholder={"City"}
              maxLength={0}
              isRequired={false}
              onChange={e => handleChange(e, "city")}
              onError={e => handleError(e, "city")}
              defaultValue={formValues.city}
              defaultError={formErrors.city}
            />
            {formValues.ffyb?.[0]?.location && (
              <Checkbox
                label={
                  "Yes, I’d like to opt into the FFYB program and receive email communications about barrel updates."
                }
                name={"notificationsAccepted"}
                onChange={e => handleChange(e, "notificationsAccepted")}
                defaultValue={formValues.ffyb?.[0]?.notificationsAccepted}
                leftCheck={true}
                variant={"light"}
              />
            )}
            <Checkbox
              label={
                "Yes, I’d like to subscribe to the newsletter and receive marketing communications."
              }
              name={"globalPolicyAccepted"}
              onChange={e => handleChange(e, "globalPolicyAccepted")}
              defaultValue={formValues.globalPolicyAccepted}
              leftCheck={true}
              variant={"light"}
            />
          </div>
          <div className={cx("formFooter")}>
            <Button
              type={"primary"}
              text={"Save"}
              icon={false}
              onClick={() => doSubmit()}
              state={sending ? "loading" : isPristine ? "disabled" : "active"}
            />
            <Button
              type={"secondary"}
              text={"Change password"}
              icon={false}
              url={withPrefix("/reset-password")}
            />
            <Button
              type={"secondary"}
              text={"Log out"}
              icon={false}
              onClick={handleLogout}
            />
            {errorMsg && (
              <div>
                <p>{errorMsg}</p>
              </div>
            )}
            {success && (
              <div>
                <p>Profile updated correctly.</p>
              </div>
            )}
            <Button
              type={"tertiary"}
              variant={"accent"}
              text={"Delete account"}
              icon={true}
              isDelete={true}
              iconAlignment={"right"}
              state={"active"}
              onClick={() => showDeleteModal()}
            />
          </div>
        </form>
      </div>
    </div>
  )
}

AccountInformationForm.propTypes = {
  handleChange: PropTypes.func,
  handleSubmit: PropTypes.func,
  formValues: PropTypes.object,
  values: PropTypes.object,
}

AccountInformationForm.defaultProps = {
  values: {},
}

export default AccountInformationForm
