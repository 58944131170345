import { gql } from "@apollo/client"

const IMAGE_FIELDS = gql`
  fragment imageFields on MediaBeamBourbonuImage {
    fieldBeamBourbonuImage {
      url
    }
  }
`
export const PageComponents = gql`
  fragment PageComponents on BeamBourbonuPiecePage {
    title
    description {
      value
    }
    fieldComponents {
      entity {
        ... on ParagraphBeamBourbonuLpImageHero {
          eyebrow: fieldBeamBourbonuEyebrow
          title: fieldBeamBourbonuTitle
          copy: fieldBeamBourbonuCopy {
            value
          }
          cta: fieldBeamBourbonuCta {
            ... on FieldParagraphBeamBourbonuLpImageHeroFieldBeamBourbonuCta {
              entity {
                ... on ParagraphBeamBourbonuCta {
                  url: fieldBeamBourbonuUrl {
                    title
                    url {
                      path
                    }
                  }
                  type: fieldBeamBourbonuType
                  target: fieldBeamBourbonuTarget
                  variant: fieldBeamBourbonuVariant
                  icon: fieldBeamBourbonuIcon
                  iconAlignment: fieldBeamBourbonuIconAlign
                }
              }
            }
          }
          image: fieldBeamBourbonuImage {
            entity {
              ... on MediaBeamBourbonuImage {
                image: fieldBeamBourbonuImage {
                  url
                  heroDesktop: derivative(style: BEAMHERODESKTOP) {
                    url
                  }
                  heroMobile: derivative(style: BEAMHEROMOBILE) {
                    url
                  }
                }
              }
            }
          }
          mobileImage: fieldBeamBourbonuMobileImage {
            entity {
              ... on MediaBeamBourbonuImage {
                image: fieldBeamBourbonuImage {
                  url
                  alt
                }
              }
            }
          }
          isBackgroundImage: fieldBeamBourbonuIsBckgrnd
          fontColor: fieldBeamBourbonuFontColor
        }
        ... on ParagraphBeamBourbonuReactiveImgLu {
          eyebrow: fieldBeamBourbonuEyebrow
          title: fieldBeamBourbonuTitle
          copy: fieldBeamBourbonuCopy {
            value
          }
          cta: fieldBeamBourbonuCta {
            ... on FieldParagraphBeamBourbonuReactiveImgLuFieldBeamBourbonuCta {
              entity {
                ... on ParagraphBeamBourbonuCta {
                  url: fieldBeamBourbonuUrl {
                    title
                    url {
                      path
                    }
                  }
                  type: fieldBeamBourbonuType
                  target: fieldBeamBourbonuTarget
                  variant: fieldBeamBourbonuVariant
                  icon: fieldBeamBourbonuIcon
                  iconAlignment: fieldBeamBourbonuIconAlign
                }
              }
            }
          }
          info: fieldBeamBourbonuFeatures {
            entity {
              ... on ParagraphBeamBourbonuRecipeFeatures {
                fieldBeamBourbonuRfeatKind {
                  entity {
                    name
                    ... on TaxonomyTermRecipeFeatures {
                      fieldBeamBourbonuIcon {
                        entity {
                          ... on MediaBeamBourbonuImage {
                            fieldBeamBourbonuImage {
                              url
                              alt
                            }
                          }
                        }
                      }
                    }
                  }
                }
                fieldBeamBourbonuRfeatDurat {
                  entity {
                    name
                    ... on TaxonomyTermRecipeFeatures {
                      fieldBeamBourbonuIcon {
                        entity {
                          ... on MediaBeamBourbonuImage {
                            fieldBeamBourbonuImage {
                              url
                              alt
                            }
                          }
                        }
                      }
                    }
                  }
                }
                fieldBeamBourbonuRfeatLevel {
                  entity {
                    name
                    ... on TaxonomyTermRecipeFeatures {
                      fieldBeamBourbonuIcon {
                        entity {
                          ... on MediaBeamBourbonuImage {
                            fieldBeamBourbonuImage {
                              url
                              alt
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          imageEyebrow: fieldBeamBourbonuRilImgE
          imageTitle: fieldBeamBourbonuRilImgT
          imageCopy: fieldBeamBourbonuRilImgC {
            value
          }
          backgroundImage: fieldBeamBourbonuRilImgB {
            entity {
              ... on MediaBeamBourbonuImage {
                image: fieldBeamBourbonuImage {
                  url
                }
              }
            }
          }
          foregroundImage: fieldBeamBourbonuRilImgI {
            entity {
              ... on MediaBeamBourbonuImage {
                image: fieldBeamBourbonuImage {
                  url
                }
              }
            }
          }
          motionType: fieldBeamBourbonuRilMotion
          imageOnRight: fieldBeamBourbonuRilIor
          isFullsize: fieldBeamBourbonuRilFullsize
          showExpanded: fieldBeamBourbonuRilExpanded
          slideImage1: fieldBeamBourbonuRilSimg1 {
            entity {
              ... on MediaBeamBourbonuImage {
                image: fieldBeamBourbonuImage {
                  url
                }
              }
            }
          }
          slideImage2: fieldBeamBourbonuRilSimg2 {
            entity {
              ... on MediaBeamBourbonuImage {
                image: fieldBeamBourbonuImage {
                  url
                }
              }
            }
          }
          slideImage1Position: fieldBeamBourbonuRilSimg1P
          slideImage2Position: fieldBeamBourbonuRilSimg2P
        }
        ... on ParagraphBeamBourbonuCopyCenter {
          eyebrow: fieldBeamBourbonuEyebrow
          title: fieldBeamBourbonuTitle
          copy: fieldBeamBourbonuCopy {
            value
          }
          cta: fieldBeamBourbonuCta {
            ... on FieldParagraphBeamBourbonuCopyCenterFieldBeamBourbonuCta {
              entity {
                ... on ParagraphBeamBourbonuCta {
                  url: fieldBeamBourbonuUrl {
                    title
                    url {
                      path
                    }
                  }
                  type: fieldBeamBourbonuType
                  target: fieldBeamBourbonuTarget
                  variant: fieldBeamBourbonuVariant
                  icon: fieldBeamBourbonuIcon
                  iconAlignment: fieldBeamBourbonuIconAlign
                }
              }
            }
          }
          leftImg: fieldBeamBourbonuImageLeft {
            entity {
              ... on MediaBeamBourbonuImage {
                image: fieldBeamBourbonuImage {
                  url
                }
              }
            }
          }
          rightImg: fieldBeamBourbonuImageRight {
            entity {
              ... on MediaBeamBourbonuImage {
                image: fieldBeamBourbonuImage {
                  url
                }
              }
            }
          }
        }
        ... on ParagraphBeamBourbonuCopyCenterSimple {
          title: fieldBeamBourbonuTitle
          copy: fieldBeamBourbonuCopy {
            value
          }
        }
        ... on ParagraphBeamBourbonuFeatureContent {
          __typename
          featured: fieldBeamBourbonuRelPieces {
            entity {
              entityId
              title
              description {
                value
              }
              entityUrl {
                path
              }
              ... on BeamBourbonuPieceVideo {
                fieldBeamBourbonuVideo {
                  entity {
                    thumbnail {
                      url
                    }
                  }
                }
                fieldBeamBourbonuCategory {
                  entity {
                    name
                  }
                }
              }
              ... on BeamBourbonuPieceArticle {
                fieldBeamBourbonuImages {
                  entity {
                    ... on MediaBeamBourbonuImage {
                      image: fieldBeamBourbonuImage {
                        url

                        cardDesktop: derivative(style: BEAMARTICLECARDDESKTOP) {
                          url
                        }
                        cardMobile: derivative(style: BEAMARTICLECARDMOBILE) {
                          url
                        }
                        cardLargeDesktop: derivative(
                          style: BEAMARTICLELARGECARDDESKTOP
                        ) {
                          url
                        }
                        cardLargeMobile: derivative(
                          style: BEAMARTICLELARGECARDMOBILE
                        ) {
                          url
                        }
                        heroDesktop: derivative(style: BEAMARTICLEHERODESKTOP) {
                          url
                        }
                        heroMobile: derivative(style: BEAMARTICLEHEROMOBILE) {
                          url
                        }
                      }
                    }
                  }
                }
                fieldBeamBourbonuCategory {
                  entity {
                    name
                  }
                }
              }
            }
          }
        }
        ... on ParagraphBeamBourbonuCardHighlight1 {
          card: fieldBeamBourbonuRelPieces {
            __typename
            entity {
              title
              entityId
              description {
                value
              }
              entityUrl {
                path
              }
              ... on BeamBourbonuPieceVideo {
                fieldBeamBourbonuVideo {
                  entity {
                    thumbnail {
                      url
                    }
                  }
                }
                fieldBeamBourbonuCategory {
                  entity {
                    name
                  }
                }
              }
              ... on BeamBourbonuPieceArticle {
                fieldBeamBourbonuImages {
                  entity {
                    ... on MediaBeamBourbonuImage {
                      image: fieldBeamBourbonuImage {
                        url

                        cardDesktop: derivative(style: BEAMARTICLECARDDESKTOP) {
                          url
                        }
                        cardMobile: derivative(style: BEAMARTICLECARDMOBILE) {
                          url
                        }
                        cardLargeDesktop: derivative(
                          style: BEAMARTICLELARGECARDDESKTOP
                        ) {
                          url
                        }
                        cardLargeMobile: derivative(
                          style: BEAMARTICLELARGECARDMOBILE
                        ) {
                          url
                        }
                        heroDesktop: derivative(style: BEAMARTICLEHERODESKTOP) {
                          url
                        }
                        heroMobile: derivative(style: BEAMARTICLEHEROMOBILE) {
                          url
                        }
                      }
                    }
                  }
                }
                fieldBeamBourbonuCategory {
                  entity {
                    name
                  }
                }
              }
            }
          }
        }
        ... on ParagraphBeamBourbonuCardHighlight2 {
          cards: fieldBeamBourbonuRelPieces2 {
            __typename
            entity {
              title
              entityId
              description {
                value
              }
              entityUrl {
                path
              }
              ... on BeamBourbonuPieceVideo {
                fieldBeamBourbonuVideo {
                  entity {
                    thumbnail {
                      url
                    }
                  }
                }
                fieldBeamBourbonuCategory {
                  entity {
                    name
                  }
                }
              }
              ... on BeamBourbonuPieceArticle {
                fieldBeamBourbonuImages {
                  entity {
                    ... on MediaBeamBourbonuImage {
                      image: fieldBeamBourbonuImage {
                        url

                        cardDesktop: derivative(style: BEAMARTICLECARDDESKTOP) {
                          url
                        }
                        cardMobile: derivative(style: BEAMARTICLECARDMOBILE) {
                          url
                        }
                        cardLargeDesktop: derivative(
                          style: BEAMARTICLELARGECARDDESKTOP
                        ) {
                          url
                        }
                        cardLargeMobile: derivative(
                          style: BEAMARTICLELARGECARDMOBILE
                        ) {
                          url
                        }
                        heroDesktop: derivative(style: BEAMARTICLEHERODESKTOP) {
                          url
                        }
                        heroMobile: derivative(style: BEAMARTICLEHEROMOBILE) {
                          url
                        }
                      }
                    }
                  }
                }
                fieldBeamBourbonuCategory {
                  entity {
                    name
                  }
                }
              }
            }
          }
        }
        ... on ParagraphBeamBourbonuCardHighlight3 {
          cards: fieldBeamBourbonuRelPieces3 {
            entity {
              __typename
              title
              entityId
              description {
                value
              }
              entityUrl {
                path
              }
              ... on BeamBourbonuPieceVideo {
                fieldBeamBourbonuVideo {
                  entity {
                    thumbnail {
                      url
                    }
                  }
                }
                fieldBeamBourbonuCategory {
                  entity {
                    name
                  }
                }
              }
              ... on BeamBourbonuPieceArticle {
                fieldBeamBourbonuImages {
                  entity {
                    ... on MediaBeamBourbonuImage {
                      fieldBeamBourbonuImage {
                        url

                        cardDesktop: derivative(style: BEAMARTICLECARDDESKTOP) {
                          url
                        }
                        cardMobile: derivative(style: BEAMARTICLECARDMOBILE) {
                          url
                        }
                        cardLargeDesktop: derivative(
                          style: BEAMARTICLELARGECARDDESKTOP
                        ) {
                          url
                        }
                        cardLargeMobile: derivative(
                          style: BEAMARTICLELARGECARDMOBILE
                        ) {
                          url
                        }
                        heroDesktop: derivative(style: BEAMARTICLEHERODESKTOP) {
                          url
                        }
                        heroMobile: derivative(style: BEAMARTICLEHEROMOBILE) {
                          url
                        }
                      }
                    }
                  }
                }
                fieldBeamBourbonuCategory {
                  entity {
                    name
                  }
                }
              }
              ... on BeamBourbonuPieceRecipe {
                fieldBeamBourbonuImage {
                  entity {
                    ... on MediaBeamBourbonuImage {
                      image: fieldBeamBourbonuImage {
                        url
                        alt
                      }
                    }
                  }
                }
                fieldBeamBourbonuCategory {
                  entity {
                    name
                  }
                }
                fieldBeamBourbonuFeatures {
                  entity {
                    ... on ParagraphBeamBourbonuRecipeFeatures {
                      fieldBeamBourbonuRfeatKind {
                        entity {
                          name
                          ... on TaxonomyTermRecipeFeatures {
                            fieldBeamBourbonuIcon {
                              entity {
                                ... on MediaBeamBourbonuImage {
                                  fieldBeamBourbonuImage {
                                    url
                                    alt
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                      fieldBeamBourbonuRfeatDurat {
                        entity {
                          name
                          ... on TaxonomyTermRecipeFeatures {
                            fieldBeamBourbonuIcon {
                              entity {
                                ... on MediaBeamBourbonuImage {
                                  fieldBeamBourbonuImage {
                                    url
                                    alt
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                      fieldBeamBourbonuRfeatLevel {
                        entity {
                          name
                          ... on TaxonomyTermRecipeFeatures {
                            fieldBeamBourbonuIcon {
                              entity {
                                ... on MediaBeamBourbonuImage {
                                  fieldBeamBourbonuImage {
                                    url
                                    alt
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }

        ... on ParagraphBeamBourbonuCardsCarousel {
          cards: fieldBeamBourbonuRelPiecesU {
            entity {
              title
              entityId
              description {
                value
              }
              entityUrl {
                path
              }
              ... on BeamBourbonuPieceVideo {
                fieldBeamBourbonuVideo {
                  entity {
                    thumbnail {
                      url
                    }
                  }
                }
                fieldBeamBourbonuCategory {
                  entity {
                    name
                  }
                }
                fieldBeamBourbonuImage {
                  entity {
                    ... on MediaBeamBourbonuImage {
                      fieldBeamBourbonuImage {
                        url
                        alt
                      }
                    }
                  }
                }
              }
              ... on BeamBourbonuPieceArticle {
                fieldBeamBourbonuImages {
                  entity {
                    ... on MediaBeamBourbonuImage {
                      fieldBeamBourbonuImage {
                        url

                        cardDesktop: derivative(style: BEAMARTICLECARDDESKTOP) {
                          url
                        }
                        cardMobile: derivative(style: BEAMARTICLECARDMOBILE) {
                          url
                        }
                        cardLargeDesktop: derivative(
                          style: BEAMARTICLELARGECARDDESKTOP
                        ) {
                          url
                        }
                        cardLargeMobile: derivative(
                          style: BEAMARTICLELARGECARDMOBILE
                        ) {
                          url
                        }
                        heroDesktop: derivative(style: BEAMARTICLEHERODESKTOP) {
                          url
                        }
                        heroMobile: derivative(style: BEAMARTICLEHEROMOBILE) {
                          url
                        }
                      }
                    }
                  }
                }
                fieldBeamBourbonuCategory {
                  entity {
                    name
                  }
                }
              }
            }
          }
        }
        ... on ParagraphBeamBourbonuContentCategories {
          categories: fieldBeamBourbonuCategories {
            entity {
              name
            }
          }
        }
      }
    }
  }
`

export const getWhiskeyFacts = gql`
  query {
    WhiskeyFacts {
      title
      description {
        value
      }
      fieldBeamBourbonuImage {
        entity {
          ... on MediaBeamBourbonuImage {
            fieldBeamBourbonuImage {
              url
              alt
            }
          }
        }
      }
      fieldBeamBourbonuSubcopy
    }
  }
`

export const RecipeData = gql`
  fragment RecipeData on BeamBourbonuPieceRecipe {
    title
    entityId
    description {
      value
    }
    fieldFlavorFinderCategory {
      entity {
        name
      }
    }
    fieldBeamBourbonuFeatBottle {
      entity {
        ... on BeamBourbonuPieceBottle {
          title
          fieldFlavorFinderCategory {
            entity {
              name
            }
          }
          fieldBeamBourbonuImage {
            entity {
              ... on MediaBeamBourbonuImage {
                fieldBeamBourbonuImage {
                  url
                  alt
                }
              }
            }
          }
        }
      }
    }
    fieldBeamBourbonuImage {
      entity {
        ... on MediaBeamBourbonuImage {
          fieldBeamBourbonuImage {
            url
            alt
          }
        }
      }
    }
    fieldBeamBourbonuCategory {
      entity {
        name
      }
    }
    relatedContent {
      entityId
      entityBundle
      title
      description {
        value
      }
      ... on BeamBourbonuPieceRecipe {
        fieldBeamBourbonuImage {
          entity {
            ... on MediaBeamBourbonuImage {
              fieldBeamBourbonuImage {
                url
                alt
              }
            }
          }
        }
      }
      ... on BeamBourbonuPieceBottle {
        fieldBeamBourbonuImage {
          entity {
            ... on MediaBeamBourbonuImage {
              fieldBeamBourbonuImage {
                url
                alt
              }
            }
          }
        }
      }
      ... on BeamBourbonuPieceArticle {
        fieldBeamBourbonuImages {
          entity {
            thumbnail {
              url
            }
          }
        }
        fieldBeamBourbonuCategory {
          entity {
            name
          }
        }
      }
      ... on BeamBourbonuPieceVideo {
        fieldBeamBourbonuVideo {
          entity {
            thumbnail {
              url
            }
          }
        }
        fieldBeamBourbonuCategory {
          entity {
            name
          }
        }
        fieldBeamBourbonuImage {
          entity {
            ... on MediaBeamBourbonuImage {
              fieldBeamBourbonuImage {
                url
                alt
              }
            }
          }
        }
        entityUrl {
          path
        }
      }
    }

    fieldBeamBourbonuRelPieces {
      entity {
        entityId
        entityBundle
        title
        description {
          value
        }
        ... on BeamBourbonuPieceArticle {
          fieldBeamBourbonuImages {
            entity {
              thumbnail {
                url
              }
            }
          }
          fieldBeamBourbonuCategory {
            entity {
              name
            }
          }
        }
        ... on BeamBourbonuPieceVideo {
          fieldBeamBourbonuVideo {
            entity {
              thumbnail {
                url
              }
            }
          }
          fieldBeamBourbonuCategory {
            entity {
              name
            }
          }
          fieldBeamBourbonuImage {
            entity {
              ... on MediaBeamBourbonuImage {
                fieldBeamBourbonuImage {
                  url
                  alt
                }
              }
            }
          }
        }
        entityUrl {
          path
        }
      }
    }
    fieldBeamBourbonuOtherRecipe {
      entity {
        entityId
        title
        entityUrl {
          path
        }
        description {
          value
        }
        ... on BeamBourbonuPieceRecipe {
          fieldBeamBourbonuImage {
            entity {
              ... on MediaBeamBourbonuImage {
                fieldBeamBourbonuImage {
                  url
                  alt
                }
              }
            }
          }
          fieldBeamBourbonuCategory {
            entity {
              name
            }
          }
          fieldBeamBourbonuFeatures {
            entity {
              ... on ParagraphBeamBourbonuRecipeFeatures {
                fieldBeamBourbonuRfeatKind {
                  entity {
                    name
                    ... on TaxonomyTermRecipeFeatures {
                      fieldBeamBourbonuIcon {
                        entity {
                          ... on MediaBeamBourbonuImage {
                            fieldBeamBourbonuImage {
                              url
                              alt
                            }
                          }
                        }
                      }
                    }
                  }
                }
                fieldBeamBourbonuRfeatDurat {
                  entity {
                    name
                    ... on TaxonomyTermRecipeFeatures {
                      fieldBeamBourbonuIcon {
                        entity {
                          ... on MediaBeamBourbonuImage {
                            fieldBeamBourbonuImage {
                              url
                              alt
                            }
                          }
                        }
                      }
                    }
                  }
                }
                fieldBeamBourbonuRfeatLevel {
                  entity {
                    name
                    ... on TaxonomyTermRecipeFeatures {
                      fieldBeamBourbonuIcon {
                        entity {
                          ... on MediaBeamBourbonuImage {
                            fieldBeamBourbonuImage {
                              url
                              alt
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    fieldBeamBourbonuDefProd
    fieldBeamBourbonuIngredients
    fieldBeamBourbonuInstructions {
      value
    }
    fieldBeamBourbonuFeatures {
      entity {
        ... on ParagraphBeamBourbonuRecipeFeatures {
          fieldBeamBourbonuRfeatKind {
            entity {
              name
              ... on TaxonomyTermRecipeFeatures {
                fieldBeamBourbonuIcon {
                  entity {
                    ... on MediaBeamBourbonuImage {
                      fieldBeamBourbonuImage {
                        url
                        alt
                      }
                    }
                  }
                }
              }
            }
          }
          fieldBeamBourbonuRfeatDurat {
            entity {
              name
              ... on TaxonomyTermRecipeFeatures {
                fieldBeamBourbonuIcon {
                  entity {
                    ... on MediaBeamBourbonuImage {
                      fieldBeamBourbonuImage {
                        url
                        alt
                      }
                    }
                  }
                }
              }
            }
          }
          fieldBeamBourbonuRfeatLevel {
            entity {
              name
              ... on TaxonomyTermRecipeFeatures {
                fieldBeamBourbonuIcon {
                  entity {
                    ... on MediaBeamBourbonuImage {
                      fieldBeamBourbonuImage {
                        url
                        alt
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export const VideoData = gql`
  fragment VideoData on BeamBourbonuPieceVideo {
    title
    entityId
    description {
      value
    }
    fieldBeamBourbonuVideo {
      entity {
        ... on MediaBeamBourbonuRemoteVideo {
          fieldBeamBourbonuVideoEmbed
        }

        ... on MediaBeamBourbonuVideo {
          fieldBeamBourbonuFile {
            entity {
              url
            }
          }
        }
      }
    }
    fieldBeamBourbonuImage {
      entity {
        ... on MediaBeamBourbonuImage {
          fieldBeamBourbonuImage {
            url
            alt
          }
        }
      }
    }
    fieldBeamBourbonuCategory {
      entity {
        name
      }
    }
    fieldBeamBourbonuContent {
      entity {
        ... on ParagraphBeamBourbonuTextOnly {
          fieldBeamBourbonuCopy {
            value
          }
        }
        ... on ParagraphBeamBourbonuTextWhithImage {
          fieldBeamBourbonuCopy {
            value
          }
          fieldBeamBourbonuImage {
            entity {
              ... on MediaBeamBourbonuImage {
                fieldBeamBourbonuImage {
                  url
                  alt
                }
              }
            }
          }
          fieldBeamBourbonuImgPosition
        }
      }
    }
    fieldBeamBourbonuBreadcrumbs {
      entity {
        ... on BeamBourbonuPieceArticle {
          title
          path {
            alias
          }
        }
        ... on BeamBourbonuPiecePage {
          title
          path {
            alias
          }
        }
      }
    }
    fieldBeamBourbonuRelPieces {
      entity {
        title
        description {
          value
        }
        entityUrl {
          path
        }
        ... on BeamBourbonuPieceVideo {
          fieldBeamBourbonuVideo {
            entity {
              thumbnail {
                url
              }
            }
          }
          fieldBeamBourbonuCategory {
            entity {
              name
            }
          }
        }
        ... on BeamBourbonuPieceArticle {
          fieldBeamBourbonuImages {
            entity {
              thumbnail {
                url
              }
            }
          }
          fieldBeamBourbonuCategory {
            entity {
              name
            }
          }
        }
      }
    }
    fieldBeamBourbonuBreadcrumbs {
      entity {
        ... on BeamBourbonuPieceArticle {
          title
          path {
            alias
          }
        }
        ... on BeamBourbonuPiecePage {
          title
          path {
            alias
          }
        }
      }
    }
    relatedContent {
      entityId
      entityBundle
      title
      description {
        value
      }
      ... on BeamBourbonuPieceArticle {
        fieldBeamBourbonuImages {
          entity {
            thumbnail {
              url
            }
          }
        }
        fieldBeamBourbonuCategory {
          entity {
            name
          }
        }
      }
      ... on BeamBourbonuPieceVideo {
        fieldBeamBourbonuVideo {
          entity {
            thumbnail {
              url
            }
          }
        }
        fieldBeamBourbonuCategory {
          entity {
            name
          }
        }
        fieldBeamBourbonuImage {
          entity {
            ... on MediaBeamBourbonuImage {
              fieldBeamBourbonuImage {
                url
                alt
              }
            }
          }
        }
        entityUrl {
          path
        }
      }
    }
  }
`

export const ArticleData = gql`
  fragment ArticleData on BeamBourbonuPieceArticle {
    title
    entityId
    description {
      value
    }
    author: fieldBeamBourbonuAuthor
    image: fieldBeamBourbonuImages {
      entity {
        ... on MediaBeamBourbonuImage {
          image: fieldBeamBourbonuImage {
            alt
            url
          }
        }
      }
    }
    category: fieldBeamBourbonuCategory {
      entity {
        name
      }
    }
    breadcrumbs: fieldBeamBourbonuBreadcrumbs {
      entity {
        ... on BeamBourbonuPieceArticle {
          title
          path {
            alias
          }
        }
        ... on BeamBourbonuPiecePage {
          title
          path {
            alias
          }
        }
      }
    }
    paragraphs: fieldBeamBourbonuContent {
      entity {
        ... on ParagraphBeamBourbonuTextOnly {
          copy: fieldBeamBourbonuCopy {
            value
          }
        }
        ... on ParagraphBeamBourbonuTextWhithImage {
          copy: fieldBeamBourbonuCopy {
            value
          }
          image: fieldBeamBourbonuImage {
            entity {
              ... on MediaBeamBourbonuImage {
                image: fieldBeamBourbonuImage {
                  alt
                  url
                }
              }
            }
          }
          position: fieldBeamBourbonuImgPosition
        }
      }
    }
    relatedPieces: fieldBeamBourbonuRelPieces {
      entity {
        entityId
        entityBundle
        title
        description {
          value
        }
        entityUrl {
          path
        }
        ... on BeamBourbonuPieceVideo {
          fieldBeamBourbonuVideo {
            entity {
              thumbnail {
                url
              }
            }
          }
          fieldBeamBourbonuCategory {
            entity {
              name
            }
          }
        }
        ... on BeamBourbonuPieceArticle {
          fieldBeamBourbonuImages {
            entity {
              thumbnail {
                url
              }
            }
          }
          fieldBeamBourbonuCategory {
            entity {
              name
            }
          }
        }
      }
    }
    relatedPiecesBucket: relatedContent {
      entityId
      entityBundle
      title
      description {
        value
      }
      ... on BeamBourbonuPieceArticle {
        fieldBeamBourbonuImages {
          entity {
            thumbnail {
              url
            }
          }
        }
        fieldBeamBourbonuCategory {
          entity {
            name
          }
        }
      }
      ... on BeamBourbonuPieceVideo {
        fieldBeamBourbonuVideo {
          entity {
            thumbnail {
              url
            }
          }
        }
        fieldBeamBourbonuCategory {
          entity {
            name
          }
        }
        fieldBeamBourbonuImage {
          entity {
            ... on MediaBeamBourbonuImage {
              fieldBeamBourbonuImage {
                url
                alt
              }
            }
          }
        }
        entityUrl {
          path
        }
      }
    }
  }
`

export const GetQuestionsQuery = gql`
  query {
    flavorFinderQuestions {
      entityId
      title
      fieldAssist
      type
      fieldSelectionMin
      fieldSelectionMax
      fieldFollowupTitle
      fieldFollowupAssist
      fieldNextQuestion {
        targetId
      }
      fieldBreaker {
        entity {
          ... on ParagraphBeamFlavorfinderBreaker {
            fieldBeamFlavorfinderLabel
            fieldBeamFlavorfinderSecond
            fieldBeamFlavorfinderAssist
            fieldBeamFlavorfinderImage {
              entity {
                ... on MediaBeamBourbonuImage {
                  fieldBeamBourbonuImage {
                    url
                  }
                }
              }
            }
          }
        }
      }
      fieldOptionsHaveImages
      fieldImage {
        entity {
          ... on MediaBeamBourbonuImage {
            fieldBeamBourbonuImage {
              url
            }
          }
        }
      }
      fieldActive {
        entity {
          ... on MediaBeamBourbonuImage {
            fieldBeamBourbonuImage {
              url
            }
          }
        }
      }
      optionGroups {
        entity {
          ... on ParagraphBeamFlavorfinderOptiongroup {
            fieldBeamFlavorfinderLabel
            fieldBeamFlavorfinderAssist
            fieldBeamFlavorfinderImage {
              entity {
                ... on MediaBeamBourbonuImage {
                  fieldBeamBourbonuImage {
                    url
                  }
                }
              }
            }
            fieldBeamFlavorfinderActive {
              entity {
                ... on MediaBeamBourbonuImage {
                  fieldBeamBourbonuImage {
                    url
                  }
                }
              }
            }
            fieldBeamFlavorfinderOptions {
              entity {
                ... on ParagraphBeamFlavorfinderOption {
                  entityId
                  fieldBeamFlavorfinderLabel
                  fieldBeamFlavorfinderAssist
                  fieldBeamFlavorfinderImage {
                    entity {
                      ... on MediaBeamBourbonuImage {
                        fieldBeamBourbonuImage {
                          url
                        }
                      }
                    }
                  }
                  fieldBeamFlavorfinderActive {
                    entity {
                      ... on MediaBeamBourbonuImage {
                        fieldBeamBourbonuImage {
                          url
                        }
                      }
                    }
                  }
                  fieldBeamFlavorfinderNext {
                    targetId
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
export const BucketComponents = gql`
  fragment BucketComponents on TaxonomyTermBeamFlavorfinderBucket {
    entityId
    name
    path {
      alias
    }
    fieldBeamBourbonuRpInfo {
      entity {
        ... on ParagraphBeamBourbonuRpInfo {
          fieldBeamBourbonuTitle
          fieldBeamBourbonuCopy {
            value
          }
        }
      }
    }
    fieldBeamBourbonuRpHero {
      entity {
        ... on ParagraphBeamBourbonuRpHeroresult {
          fieldBeamBourbonuCopy {
            value
          }
          fieldBeamBourbonuBackground {
            entity {
              ...imageFields
            }
          }
          fieldBeamBourbonuImage {
            entity {
              ...imageFields
            }
          }
          fieldBeamBourbonuActiveImage {
            entity {
              ...imageFields
            }
          }
        }
      }
    }
    fieldBeamBourbonuRpFnotes {
      entity {
        ... on ParagraphBeamBourbonuRpCardslides {
          fieldBeamBourbonuEyebrow
          fieldBeamBourbonuTitle
          fieldBeamBourbonuCopy {
            value
          }
          fieldBeamBourbonuCards {
            entity {
              ... on ParagraphBeamBourbonuRpCardslide {
                fieldBeamBourbonuEyebrow
                fieldBeamBourbonuTitle
                fieldBeamBourbonuCopy {
                  value
                }
                fieldBeamBourbonuBackground {
                  entity {
                    ...imageFields
                  }
                }
                fieldBeamBourbonuImage {
                  entity {
                    ...imageFields
                  }
                }
              }
            }
          }
        }
      }
    }
    fieldBeamBourbonuRpShareable {
      entity {
        ... on ParagraphBeamBourbonuRpShareables {
          fieldBeamBourbonuCopy {
            value
            __typename
          }
          fieldBeamBourbonuThreeimgs {
            entity {
              ...imageFields
              __typename
            }
            __typename
          }
          fieldBeamBourbonuArchive {
            entity {
              ...imageFields
              __typename
            }
            __typename
          }
          __typename
        }
        __typename
      }
      __typename
    }
    fieldBeamBourbonuRpWtlf {
      entity {
        ... on ParagraphBeamBourbonuRpCardslides {
          fieldBeamBourbonuEyebrow
          fieldBeamBourbonuTitle
          fieldBeamBourbonuCopy {
            value
          }
          fieldBeamBourbonuCards {
            entity {
              ... on ParagraphBeamBourbonuRpCardslide {
                fieldBeamBourbonuEyebrow
                fieldBeamBourbonuTitle
                fieldBeamBourbonuCopy {
                  value
                }
                fieldBeamBourbonuBackground {
                  entity {
                    ...imageFields
                  }
                }
                fieldBeamBourbonuImage {
                  entity {
                    ...imageFields
                  }
                }
              }
            }
          }
        }
      }
    }
    fieldBeamBourbonuRelContent {
      entity {
        title
        description {
          value
        }
        entityUrl {
          path
        }
        ... on BeamBourbonuPieceRecipe {
          fieldBeamBourbonuIngredients
          fieldBeamBourbonuDefProd
          fieldBeamBourbonuInstructions {
            value
          }
          fieldBeamBourbonuImage {
            entity {
              ...imageFields
            }
          }
        }
        ... on BeamBourbonuPieceVideo {
          fieldBeamBourbonuVideo {
            entity {
              thumbnail {
                url
              }
            }
          }
          fieldBeamBourbonuCategory {
            entity {
              name
            }
          }
        }
        ... on BeamBourbonuPieceArticle {
          fieldBeamBourbonuImages {
            entity {
              ... on MediaBeamBourbonuImage {
                image: fieldBeamBourbonuImage {
                  url

                  cardDesktop: derivative(style: BEAMARTICLECARDDESKTOP) {
                    url
                  }
                  cardMobile: derivative(style: BEAMARTICLECARDMOBILE) {
                    url
                  }
                  cardLargeDesktop: derivative(
                    style: BEAMARTICLELARGECARDDESKTOP
                  ) {
                    url
                  }
                  cardLargeMobile: derivative(
                    style: BEAMARTICLELARGECARDMOBILE
                  ) {
                    url
                  }
                  heroDesktop: derivative(style: BEAMARTICLEHERODESKTOP) {
                    url
                  }
                  heroMobile: derivative(style: BEAMARTICLEHEROMOBILE) {
                    url
                  }
                }
              }
            }
          }
          fieldBeamBourbonuCategory {
            entity {
              name
            }
          }
        }
      }
    }
    fieldBeamBourbonuRelContTxt {
      entity {
        ... on ParagraphBeamBourbonuRpRelContText {
          fieldBeamBourbonuEyebrow
          fieldBeamBourbonuTitle
          fieldBeamBourbonuCopy {
            value
          }
        }
      }
    }
  }
`
export const SendResults = gql`
  ${BucketComponents}
  mutation($options: [BeamFlavorFinderQuestionOptionValue]!) {
    flavorFinderSubmit(options: $options) {
      bucket {
        ...BucketComponents
      }
      otherBuckets {
        entityId
        name
        fieldBeamBourbonuRpInfo {
          entity {
            ... on ParagraphBeamBourbonuRpInfo {
              fieldBeamBourbonuTitle
              fieldBeamBourbonuCopy {
                value
              }
              fieldBeamBourbonuImage {
                entity {
                  ...imageFields
                }
              }
              fieldBeamBourbonuActiveImage {
                entity {
                  ...imageFields
                }
              }
            }
          }
        }
      }
      bottles {
        entityId
        entityBundle
        title
        description {
          value
        }
        ... on BeamBourbonuPieceBottle {
          fieldBeamBourbonuShareImage {
            entity {
              ...imageFields
              __typename
            }
            __typename
          }
          fieldBeamFlavorfinderTerms {
            entity {
              name
            }
          }
          fieldBeamBourbonuFeatNotes {
            entity {
              name
            }
          }
          fieldBeamBourbonuEpid
          fieldBeamBourbonuImage {
            entity {
              ...imageFields
            }
          }
          fieldBeamBourbonuFeatures {
            entity {
              ... on ParagraphBeamBourbonuBottleFeatures {
                fieldBourbonuBfAge
                fieldBourbonuBfPrice
                fieldBourbonuBfProof
              }
            }
          }
          fieldBeamBourbonuRelRecipes {
            entity {
              title
              description {
                value
              }
              ... on BeamBourbonuPieceRecipe {
                fieldBeamBourbonuIngredients
                fieldBeamBourbonuDefProd
                fieldBeamBourbonuInstructions {
                  value
                }
                fieldBeamBourbonuImage {
                  entity {
                    ...imageFields
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  fragment imageFields on Media {
    ... on MediaBeamBourbonuImage {
      fieldBeamBourbonuImage {
        url
      }
    }
  }
`

export const GetUserInformation = gql`
  query {
    currentUser {
      firstname
      lastname
      email
      birthdate
      country
      state
      city
      globalPolicyAccepted
      privacyPolicyAccepted
      ffyb {
        startDate
        location
        notificationsAccepted
      }
      fieldUserprofileFavoritPieces {
        entity {
          entityId
        }
      }
      fieldUserprofileWishlist {
        entity {
          id
          entityBundle
          ... on BeamBourbonuPieceBottle {
            title
            description {
              value
            }
            fieldBeamBourbonuShareImage {
              entity {
                ... on MediaBeamBourbonuImage {
                  fieldBeamBourbonuImage {
                    url
                  }
                }
              }
            }
            fieldBeamFlavorfinderTerms {
              entity {
                name
              }
            }
            fieldBeamBourbonuFeatNotes {
              entity {
                name
              }
            }
            fieldBeamBourbonuImage {
              entity {
                ... on MediaBeamBourbonuImage {
                  fieldBeamBourbonuImage {
                    url
                  }
                }
              }
            }
            fieldBeamBourbonuEpid
            fieldBeamBourbonuFeatures {
              entity {
                ... on ParagraphBeamBourbonuBottleFeatures {
                  fieldBourbonuBfAge
                  fieldBourbonuBfPrice
                  fieldBourbonuBfProof
                }
              }
            }
          }
        }
      }
      lastFlavorFinderResult {
        bottles {
          __typename
          entityId
          ... on BeamBourbonuPieceBottle {
            fieldBeamBourbonuShareImage {
              entity {
                ... on MediaBeamBourbonuImage {
                  fieldBeamBourbonuImage {
                    url
                  }
                }
              }
            }
            fieldBeamFlavorfinderTerms {
              entity {
                name
              }
            }
            fieldBeamBourbonuFeatNotes {
              entity {
                name
              }
            }
            fieldBeamBourbonuImage {
              entity {
                ... on MediaBeamBourbonuImage {
                  fieldBeamBourbonuImage {
                    url
                  }
                }
              }
            }
          }
        }
        bucket {
          name
          path {
            alias
          }
          fieldBeamBourbonuRpShareable {
            entity {
              ... on ParagraphBeamBourbonuRpShareables {
                fieldBeamBourbonuCopy {
                  value
                  __typename
                }
                fieldBeamBourbonuThreeimgs {
                  entity {
                    ... on MediaBeamBourbonuImage {
                      fieldBeamBourbonuImage {
                        url
                      }
                    }
                    __typename
                  }
                  __typename
                }
                fieldBeamBourbonuArchive {
                  entity {
                    ... on MediaBeamBourbonuImage {
                      fieldBeamBourbonuImage {
                        url
                      }
                    }
                    __typename
                  }
                }
              }
            }
          }
        }
      }
      ffyb {
        startDate
        location
        notificationsAccepted
      }
      latestBooking {
        bookingId
        experienceName
        experienceDate
        numberGuestsBooked
      }
      anyroadTicketDetails {
        eyebrow
        title
        description
        image
        tab
        locationId
      }
    }
  }
`

export const GetUserQuizInformation = gql`
  ${IMAGE_FIELDS}
  ${BucketComponents}
  query {
    currentUser {
      lastFlavorFinderResult {
        bucket {
          ...BucketComponents
        }
        otherBuckets {
          entityId
          name
          fieldBeamBourbonuRpInfo {
            entity {
              ... on ParagraphBeamBourbonuRpInfo {
                fieldBeamBourbonuTitle
                fieldBeamBourbonuCopy {
                  value
                }
                fieldBeamBourbonuImage {
                  entity {
                    ...imageFields
                  }
                }
                fieldBeamBourbonuActiveImage {
                  entity {
                    ...imageFields
                  }
                }
              }
            }
          }
        }
        bottles {
          entityId
          entityBundle
          title
          description {
            value
          }
          ... on BeamBourbonuPieceBottle {
            fieldBeamBourbonuShareImage {
              entity {
                ...imageFields
                __typename
              }
              __typename
            }
            fieldBeamFlavorfinderTerms {
              entity {
                name
              }
            }
            fieldBeamBourbonuFeatNotes {
              entity {
                name
              }
            }
            fieldBeamBourbonuEpid
            fieldBeamBourbonuImage {
              entity {
                ...imageFields
              }
            }
            fieldBeamBourbonuFeatures {
              entity {
                ... on ParagraphBeamBourbonuBottleFeatures {
                  fieldBourbonuBfAge
                  fieldBourbonuBfPrice
                  fieldBourbonuBfProof
                }
              }
            }
          }
        }
      }
    }
  }
`
export const UserFavorites = gql`
  fragment UserFavorites on Query {
    currentUser {
      fieldUserprofileFavoritPieces {
        entity {
          entityId
          title
          entityUrl {
            path
          }
          description {
            value
          }
          ... on BeamBourbonuPieceRecipe {
            fieldBeamBourbonuImage {
              entity {
                ... on MediaBeamBourbonuImage {
                  fieldBeamBourbonuImage {
                    url
                    alt
                  }
                }
              }
            }
            fieldBeamBourbonuCategory {
              entity {
                name
              }
            }
            fieldBeamBourbonuFeatures {
              entity {
                ... on ParagraphBeamBourbonuRecipeFeatures {
                  fieldBeamBourbonuRfeatKind {
                    entity {
                      name
                      ... on TaxonomyTermRecipeFeatures {
                        fieldBeamBourbonuIcon {
                          entity {
                            ... on MediaBeamBourbonuImage {
                              fieldBeamBourbonuImage {
                                url
                                alt
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                  fieldBeamBourbonuRfeatDurat {
                    entity {
                      name
                      ... on TaxonomyTermRecipeFeatures {
                        fieldBeamBourbonuIcon {
                          entity {
                            ... on MediaBeamBourbonuImage {
                              fieldBeamBourbonuImage {
                                url
                                alt
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                  fieldBeamBourbonuRfeatLevel {
                    entity {
                      name
                      ... on TaxonomyTermRecipeFeatures {
                        fieldBeamBourbonuIcon {
                          entity {
                            ... on MediaBeamBourbonuImage {
                              fieldBeamBourbonuImage {
                                url
                                alt
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          ... on BeamBourbonuPieceVideo {
            title
            entityId
            description {
              value
            }
            fieldBeamBourbonuVideo {
              entity {
                ... on MediaBeamBourbonuRemoteVideo {
                  fieldBeamBourbonuVideoEmbed
                }
                ... on MediaBeamBourbonuVideo {
                  fieldBeamBourbonuFile {
                    entity {
                      url
                    }
                  }
                }
              }
            }
            fieldBeamBourbonuImage {
              entity {
                ... on MediaBeamBourbonuImage {
                  fieldBeamBourbonuImage {
                    url
                    alt
                  }
                }
              }
            }
            fieldBeamBourbonuCategory {
              entity {
                name
              }
            }
            fieldBeamBourbonuContent {
              entity {
                ... on ParagraphBeamBourbonuTextOnly {
                  fieldBeamBourbonuCopy {
                    value
                  }
                }
                ... on ParagraphBeamBourbonuTextWhithImage {
                  fieldBeamBourbonuCopy {
                    value
                  }
                  fieldBeamBourbonuImage {
                    entity {
                      ... on MediaBeamBourbonuImage {
                        fieldBeamBourbonuImage {
                          url
                          alt
                        }
                      }
                    }
                  }
                  fieldBeamBourbonuImgPosition
                }
              }
            }
            fieldBeamBourbonuBreadcrumbs {
              entity {
                ... on BeamBourbonuPieceArticle {
                  title
                  path {
                    alias
                  }
                }
                ... on BeamBourbonuPiecePage {
                  title
                  path {
                    alias
                  }
                }
              }
            }
            fieldBeamBourbonuRelPieces {
              entity {
                title
                description {
                  value
                }
                entityUrl {
                  path
                }
                ... on BeamBourbonuPieceVideo {
                  fieldBeamBourbonuVideo {
                    entity {
                      thumbnail {
                        url
                      }
                    }
                  }
                  fieldBeamBourbonuCategory {
                    entity {
                      name
                    }
                  }
                }
                ... on BeamBourbonuPieceArticle {
                  fieldBeamBourbonuImages {
                    entity {
                      thumbnail {
                        url
                      }
                    }
                  }
                  fieldBeamBourbonuCategory {
                    entity {
                      name
                    }
                  }
                }
              }
            }
            fieldBeamBourbonuBreadcrumbs {
              entity {
                ... on BeamBourbonuPieceArticle {
                  title
                  path {
                    alias
                  }
                }
                ... on BeamBourbonuPiecePage {
                  title
                  path {
                    alias
                  }
                }
              }
            }
          }
          ... on BeamBourbonuPieceArticle {
            title
            entityId
            description {
              value
            }
            author: fieldBeamBourbonuAuthor
            fieldBeamBourbonuImages {
              entity {
                ... on MediaBeamBourbonuImage {
                  image: fieldBeamBourbonuImage {
                    alt
                    url
                  }
                }
              }
            }
            fieldBeamBourbonuCategory {
              entity {
                name
              }
            }
            breadcrumbs: fieldBeamBourbonuBreadcrumbs {
              entity {
                ... on BeamBourbonuPieceArticle {
                  title
                  path {
                    alias
                  }
                }
                ... on BeamBourbonuPiecePage {
                  title
                  path {
                    alias
                  }
                }
              }
            }
            paragraphs: fieldBeamBourbonuContent {
              entity {
                ... on ParagraphBeamBourbonuTextOnly {
                  copy: fieldBeamBourbonuCopy {
                    value
                  }
                }
                ... on ParagraphBeamBourbonuTextWhithImage {
                  copy: fieldBeamBourbonuCopy {
                    value
                  }
                  image: fieldBeamBourbonuImage {
                    entity {
                      ... on MediaBeamBourbonuImage {
                        image: fieldBeamBourbonuImage {
                          alt
                          url
                        }
                      }
                    }
                  }
                  position: fieldBeamBourbonuImgPosition
                }
              }
            }
            fieldBeamBourbonuRelPieces {
              entity {
                title
                description {
                  value
                }
                entityUrl {
                  path
                }
                ... on BeamBourbonuPieceVideo {
                  fieldBeamBourbonuVideo {
                    entity {
                      thumbnail {
                        url
                      }
                    }
                  }
                  fieldBeamBourbonuCategory {
                    entity {
                      name
                    }
                  }
                }
                ... on BeamBourbonuPieceArticle {
                  fieldBeamBourbonuImages {
                    entity {
                      thumbnail {
                        url
                      }
                    }
                  }
                  fieldBeamBourbonuCategory {
                    entity {
                      name
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export const UpdateCurrentUser = gql`
  mutation($profile: BeamUserProfileUpdate!) {
    updateCurrentUser(profile: $profile)
  }
`

export const RegisterCurrentUser = gql`
  mutation($profile: BeamUserProfileUpdate!) {
    registerCurrentUser(profile: $profile)
  }
`

export const UpdateFavoritePiece = gql`
  mutation($piece: FavoritePiece!) {
    updateFavoritePiece(piece: $piece) {
      id
      title
      favoriteForUser
    }
  }
`
export const UpdateFavoriteBottle = gql`
  mutation($piece: FavoritePiece!) {
    updateWishlist(piece: $piece) {
      id
      title
      inWishlist
    }
  }
`

export const SignUpFfyb = gql`
  mutation($ffyb: BeamUserProfileFfybUpdate!) {
    signUpFfyb(profile: $ffyb)
  }
`

export const UpdateFfyb = gql`
  mutation($ffyb: BeamUserProfileFfybUpdate!) {
    updateFfyb(profile: $ffyb)
  }
`

export const searchApiQuery = gql`
  query searchAPISearch(
    $index_id: String!
    $facets: [FacetInput]!
    $sort: [SortInput]!
    $range: RangeInput!
    $groups: [ConditionGroupInput]!
  ) {
    searchAPISearch(
      index_id: $index_id
      facets: $facets
      sort: $sort
      range: $range
      condition_group: { groups: $groups }
    ) {
      result_count
      facets {
        name
        values {
          filter
          count
        }
      }
      documents {
        ... on BeamBourbonuPieceContentsIndexDoc {
          id
          url
          title
          description
          articlefeatured
          videofeatured
          type
          kind
          iconkind
          level
          iconlevel
          duration
          iconduration
          brand
          category
        }
      }
    }
  }
`

export const GetSettings = gql`
  query {
    flavorFinderSettings {
      brandLink
      brandLogo {
        ... on MediaBeamBourbonuImage {
          fieldBeamBourbonuImage {
            url
          }
        }
      }
    }
  }
`
