import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames/bind"
import * as styles from "./articleDetail.module.scss"

const ArticleDetail = ({ copy, image, position }) => {
  const cx = classNames.bind(styles)
  const isFullBleed = position === "fullbleed"

  return (
    <div className={cx("root")}>
      {isFullBleed ? (
        <div className={cx("fullBleed")}>
          <div className={cx("imageWrapper")}>
            <img src={image.url} alt={image.alt} />
          </div>
          <div className={cx("captionWrapper")}>
            <div className={cx("container")}>
              <div className={cx("copyContainer")} dangerouslySetInnerHTML={{ __html: copy }} />
            </div>
          </div>
        </div>
      ) : (
        <div className={cx("grid", position)}>
          {image ? (
            <>
              <div className={cx("colSix")}>
                <div className={cx("container")}>
                  <img src={image.url} alt={image.alt} />
                </div>
              </div>
              <div className={cx("colSix")}>
                <div className={cx("container")}>
                  <div className={cx("copyContainer")} dangerouslySetInnerHTML={{ __html: copy }} />
                </div>
              </div>
            </>
          ) : (
            <div className={cx("colTwelve")}>
              <div className={cx("container")}>
                <div className={cx("copyContainer")} dangerouslySetInnerHTML={{ __html: copy }} />
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

ArticleDetail.propTypes = {
  copy: PropTypes.string,
  image: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
    PropTypes.string,
  ]),
  position: PropTypes.string,
}

ArticleDetail.defaultProps = {
  copy: ``,
  image: ``,
  position: ``,
}

export default ArticleDetail
