import React from "react"
import RecipeHero from "../../molecules/RecipeHero/RecipeHero"
import RecipeInstruction from "../../molecules/RecipeInstruction/RecipeInstruction"
import RecipeMoreCocktails from "../../organisms/RecipeMoreCocktails/RecipeMoreCocktails"
import * as styles from "./recipePage.module.scss"
import classNames from "classnames/bind"
import RelatedContent from "../../organisms/RelatedContent/RelatedContent"
import { withPrefix } from "../with-prefix"

import DesktopLPImage from "./BU-WhiskeyFinder-CTA-D.png"
import MobileLPImage from "./BU-WhiskeyFinder-CTA-M.png"

const RecipePage = ({ recipeData }) => {

  const cx = classNames.bind(styles)

  let recipeHeroProps = {}
  let recipeInstructionsProps = {}
  let recipeMoreCocktailsProps = { cards: null }
  let relatedContentProps = { cards: null }
  let relatedCardsProps = { cards: null }

  recipeHeroProps = {
    category: recipeData.fieldBeamBourbonuCategory?.entity?.name,
    title: recipeData.title,
    body: recipeData.description?.value,
    image: {
      src:
        recipeData.fieldBeamBourbonuImage?.entity?.fieldBeamBourbonuImage?.url,
      alt:
        recipeData.fieldBeamBourbonuImage?.entity?.fieldBeamBourbonuImage?.alt,
    },
    infoIcons: [
      {
        icon:
          recipeData.fieldBeamBourbonuFeatures?.entity
            ?.fieldBeamBourbonuRfeatLevel?.entity?.fieldBeamBourbonuIcon?.entity
            ?.fieldBeamBourbonuImage?.url,
        text:
          recipeData.fieldBeamBourbonuFeatures?.entity
            ?.fieldBeamBourbonuRfeatLevel?.entity?.name,
      },
      {
        icon:
          recipeData.fieldBeamBourbonuFeatures?.entity
            ?.fieldBeamBourbonuRfeatDurat?.entity?.fieldBeamBourbonuIcon?.entity
            ?.fieldBeamBourbonuImage?.url,
        text:
          recipeData.fieldBeamBourbonuFeatures?.entity
            ?.fieldBeamBourbonuRfeatDurat?.entity?.name,
      },
      {
        icon:
          recipeData.fieldBeamBourbonuFeatures?.entity
            ?.fieldBeamBourbonuRfeatKind?.entity?.fieldBeamBourbonuIcon?.entity
            ?.fieldBeamBourbonuImage?.url,
        text:
          recipeData.fieldBeamBourbonuFeatures?.entity
            ?.fieldBeamBourbonuRfeatKind?.entity?.name,
      },
    ],
    favoriteCta: {
      text: "Add to favorites",
      type: "favorite",
      icon: true,
      iconAlignment: "left",
      pieceId: recipeData.entityId,
    },
    shareCta: {
      text: "Share",
      type: "share",
      icon: true,
      iconAlignment: "left",
    },
  }

  recipeInstructionsProps = {
    ingredients: recipeData.fieldBeamBourbonuIngredients,
    instructions: recipeData.fieldBeamBourbonuInstructions?.value,
    productCard: {
      eyebrow:
        recipeData.fieldBeamBourbonuFeatBottle?.entity
          ?.fieldFlavorFinderCategory?.entity?.name,
      header: recipeData.fieldBeamBourbonuFeatBottle?.entity?.title,
      image: {
        url:
          recipeData.fieldBeamBourbonuFeatBottle?.entity?.fieldBeamBourbonuImage
            ?.entity?.fieldBeamBourbonuImage?.url,
        alt:
          recipeData.fieldBeamBourbonuFeatBottle?.entity?.fieldBeamBourbonuImage
            ?.entity?.fieldBeamBourbonuImage?.alt,
      },
      mobileImage: {
        url:
          recipeData.fieldBeamBourbonuFeatBottle?.entity?.fieldBeamBourbonuImage
            ?.entity?.fieldBeamBourbonuImage?.url,
        alt:
          recipeData.fieldBeamBourbonuFeatBottle?.entity?.fieldBeamBourbonuImage
            ?.entity?.fieldBeamBourbonuImage?.alt,
      },
      type: "recipe",
    },
  }

  if (recipeData.fieldBeamBourbonuOtherRecipe?.length > 0) {
    recipeData.fieldBeamBourbonuOtherRecipe?.forEach((card, index) => {
      const cocktailCard = {
        eyebrow:
          card.entity?.fieldBeamBourbonuCategory?.entity?.name ||
          "Cocktail category",
        header: card.entity?.title,
        cta: {
          text: "View Recipe",
          url: withPrefix(card.entity?.entityUrl?.path),
          type: "tertiary",
          variant: "positive",
          icon: true,
          iconAlignment: "right",
        },
        image:
          card.entity?.fieldBeamBourbonuImage?.entity?.fieldBeamBourbonuImage,
        mobileImage:
          card.entity?.fieldBeamBourbonuImage?.entity?.fieldBeamBourbonuImage,
        type: "recipe",
        info: [
          {
            text:
              card.entity?.fieldBeamBourbonuFeatures?.entity
                ?.fieldBeamBourbonuRfeatLevel?.entity?.name,
            icon:
              card.entity?.fieldBeamBourbonuFeatures?.entity
                ?.fieldBeamBourbonuRfeatLevel?.entity?.fieldBeamBourbonuIcon
                ?.entity?.fieldBeamBourbonuImage?.url,
          },
          {
            text:
              card.entity?.fieldBeamBourbonuFeatures?.entity
                ?.fieldBeamBourbonuRfeatDurat?.entity?.name,
            icon:
              card.entity?.fieldBeamBourbonuFeatures?.entity
                ?.fieldBeamBourbonuRfeatDurat?.entity?.fieldBeamBourbonuIcon
                ?.entity?.fieldBeamBourbonuImage?.url,
          },
          {
            text:
              card.entity?.fieldBeamBourbonuFeatures?.entity
                ?.fieldBeamBourbonuRfeatKind?.entity?.name,
            icon:
              card.entity?.fieldBeamBourbonuFeatures?.entity
                ?.fieldBeamBourbonuRfeatKind?.entity?.fieldBeamBourbonuIcon
                ?.entity?.fieldBeamBourbonuImage?.url,
          },
        ],
      }
      recipeMoreCocktailsProps.cards
        ? recipeMoreCocktailsProps.cards.push(cocktailCard)
        : (recipeMoreCocktailsProps.cards = [cocktailCard])
    })
  }
  recipeMoreCocktailsProps.imageHero = {
    eyebrow: "Whiskey Finder",
    title: "Let us find your perfect whiskey",
    copy:
      "Answer a few questions and we’ll map your whiskey palate, share those results with you and then introduce you to a bottle or two we think you’ll enjoy.",
    cta: {
      text: "Take the Quiz",
      url: withPrefix("/whiskey-finder/quiz"),
      type: "primary",
      variant: "positive",
      icon: false,
      iconAlignment: "",
    },
    image: {
      heroDesktop: {
        url: DesktopLPImage,
        alt: "Whiskey Finder image",
      },
    },
    mobileImage: {
      url: MobileLPImage,
      alt: "Whiskey Finder image",
    },
  }

  relatedContentProps.eyebrow = "more to explore"
  recipeData.fieldBeamBourbonuRelPieces?.forEach((piece, index) => {
    const card = {
      eyebrow: "our story",
      header: piece.entity?.title,
      entityId: piece.entity?.entityId,
      copy: piece.entity?.description?.value,
      image: {
        url: piece.entity?.fieldBeamBourbonuImages
          ? piece.entity?.fieldBeamBourbonuImages[0]?.entity?.thumbnail?.url
          : piece.entity?.fieldBeamBourbonuImage?.entity?.fieldBeamBourbonuImage.url,
        alt: "Related content image",
      },
      mobileImage: {
        url: piece.entity?.fieldBeamBourbonuImages
          ? piece.entity?.fieldBeamBourbonuImages[1]?.entity?.thumbnail?.url
          : piece.entity?.fieldBeamBourbonuImage?.entity?.fieldBeamBourbonuImage.url,
        alt: "Related content image",
      },
      type: "related",
      url: withPrefix(piece.entity?.entityUrl?.path),
      variant: "2UP",
      hasSave: false,
      hasShare: false,
      cta: {
        text: "Read Now",
        url: withPrefix(piece.entity?.entityUrl?.path),
        type: "tertiary",
        variant: "positive",
        icon: true,
        iconAlignment: "right",
      },
    }
    relatedContentProps.cards
      ? relatedContentProps.cards.push(card)
      : (relatedContentProps.cards = [card])
  })

  relatedCardsProps.eyebrow = "related"
  recipeData.relatedContent?.forEach((piece, index) => {
    const card = {
      eyebrow: "our story",
      header: piece?.title,
      entityId: piece?.entityId,
      copy: piece?.description?.value,
      image: {
        url: piece?.fieldBeamBourbonuImages
          ? piece?.fieldBeamBourbonuImages[0]?.entity?.thumbnail?.url
          : piece?.fieldBeamBourbonuImage?.entity?.fieldBeamBourbonuImage?.url,
        alt: "Related content image",
      },
      mobileImage: {
        url: piece?.fieldBeamBourbonuImages
          ? piece?.fieldBeamBourbonuImages[1]?.entity?.thumbnail?.url
          : piece?.fieldBeamBourbonuImage?.entity?.fieldBeamBourbonuImage?.url,
        alt: "Related content image",
      },
      type: "related",
      url: withPrefix(piece?.entityUrl?.path),
      variant: "2UP",
      hasSave: false,
      hasShare: false,
      cta: {
        text: "Read Now",
        url: withPrefix(piece?.entityUrl?.path),
        type: "tertiary",
        variant: "positive",
        icon: true,
        iconAlignment: "right",
      },
    }
    relatedCardsProps.cards
      ? relatedCardsProps.cards.push(card)
      : (relatedCardsProps.cards = [card])
  })

  if (recipeData.fieldBeamBourbonuRelPieces.length <= 1 && relatedCardsProps.cards) {
    relatedContentProps.cards = [...relatedCardsProps.cards].filter(
      elem => elem.entityId != recipeData.entityId
    )
  }

  return (
    <div className={cx("wrapper")}>
      {recipeHeroProps.image?.src && recipeHeroProps.title && (
        <RecipeHero {...recipeHeroProps} />
      )}
      {recipeInstructionsProps.ingredients.length > 0 &&
        recipeInstructionsProps.instructions && (
          <RecipeInstruction {...recipeInstructionsProps} />
        )}
      {recipeMoreCocktailsProps.cards && (
        <RecipeMoreCocktails {...recipeMoreCocktailsProps} />
      )}
      {relatedContentProps.cards && <RelatedContent {...relatedContentProps} />}
    </div>
  )
}
export default RecipePage
