// extracted by mini-css-extract-plugin
export var wrapper = "contentCardsContainer-module--wrapper--3Madw";
export var smallContainer = "contentCardsContainer-module--smallContainer--1IVLx";
export var content = "contentCardsContainer-module--content--gOXu-";
export var cardsContainer = "contentCardsContainer-module--cardsContainer--4Bix_";
export var cardsWrapper = "contentCardsContainer-module--cardsWrapper--NNUhF";
export var carousel = "contentCardsContainer-module--carousel--1d5A-";
export var scrollBar = "contentCardsContainer-module--scrollBar--QqNhH";
export var max4 = "contentCardsContainer-module--max-4--3Y8wb";
export var max5 = "contentCardsContainer-module--max-5--QYgT4";
export var max6 = "contentCardsContainer-module--max-6--3UeAD";
export var max7 = "contentCardsContainer-module--max-7--2vway";
export var max8 = "contentCardsContainer-module--max-8--PcySQ";
export var scrollBarWrapper = "contentCardsContainer-module--scrollBarWrapper--1cDZF";