import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import classNames from "classnames/bind"

import * as styles from "./optionGroup.module.scss"

const OptionGroup = ({
  defaultImage,
  selectedImage,
  title,
  subtitle,
  id,
  options,
  onSelect,
  onFocus,
  active,
  value,
  overlaps,
  snapToColumn,
  selection,
  onlyOption,
  onBlur,
}) => {
  const [currentValue, setCurrentValue] = useState(value)
  const cx = classNames.bind(styles)

  useEffect(() => {
    document.addEventListener("keyup", handleKeyUp)
    return document.addEventListener("keyup", handleKeyUp)
  })

  useEffect(() => {
    let currentSelection = null
    if (selection && !value) {
      options.map(option => {
        if (selection.some(id => id === option.id)) {
          currentSelection = option.id
        }
      })
      setCurrentValue(currentSelection)
    }
  }, selection)

  useEffect(() => {
    setCurrentValue(value)
  }, [value])

  const handleClick = preferenceValue => {
    setCurrentValue(preferenceValue)
    onSelect(id, preferenceValue)
  }

  const handleActive = () => {
    if (!active) {
      onFocus()
    }
  }

  const handleBlur = (e, back = false) => {
    e.preventDefault()
    if (onlyOption) {
      onBlur(back ? id - 1 : id)
    }
  }

  const handleKeyUp = e => {
    const isOption = e.target.className === cx("option")
    if (isOption) {
      if (e.key === "Shift" || e.keyCode === 16) {
        handleBlur(e, true)
      } else if (e.key === "Tab" || e.keyCode === 9) {
        handleBlur(e)
      }
    }
  }

  let columnStyles = {}
  let offset = {}
  if (snapToColumn) {
    const groupSize =
      snapToColumn.span * snapToColumn.width +
      (snapToColumn.span - 1) * snapToColumn.gap
    columnStyles = {
      width: `${groupSize}px`,
      height: `${groupSize}px`,
    }
    offset = {
      width: `${groupSize}px`,
      marginLeft:
        id > 0
          ? overlaps
            ? `-${snapToColumn.gap + snapToColumn.width}px`
            : 0
          : 0,
    }
  }

  return (
    <div
      className={cx("optionWrapper", active && "active", overlaps && "overlap")}
      style={{ ...offset }}
    >
      <div
        className={cx("option", active && !onlyOption && "active")}
        style={{ ...columnStyles }}
        onClick={handleActive}
        onFocus={handleActive}
        tabIndex={0}
      >
        {defaultImage && (
          <div>
            <img src={defaultImage.url} alt={title} />
            <img
              src={selectedImage.url}
              alt={title}
              className={cx("complete", currentValue && "visible")}
            />
          </div>
        )}
        <h2>{title}</h2>
        <p>{subtitle}</p>
      </div>
      <div className={cx("preferenceWrapper")}>
        {options &&
          !onlyOption &&
          options.map((preference, index) => {
            const key = `preference-${index}`
            return (
              <button
                key={key}
                onClick={e => handleClick(preference.id)}
                onFocus={handleActive}
                className={cx("preference", {
                  ["selected"]: currentValue === preference.id,
                })}
              >
                {preference.title}
              </button>
            )
          })}
      </div>
    </div>
  )
}

OptionGroup.propTypes = {
  onSelect: PropTypes.func,
  onFocus: PropTypes.func,
  active: PropTypes.bool,
  defaultImage: PropTypes.object,
  selectedImage: PropTypes.object,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  options: PropTypes.arrayOf(PropTypes.object),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  overlaps: PropTypes.bool,
  snapToColumn: PropTypes.object,
  selection: PropTypes.array,
  onlyOption: PropTypes.bool,
  onBlur: PropTypes.func,
}

OptionGroup.defaultProps = {
  onSelect: () => {},
  onFocus: () => {},
  active: false,
  value: "",
  overlaps: false,
  snapToColumn: null,
  selection: null,
  onlyOption: false,
  onBlur: () => {},
}

export default OptionGroup
