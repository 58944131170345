import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames/bind"

import DeviceInfo from "../../utils/device-info"

import * as styles from "./slideCard.module.scss"

const SlideCardImageColumn = ({ image, reference, onImageClick }) => {
  const cx = classNames.bind(styles)
  return (
    <div className={cx("imageCol")} ref={ref => (reference = ref)}>
      <img src={image.url} alt={image.alt} onClick={onImageClick} />
    </div>
  )
}

SlideCardImageColumn.propTypes = {
  image: PropTypes.object,
}

SlideCardImageColumn.defaultProps = {
  image: { url: "", alt: "" },
}

const SlideCardWrapper = props => (
  <DeviceInfo>
    {deviceInfo => <SlideCardImageColumn deviceInfo={deviceInfo} {...props} />}
  </DeviceInfo>
)

export default SlideCardWrapper
