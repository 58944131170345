import React, { useState, useEffect } from "react"
import classNames from "classnames/bind"
import DeviceInfo from "../../utils/device-info"
import CategoryFilters from "../../molecules/CategoryFilters/CategoryFilters"
import CategoryResults from "../../molecules/CategoryResults/CategoryResults"

import * as styles from "./searchCategories.module.scss"

const favFilters = [
  {
    name: "type",
    values: [
      {
        value: "video",
        label: "Videos",
      },
      {
        value: "article",
        label: "Articles",
      },
      {
        value: "recipe",
        label: "Recipes",
      },
    ],
  },
]

const SearchCategories = ({
  deviceInfo,
  allFilters,
  categories,
  selected,
  favoritePieces,
  onChange,
  onChangeFilters,
  results,
  showPagination,
  handleOnLoadMore,
  loading,
  selectedOptions,
  updateState,
  clearAllFilters,
}) => {
  const cx = classNames.bind(styles)
  const [toggleFilters, setToggleFilters] = useState(true)
  const [selectedFilterFav, setSelectedFilterFav] = useState("all")

  const handleOnClick = e => {
    e.preventDefault()
    setToggleFilters(!toggleFilters)
  }

  const filteredFavs =
    selectedFilterFav !== "all"
      ? favoritePieces.filter(fav => fav.type === selectedFilterFav)
      : favoritePieces

  const filters =
    allFilters &&
    allFilters.filter(
      filter =>
        filter.values.some(value => value.count > 0) &&
        filter.name !== "category"
    )

  useEffect(() => {
    if (deviceInfo.viewport === "small") {
      setToggleFilters(false)
    }

    window.addEventListener("resize", () => {
      if (deviceInfo.viewport !== "small") {
        setToggleFilters(false)
      } else {
        setToggleFilters(true)
      }
    })
  }, [deviceInfo])

  return (
    <div className={cx("searchCategories")}>
      <div className={cx("tabs")}>
        <div className={cx("category")}>
          <ul>
            {categories &&
              categories.map(category => {
                return (
                  <li
                    key={category.name}
                    onClick={() => onChange(category)}
                    className={cx(
                      category.name === selected.name ? "selected" : ""
                    )}
                  >
                    <h5>{category.name} </h5>
                  </li>
                )
              })}
            {favoritePieces && (
              <li
                onClick={() => onChange({ name: "favorites" })}
                className={cx("favorites" === selected.name ? "selected" : "")}
              >
                <h5>Favorites</h5>
              </li>
            )}
          </ul>
        </div>
      </div>
      <div className={cx("filtersButton")}>
        <button onMouseDown={e => handleOnClick(e)}>
          <h4>Filters & Sort</h4>
        </button>
      </div>
      {toggleFilters && (
        <div className={cx("overlay")}>
          <div className={cx("filters")}>
            {selected.name === "favorites" ? (
              <CategoryFilters
                selected={selectedFilterFav}
                filters={favFilters}
                onChange={setSelectedFilterFav}
                hasSort={false}
              />
            ) : (
              <>
                <div className={cx("controls")}>
                  <h4>Filters & Sort</h4>
                  <i
                    className={cx("cross")}
                    onMouseDown={e => handleOnClick(e)}
                  />
                </div>
                <CategoryFilters
                  filters={filters}
                  onChange={(value, order, name) =>
                    onChangeFilters(value, order, name)
                  }
                  selectedOptions={selectedOptions}
                  updateState={e => updateState(e)}
                />
              </>
            )}
          </div>
        </div>
      )}
      {loading ? (
        "Loading..."
      ) : (
        <div className={cx("results")}>
          {selected.name === "favorites" && filteredFavs.length > 0 ? (
            <CategoryResults results={filteredFavs} />
          ) : results && results.length > 0 ? (
            <CategoryResults results={results} />
          ) : (
            <div className={cx("noResults")}>
              <h2>Sorry!</h2>
              <p>No results to show here. </p>
              <button onClick={e => clearAllFilters(e)}>
                Clear All Filters
              </button>
            </div>
          )}

          {showPagination && (
            <div className={cx("loadMore")}>
              <button onClick={e => handleOnLoadMore(e)}>
                <h4>Load More</h4>
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

const SearchCategoriesWrapper = props => (
  <DeviceInfo>
    {deviceInfo => <SearchCategories deviceInfo={deviceInfo} {...props} />}
  </DeviceInfo>
)

export default SearchCategoriesWrapper
