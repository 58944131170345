import React, { useState, useRef, useEffect } from "react"
import PropTypes from "prop-types"
import classNames from "classnames/bind"

import { gsap } from "gsap"
import { Draggable } from "gsap/Draggable"

import CopyLink from "../../atoms/CopyLink/CopyLink"
import Button from "../../atoms/Button/Button"
import SocialMedia from "../../atoms/SocialMedia/SocialMedia"

import { EVENTS, reports } from "../../utils/reports"
import DeviceInfo from "../../utils/device-info"

import * as styles from "./shareModule.module.scss"
const cx = classNames.bind(styles)

const handlePopup = (e, url) => {
  const height = 600 < window.innerHeight ? 600 : window.innerHeight
  const width = 600 < window.innerWidth ? 600 : window.innerWidth
  window.open(url, "popup", "height=" + height + ",width=" + width)
}

const ShareModule = ({
  titles,
  subCopy,
  images,
  linkUrl,
  ctasTitle,
  primaryCta,
  secondaryCta,
  animation,
  deviceInfo,
  clickableDots,
  hideRetakeCta,
  shareableCopy,
}) => {
  const [width, setWidth] = useState(0)
  const [currentSlide, setCurrentSlide] = useState(0)
  const [dragStart, setDragStart] = useState(0)
  const [dragEnd, setDragEnd] = useState(0)
  const containerRef = useRef(null)
  const carouselBlockRef = useRef(null)
  const carouselWrapperRef = useRef(null)
  const contentRef = useRef(null)
  const contentMobileRef = useRef(null)
  const [currentDownload, setCurrentDownload] = useState("")

  useEffect(() => {
    updateSlideSize()
    window.addEventListener("resize", updateSlideSize)
    updateSlideSize()
    return () => window.removeEventListener("resize", updateSlideSize)
  }, [])

  useEffect(() => {
    updateSlideSize()
    window.addEventListener("orientationchange", updateSlideSize)
    updateSlideSize()
    return () =>
      window.removeEventListener("orientationchange", updateSlideSize)
  }, [])

  useEffect(() => {
    updateSlideSize()
    setTimeout(() => {
      prepareAnimation()
    }, 1000)
  }, [carouselBlockRef.current, width])

  //fix for profile implementation width=0 issue
  useEffect(() => {
    let interval
    if (width === 0) {
      interval = setInterval(() => {
        updateSlideSize()
        if (width !== 0) {
          clearInterval(interval)
        }
      }, 500)
    }
    return () => clearInterval(interval)
  }, [width])

  useEffect(() => {
    setCurrentDownload(images[currentSlide]?.url || "")
  }, [currentSlide])

  useEffect(() => {
    if (dragStart > dragEnd) {
      // move foward
      if (currentSlide + 1 < images.length) {
        goToSlide(currentSlide + 1, "left")
        setCurrentSlide(currentSlide + 1)
      } else {
        goToSlide(0, "left")
        setCurrentSlide(0)
      }
    } else if (dragStart < dragEnd) {
      // move back
      if (currentSlide - 1 >= 0) {
        goToSlide(currentSlide - 1, "right")
        setCurrentSlide(currentSlide - 1)
      } else {
        goToSlide(images.length - 1, "right")
        setCurrentSlide(images.length - 1)
      }
    }
  }, [dragEnd])

  const prepareAnimation = (newCurrentSlide = currentSlide, direction = "") => {
    if (deviceInfo.isMobile && contentMobileRef.current) {
      titles.forEach((title, index) => {
        if (index === newCurrentSlide) {
          contentMobileRef.current.style.height =
            contentMobileRef.current.children[index].offsetHeight + 88 + "px"
        }
      })
      Draggable.create(carouselWrapperRef.current, {
        type: "x",
        edgeResistance: 0.5,
        dragResistance: 0.95,
        bounds: {
          minX: 0,
          maxX: 0,
        },
        lockAxis: true,
        onDragStart: args => {
          setDragStart(args.clientX)
        },
        onDragEnd: args => {
          setDragEnd(args.clientX)
        },
      })
      if (direction) {
        const imagesWrapper = carouselWrapperRef.current
        const children = imagesWrapper.children
        const textWrapper = contentMobileRef.current
        const textChildren = textWrapper.children
        const transitionDuration = 0.5
        titles.map((title, index) => {
          gsap.to(textChildren[index], {
            duration: transitionDuration,
            ease: "sine.out",
            opacity: 0,
            zIndex: 0,
          })
          if (newCurrentSlide === index) {
            gsap.to(textChildren[index], {
              duration: transitionDuration,
              ease: "sine.in",
              opacity: 1,
              zIndex: 10,
              delay: transitionDuration,
            })
          }
        })
        images.map((slide, index) => {
          if (currentSlide === index) {
            gsap.to(children[index], {
              duration: transitionDuration,
              ease: "none",
              opacity: 0,
              zIndex: 0,
              xPercent: direction === "left" ? -80 : 80,
            })
          }
          if (index !== newCurrentSlide && index !== currentSlide) {
            gsap.to(children[index], {
              duration: transitionDuration / 10,
              ease: "none",
              opacity: 0,
              zIndex: 0,
              xPercent: direction === "left" ? -80 : 80,
            })
          }
          if (newCurrentSlide === index) {
            gsap.to(children[index], {
              duration: transitionDuration / 10,
              ease: "none",
              opacity: 0,
              zIndex: 0,
              delay: transitionDuration / 10,
              xPercent: direction === "left" ? 100 : -100,
            })
            gsap.to(children[index], {
              duration: transitionDuration - transitionDuration / 10,
              ease: "none",
              opacity: 1,
              zIndex: 1,
              delay: transitionDuration * 0.8,
              xPercent: 0,
            })
          }
        })
      }
    } else {
      if (containerRef.current && carouselWrapperRef.current && width !== 0) {
        const imagesWrapper = carouselWrapperRef.current
        const children = imagesWrapper.children
        const textWrapper = contentRef.current
        const textChildren = textWrapper.children
        const transitionDuration = 0.5
        titles.map((title, index) => {
          gsap.to(textChildren[index], {
            duration: transitionDuration,
            ease: "sine.out",
            opacity: 0,
            zIndex: 0,
          })
          if (newCurrentSlide === index) {
            gsap.to(textChildren[index], {
              duration: transitionDuration,
              ease: "sine.in",
              opacity: 1,
              zIndex: 10,
              delay: transitionDuration,
            })
          }
        })
        images.map((slide, index) => {
          gsap.to(children[index], {
            duration: transitionDuration,
            ease: "sine.out",
            opacity: 0,
            zIndex: 0,
            yPercent: -10,
          })
          if (newCurrentSlide === index) {
            gsap.to(children[index], {
              duration: transitionDuration / 10,
              ease: "none",
              opacity: 0,
              zIndex: 0,
              delay: transitionDuration,
              yPercent: 10,
            })
            gsap.to(children[index], {
              duration: transitionDuration - transitionDuration / 10,
              ease: "sine.in",
              opacity: 1,
              zIndex: 10,
              delay: transitionDuration + transitionDuration / 10,
              yPercent: 0,
            })
          }
        })
      }
    }
  }

  const updateCurrent = progress => {
    const snapVal = 1 / images.length
    const current = Math.round(progress / snapVal)
    if (current < images.length) {
      setCurrentSlide(current)
    }
  }

  const updateSlideSize = () => {
    const blockRef = carouselBlockRef.current
    if (blockRef) {
      const newWidth = blockRef.clientWidth
      const smallViewport = deviceInfo.viewport === "small"
      let newHeight = smallViewport ? 397 : 620
      if (smallViewport) {
        newHeight = 364
      }

      gsap.to(blockRef, {
        height: `${newHeight}px`,
        maxHeight: `${newHeight}px`,
      })
      setWidth(newWidth)
      if (animation === "horizontal-slide" && carouselWrapperRef.current) {
        carouselWrapperRef.current.style.width = `${newWidth * images.length}px`
      }
    }
    if (contentMobileRef.current) {
      titles.forEach((title, index) => {
        if (index === currentSlide) {
          contentMobileRef.current.style.height =
            contentMobileRef.current.children[index].offsetHeight + 88 + "px"
        }
      })
    }
  }

  const goToSlide = (slide, direction) => {
    setCurrentSlide(slide)
    prepareAnimation(slide, direction)
  }

  const next = () => {
    reports({
      event: EVENTS.CAROUSEL_INTERACTION,
      direction: "right",
      title: titles[currentSlide],
    })
    const nextSlide = currentSlide < images.length - 1 ? currentSlide + 1 : 0
    goToSlide(nextSlide)
  }

  const prev = () => {
    reports({
      event: EVENTS.CAROUSEL_INTERACTION,
      direction: "left",
      title: titles[currentSlide],
    })
    const nextSlide = currentSlide > 0 ? currentSlide - 1 : images.length - 1
    goToSlide(nextSlide)
  }

  return (
    <div className={cx("container")} ref={containerRef} id="share-module">
      <div className={cx("grid")}>
        {images.length > 1 && (
          <div className={cx("controls")}>
            <div
              className={cx("prev")}
              onClick={() => prev()}
              aria-hidden="true"
            />
            <div
              className={cx("next")}
              onClick={() => next()}
              aria-hidden="true"
            />
          </div>
        )}
        {deviceInfo.isMobile && (
          <div className={cx("copyMobile")} ref={contentMobileRef}>
            {titles.map((title, index) => {
              return (
                <div key={index}>
                  <h6>{title}</h6>
                  <p>{subCopy}</p>
                </div>
              )
            })}
          </div>
        )}
        <div className={cx("carouselBlock")} ref={carouselBlockRef}>
          <div className={cx("carouselContainer")}>
            <div
              className={cx("image", "carouselWrapper")}
              ref={carouselWrapperRef}
            >
              {images.map((image, index) => {
                return (
                  image?.url && (
                    <img
                      key={image.alt + "_" + index}
                      src={image.url}
                      alt={image.alt}
                      onClick={() => {
                        reports({
                          event: EVENTS.IMAGE_CLICK,
                          type: "share",
                          title: `Share Image ${index + 1}`,
                        })
                      }}
                      onTouchEnd={() => {
                        reports({
                          event: EVENTS.QUIZ_SHARE,
                          method: "Download",
                        })
                      }}
                    />
                  )
                )
              })}
            </div>
          </div>
          {deviceInfo.isMobile && (
            <div className={cx("dotsWrapper")}>
              {images &&
                images.map((slide, index) => {
                  return (
                    <button
                      key={`dot-${index}`}
                      className={cx("dot", {
                        ["active"]: currentSlide === index,
                        ["clickable"]: clickableDots,
                      })}
                      onClick={() => goToSlide(index)}
                    >
                      {index}
                    </button>
                  )
                })}
            </div>
          )}
        </div>
        <div ref={contentRef} className={cx("content")}>
          {!deviceInfo.isMobile ? (
            titles.map((title, index) => {
              return (
                <div className={cx("textColumn")} key={index}>
                  <div className={cx("copyDesktop")}>
                    <h6>{title}</h6>
                    <p>{subCopy}</p>
                  </div>

                  <div className={cx("media")}>
                    <div className={cx("socialMedia")}>
                      <a
                        data-gtm-social-share="twitter"
                        data-gtm-url={linkUrl}
                        target="popup"
                        href={`http://twitter.com/share?url=${linkUrl}&text=${shareableCopy}`}
                        onClick={e => {
                          reports({
                            event: EVENTS.QUIZ_SHARE,
                            method: "Twitter",
                          })
                          return handlePopup(
                            e,
                            `http://twitter.com/share?url=${linkUrl}&text=${shareableCopy}`
                          )
                        }}
                      >
                        <SocialMedia variant="twitter" />
                      </a>
                      <a
                        data-gtm-social-share="facebook"
                        data-gtm-url={linkUrl}
                        target="popup"
                        href={`http://www.facebook.com/sharer.php?u=${linkUrl}&display=popup`}
                        onClick={e => {
                          reports({
                            event: EVENTS.QUIZ_SHARE,
                            method: "Facebook",
                          })
                          return handlePopup(
                            e,
                            `http://www.facebook.com/sharer.php?u=${linkUrl}&display=popup`
                          )
                        }}
                      >
                        <SocialMedia variant="facebook" />
                      </a>
                      <a
                        data-gtm-social-share="instagram"
                        data-gtm-url={linkUrl}
                        download="share"
                        href={currentDownload}
                        target="_blank"
                        onClick={() => {
                          reports({
                            event: EVENTS.QUIZ_SHARE,
                            method: "Instagram",
                          })
                        }}
                      >
                        <SocialMedia variant="instagram" />
                      </a>
                      <a
                        data-gtm-social-share="url link"
                        data-gtm-url={linkUrl}
                        download="share"
                        href={currentDownload}
                        target="_blank"
                        onClick={() => {
                          reports({
                            event: EVENTS.QUIZ_SHARE,
                            method: "Download",
                          })
                        }}
                      >
                        <SocialMedia variant="download" />
                      </a>
                    </div>
                    <CopyLink url={linkUrl} />
                  </div>
                  {!hideRetakeCta && (
                    <div className={cx("account")}>
                      {ctasTitle && <h4>{ctasTitle}</h4>}
                      <div className={cx("ctas")}>
                        {primaryCta && <Button {...primaryCta} />}
                        {secondaryCta && <Button {...secondaryCta} />}
                      </div>
                    </div>
                  )}
                </div>
              )
            })
          ) : (
            <>
              <div className={cx("media")}>
                <div className={cx("socialMedia")}>
                  <a
                    data-gtm-social-share="twitter"
                    data-gtm-url={linkUrl}
                    target="popup"
                    href={`http://twitter.com/share?url=${linkUrl}&text=${shareableCopy}`}
                    onClick={e => {
                      reports({
                        event: EVENTS.QUIZ_SHARE,
                        method: "Twitter",
                      })
                      return handlePopup(
                        e,
                        `http://twitter.com/share?url=${linkUrl}&text=${shareableCopy}`
                      )
                    }}
                  >
                    <SocialMedia variant="twitter" />
                  </a>
                  <a
                    data-gtm-social-share="facebook"
                    data-gtm-url={linkUrl}
                    target="popup"
                    href={`http://www.facebook.com/sharer.php?u=${linkUrl}&display=popup`}
                    onClick={e => {
                      reports({
                        event: EVENTS.QUIZ_SHARE,
                        method: "Facebook",
                      })
                      return handlePopup(
                        e,
                        `http://www.facebook.com/sharer.php?u=${linkUrl}&display=popup`
                      )
                    }}
                  >
                    <SocialMedia variant="facebook" />
                  </a>
                  <a
                    data-gtm-social-share="instagram"
                    data-gtm-url={linkUrl}
                    download="share"
                    href={currentDownload}
                    target="_blank"
                    onClick={() => {
                      reports({
                        event: EVENTS.QUIZ_SHARE,
                        method: "Instagram",
                      })
                    }}
                  >
                    <SocialMedia variant="instagram" />
                  </a>
                  <a
                    data-gtm-social-share="url link"
                    data-gtm-url={linkUrl}
                    download="share"
                    href={currentDownload}
                    target="_blank"
                    onClick={() => {
                      reports({
                        event: EVENTS.QUIZ_SHARE,
                        method: "Download",
                      })
                    }}
                  >
                    <SocialMedia variant="download" />
                  </a>
                </div>
                <CopyLink url={linkUrl} />
              </div>
              {!hideRetakeCta && (
                <div className={cx("account")}>
                  {ctasTitle && <h4>{ctasTitle}</h4>}
                  <div className={cx("ctas")}>
                    {primaryCta && <Button {...primaryCta} />}
                    {secondaryCta && <Button {...secondaryCta} />}
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  )
}

const ShareModulelWrapper = props => (
  <DeviceInfo>
    {deviceInfo => <ShareModule deviceInfo={deviceInfo} {...props} />}
  </DeviceInfo>
)

ShareModule.propTypes = {
  titles: PropTypes.array,
  subCopy: PropTypes.string,
  linkUrl: PropTypes.string,
  ctasTitle: PropTypes.string,
  images: PropTypes.array,
  primaryCta: PropTypes.object,
  secondaryCta: PropTypes.object,
  animation: PropTypes.string,
  clickableDots: PropTypes.bool,
  shareableCopy: PropTypes.string,
  hideRetakeCta: PropTypes.bool,
}

ShareModule.defaultProps = {
  animation: "horizontal-slide",
  clickableDots: true,
  hideRetakeCta: false,
}

ShareModule.defaultProps = {}

export default ShareModulelWrapper
