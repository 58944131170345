import React from "react"
import PropTypes from "prop-types"

import Button from "../../../atoms/Button/Button"
import Checkbox from "../../../atoms/Checkbox/Checkbox"

const OptInModal = ({
  title,
  copy,
  primaryCta,
  handleClose,
  acceptTerms,
  setAcceptTerms,
}) => {
  return (
    <>
      <h3>{title}</h3>
      <p>{copy}</p>
      <Checkbox
        label={
          "Yes, I’d like to opt into email communications and barrel updates from the Fill and Follow Your Barrel program."
        }
        name={"globalPolicyAccepted"}
        onChange={() => setAcceptTerms(!acceptTerms)}
        defaultValue={acceptTerms}
        leftCheck={true}
        variant={"light"}
      />
      <Button {...primaryCta} onClick={handleClose} />
    </>
  )
}

OptInModal.propTypes = {
  title: PropTypes.string,
  copy: PropTypes.string,
  primaryCta: PropTypes.object,
  handleClose: PropTypes.func,
}
OptInModal.defaultProps = {}

export default OptInModal
