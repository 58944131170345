import React, { useEffect, useState } from "react"
import classNames from "classnames/bind"
import Input from "../../atoms/Input/Input"
import Password from "../../atoms/Password/Password"
import Checkbox from "../../atoms/Checkbox/Checkbox"
import Button from "../../atoms/Button/Button"
import * as styles from "./signInPage.module.scss"
import { Link } from "gatsby"
import { navigate } from "@reach/router"
import { withPrefix } from "../with-prefix"
import useCognitoUserData from "../cognito-user-data"
import { EVENTS, reports } from "../reports"
import { useQuizResult } from "../../../hooks/useQuizResults"
import { lastQuizOptions } from "../session-variable"
import moment from"moment";

const SignInPage = ({ location }) => {
  const requiredFieldNames = ["email", "password"]
  const {
    setTempUserData,
    isLoggedIn,
    sessionChecked,
    checkSession,
    setUpdateSession,
    signIn,
    userData
  } = useCognitoUserData()
  const [errorMsg, setErrorMsg] = useState("")
  const [formValues, setFormValues] = useState({})
  const [formErrors, setFormErrors] = useState({})
  const { sendResult } = useQuizResult()
  const params = new URLSearchParams(location?.search)
  const ffybLocation = params.get("location") || ""
  const cx = classNames.bind(styles)
  const isEnabled = () => {
    let isEnabled = true
    requiredFieldNames.forEach(key => {
      if (!formValues[key]) {
        isEnabled = false
      }
    })
    for (const key in formErrors) {
      if (formErrors[key]) {
        isEnabled = false
      }
    }
    return isEnabled
  }

  const handleChange = (value, name) => {
    setFormValues({ ...formValues, [name]: value })
  }

  const handleError = (value, name) => {
    setFormErrors({ ...formErrors, [name]: value })
  }

  //check isLoggedIn
  useEffect(() => {
    const uData = userData?.currentUser;
    if (sessionChecked && isLoggedIn() && uData) {
      const dateNew = moment(uData.birthdate).format('YYYY/MM/DD');
      reports({
        event: EVENTS.LOGIN,
        userFirstName: uData.firstname,
        userLastName: uData.lastname,
        userEmail: uData.email,
        userBirthDate: dateNew,
        userState: uData.state,
        userCity: uData.city,
        userCountry: uData.country,
      })
      if (ffybLocation) {
        return navigate(
          `${withPrefix("/profile")}?location=${ffybLocation}`
        )
      }
      navigate(withPrefix("/profile"))
    }
  }, [sessionChecked, userData])

  const handleSubmit = e => {
    e.preventDefault()
    if (!isEnabled()) {
      return
    }
    setErrorMsg("")
    signIn(formValues["email"], formValues["password"], userAttributes => {
      setTempUserData(userAttributes)
      navigate(withPrefix("/reset-password"))
    })
      .then(async () => {
        //update AWS credentials
        setUpdateSession(true)
        checkSession()
        const quizOptions = lastQuizOptions().get()
        if (quizOptions) {
          await sendResult({ variables: { options: quizOptions } })
        }
        // if (ffybLocation) {
        //   return navigate(
        //     `${withPrefix("/fill-and-follow")}?location=${ffybLocation}`
        //   )
        // }
        // navigate(withPrefix("/profile"))
      })
      .catch(err => {
        setErrorMsg(err.message)
      })
  }

  return (
    <div className={cx("wrapper")}>
      <div className={cx("container")}>
        <form onSubmit={e => handleSubmit(e)} className={cx("formContainer")}>
          <div className={cx("formHeader")}>
            <h1>Sign in</h1>
          </div>
          <div className={cx("formFields")}>
            <Input
              type={"email"}
              name={"email"}
              label={"Email"}
              placeholder={"Email"}
              maxLength={0}
              isRequired={false}
              onChange={e => handleChange(e, "email")}
              onError={e => handleError(e, "email")}
            />
            <Password
              name={"password"}
              label={"Password"}
              placeholder={"Password"}
              maxLength={0}
              isRequired={false}
              onChange={e => handleChange(e, "password")}
              onError={e => handleError(e, "password")}
              defaultError={formErrors.password}
              isFromSignIn={true}
            />
            <div>
              <Checkbox label={"Remember Me"} leftCheck={true} />
              <Link to={withPrefix("/forgot-password")}>Forgot password</Link>
            </div>
          </div>
          <div className={cx("formFooter")}>
            <Button
              type={"primary"}
              text={"Sign in"}
              icon={false}
              state={isEnabled() ? "active" : "disabled"}
            />
            {errorMsg && <p>{errorMsg}</p>}
            <p>Don’t have a account with us?</p>
            <Button
              type={"tertiary"}
              text={"Create account"}
              iconAlignment={"right"}
              icon={true}
              url={
                withPrefix("/sign-up") +
                (ffybLocation ? `?location=${ffybLocation}` : "")
              }
            />
          </div>
        </form>
      </div>
    </div>
  )
}

export default SignInPage
