import React, { useContext, useRef, useEffect, useState } from "react"

import { EVENTS, reports } from "../utils/reports"

import { navigate } from "@reach/router"
import { withPrefix } from "../utils/with-prefix"

import { ModalContext } from "../../context/ModalContext"

import Layout from "../atoms/layout/Layout"
import ResultsHeader from "../atoms/ResultsHeader/ResultsHeader"

import ParallaxLockup from "../organisms/ParallaxLockup/ParallaxLockup"
import ShareModule from "../organisms/ShareModule/ShareModule"
import ResultsHero from "../organisms/ResultsHero/ResultsHero"
import ParallaxLockupWithCarousel from "../organisms/ParallaxLockupWithCarousel/ParallaxLockupWithCarousel"

import useCognitoUserData from "../utils/cognito-user-data"
import { useQuery } from "@apollo/client"
import { GetUserInformation } from "../utils/fragments"
import ParallaxLockupA from "../organisms/ParallaxLockupA/ParallaxLockupA"
import ParallaxLockupC from "../organisms/ParallaxLockupC/ParallaxLockupC"
import { ScrollTrigger } from "gsap/ScrollTrigger"

const BucketPage = ({ result }) => {
  let path = result?.flavorFinderSubmit
    ? result.flavorFinderSubmit.bucket
    : result
  const {
    fieldBeamBourbonuImage: heroImage,
    fieldBeamBourbonuActiveImage: heroActiveImage,
    fieldBeamBourbonuBackground: heroBackground,
    fieldBeamBourbonuCopy: heroCopy,
  } = path?.fieldBeamBourbonuRpHero?.entity || {}

  const {
    fieldBeamBourbonuTitle: flavorNotesTitle,
    fieldBeamBourbonuEyebrow: flavorNotesEyebrow,
    fieldBeamBourbonuCards: flavorNotesCards,
    fieldBeamBourbonuCopy: flavorNotesCopy,
  } = path?.fieldBeamBourbonuRpFnotes?.entity || {}

  const {
    fieldBeamBourbonuCopy: shareableCopy,
    fieldBeamBourbonuThreeimgs,
    fieldBeamBourbonuArchive: shareableArchive,
  } = path?.fieldBeamBourbonuRpShareable?.entity || {}
  const shareableThreeimgs = fieldBeamBourbonuThreeimgs
    ? fieldBeamBourbonuThreeimgs.map(img => img?.entity?.fieldBeamBourbonuImage)
    : []
  const isShareEnabled = fieldBeamBourbonuThreeimgs?.length > 0
  const defaultShareData = {
    titles: [
      "Compare Your Palate",
      "Show Off Your Knowledge",
      "Share your bottle",
    ],
    subCopy:
      "Kick off a conversation about whiskey with friends over 21 and start exploring together.",
    linkUrl: "https://www.beamdistillg.com/whiskey",
    ctasTitle: "Save your results",
    primaryCta: {
      text: "Create account",
      url: withPrefix("/sign-up"),
      type: "primary",
      variant: "positive",
      icon: false,
      iconAlignment: "",
    },
    secondaryCta: {
      url: withPrefix("/sign-in"),
      text: "Sign In",
      type: "secondary",
      variant: "positive",
    },
  }
  const bottlesPath = result?.flavorFinderSubmit?.bottles || result?.bottles
  const bottles = bottlesPath || []
  const bottleImage =
    bottles?.[0]?.fieldBeamBourbonuShareImage?.entity?.fieldBeamBourbonuImage
  if (bottleImage) {
    shareableThreeimgs.push(bottleImage)
  }

  const pieces = []
  if (bottles.length > 0) {
    bottles.map(
      bottle =>
        bottle?.fieldBeamBourbonuRelRecipes &&
        pieces.push(...bottle.fieldBeamBourbonuRelRecipes)
    )
  }
  if (result?.fieldBeamBourbonuRelContent?.length > 0) {
    result?.fieldBeamBourbonuRelContent.map(relContent =>
      pieces.push(relContent)
    )
  }

  const slides = pieces?.map(({ entity }) => {
    const type =
      entity.__typename === "BeamBourbonuPieceRecipe"
        ? "recipe"
        : entity.__typename === "BeamBourbonuPieceArticle"
        ? "article"
        : "video"
    return {
      title: entity.title,
      eyebrow: entity.fieldBeamBourbonuEyebrow,
      subcopy: entity.description?.value,
      type: type === "recipe" ? "article" : type,
      ctaBottom: true,
      image: {
        url:
          type === "recipe"
            ? entity.fieldBeamBourbonuImage?.entity?.fieldBeamBourbonuImage?.url
            : type === "article"
            ? entity.fieldBeamBourbonuImages[0]?.entity?.image.cardDesktop.url
            : entity.fieldBeamBourbonuVideo?.entity?.thumbnail?.url,
        alt: "background",
      },
      cta: {
        text:
          entity.__typename === "BeamBourbonuPieceRecipe"
            ? "View Recipe"
            : entity.__typename === "BeamBourbonuPieceArticle"
            ? "Read now"
            : "Watch now",
        url: withPrefix(entity?.entityUrl?.path),
        type: "tertiary",
        variant: "positive",
        icon: true,
        iconAlignment: "right",
      },
      url: withPrefix(entity?.entityUrl?.path),
    }
  })

  const otherBucketsPath =
    result?.flavorFinderSubmit?.otherBuckets || result?.otherBuckets

  const otherBuckets =
    otherBucketsPath?.filter(item => item.fieldBeamBourbonuRpInfo != null) || []

  const {
    fieldBeamBourbonuTitle: otherBucketsTitle,
    fieldBeamBourbonuCopy: otherBucketsCopy,
  } = path?.fieldBeamBourbonuRpInfo?.entity || {}

  const {
    fieldBeamBourbonuTitle: wtlfTitle,
    fieldBeamBourbonuEyebrow: wtlfEyebrow,
    fieldBeamBourbonuCards: wtlfCards,
    fieldBeamBourbonuCopy: wtlfCopy,
  } = path?.fieldBeamBourbonuRpWtlf?.entity || {}

  const {
    fieldBeamBourbonuTitle: relTitle,
    fieldBeamBourbonuEyebrow: relEyebrow,
    fieldBeamBourbonuCopy: relCopy,
  } = path?.fieldBeamBourbonuRelContTxt?.entity || {}

  const { data } = useQuery(GetUserInformation)

  const handleRetakeQuiz = () => {
    navigate(withPrefix("/whiskey-finder/quiz"))
  }

  const getShareUrl = path => {
    return window.location.href.replace("/whiskey-finder/results", path)
  }

  const currentUrl =
    typeof window !== "undefined" ? getShareUrl(path?.path?.alias) : ""

  const { openModal, setShareCompleteData, getShareCompleteData } = useContext(
    ModalContext
  )

  useEffect(() => {
    if (!getShareCompleteData() && isShareEnabled) {
      setShareCompleteData({
        shareImages: shareableThreeimgs,
        shareableCopy: shareableCopy?.value,
        linkUrl: currentUrl,
      })
    }
  }, [isShareEnabled])

  const holderElem = useRef(null)

  const { isLoggedIn } = useCognitoUserData()

  const [showModal, setShowModal] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [darkMode, setDarkMode] = useState(false)

  const onScroll = () => {
    if (!holderElem.current) {
      setShowModal(false)
      return
    }

    const top =
      holderElem.current.getBoundingClientRect().top +
      holderElem.current.clientHeight
    const offset = 120

    setShowModal(top + offset >= 0 && top - offset <= window.innerHeight)
  }

  const toggleHeaderMode = () => {
    setDarkMode(prev => !prev)
  }

  const onHeroAnimationStart = () => {
    toggleHeaderMode()
    ScrollTrigger.refresh()
  }

  const onHeroAnimationEnd = () => {
    toggleHeaderMode()
    ScrollTrigger.refresh()
  }

  useEffect(() => {
    reports({ event: EVENTS.PAGEVIEW_RESULTS })
  }, [])

  useEffect(() => {
    if (!isLoggedIn() && result?.flavorFinderSubmit && !showModal) {
      document.addEventListener("scroll", onScroll, true)
      return () => document.removeEventListener("scroll", onScroll, true)
    }
  }, [holderElem])

  useEffect(() => {
    if (showModal && !isModalOpen) {
      document.removeEventListener("scroll", onScroll, true)
      setIsModalOpen(true)
      openModal({
        title: "Save your results",
        copy: "Track how your palate evolves and share with friends.",
        variant: "save",
        top: window.scrollY,
      })
    }
  }, [showModal])

  const shareData = {
    ...defaultShareData,
    ...(isLoggedIn() && {
      primaryCta: null,
      secondaryCta: {
        text: "Retake Quiz",
        url: withPrefix("/whiskey-finder/quiz"),
        type: "secondary",
        variant: "positive",
      },
    }),
  }
  return (
    <Layout>
      <ResultsHeader darkMode={darkMode} />
      {heroCopy && (
        <ResultsHero
          resultImage={{
            url: heroImage.entity.fieldBeamBourbonuImage.url,
          }}
          resultImageActive={{
            url: heroActiveImage.entity.fieldBeamBourbonuImage?.url,
          }}
          backgroundImage={{
            url: heroBackground.entity.fieldBeamBourbonuImage?.url,
          }}
          copy={heroCopy.value}
          onAnimationEnd={onHeroAnimationEnd}
          onAnimationStart={onHeroAnimationStart}
        />
      )}
      {flavorNotesTitle && (
        <ParallaxLockupWithCarousel
          title={flavorNotesTitle}
          eyebrow={flavorNotesEyebrow}
          subcopy={flavorNotesCopy.value}
          slides={flavorNotesCards.map(({ entity }) => ({
            title: entity.fieldBeamBourbonuTitle,
            eyebrow: entity.fieldBeamBourbonuEyebrow,
            subcopy: entity.fieldBeamBourbonuCopy?.value,
            image: {
              url:
                entity.fieldBeamBourbonuImage?.entity?.fieldBeamBourbonuImage
                  ?.url,
              alt: entity.fieldBeamBourbonuTitle,
            },
            background: {
              url:
                entity.fieldBeamBourbonuBackground?.entity
                  ?.fieldBeamBourbonuImage?.url,
              alt: "background",
            },
          }))}
        />
      )}
      {wtlfTitle && (
        <div ref={holderElem}>
          <ParallaxLockupA
            title={wtlfTitle}
            eyebrow={wtlfEyebrow}
            subcopy={wtlfCopy.value}
            variant="carousel-A"
            slides={wtlfCards.map(({ entity }) => ({
              title: entity.fieldBeamBourbonuTitle,
              eyebrow: entity.fieldBeamBourbonuEyebrow,
              subcopy: entity.fieldBeamBourbonuCopy?.value,
              image: {
                url:
                  entity.fieldBeamBourbonuBackground?.entity
                    ?.fieldBeamBourbonuImage?.url,
                alt: "background",
              },
            }))}
          />
        </div>
      )}
      {bottles.length > 0 && (
        <ParallaxLockup
          slides={bottles.map(item => ({
            image: {
              url:
                item.fieldBeamBourbonuImage?.entity?.fieldBeamBourbonuImage
                  ?.url,
            },
            bottleName: item.title,
            bottleDescription: item.description.value,
            cta: {
              text: "Buy Now",
              iconAlignment: "right",
              icon: true,
              type: "primary",
              variant: "positive",
              state: "active",
            },
            proof: item.fieldBeamBourbonuFeatures.entity?.fieldBourbonuBfProof,
            age: item.fieldBeamBourbonuFeatures.entity?.fieldBourbonuBfAge,
            price: item.fieldBeamBourbonuFeatures.entity?.fieldBourbonuBfPrice,
            flavorNotes: item.fieldBeamBourbonuFeatNotes?.map(({ entity }) => [
              entity.name,
            ]),
            productId: item.fieldBeamBourbonuEpid,
            bottleId: item.entityId,
          }))}
        />
      )}
      {pieces.length > 0 && (
        <ParallaxLockupA
          title={relTitle}
          eyebrow={relEyebrow}
          subcopy={relCopy?.value}
          variant="related-content"
          slides={slides}
        />
      )}
      {isShareEnabled && (
        <ShareModule
          {...shareData}
          linkUrl={currentUrl}
          images={shareableThreeimgs}
          shareableCopy={shareableCopy?.value || ""}
          hideRetakeCta={data?.currentUser}
        />
      )}
      {otherBuckets.length > 0 && (
        <ParallaxLockupC
          animation="switch"
          hasDots={true}
          clickableDots={true}
          variant="carousel-C"
          title={otherBucketsTitle}
          subcopy={otherBucketsCopy?.value}
          cta={{
            text: "Retake quiz",
            url: withPrefix("/whiskey-finder/quiz"),
            type: "tertiary",
            variant: "positive",
            icon: true,
            reverseIcon: true,
            iconAlignment: "left",
            onClick: handleRetakeQuiz,
          }}
          slides={otherBuckets.map(item => ({
            image: {
              url:
                item.fieldBeamBourbonuRpInfo?.entity.fieldBeamBourbonuImage
                  ?.entity.fieldBeamBourbonuImage?.url,
              alt: "background",
            },
          }))}
        />
      )}
    </Layout>
  )
}

export default BucketPage
