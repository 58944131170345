// extracted by mini-css-extract-plugin
export var container = "shareModule-module--container--1y5L0";
export var grid = "shareModule-module--grid--3Zdfx";
export var controls = "shareModule-module--controls--8zhVQ";
export var prev = "shareModule-module--prev--37drr";
export var next = "shareModule-module--next--2lpFJ";
export var copyMobile = "shareModule-module--copyMobile--1l5ew";
export var carouselBlock = "shareModule-module--carouselBlock--1NNkw";
export var carouselContainer = "shareModule-module--carouselContainer--1nX2Z";
export var carouselWrapper = "shareModule-module--carouselWrapper--3-D5r";
export var content = "shareModule-module--content--1uK-R";
export var textColumn = "shareModule-module--textColumn--3mqHI";
export var media = "shareModule-module--media--1wdS9";
export var socialMedia = "shareModule-module--socialMedia--2hcBr";
export var account = "shareModule-module--account--8G9Cc";
export var ctas = "shareModule-module--ctas--mx7Dg";
export var dotsWrapper = "shareModule-module--dotsWrapper--2PIqw";
export var dot = "shareModule-module--dot--2Ey1w";
export var active = "shareModule-module--active--2mUpH";
export var clickable = "shareModule-module--clickable--19iBh";