import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import classNames from "classnames/bind"

import DeviceInfo from "../../utils/device-info"

import * as styles from "./tabItem.module.scss"

const cx = classNames.bind(styles)

const TabItem = ({ children, name, closed, deviceInfo }) => {
  const [isClosed, setIsClosed] = useState(closed)

  useEffect(() => {
    setIsClosed(closed)
  }, [closed])

  const handlerOnClick = () => setIsClosed(oldState => !oldState)

  return (
    <div className={cx("container")}>
      {deviceInfo.viewport === "small" && (
        <div className={cx("accordionHead")} onClick={handlerOnClick}>
          <h4>{name}</h4>
          <span
            className={cx({
              ["arrowUp"]: !isClosed,
              ["arrowDown"]: isClosed,
            })}
          />
        </div>
      )}
      <div
        className={cx({
          ["accordionContent"]: true,
          ["closed"]: isClosed,
        })}
      >
        {children}
      </div>
    </div>
  )
}

TabItem.propTypes = {
  name: PropTypes.string,
  children: PropTypes.node,
  closed: PropTypes.bool,
  deviceInfo: PropTypes.object,
}

TabItem.defaultProps = {
  closed: false,
}

const TabItemWrapper = props => (
  <DeviceInfo>
    {deviceInfo => <TabItem deviceInfo={deviceInfo} {...props} />}
  </DeviceInfo>
)

export default TabItemWrapper
