import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames/bind"

import ImageSwitcher from "../../atoms/ImageSwitcher/ImageSwitcher"

import * as styles from "./contentFillAndFollow.module.scss"

const cx = classNames.bind(styles)

export const brands = {
  CLB_CRAFTFILL_1: "Jim Beam®",
  CLB_CRAFTFILL_2: "Basil Hayden®",
}

const ContentFillAndFollow = ({
  eyebrow,
  header,
  body,
  time,
  location,
  image,
  mobileImage,
}) => {
  const brand = brands[location]
  return (
    <div className={cx("container")}>
      <div className={cx("contentContainer")}>
        <h4>{eyebrow}</h4>
        <h2>{header}</h2>
        <p>{body}</p>
        <div className={cx("fields")}>
          <div className={cx("timeField")}>
            <h4>START DATE</h4>
            <h3>{time}</h3>
          </div>
          <div className={cx("locationField")}>
            <h4>BRAND</h4>
            <h3>{brand}</h3>
          </div>
        </div>
      </div>
      <div className={cx("imageContainer")}>
        <ImageSwitcher
          img={image}
          smallImg={mobileImage}
          alt={header}
          breakpoint="md"
        />
      </div>
    </div>
  )
}

ContentFillAndFollow.propTypes = {
  eyebrow: PropTypes.string,
  header: PropTypes.string,
  body: PropTypes.string,
  time: PropTypes.string,
  location: PropTypes.string,
  image: PropTypes.object,
  mobileImage: PropTypes.object,
}
ContentFillAndFollow.defaultProps = {}

export default ContentFillAndFollow
