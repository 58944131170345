import React, { useRef } from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import classNames from "classnames/bind"

import * as styles from "./button.module.scss"
import { reports } from "../../utils/reports"

const Button = ({
  type,
  variant,
  state,
  icon,
  text,
  url,
  target,
  iconAlignment,
  onClick,
  isDelete,
  reverseIcon,
  trackingObjects,
  isFav,
}) => {
  const buttonRef = useRef(null)

  const cx = classNames.bind(styles)

  const buttonClassName = cx({
    [type]: type,
    disabled: state === "disabled",
    [variant]: variant,
    golden: state === "loading" || state === "confirmed",
    iconOnly: !text,
    iconLeft: iconAlignment === "left",
    iconRight: iconAlignment === "right",
  })

  const iconClassName = cx({
    iconBag:
      icon &&
      (state === "active" || state === "disabled") &&
      type !== "tertiary" &&
      !isFav,
    iconLoading: icon && state === "loading",
    iconCheckmark: icon && state === "confirmed",
    iconArrowRight: icon && type === "tertiary" && !isDelete && !reverseIcon,
    iconArrowLeft: icon && type === "tertiary" && !isDelete && reverseIcon,
    iconTrash: icon && type === "tertiary" && isDelete,
    iconFav: icon && isFav,
  })

  let label = ""
  if (text) {
    label = text
    if (state === "loading") {
      label = icon ? "loading" : "loading..."
    }
    if (state === "confirmed") {
      label = "confirmed"
    }
  }

  const handlerOnClick = () => {
    if (trackingObjects) {
      trackingObjects.forEach(obj => reports(obj))
    }
    onClick()
    buttonRef.current.blur()
  }

  return (
    <div className={cx("button")}>
      {!url ? (
        <button
          ref={buttonRef}
          onClick={handlerOnClick}
          className={buttonClassName}
          disabled={state === "loading"}
        >
          {icon && <i className={iconClassName} />}
          {label}
        </button>
      ) : (
        <Link
          ref={buttonRef}
          onClick={() => {
            handlerOnClick()
            return buttonRef.current.blur()
          }}
          className={buttonClassName}
          to={url}
          target={target}
        >
          {icon && <i className={iconClassName} />}
          {label}
        </Link>
      )}
    </div>
  )
}

Button.propTypes = {
  type: PropTypes.oneOf(["primary", "secondary", "tertiary"]),
  variant: PropTypes.oneOf(["positive", "accent"]),
  state: PropTypes.oneOf(["active", "disabled", "loading", "confirmed"]),
  icon: PropTypes.bool,
  iconAlignment: PropTypes.oneOf(["right", "left"]),
  text: PropTypes.string,
  url: PropTypes.string,
  target: PropTypes.oneOf(["_blank", "_self"]),
  onClick: PropTypes.func,
  isDelete: PropTypes.bool,
  isFav: PropTypes.bool,
  reverseIcon: PropTypes.bool,
  trackingObjects: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
}

Button.defaultProps = {
  url: "",
  type: "primary",
  variant: "positive",
  state: "active",
  icon: false,
  text: "",
  target: "_self",
  iconAlignment: "left",
  onClick: () => {},
  isDelete: false,
  reverseIcon: false,
  trackingObjects: null,
}

export default Button
