import React, { useEffect } from "react"
import { ApolloClient, createHttpLink, InMemoryCache } from "@apollo/client"
import { ApolloProvider } from "@apollo/react-hooks"
import { setContext } from "@apollo/client/link/context"
import * as AmazonCognitoIdentity from "amazon-cognito-identity-js"
import useCognitoUserData from "./cognito-user-data"

const getCognitoJwt = () => {
  const poolData = {
    UserPoolId: process.env.GATSBY_AWS_USER_POOL_ID,
    ClientId: process.env.GATSBY_AWS_CLIENT_ID,
  }
  return new Promise((resolve, reject) => {
    if (!poolData.UserPoolId || !poolData.ClientId) {
      return resolve("")
    }
    const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData)
    const cognitoUser = userPool.getCurrentUser()
    if (cognitoUser != null) {
      cognitoUser.getSession(function (err, session) {
        if (err || !session.isValid()) {
          return reject(err)
        }
        const refresh_token = session.getRefreshToken()
        cognitoUser.refreshSession(refresh_token, (err, session) => {
          if (err) {
            reject(err)
          } else {
            const jwt = session.getIdToken().getJwtToken()
            resolve(jwt)
          }
        })
      })
    } else {
      resolve("")
    }
  })
}

const authLink = setContext(({ headers }) => {
  return getCognitoJwt()
    .then(jwt => {
      return {
        headers: {
          Authorization: jwt ? `Bearer ${jwt}` : "",
        },
      }
    })
    .catch(err => {
      console.error("err: ", err)
    })
})

const httpLink = createHttpLink({
  uri: process.env.GATSBY_DRUPAL_BASE_URL + "/graphql",
})

const client =
  typeof window === "undefined"
    ? "undefined"
    : new ApolloClient({
        cache: new InMemoryCache(),
        link: authLink.concat(httpLink),
      })

const ApolloWrapper = ({ children }) => {
  const { updateSession, setUpdateSession } = useCognitoUserData()

  useEffect(() => {
    if (client && updateSession) {
      client.stop()
      client.clearStore()
      client.cache.reset()
      client.resetStore()
      setUpdateSession(false)
    }
  }, [updateSession])

  return client ? (
    <ApolloProvider client={client}>{children}</ApolloProvider>
  ) : (
    <>{children}</>
  )
}

export default ApolloWrapper
