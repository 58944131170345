import React, { useState, useEffect, useRef } from "react"
import PropTypes from "prop-types"
import classNames from "classnames/bind"
import DeviceInfo from "../../utils/device-info"
import * as styles from "./multipleOption.module.scss"

import QuestionOption from "../../atoms/QuestionOption/QuestionOption"

const MultipleOption = ({
  deviceInfo,
  title,
  subtitle,
  state,
  defaultImage,
  selectedImage,
  id,
  onClick,
  disabled,
  extraMargin,
}) => {
  const [width, setWidth] = useState(0)
  const [isSelected, setIsSelected] = useState()
  const [currentState, setCurrentState] = useState(state)

  const optionRef = useRef(null)

  const cx = classNames.bind(styles)

  useEffect(() => {
    updateRatios()
    window.addEventListener("resize", updateRatios)
    updateRatios()
    return () => window.removeEventListener("resize", updateRatios)
  }, [])

  useEffect(() => {
    setIsSelected(state === "selected")
  }, [state])

  useEffect(() => {
    updateRatios()
  }, [optionRef.current])

  const updateRatios = () => {
    if (optionRef.current) {
      let newWidth = optionRef.current.clientWidth
      if (
        optionRef.current.parentNode.clientWidth / 2 <
        optionRef.current.clientWidth
      ) {
        newWidth =
          (newWidth -
            (deviceInfo.viewport === "small"
              ? 21
              : deviceInfo.viewport === "medium"
              ? 24
              : 28)) /
          2
      }
      setWidth(newWidth)
    }
  }

  const handleClick = e => {
    onClick(id, !isSelected)
    setIsSelected(prevState => !prevState)
    setCurrentState(isSelected ? "selected" : "unselected")
    optionRef.current.blur()
  }

  const handleKeyPress = e => {
    e.stopPropagation()
    if (e.key === "Enter" || e.keyCode === 13) {
      if (!disabled) {
        handleClick(id)
      }
    }
  }

  const baseOption = {
    title: title,
    subtitle: subtitle,
    onClick: () => {
      handleClick(id)
    },
    isSelected: isSelected,
    className: cx("option"),
    id: id,
  }

  return (
    <div
      className={cx("wrapper", {
        ["isSelected"]: isSelected,
        ["button"]: !defaultImage,
        ["image"]: defaultImage,
        ["idle"]: state === "default",
        ["disabled"]: disabled,
        ["extraMargin"]: extraMargin,
      })}
      ref={optionRef}
      style={
        width > 0 && defaultImage
          ? {
              minHeight: `${width > 278 ? 278 : width}px`,
            }
          : {}
      }
      onKeyDown={e => handleKeyPress(e)}
      tabIndex={0}
    >
      {defaultImage && (
        <>
          <div
            className={cx("circle")}
            style={width > 0 ? { height: `${width}px` } : {}}
          ></div>
          <QuestionOption
            {...baseOption}
            image={defaultImage}
            selectedImage={selectedImage}
          />
        </>
      )}
      {!defaultImage && <QuestionOption {...baseOption} />}
    </div>
  )
}

MultipleOption.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  state: PropTypes.oneOf(["default", "selected", "unselected", "disabled"]),
  defaultImage: PropTypes.object,
  selectedImage: PropTypes.object,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  extraMargin: PropTypes.bool,
}

MultipleOption.defaultProps = {
  title: `This is the option's title`,
  subtitle: ``,
  state: `default`,
  defaultImage: null,
  selectedImage: null,
  id: 3,
  disabled: false,
  onClick: () => {},
  extraMargin: false,
}

const MultipleOptionWrapper = props => (
  <DeviceInfo>
    {deviceInfo => <MultipleOption deviceInfo={deviceInfo} {...props} />}
  </DeviceInfo>
)

export default MultipleOptionWrapper
