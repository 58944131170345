import React, { useEffect, useState, useRef } from "react"
import PropTypes from "prop-types"

import classNames from "classnames/bind"

import SingleSelectContainer from "../../organisms/SingleSelectContainer/SingleSelectContainer"
import MultipleSelectContainer from "../../organisms/MultipleSelectContainer/MultipleSelectContainer"
import MultipleSelectSliderContainer from "../../organisms/MultipleSelectSliderContainer/MultipleSelectSliderContainer"
import DeviceInfo from "../../utils/device-info"

import * as styles from "./question.module.scss"

const GET_QUESTION_OPTIONS_BY_TYPE = {
  single: SingleSelectContainer,
  single_thisthat: SingleSelectContainer,
  single_scale: SingleSelectContainer,
  multiple: MultipleSelectContainer,
  multiple_preference: MultipleSelectSliderContainer,
  multiple_followup: MultipleSelectContainer,
}

const Question = ({
  deviceInfo,
  id,
  text,
  subCopy,
  options,
  image,
  selectedImage,
  selectedAnswer,
  onSelect,
  type,
  fadeOut,
  min,
  max,
  followUpData,
  selectedFavorite,
  ...props
}) => {
  const [offset, setOffset] = useState(0)
  const [offsetLeft, setOffsetLeft] = useState(0)
  const optionsRef = useRef(null)
  const questionRef = useRef(null)
  const QuestionOptionsContainer = GET_QUESTION_OPTIONS_BY_TYPE[type]
  const [isFollowUp, setIsFollowUp] = useState(false)
  const [isSingleSelect, setSingleSelect] = useState(false)
  const [isScale, setIsScale] = useState(false)
  const [isPreference, setIsPreference] = useState(false)
  const cx = classNames.bind(styles)

  useEffect(() => {
    window.addEventListener("resize", updateOptionsOffset)
    return () => window.removeEventListener("resize", updateOptionsOffset)
  }, [])

  useEffect(() => {
    setIsFollowUp(type === "multiple_followup")
    setSingleSelect(type === "single")
    setIsScale(type === "single_scale")
    setIsPreference(type === "multiple_preference")
  }, [type, options])

  useEffect(() => {
    updateOptionsOffset()
  }, [isPreference])

  const updateOptionsOffset = () => {
    if (optionsRef.current && questionRef.current) {
      const parent = questionRef.current.parentNode.parentNode
      setOffset(parent.offsetLeft)
      if (deviceInfo.viewport === "small") {
        setOffsetLeft(24)
      } else {
        const gap = deviceInfo.viewport === "medium" ? 24 : 28
        const questionWidth = questionRef.current.clientWidth
        const columnWidth = (questionWidth - 11 * gap) / 12
        setOffsetLeft((columnWidth - 1) / 2 + gap)
      }
    }
  }

  const handlerOnSelect = answerId => {
    let answersToReport
    const allOptions = options.reduce(
      (acc, item) => acc.concat(item.options),
      []
    )
    const { favorite } = answerId
    const answer = answerId.ids || answerId
    const ids = typeof answer !== "object" ? answer.split(",") : null

    if (ids && ids.length > 1) {
      const arrayOfOptions = allOptions.filter(opt => ids.includes(opt.id))
      if (favorite) {
        answersToReport = arrayOfOptions.find(opt => opt.id === favorite).title
      } else {
        const arrayOfTitles = arrayOfOptions.map(opt => opt.title)
        answersToReport = arrayOfTitles.join(" | ")
      }
    } else if (ids) {
      answersToReport = allOptions.find(opt => opt.id === answer).title
    }

    onSelect(answerId, answersToReport)
  }
  const isMultiSingle =
    isSingleSelect &&
    options[0] &&
    options[0].options.length > 2 &&
    options[0].options.some(option => option.image)

  const isMultiSingleNoPic =
    isSingleSelect &&
    options[0] &&
    options[0].options.length > 2 &&
    options[0].options.some(option => option.image === null)

  return (
    <div
      className={cx(
        "container",
        fadeOut ? "onFadeOut" : "onFadeIn",
        isMultiSingle && "noFlex"
      )}
      ref={questionRef}
    >
      <div className={cx("grid")}>
        <div className={cx("textCol")}>
          <p>{followUpData ? followUpData.title : text}</p>
          <p className={cx("auxText")}>
            {followUpData ? followUpData.assist : subCopy}
          </p>
        </div>
        {deviceInfo.viewport !== "small" && (
          <div className={cx("dividerCol")}>
            <span />
          </div>
        )}
        <div
          className={cx("optionsCol")}
          ref={optionsRef}
          style={{
            width: isPreference
              ? deviceInfo.viewport === "small"
                ? "100vw"
                : `calc(100% + ${offset + offsetLeft}px)`
              : "100%",
            marginLeft: isPreference ? `-${offsetLeft}px` : "0",
          }}
        >
          {options && (
            <QuestionOptionsContainer
              {...props}
              offset={{
                widthOffset: offset + offsetLeft,
                offsetLeft: offsetLeft,
              }}
              selectedOption={selectedAnswer}
              onSelect={id => handlerOnSelect(id)}
              options={options}
              image={image}
              selectedImage={selectedImage ? selectedImage : image}
              isFollowUp={isFollowUp}
              followUpData={followUpData}
              isScale={isScale}
              isMultiSingle={isMultiSingle}
              isMultiSingleNoPic={isMultiSingleNoPic}
              min={min}
              max={max}
              selectedFavorite={selectedFavorite}
            />
          )}
        </div>
      </div>
    </div>
  )
}

Question.propTypes = {
  id: PropTypes.string,
  text: PropTypes.string,
  subCopy: PropTypes.string,
  options: PropTypes.array,
  image: PropTypes.object,
  selectedImage: PropTypes.object,
  selectedAnswer: PropTypes.string,
  onSelect: PropTypes.func,
  type: PropTypes.string,
  fadeOut: PropTypes.bool,
  min: PropTypes.number,
  max: PropTypes.number,
  followUpData: PropTypes.object,
  selectedFavorite: PropTypes.string,
}

Question.defaultProps = {
  text: "",
  onSelect: () => {},
  type: "single",
  fadeOut: false,
  followUpData: null,
  selectedFavorite: null,
}

const QuestionWrapper = props => (
  <DeviceInfo>
    {deviceInfo => <Question deviceInfo={deviceInfo} {...props} />}
  </DeviceInfo>
)

export default QuestionWrapper
