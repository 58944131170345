// extracted by mini-css-extract-plugin
export var container = "parallaxLockupWithCarousel-module--container--1A2Az";
export var pieceCarousel = "parallaxLockupWithCarousel-module--pieceCarousel--1OfTT";
export var grid = "parallaxLockupWithCarousel-module--grid--3U9tY";
export var titleTag = "parallaxLockupWithCarousel-module--titleTag--1U-lg";
export var copyTag = "parallaxLockupWithCarousel-module--copyTag--tq069";
export var eyebrowTag = "parallaxLockupWithCarousel-module--eyebrowTag--jyIe0";
export var textBlock = "parallaxLockupWithCarousel-module--textBlock--1i-Y2";
export var carouselBlock = "parallaxLockupWithCarousel-module--carouselBlock--15KtA";
export var square = "parallaxLockupWithCarousel-module--square--1CQ5t";
export var carouselOnLeft = "parallaxLockupWithCarousel-module--carouselOnLeft--3hfDS";
export var inverseMobile = "parallaxLockupWithCarousel-module--inverseMobile--23yoQ";
export var carouselContainer = "parallaxLockupWithCarousel-module--carouselContainer--1ywJo";
export var content = "parallaxLockupWithCarousel-module--content--6zKgr";
export var carouselWrapper = "parallaxLockupWithCarousel-module--carouselWrapper--1Y7g3";
export var circular = "parallaxLockupWithCarousel-module--circular--972ln";
export var vertical = "parallaxLockupWithCarousel-module--vertical--NluIH";
export var horizontal = "parallaxLockupWithCarousel-module--horizontal--2SH0F";
export var fadein = "parallaxLockupWithCarousel-module--fadein--2X5JC";
export var swap = "parallaxLockupWithCarousel-module--swap--1xmal";
export var slide = "parallaxLockupWithCarousel-module--slide--22c8Z";
export var carouselBackground = "parallaxLockupWithCarousel-module--carouselBackground--uNTVA";
export var card = "parallaxLockupWithCarousel-module--card--1qa6h";
export var shadow = "parallaxLockupWithCarousel-module--shadow--1N_be";
export var ctaSlide = "parallaxLockupWithCarousel-module--ctaSlide--18ooT";
export var dotsWrapper = "parallaxLockupWithCarousel-module--dotsWrapper--wDxfk";
export var dot = "parallaxLockupWithCarousel-module--dot--2Ax_p";
export var active = "parallaxLockupWithCarousel-module--active--3ljT0";
export var clickable = "parallaxLockupWithCarousel-module--clickable--YRs-S";