import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames/bind"

import Button from "../../atoms/Button/Button"
import ImageSwitcher from "../../atoms/ImageSwitcher/ImageSwitcher"

import { EVENTS } from "../../utils/reports"

import * as styles from "./lpImageHero.module.scss"

const LPImageHero = ({
  eyebrow,
  title,
  copy,
  cta,
  image,
  mobileImage,
  isBackgroundImage,
  fontColor,
  halfWidthColumn,
  isTab,
}) => {
  const cx = classNames.bind(styles)
  const styl =
    isBackgroundImage && image.url
      ? {
          backgroundImage: `linear-gradient(90deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 100%), url(${image.url})`,
        }
      : null

  const isLimestone = fontColor === "limestone"

  const root = cx({
    root: true,
    hasBackground: isBackgroundImage,
    halfWidthColumn: halfWidthColumn,
    isTab: isTab,
  })

  return (
    <div className={root} style={styl}>
      <div className={cx("container", "grid")}>
        <div className={cx(!halfWidthColumn ? "colSeven" : "colSix")}>
          <div
            className={cx("content", isLimestone ? "limestone" : "")}
          >
            {eyebrow && <h4>{eyebrow}</h4>}
            <h1>{title}</h1>
            {copy && <p>{copy}</p>}
            {cta && (
              <Button
                {...cta}
                trackingObjects={[
                  {
                    event: EVENTS.QUIZ_START_BUTTOM,
                    header: title,
                  },
                ]}
              />
            )}
          </div>
        </div>
        <div className={cx(!halfWidthColumn ? "colFive" : "colSix")}>
          {image.heroDesktop && mobileImage ? (
            <ImageSwitcher
              img={image.heroDesktop}
              smallImg={mobileImage}
              alt={title}
              breakpoint="md"
            />
          ) : image ? (
            <img src={image.url} alt={image.alt} />
          ) : null}
        </div>
      </div>
    </div>
  )
}

LPImageHero.propTypes = {
  eyebrow: PropTypes.string,
  title: PropTypes.string,
  copy: PropTypes.string,
  cta: PropTypes.object,
  image: PropTypes.object,
  halfWidthColumn: PropTypes.bool,
  isTab: PropTypes.bool,
}

LPImageHero.defaultProps = {
  eyebrow: ``,
  title: ``,
  copy: ``,
  cta: null,
  image: {},
  halfWidthColumn: false,
  isTab: false,
}

export default LPImageHero
