const sessionVariable = name => {
  return {
    get() {
      const value = localStorage.getItem(name)
      return value ? JSON.parse(value) : null
    },
    set(value) {
      localStorage.setItem(name, JSON.stringify(value))
    },
    clear() {
      localStorage.removeItem(name)
    },
  }
}

export const lastQuizOptions = () => sessionVariable("lastQuizOptions")
export const lastRegistrationSource = () => sessionVariable("lastRegistrationSource")
