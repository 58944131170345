import React from "react"
import PropTypes from "prop-types"

import classNames from "classnames/bind"

import * as styles from "./questionOption.module.scss"

const QuestionOption = ({
  title,
  subtitle,
  id,
  image,
  onClick,
  selectedImage,
  isSelected,
  className,
}) => {
  const cx = classNames.bind(styles)

  const handlerOnClick = () => {
    onClick(id)
  }

  return (
    <div className={cx("container", className)} onClick={handlerOnClick}>
      {image && (
        <div className={cx("image")}>
          <img src={image.url} alt={image.alt} />
          {selectedImage && (
            <img
              src={selectedImage.url}
              alt={selectedImage.alt}
              className={cx("selected", isSelected && "visible")}
            />
          )}
        </div>
      )}
      <h2 className={cx("title")}> {title} </h2>
      {subtitle && <p className={cx("subtitle")}> {subtitle} </p>}
    </div>
  )
}

QuestionOption.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  value: PropTypes.string,
  image: PropTypes.object,
  selectedImage: PropTypes.object,
  onClick: PropTypes.func,
  isSelected: PropTypes.bool,
  className: PropTypes.string,
}

QuestionOption.defaultProps = {
  title: "",
  className: "",
  onClick: () => {},
}

export default QuestionOption
