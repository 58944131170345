import React, { useEffect, useRef, useState } from "react"
import PropTypes from "prop-types"
import classNames from "classnames/bind"
import DeviceInfo from "../../utils/device-info"
import * as styles from "./contentCardsContainer.module.scss"
import { Draggable } from "gsap/Draggable"
// Molecules
import ContentCard from "../../molecules/ContentCard/ContentCard"
import ContentCopyHeader from "../../molecules/ContentCopyHeader/ContentCopyHeader"
import { EVENTS, reports } from "../../utils/reports"
import { withPrefix } from "../../utils/with-prefix"

const ContentCardsContainer = ({
  deviceInfo,
  title,
  copy,
  cards,
  featured,
  card,
}) => {
  const hasMobileCarouselMode = () => {
    let enableCarouselMode = false
    if (cards && cards.length > 1)
      cards.map(card => {
        const type = card.entity
          ? card.entity.__typename === "BeamBourbonuPieceArticle"
            ? "article"
            : card.entity.__typename === "BeamBourbonuPieceRecipe"
              ? "recipe"
              : card.entity.__typename === "BeamBourbonuPieceVideo"
                ? "video"
                : ""
          : card.type
        if (type !== "article" && cards.length > 1) {
          enableCarouselMode = true
        }
      })
    return enableCarouselMode
  }

  const [currentCard, setCurrentCard] = useState(0)
  const [progress, setProgress] = useState(0)
  const [carouselWidth, setCarouselWidth] = useState("100%")
  const [enableCarousel, setEnableCarousel] = useState(
    cards && cards.length > 3 && hasMobileCarouselMode()
  )
  const [mobileCarousel, setMobileCarousel] = useState(hasMobileCarouselMode())
  const [enableDrag, setEnableDrag] = useState(false)
  const [dragStart, setDragStart] = useState(0)
  const [dragEnd, setDragEnd] = useState(0)

  const wrapperRef = useRef(null)

  const cx = classNames.bind(styles)

  useEffect(() => {
    let width = "100%"
    if (cards) {
      if (
        enableCarousel ||
        (mobileCarousel && ["large", "big"].indexOf(deviceInfo.viewport) === -1)
      ) {
        // update
        if (wrapperRef.current) {
          const wrapperWidth = wrapperRef.current.parentNode.clientWidth
          const childWidth =
            (wrapperWidth -
              (["large", "big"].indexOf(deviceInfo.viewport) > -1 ? 56 : 0)) /
            (["large", "big"].indexOf(deviceInfo.viewport) > -1 ? 3 : 1)
          width = `${childWidth * cards.length +
            (cards.length - 1) *
            (deviceInfo.viewport === "small" ||
              deviceInfo.viewport === "medium"
              ? 16
              : 28)
            }px`
        }
      }

      const wrapperWidth = wrapperRef.current.parentNode.clientWidth
      if (["large", "big"].indexOf(deviceInfo.viewport) > -1) {
        // desktop
        if (enableCarousel) {
          const childWidth = (wrapperWidth - 56) / 3
          width = `${childWidth * cards.length + (cards.length - 1) * 28}px`
        }
      } else {
        // mobile
        if (mobileCarousel) {
          width = `${wrapperWidth * cards.length + (cards.length - 1) * 16}px`
        }
      }
    }

    setCarouselWidth(width)
  }, [deviceInfo.viewport])

  useEffect(() => {
    const wrapper = wrapperRef.current
    if (
      wrapper &&
      !enableDrag &&
      carouselWidth !== "100%" &&
      (enableCarousel || mobileCarousel)
    ) {
      const wrapperWidth = wrapper.parentNode.clientWidth
      const scrollWidth = wrapper.scrollWidth
      const scrollMax = scrollWidth - wrapperWidth
      Draggable.create(wrapper, {
        type: "x",
        trigger: wrapper.children,
        bounds: {
          minX: 0,
          maxX: -(["large", "big"].indexOf(deviceInfo.viewport) === -1
            ? parseInt(wrapper.style.width)
            : scrollMax),
        },
        onDragStart: args => {
          setDragStart(args.clientX)
        },
        onDragEnd: args => {
          setDragEnd(args.clientX)
        },
      })
      setEnableDrag(true)
    }
  }, [wrapperRef.current, carouselWidth])

  useEffect(() => {
    if (cards) {
      let card = currentCard
      if (dragStart > dragEnd) {
        // move foward
        if (card + 1 < cards.length) {
          if (card <= 3 && ["large", "big"].indexOf(deviceInfo.viewport) > -1) {
            card = 3
          }
          updateDragAndScroll(card + 1)
        }
      } else {
        // move back
        if (card - 1 >= 0) {
          if (
            card - 1 <= 3 &&
            ["large", "big"].indexOf(deviceInfo.viewport) > -1
          ) {
            card = 1
          }
          updateDragAndScroll(card - 1)
        }
      }
      if (dragEnd > 0 && dragStart > 0) {
        const direction = dragStart < dragEnd ? "left" : "right"
        reports({
          event: EVENTS.CAROUSEL_INTERACTION,
          title,
          direction,
        })
      }
    }
  }, [dragEnd])

  const updateRange = value => {
    // update wrapper scroll
    if (wrapperRef.current && cards) {
      const wrapper = wrapperRef.current
      const wrapperWidth = wrapper.parentNode.clientWidth
      const scrollWidth = wrapper.clientWidth
      const scrollMax = scrollWidth - wrapperWidth
      const steps =
        ["large", "big"].indexOf(deviceInfo.viewport) === -1
          ? cards.length - 1
          : 100
      let scrollTo = 0
      if (["large", "big"].indexOf(deviceInfo.viewport) === -1) {
        scrollTo = getOffset(value) * -1
      } else {
        scrollTo = scrollMax * ((value / steps) * -1)
        if (scrollMax < scrollTo) {
          scrollTo = scrollMax
        }
      }
      // move "scroll" on wrapper
      wrapper.parentNode.scrollLeft = 0
      wrapper.style.transform = `translate3d(${scrollTo}px, 0px, 0px)`
    }
  }

  const getOffset = card => {
    const offset = 16 - (deviceInfo.viewport === "small" ? 48 : 120)
    return (window.innerWidth + offset) * card
  }

  const handleChangeRange = value => {
    const direction = value < progress ? "left" : "right"
    reports({
      event: EVENTS.CAROUSEL_INTERACTION,
      title,
      direction,
    })
    setProgress(value)
    let newCurrent = currentCard
    if (["large", "big"].indexOf(deviceInfo.viewport) === -1) {
      newCurrent = value
    } else if (cards) {
      const val = (value * cards.length) / 100
      // set current by aproximation to keep smooth draggin on range
      if (Math.round(val) === Math.ceil(val)) {
        if (Math.round(val) !== currentCard) {
          newCurrent = Math.round(val)
        }
      } else {
        if (Math.round(val) > 0 && currentCard !== 0) {
          newCurrent = 0
        }
      }
    }

    updateRange(value)
    setCurrentCard(newCurrent)
  }

  const updateDragAndScroll = card => {
    wrapperRef.current.parentNode.scrollLeft = 0
    if (cards && mobileCarousel) {
      const percent = (100 / (cards.length - 1)) * card
      const range =
        ["large", "big"].indexOf(deviceInfo.viewport) === -1 ? card : percent
      updateRange(range)
      setProgress(range)
      setCurrentCard(card)
    }
  }

  const showCarousel = () =>
    (cards && enableCarousel) ||
    (mobileCarousel && ["large", "big"].indexOf(deviceInfo.viewport) === -1)

  const getCards = () => {
    let items = cards || featured || card || {}
    if (items.length) {
      return items.map(card => {
        if (cards[0].entity) {
          return parseCard(card, items.length)
        } else {
          // for stories to work properly
          return card
        }
      })
    } else {
      let cardObj = []
      cardObj[0] = parseCard(items, 1)
      return cardObj
    }
  }

  const parseInfo = info => {
    return [
      {
        text: info.fieldBeamBourbonuRfeatDurat?.entity?.name,
        icon:
          info.fieldBeamBourbonuRfeatDurat?.entity?.fieldBeamBourbonuIcon?.entity?.fieldBeamBourbonuImage.url,
      },
      {
        text: info.fieldBeamBourbonuRfeatKind?.entity?.name,
        icon:
          info.fieldBeamBourbonuRfeatKind?.entity?.fieldBeamBourbonuIcon?.entity?.fieldBeamBourbonuImage.url,
      },
      {
        text: info.fieldBeamBourbonuRfeatLevel?.entity?.name,
        icon:
          info.fieldBeamBourbonuRfeatLevel?.entity?.fieldBeamBourbonuIcon?.entity?.fieldBeamBourbonuImage.url,
      },
    ]
  }

  const parseCard = (card, length) => {
    const cardType =
      card.entity.__typename === "BeamBourbonuPieceArticle"
        ? "article"
        : card.entity.__typename === "BeamBourbonuPieceRecipe"
          ? "recipe"
          : card.entity.__typename === "BeamBourbonuPieceVideo"
            ? "video"
            : ""
    return {
      eyebrow: card.entity?.fieldBeamBourbonuCategory?.entity?.name || "",
      header: card.entity.title,
      copy: cardType !== "recipe" ? card.entity.description.value : "",
      hasShare: cardType !== "recipe",
      hasSave: cardType !== "recipe",
      url: card.entity.entityUrl ? withPrefix(card.entity.entityUrl.path) : "/",
      cta:
        cardType !== "video"
          ? {
            text:
              cardType === "recipe"
                ? "View Recipe"
                : cardType === "article"
                  ? "Read Now"
                  : "View",
            url: withPrefix(card.entity.entityUrl.path),
            type: "tertiary",
            variant: "positive",
            icon: true,
            iconAlignment: "right",
          }
          : null,
      image: card.entity.fieldBeamBourbonuImages
        ? card.entity.fieldBeamBourbonuImages[0].entity?.image
        : card.entity.fieldBeamBourbonuImage
          ? card.entity.fieldBeamBourbonuImage.entity.image
            ? card.entity.fieldBeamBourbonuImage.entity.image
            : card.entity.fieldBeamBourbonuImage.entity.fieldBeamBourbonuImage
          : card.entity.fieldBeamBourbonuVideo
            ? card.entity.fieldBeamBourbonuVideo.entity.thumbnail?.url
            : null,
      mobileImage: card.entity.fieldBeamBourbonuImages
        ? card.entity.fieldBeamBourbonuImages[0].entity?.image
        : card.entity.fieldBeamBourbonuImage
          ? card.entity.fieldBeamBourbonuImage.entity.image
            ? card.entity.fieldBeamBourbonuImage.entity.image
            : card.entity.fieldBeamBourbonuImage.entity.fieldBeamBourbonuImage
          : card.entity.fieldBeamBourbonuVideo
            ? card.entity.fieldBeamBourbonuVideo.entity.thumbnail?.url
            : null,
      type: cardType,
      info: card.entity?.fieldBeamBourbonuFeatures
        ? parseInfo(card.entity.fieldBeamBourbonuFeatures.entity)
        : null,
      variant: length == 1 ? "1UP" : length == 2 ? "2UP" : "3UP",
      pieceId: card.entity.entityId,
    }
  }

  const parsedCards = getCards()

  return (
    <div
      className={cx("wrapper", {
        ["carousel"]: showCarousel(),
        ["noHeader"]: !(title || copy),
      })}
    >
      {(title || copy) && <ContentCopyHeader title={title} copy={copy} />}
      <div className={cx("smallContainer")}>
        <div className={cx("content")}>
          <div className={cx("cardsContainer")}>
            <div
              className={cx("cardsWrapper", {
                ["carousel"]: showCarousel(),
              })}
              ref={wrapperRef}
              style={{
                width: carouselWidth,
              }}
            >
              {parsedCards &&
                parsedCards.map((card, index) => {
                  return (
                    <ContentCard
                      {...card}
                      key={`card-${index}`}
                      carousel={showCarousel()}
                      onFocus={() => updateDragAndScroll(index)}
                    />
                  )
                })}
            </div>
          </div>
          {showCarousel() && (
            <div className={cx("scrollBarWrapper")}>
              <input
                className={cx("scrollBar", {
                  [`max-${cards.length}`]: cards.length > 0,
                })}
                type={"range"}
                value={progress}
                min={0}
                max={
                  ["large", "big"].indexOf(deviceInfo.viewport) === -1
                    ? cards.length - 1
                    : 100
                }
                onChange={e => handleChangeRange(e.target.value)}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

ContentCardsContainer.propTypes = {
  title: PropTypes.string,
  copy: PropTypes.string,
  cards: PropTypes.array,
  card: PropTypes.object,
  featured: PropTypes.object,
}

ContentCardsContainer.defaultProps = {
  title: ``,
  copy: ``,
  cards: null,
  card: null,
  featured: null,
}

const ContentCardsContainerWrapper = props => (
  <DeviceInfo>
    {deviceInfo => <ContentCardsContainer deviceInfo={deviceInfo} {...props} />}
  </DeviceInfo>
)

export default ContentCardsContainerWrapper
