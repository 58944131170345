import React, { useContext, useEffect, useState } from "react"
import PropTypes from "prop-types"
import classNames from "classnames/bind"
import { useMutation, useQuery } from "@apollo/client"

import { ModalContext } from "../../../context/ModalContext"

import { EVENTS, reports } from "../../utils/reports"
import {
  UpdateFavoritePiece,
  GetUserInformation,
  UpdateFavoriteBottle,
} from "../../utils/fragments"

import ShareLink from "../../atoms/ShareLink/ShareLink"

import * as styles from "./saveButton.module.scss"
const cx = classNames.bind(styles)

const SaveButton = ({ title, savedTitle, pieceId, text, bottle, contentData }) => {
  const [favorite, setFavorite] = useState(false)
  const { openModal } = useContext(ModalContext)
  const { data, loading } = useQuery(GetUserInformation)
  const [logged, setLogged] = useState(!!data?.currentUser)

  const [updateFavoritePiece, {}] = useMutation(UpdateFavoritePiece)
  const [updateWishlist, {}] = useMutation(UpdateFavoriteBottle)

  const updateFunction = bottle ? updateWishlist : updateFavoritePiece

  useEffect(() => {
    if (data && data.currentUser) {
      setLogged(true)
      const isFav = bottle
        ? data.currentUser?.fieldUserprofileWishlist?.some(
            piece => piece.entity.id == pieceId
          )
        : data.currentUser?.fieldUserprofileFavoritPieces?.some(
            piece => piece.entity.entityId == pieceId
          )
      setFavorite(isFav)
    }
  }, [data, pieceId])

  const onClick = e => {
    e.persist()
    if (loading) return
    updateFunction({
      variables: { piece: { id: pieceId } },
    })
    if (logged) {
      setFavorite(fav => !fav)
    } else {
      openModal({
        title: "Save your results",
        copy: "Track how your palate evolves and share with friends.",
        variant: "save",
        top: window.scrollY,
      })
    }
    reports({
      event: favorite ? EVENTS.REMOVE_FROM_FAVORITE : EVENTS.QUIZ_SAVE,
      title: contentData?.title,
      type: contentData?.type,
    })
  }

  return text ? (
    <div className={cx("button")} onClick={onClick}>
      <h4>{text}</h4>
      <ShareLink
        type="favorite"
        text={favorite ? savedTitle : title}
        icon={true}
        iconAlignment="left"
        selected={favorite}
        url={null}
      />
    </div>
  ) : (
    <ShareLink
      onClick={onClick}
      type="favorite"
      text={favorite ? savedTitle : title}
      icon={true}
      iconAlignment="left"
      selected={favorite}
      url={null}
    />
  )
}

SaveButton.propTypes = {
  title: PropTypes.string,
  savedTitle: PropTypes.string,
  pieceId: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
}

SaveButton.defaultProps = {
  pieceId: null,
}

export default SaveButton
