import * as React from "react"
import { useQuery } from "@apollo/react-hooks"
import gql from "graphql-tag"
import ErrorPage from "../utils/errorPage/errorPage"
import LoadingPage from "../utils/loadingPage/loadingPage"
import ArticlePiece from "../templates/article"
import PagePiece from "../templates/page"
import RecipePiece from "../templates/recipe"
import BucketPage from "../templates/bucket"
import VideoPiece from "../templates/video"
import NotFound from "../../pages/404"
import { withPrefix } from "../utils/with-prefix"
import {
  BucketComponents,
  RecipeData,
  PageComponents,
  VideoData,
  ArticleData,
  UserFavorites,
} from "../utils/fragments"

const PieceRouteLoader = ({ path }) => {
  const queryResult = useQuery(getQueryRequest(path), {
    variables: { path },
  })

  if (queryResult.loading) {
    return <LoadingPage />
  }

  if (queryResult.error) {
    return <ErrorPage />
  }

  const entity = queryResult.data.pieceByPath
  if (!entity) {
    return <NotFound />
  }

  switch (entity.entityBundle) {
    case "article":
      return <ArticlePiece data={entity} />

    case "beam_flavorfinder_bucket":
      return <BucketPage result={entity} />

    case "page":
      return (
        <PagePiece
          piece={entity}
          data={entity.fieldComponents}
          userFavorites={
            queryResult.data.currentUser?.fieldUserprofileFavoritPieces
          }
        />
      )

    case "recipe":
      return <RecipePiece piece={entity} />

    case "video":
      return <VideoPiece piece={entity} />

    default:
      throw new Error("Unknown entity bundle")
  }
}

const searchPaths = ["/search/videos", "/search/articles", "/search/recipes"]

const PieceRoute = ({ uri }) => {
  if (typeof window === "undefined") {
    return <LoadingPage />
  }

  const pathPrefix = withPrefix("/").replace(/\/$/, "")
  const path = (uri.replace(pathPrefix, "") || "/").replace(/\/$/, "")

  let requestPath = path
  searchPaths.map(searchPath => {
    if (path === searchPath) {
      requestPath = "/search"
    }
  })

  return <PieceRouteLoader path={requestPath} />
}

export default PieceRoute

const getQueryRequest = path => {
  return gql`
  fragment imageFields on MediaBeamBourbonuImage {
    fieldBeamBourbonuImage {
      url
    }
  }
  ${BucketComponents}
  ${PageComponents}
  ${RecipeData}
  ${VideoData}
  ${ArticleData}
  ${(path === "/bourbon-u") | (path === "/search") ? UserFavorites : ""}
  query($path: String!) {
    pieceByPath(path: $path) {
      entityBundle
      ...BucketComponents
      ... on TaxonomyTermBeamFlavorfinderBucket {
        otherBuckets {
          entityId
          name
          fieldBeamBourbonuRpInfo {
            entity {
              ... on ParagraphBeamBourbonuRpInfo {
                fieldBeamBourbonuTitle
                fieldBeamBourbonuCopy {
                  value
                }
                fieldBeamBourbonuImage {
                  entity {
                    ... on MediaBeamBourbonuImage {
                      fieldBeamBourbonuImage {
                        url
                      }
                    }
                  }
                }
                fieldBeamBourbonuActiveImage {
                  entity {
                    ... on MediaBeamBourbonuImage {
                      fieldBeamBourbonuImage {
                        url
                      }
                    }
                  }
                }
              }
            }
          }
        }
        bottles {
          entityId
          entityBundle
          title
          description {
            value
          }
          ... on BeamBourbonuPieceBottle {
            fieldBeamBourbonuShareImage {
              entity {
                ...imageFields
                __typename
              }
              __typename
            }
            fieldBeamFlavorfinderTerms {
              entity {
                name
              }
            }
            fieldBeamBourbonuFeatNotes {
              entity {
                name
              }
            }
            fieldBeamBourbonuEpid
            fieldBeamBourbonuImage {
              entity {
                ... on MediaBeamBourbonuImage {
                  fieldBeamBourbonuImage {
                    url
                  }
                }
              }
            }
            fieldBeamBourbonuFeatures {
              entity {
                ... on ParagraphBeamBourbonuBottleFeatures {
                  fieldBourbonuBfAge
                  fieldBourbonuBfPrice
                  fieldBourbonuBfProof
                }
              }
            }
            fieldBeamBourbonuRelRecipes {
              entity {
                title
                description {
                  value
                }
                        entityUrl {
          path
        }
                ... on BeamBourbonuPieceRecipe {
                  fieldBeamBourbonuIngredients
                  fieldBeamBourbonuDefProd
                  fieldBeamBourbonuInstructions {
                    value
                  }
                  fieldBeamBourbonuImage {
                    entity {
                      ...imageFields
                    }
                  }
                  fieldFlavorFinderCategory {
                    entity {
                      name
                    }
                  }
                  fieldBeamBourbonuFeatures {
            entity {
              ... on ParagraphBeamBourbonuRecipeFeatures {
                fieldBeamBourbonuRfeatKind {
                  entity {
                    name
                    ... on TaxonomyTermRecipeFeatures {
                      fieldBeamBourbonuIcon {
                        entity {
                          ... on MediaBeamBourbonuImage {
                            fieldBeamBourbonuImage {
                              url
                              alt
                            }
                          }
                        }
                      }
                    }
                  }
                }
                fieldBeamBourbonuRfeatDurat {
                  entity {
                    name
                    ... on TaxonomyTermRecipeFeatures {
                      fieldBeamBourbonuIcon {
                        entity {
                          ... on MediaBeamBourbonuImage {
                            fieldBeamBourbonuImage {
                              url
                              alt
                            }
                          }
                        }
                      }
                    }
                  }
                }
                fieldBeamBourbonuRfeatLevel {
                  entity {
                    name
                    ... on TaxonomyTermRecipeFeatures {
                      fieldBeamBourbonuIcon {
                        entity {
                          ... on MediaBeamBourbonuImage {
                            fieldBeamBourbonuImage {
                              url
                              alt
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
                }
              }
            }
          }
        }
      }
      ...PageComponents
      ...RecipeData
      ...VideoData
      ...ArticleData
    }
    ${(path === "/bourbon-u") | (path === "/search") ? "...UserFavorites" : ""}
  }
`
}
