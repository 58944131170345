import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames/bind"
import Button from "../../atoms/Button/Button"

import { EVENTS } from "../../utils/reports"

import * as styles from "./copyCenter.module.scss"

const CopyCenter = ({ title, copy, cta, leftImg, rightImg }) => {
  const cx = classNames.bind(styles)
  const isTop = title.toLowerCase().indexOf("Bourbon U".toLowerCase()) !== -1
  const hasBackground = leftImg || rightImg

  return (
    <div className={cx("root", hasBackground ? "background" : "" )}>
      {(leftImg || rightImg) && (
        <div className={cx("backgroundWrapper")}>
          <div className={cx("grid")}>
            <div className={cx("colSix")}>
              {leftImg && <img alt={title} src={leftImg.url} />}
            </div>
            <div className={cx("colSix")}>
              {rightImg && <img alt={title} src={rightImg.url} />}
            </div>
          </div>


        </div>
      )}
      <div
        className={cx("contentWrapper", {
          ["top"]: isTop,
        })}
      >
        <div className={cx("container")}>
          <div className={cx("grid")}>
            <div className={cx("colTwelve")}>
              <h2 dangerouslySetInnerHTML={{ __html: title }} />
              {copy && <p dangerouslySetInnerHTML={{ __html: copy }} />}
              {cta && (
                <Button
                  {...cta}
                  variant={!cta?.variant && "positive"}
                  trackingObjects={[
                    {
                      event: EVENTS.QUIZ_START_BUTTOM,
                      header: title,
                    },
                  ]}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

CopyCenter.propTypes = {
  title: PropTypes.string,
  copy: PropTypes.string,
  cta: PropTypes.object,
  leftImg: PropTypes.object,
  rightImg: PropTypes.object,
}

CopyCenter.defaultProps = {
  title: ``,
  copy: ``,
  cta: null,
  leftImg: ``,
  rightImg: ``,
}

export default CopyCenter
