import React, { useEffect, useState } from "react"
import classNames from "classnames/bind"
import Input from "../../atoms/Input/Input"
import Password from "../../atoms/Password/Password"
import Checkbox from "../../atoms/Checkbox/Checkbox"
import Button from "../../atoms/Button/Button"
import * as styles from "./signUpPage.module.scss"
import Dropdown from "../../atoms/Dropdown/Dropdown"
import { navigate } from "@reach/router"
import { withPrefix } from "../with-prefix"
import { EVENTS, reports } from "../reports"
import { countries, states } from "../locations"
import useCognitoUserData from "../cognito-user-data"
import { useMutation } from "@apollo/client"
import { RegisterCurrentUser } from "../fragments"
import { validateOnlyLetters } from "../helpers"
import { useQuizResult } from "../../../hooks/useQuizResults"
import { lastQuizOptions, lastRegistrationSource } from "../session-variable"

const SignUpPage = ({ location }) => {
  const requiredFieldNames = [
    "firstname",
    "lastname",
    "email",
    "birthday",
    "password",
    "confirmPassword",
    "country",
    "state",
    "city",
    "termsAndConditions",
  ]
  const params = new URLSearchParams(location?.search)
  const ffybLocation = params.get("location")
  const bookingId = params.get("booking")

  const [formValues, setFormValues] = useState({
    subscription: false,
    termsAndConditions: false,
  })
  const [errorMsg, setErrorMsg] = useState("")
  const [sending, setSending] = useState(false)
  const [registerCurrentUser, { data, loading, error }] = useMutation(
    RegisterCurrentUser
  )
  const [formErrors, setFormErrors] = useState({})
  const { sendResult } = useQuizResult()
  const { signUp, signIn } = useCognitoUserData()
  const cx = classNames.bind(styles)

  const isEnabled = () => {
    let isEnabled = true
    requiredFieldNames.forEach(key => {
      if (!formValues[key]) {
        isEnabled = false
      }
    })
    for (const key in formErrors) {
      if (formErrors[key]) {
        isEnabled = false
      }
    }
    return isEnabled && !sending
  }
  const handleChange = (value, name) => {
    setFormValues({ ...formValues, [name]: value })
    if (
      name === "city" ||
      name === "state" ||
      name === "firstname" ||
      name === "lastname"
    ) {
      if (!validateOnlyLetters(value)) {
        setFormErrors({ ...formErrors, [name]: "Only letters are allowed" })
      } else {
        setFormErrors({ ...formErrors, [name]: false })
      }
    }
    if (name === "password" && formValues.confirmPassword) {
      if (value !== formValues.confirmPassword) {
        setFormErrors({
          ...formErrors,
          confirmPassword: "Passwords don't match.",
        })
      } else {
        setFormErrors({
          ...formErrors,
          confirmPassword: false,
          password: false,
        })
      }
    }
  }

  const handleError = (value, name) => {
    setFormErrors({ ...formErrors, [name]: value })
  }

  const handleSubmit = e => {
    e.preventDefault()
  }

  const doSubmit = () => {
    if (!isEnabled()) {
      return
    }

    setErrorMsg("")
    setSending(true)

    if (formValues.password !== formValues.confirmPassword) {
      return setErrorMsg("Passwords don't match.")
    }

    setSending(true)
    signUp(formValues.email, formValues.password)
      .then(() => signIn(formValues.email, formValues.password))
      .then(() => {
        const data = {
          firstname: formValues.firstname,
          lastname: formValues.lastname,
          email: formValues.email,
          birthdate: formValues.birthday,
          country: formValues.country,
          state: formValues.state,
          city: formValues.city,
          globalPolicyAccepted: formValues.subscription,
          privacyPolicyAccepted: formValues.termsAndConditions,
          termsAndConditionsAccepted: formValues.termsAndConditions,
          photoBoothAccepted: false,
          source: lastRegistrationSource().get(),
          bookingId: bookingId,
        }
        registerCurrentUser({ variables: { profile: data } })
      })
      .catch(err => {
        setErrorMsg(err.message)
        setSending(false)
      })
  }
  useEffect(async () => {
    if (error) {
      setSending(false)
      console.error("error: ", error)
      setErrorMsg(error.message)
      return
    }

    if (sending && data && formValues) {
      reports({
        event: EVENTS.REGISTER,
        method: "mail",
        login_status:"true",
        userFirstName: formValues.firstname,
        userLastName: formValues.lastname,
        userEmail: formValues.email,
        userBirthDate: formValues.birthday,
        userState: formValues.state,
        userCity: formValues.city,
        userCountry: formValues.country,
      })
      if (formValues.subscription) {
        reports({
          event: EVENTS.NEWSLETTER_SIGN_UP,
          login_status: "true",
          email: formValues.email,
          first_name: formValues.firstname,
          last_name: formValues.lastname,
          birthdate: formValues.birthday,
          state: formValues.state,
          city: formValues.city,
          country: formValues.country
        })
      }
      const quizOptions = lastQuizOptions().get()
      if (quizOptions) {
        await sendResult({ variables: { options: quizOptions } })
      }
      if (ffybLocation) {
        return navigate(
          `${withPrefix("/profile")}?location=${ffybLocation}`
        )
      }
      navigate(withPrefix("/profile"))
    }
  }, [sending, data, formValues, error])
  return (
    <div className={cx("wrapper")}>
      <div className={cx("container")}>
        <form onSubmit={e => handleSubmit(e)} className={cx("formContainer")}>
          <div className={cx("formHeader")}>
            <h1>Create account</h1>
          </div>
          <div className={cx("formFields")}>
            <Input
              type={"text"}
              name={"firstName"}
              label={"First Name"}
              placeholder={"First Name"}
              maxLength={0}
              isRequired={false}
              onChange={e => handleChange(e, "firstname")}
              onError={e => handleError(e, "firstname")}
              defaultError={formErrors.firstname}
            />
            <Input
              type={"text"}
              name={"lastname"}
              label={"Last Name"}
              placeholder={"Last Name"}
              maxLength={0}
              isRequired={false}
              onChange={e => handleChange(e, "lastname")}
              onError={e => handleError(e, "lastname")}
              defaultError={formErrors.lastname}
            />
            <Input
              type={"email"}
              name={"email"}
              label={"Email"}
              placeholder={"Email"}
              maxLength={320}
              helperText={"Max length"}
              isRequired={false}
              onChange={e => handleChange(e, "email")}
              onError={e => handleError(e, "email")}
            />
            <Input
              type={"date"}
              name={"birthday"}
              label={"Date of birth"}
              placeholder={"DD/MM/YYYY"}
              maxLength={0}
              isRequired={true}
              onChange={e => handleChange(e, "birthday")}
              onError={e => handleError(e, "birthday")}
            />
            <Password
              name={"password"}
              label={"Password"}
              placeholder={"Password"}
              maxLength={0}
              helperText={
                "Min. 8 characters. Mix of numbers, letters and upper and lower cases."
              }
              isRequired={false}
              onChange={e => handleChange(e, "password")}
              onError={e => handleError(e, "password")}
            />
            <Password
              name={"confirmPassword"}
              label={"Confirm Password"}
              placeholder={"Password"}
              maxLength={0}
              isRequired={false}
              onChange={e => handleChange(e, "confirmPassword")}
              onError={e => handleError(e, "confirmPassword")}
              valueToConfirm={formValues.password}
              defaultError={formErrors.confirmPassword}
            />
            <Dropdown
              name={"country"}
              label={"Country"}
              placeholder={"Choose Country"}
              listItems={countries.map(country => {
                return { label: country.Name, value: country.Code }
              })}
              isRequired={false}
              onSelect={e => handleChange(e, "country")}
            />
            {formValues.country === "US" || formValues.country === "CA" ? (
              <Dropdown
                name={"state"}
                label={"State"}
                placeholder={"Choose State"}
                listItems={states
                  .find(state => state.abbreviation === formValues.country)
                  ?.states.map(state => {
                    return {
                      label: state.name,
                      value: state.abbreviation,
                    }
                  })}
                isRequired={false}
                onSelect={e => handleChange(e, "state")}
              />
            ) : (
              <Input
                type={"text"}
                name={"state"}
                label={"State"}
                placeholder={"State"}
                maxLength={0}
                isRequired={false}
                onChange={e => handleChange(e, "state")}
                onError={e => handleError(e, "state")}
                defaultError={formErrors.state}
              />
            )}
            <Input
              type={"text"}
              name={"city"}
              label={"City"}
              placeholder={"City"}
              maxLength={0}
              isRequired={false}
              onChange={e => handleChange(e, "city")}
              onError={e => handleError(e, "city")}
              defaultError={formErrors.city}
            />
            <Checkbox
              label={
                "Yes, I’d like to hear more about offers, events and promotions from James B. Beam Distilling Co."
              }
              name={"subscription"}
              onChange={e => handleChange(e, "subscription")}
              defaultValue={formValues.subscription}
              variant={"light"}
            />
            <Checkbox
              name={"termsAndConditions"}
              onChange={e => handleChange(e, "termsAndConditions")}
              variant={"light"}
              defaultValue={formValues.termsAndConditions}
            >
              <p>
                I agree to the{" "}
                <a
                  href={"https://www.suntoryglobalspirits.com/terms-conditions"}
                  target={"_blank"}
                >
                  Terms of Use
                </a>{" "}
                and to the collection and processing of my personal data in line
                with the{" "}
                <a
                  href={"https://www.suntoryglobalspirits.com/privacy-policy"}
                  target={"_blank"}
                >
                  Privacy Policy
                </a>
                .
              </p>
            </Checkbox>
            {errorMsg && (
              <div>
                <p>{errorMsg}</p>
              </div>
            )}
          </div>
          <div className={cx("formFooter")}>
            <Button
              type={"primary"}
              text={"Create account"}
              icon={false}
              state={isEnabled() ? "active" : "disabled"}
              onClick={doSubmit}
            />
            <p>Already have an account?</p>
            <Button
              type={"tertiary"}
              text={"Sign In"}
              iconAlignment={"right"}
              icon={true}
              url={withPrefix("/sign-in")}
            />
          </div>
        </form>
      </div>
    </div>
  )
}

export default SignUpPage
