import React from "react"
import classNames from "classnames/bind"

import * as styles from "./videoPlayer.module.scss"

import Video from "./Video.png"
import VideoMobile from "./VideoMobile.png"
import DeviceInfo from "../../utils/device-info"

const cx = classNames.bind(styles)

const VideoPlayer = ({ sourceUrl, linkUrl, deviceInfo }) => {
  const src =
    linkUrl &&
    linkUrl
      .replace("watch?v=", "embed/")
      .replace("vimeo.com", "player.vimeo.com/video")

  const poster = deviceInfo.isMobile ? VideoMobile : Video

  return (
    <div className={cx("videoWrapper")}>
      <div className={cx("responsiveVideo")}>
        {linkUrl ? (
          <iframe
            src={src}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Embedded youtube"
          />
        ) : (
          <video controls poster={poster}>
            <source src={sourceUrl} type="video/mp4" />
          </video>
        )}
      </div>
    </div>
  )
}

const VideoPlayerWrapper = props => (
  <DeviceInfo>
    {deviceInfo => <VideoPlayer deviceInfo={deviceInfo} {...props} />}
  </DeviceInfo>
)

export default VideoPlayerWrapper
