import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import classNames from "classnames/bind"

import * as styles from "./scaleOptionContainer.module.scss"

const ScaleOptionContainer = ({ options, onSelect, selectedOption }) => {
  const cx = classNames.bind(styles)
  const [currentStep, setCurrentStep] = useState()
  const [currentLabel, setCurrentLabel] = useState(
    options && options[0] ? options[0].title : ""
  )

  const handleInputChange = e => {
    setStep(e.currentTarget.value)
  }

  const setStep = value => {
    setCurrentStep(Math.round(value))
    setCurrentLabel(
      options && options[Math.round(value)]
        ? options[Math.round(value)].title
        : ""
    )
    onSelect(options[Math.round(value)].id)
  }

  useEffect(() => {
    if (selectedOption && !currentStep) {
      options.forEach((element, index) => {
        if (element.id === selectedOption) {
          setStep(index)
          return
        }
      })
    }
  }, [selectedOption])

  return (
    <div className={cx("scaleOptionWrapper")}>
      <div className={cx("scaleWrapper")}>
        <input
          className={cx("indicator")}
          type="range"
          step="0.05"
          min="0"
          max={options.length - 1}
          value={currentStep ? currentStep : 0}
          onInput={handleInputChange}
        />

        <div className={cx("progress")}>
          {options.map((option, idx) => {
            const isSelected = currentStep === idx
            const isPrevious = currentStep >= idx

            const styles = cx({
              stepWrapper: true,
              previous: isPrevious,
              selected: isSelected,
            })

            return (
              <div className={styles} key={option.id}>
                <div className={cx("chevron")} />
              </div>
            )
          })}
        </div>
      </div>
      <h2>{currentLabel}</h2>
    </div>
  )
}

ScaleOptionContainer.propTypes = {
  options: PropTypes.array,
  onSelect: PropTypes.func,
}

ScaleOptionContainer.defaultProps = {
  options: ``,
  onSelect: ``,
}

export default ScaleOptionContainer
