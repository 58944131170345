import React, { useState, useEffect, useRef } from "react"
import PropTypes from "prop-types"
import classNames from "classnames/bind"

import * as styles from "./checkbox.module.scss"

const Checkbox = ({
  name,
  label,
  isRequired,
  defaultValue,
  isDisabled,
  autocomplete,
  leftCheck,
  onChange,
  variant,
  children,
}) => {
  const [value, setValue] = useState(defaultValue)
  const [active, setActive] = useState(false)

  const checkboxRef = useRef(null)

  const cx = classNames.bind(styles)

  const handleFocusAndBlur = () => {
    let click = false
    checkboxRef.current.addEventListener("mousedown", () => {
      click = true
      setActive(false)
    })
    checkboxRef.current.addEventListener("focus", () => {
      setActive(!click)
    })
    checkboxRef.current.addEventListener("blur", () => {
      click = false
      setActive(false)
    })
  }

  useEffect(() => {
    handleFocusAndBlur()
    return handleFocusAndBlur()
  }, [])

  const formCheckboxClassName = cx({
    formCheckbox: true,
    disabled: isDisabled,
    focused: active,
    leftCheck: leftCheck,
    light: variant === "light",
  })

  const handleOnChange = e => {
    setValue(e)
    onChange(e)
  }

  return (
    <div className={formCheckboxClassName}>
      <label htmlFor={name} onMouseOver={() => setActive(false)}>
        {children || label}
        {isRequired && <span aria-hidden="true">*</span>}
        <input
          type={"checkbox"}
          id={name}
          name={name}
          autoComplete={autocomplete}
          required={isRequired}
          onChange={() => handleOnChange(!value)}
          value={value}
          checked={value}
          key={name}
          ref={checkboxRef}
        />
      </label>
    </div>
  )
}

Checkbox.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  isRequired: PropTypes.bool,
  isDisabled: PropTypes.bool,
  defaultValue: PropTypes.bool,
  autocomplete: PropTypes.string,
  leftCheck: PropTypes.bool,
  onChange: PropTypes.func,
  variant: PropTypes.oneOf(["dark", "light"]),
  children: PropTypes.node,
}

Checkbox.defaultProps = {
  name: "",
  label: "",
  isRequired: false,
  isDisabled: false,
  defaultValue: false,
  autocomplete: "off",
  leftCheck: false,
  onChange: () => {},
  variant: "dark",
  children: null,
}

export default Checkbox
