import * as React from "react"
import classNames from "classnames/bind"

import Layout from "../atoms/layout/Layout"
import Blocks from "../organisms/Blocks/Blocks"

import * as styles from "../atoms/layout/layout.module.scss"

const cx = classNames.bind(styles)

const PagePiece = ({ data, userFavorites }) => {
  return (
    <Layout includeHeaderOffset>
      <Blocks items={data} favoritePieces={userFavorites} />
    </Layout>
  )
}

export default PagePiece
