import * as React from "react"
import Layout from "../atoms/layout/Layout"
import Blocks from "../organisms/Blocks/Blocks"

const ArticlePiece = ({ data }) => {
  const items = data.paragraphs.map(content => {
    return content
  })
  items.unshift({ entity: data })
  if (data.relatedPieces) {
    items.push({
      __typename: "FieldBeamBourbonuPieceArticleRelatedContent",
      entity: {
        entityId: data.entityId,
        relatedContent: [...data.relatedPieces, ...data.relatedPiecesBucket],
        __typename: "BeamBourbonuPieceArticleRelatedContent",
      },
    })
  }

  return (
    <Layout includeHeaderOffset>
      <Blocks items={items} />
    </Layout>
  )
}
export default ArticlePiece
