// extracted by mini-css-extract-plugin
export var container = "parallaxLockupA-module--container--1Tmnd";
export var pieceCarousel = "parallaxLockupA-module--pieceCarousel--3r_4a";
export var grid = "parallaxLockupA-module--grid--3APga";
export var titleTag = "parallaxLockupA-module--titleTag--2MdVt";
export var copyTag = "parallaxLockupA-module--copyTag--2DccE";
export var eyebrowTag = "parallaxLockupA-module--eyebrowTag--2wM9P";
export var textBlock = "parallaxLockupA-module--textBlock--25qgQ";
export var carouselBlock = "parallaxLockupA-module--carouselBlock--1_XuY";
export var piece = "parallaxLockupA-module--piece--3fgYN";
export var carouselContainer = "parallaxLockupA-module--carouselContainer--2oRBi";
export var carouselWrapper = "parallaxLockupA-module--carouselWrapper--2U8Zy";
export var slide = "parallaxLockupA-module--slide--1_W57";
export var shadow = "parallaxLockupA-module--shadow--7SNeU";
export var content = "parallaxLockupA-module--content--3db2X";
export var ctaSlide = "parallaxLockupA-module--ctaSlide--O_-5Q";
export var dotsWrapper = "parallaxLockupA-module--dotsWrapper--2LWlU";
export var dot = "parallaxLockupA-module--dot--2ZCwe";
export var active = "parallaxLockupA-module--active--2YP4N";
export var slideContainer = "parallaxLockupA-module--slideContainer--3Bkv7";