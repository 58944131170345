import { withPrefix } from "../utils/with-prefix"
export const normalizeFields = entity => {
  const newEntity = JSON.parse(JSON.stringify(entity))

  if (entity.info) {
    newEntity.info = [
      {
        text: entity.info.entity?.fieldBeamBourbonuRfeatLevel?.entity?.name,
        icon:
          entity.info.entity?.fieldBeamBourbonuRfeatLevel?.entity
            ?.fieldBeamBourbonuIcon?.entity?.fieldBeamBourbonuImage?.url,
      },
      {
        text: entity.info.entity?.fieldBeamBourbonuRfeatDurat?.entity?.name,
        icon:
          entity.info.entity?.fieldBeamBourbonuRfeatDurat?.entity
            ?.fieldBeamBourbonuIcon?.entity?.fieldBeamBourbonuImage?.url,
      },
      {
        text: entity.info.entity?.fieldBeamBourbonuRfeatKind?.entity?.name,
        icon:
          entity.info.entity?.fieldBeamBourbonuRfeatKind?.entity
            ?.fieldBeamBourbonuIcon?.entity?.fieldBeamBourbonuImage?.url,
      },
    ]
  }

  if (entity.copy) {
    newEntity.copy = entity.copy.value
  }

  if (entity.description) {
    newEntity.copy = entity.description.value
  }

  if (entity.cta) {
    newEntity.cta = {
      url: entity.cta.entity?.url?.url?.path,
      text: entity.cta.entity?.url?.title,
      type: entity.cta.entity?.type,
      variant: entity.cta.entity?.variant,
      state: entity.cta.entity?.state,
      icon: entity.cta.entity?.icon,
      target: entity.cta.entity?.target,
      iconAlignment: entity.cta.entity?.iconAlignment,
    }
  }

  if (entity.image) {
    if (entity.image.length) {
      newEntity.image = entity.image.map(img => {
        return img.entity?.image
      })
    } else {
      newEntity.image = entity.image.entity?.image
    }
  }

  if (entity.mobileImage) {
    newEntity.mobileImage = entity.mobileImage.entity?.image
  }

  if (entity.leftImg) {
    newEntity.leftImg = entity.leftImg.entity?.image
  }

  if (entity.rightImg) {
    newEntity.rightImg = entity.rightImg.entity?.image
  }

  if (entity.imageCopy) {
    newEntity.imageCopy = entity.imageCopy.value
  }

  if (entity.backgroundImage) {
    newEntity.backgroundImage = entity.backgroundImage.entity?.image
  }

  if (entity.foregroundImage) {
    newEntity.foregroundImage = entity.foregroundImage.entity?.image
  }

  if (entity.slideImage1) {
    newEntity.slideImage1 = entity.slideImage1.entity?.image
  }

  if (entity.slideImage2) {
    newEntity.slideImage2 = entity.slideImage2.entity?.image
  }

  if (entity.position) {
    newEntity.position = entity.position
  }

  if (entity.breadcrumbs) {
    newEntity.breadcrumbs = entity.breadcrumbs.map(breadcrumb => {
      return {
        url: breadcrumb.entity?.path?.alias,
        label: breadcrumb.entity?.title,
      }
    })
  }

  if (entity.relatedContent) {
    newEntity.eyebrow = "more to explore"
    newEntity.cards = entity.relatedContent?.map(related => {
      const piece = related.entity ? related.entity : related
      const thumbnail = piece?.fieldBeamBourbonuVideo
        ? piece?.fieldBeamBourbonuVideo?.entity?.thumbnail?.url
        : piece?.fieldBeamBourbonuImages &&
          piece?.fieldBeamBourbonuImages[0].entity?.thumbnail?.url

      return {
        eyebrow: "our story",
        header: piece?.title,
        entityId: piece?.entityId,
        entityBundle: piece?.entityBundle,
        copy: piece?.description?.value,
        image: { url: thumbnail },
        mobileImage: { url: thumbnail },
        type: "related",
        url: withPrefix(piece?.entityUrl?.path),
        variant: "2UP",
        hasSave: false,
        hasShare: false,
        cta: {
          text: "Read Now",
          url: withPrefix(piece?.entityUrl?.path),
          type: "tertiary",
          variant: "positive",
          icon: true,
          iconAlignment: "right",
        },
      }
    })
    newEntity.cards = newEntity.cards.filter(
      elem =>
        elem.entityId !== entity.entityId && elem.entityBundle !== "recipe"
    )
    delete newEntity.relatedPieces
  }

  if (entity.entityId) {
    newEntity.pieceId = entity.entityId
  }

  if (entity.categories) {
    newEntity.categories = entity.categories.map(category => {
      return {
        name: category.entity.name,
      }
    })
  }

  return newEntity
}

export const getOptionGroupsFromQuestion = question => {
  return (
    question.optionGroups &&
    question.optionGroups.map(group => {
      const groupUrl =
        group.entity.fieldBeamFlavorfinderImage?.entity?.fieldBeamBourbonuImage
          ?.url
      const selectedGroupUrl =
        group.entity.fieldBeamFlavorfinderActive?.entity?.fieldBeamBourbonuImage
          ?.url
      return {
        id: group.entity.entityId,
        title: group.entity.fieldBeamFlavorfinderLabel,
        subtitle: group.entity.fieldBeamFlavorfinderAssist,
        image: groupUrl
          ? {
              url: groupUrl,
              alt: "",
            }
          : null,
        selectedImage: selectedGroupUrl
          ? {
              url: selectedGroupUrl,
              alt: "",
            }
          : null,
        active: group.entity.fieldBeamFlavorfinderActive,
        options: group.entity.fieldBeamFlavorfinderOptions.map(({ entity }) => {
          const optionUrl =
            group.entity.fieldBeamFlavorfinderImage?.entity
              ?.fieldBeamBourbonuImage?.url
          const selectedOptionUrl =
            group.entity.fieldBeamFlavorfinderActive?.entity
              ?.fieldBeamBourbonuImage?.url

          const optionImage = entity.fieldBeamFlavorfinderImage
            ? {
                url:
                  entity.fieldBeamFlavorfinderImage?.entity
                    ?.fieldBeamBourbonuImage?.url,
                alt: "",
              }
            : null

          const selectedOptionImage = entity.fieldBeamFlavorfinderActive
            ? {
                url:
                  entity.fieldBeamFlavorfinderActive?.entity
                    ?.fieldBeamBourbonuImage?.url,
                alt: "",
              }
            : optionImage

          return {
            id: entity.entityId,
            title: entity.fieldBeamFlavorfinderLabel,
            subtitle: entity.fieldBeamFlavorfinderAssist,
            image: optionUrl
              ? {
                  url: optionUrl,
                  alt: "",
                }
              : optionImage,
            selectedImage: selectedOptionUrl
              ? {
                  url: selectedOptionUrl,
                  alt: "",
                }
              : selectedOptionImage,
            next: entity.fieldBeamFlavorfinderNext,
          }
        }),
      }
    })
  )
}

export const getOptionsFromGroups = groups =>
  groups.reduce((options, group) => options.concat(group.options), [])

export const validateBirth = (date, onBlur = false) => {
  const today = new Date()
  const ageLimit = today.setFullYear(today.getFullYear() - 21)
  const centuryLimit = today.setFullYear(today.getFullYear() - 100)
  const birth = new Date(date)
  if (!onBlur) {
    return birth < ageLimit
  } else {
    return birth < ageLimit && centuryLimit < birth
  }
}

export const validateEmail = email => {
  const rule = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
  const validUsernameLength = email.split("@")[0]?.length <= 64
  const validDomainLength = email.split("@")[1]?.length <= 255
  return (
    rule.exec(email) &&
    rule.exec(email)[0] === email &&
    validUsernameLength &&
    validDomainLength
  )
}

export const validatePassword = password => {
  const rule = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z~@#$%^&·*+_/=`|{}:;!.?\"()\[\]\-\d]{8,}$/
  return rule.exec(password) && rule.exec(password)[0] === password
}

export const validateOnlyLetters = value => {
  const rule = /^[a-zA-Z\s]*$/
  return rule.exec(value) && rule.exec(value)[0] === value
}
