import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames/bind"
import * as styles from "./relatedContent.module.scss"

import ContentCard from "../../molecules/ContentCard/ContentCard.js"

const RelatedContent = ({ cards, eyebrow }) => {
  const cx = classNames.bind(styles)

  return (
    <>
      {cards && (
        <div className={cx("wrapper")}>
          <div className={cx("container")}>
            <div className={cx("content")}>
              <div className={cx("label")}>
                <p>{eyebrow}</p>
              </div>
              <div className={cx("cardsWrapper")}>
                {cards.map((card, index) => {
                  if (index >= 2) {
                    return false
                  }
                  return (
                    <ContentCard
                      {...card}
                      variant={"2UP"}
                      key={`card-${index}`}
                    />
                  )
                })}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

RelatedContent.propTypes = {
  eyebrow: PropTypes.string,
  cards: PropTypes.array,
}

RelatedContent.defaultProps = {
  eyebrow: "",
  cards: null,
}

export default RelatedContent
