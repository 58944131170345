import React from "react"
import PropTypes from "prop-types"

import Button from "../../../atoms/Button/Button"

const SaveModal = ({ title, copy, primaryCta, secondaryCta, handleClose }) => {
  return (
    <>
      <h3>{title}</h3>
      <p>{copy}</p>
      <Button {...primaryCta} onClick={handleClose} />
      <Button {...secondaryCta} onClick={handleClose} />
    </>
  )
}

SaveModal.propTypes = {
  title: PropTypes.string,
  copy: PropTypes.string,
  primaryCta: PropTypes.object,
  secondaryCta: PropTypes.object,
  handleClose: PropTypes.func,
}
SaveModal.defaultProps = {}

export default SaveModal
