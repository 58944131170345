import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames/bind"
import DeviceInfo from "../../utils/device-info"

import * as styles from "./contentCopyHeader.module.scss"
import Button from "../../atoms/Button/Button"

const ContentCopyHeader = ({ deviceInfo, title, copy, cta }) => {
  const cx = classNames.bind(styles)

  return (
    <div className={cx("container")}>
      <div>
        <h2>{title}</h2>
        <p>{copy}</p>
        {cta && <Button {...cta} />}
      </div>
    </div>
  )
}

ContentCopyHeader.propTypes = {
  title: PropTypes.string.isRequired,
  copy: PropTypes.string.isRequired,
  cta: PropTypes.object,
}

ContentCopyHeader.defaultProps = {
  title: "",
  copy: "",
  cta: {
    text: "see all",
    type: "tertiary",
    url: "#",
    iconAlignment: "right",
    icon: true,
  },
}

const ContentCopyHeaderWrapper = props => (
  <DeviceInfo>
    {deviceInfo => <ContentCopyHeader deviceInfo={deviceInfo} {...props} />}
  </DeviceInfo>
)

export default ContentCopyHeaderWrapper
