import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames/bind"
import * as styles from "./favoriteBottle.module.scss"

import ContentCard from "../../molecules/ContentCard/ContentCard.js"

const FavoriteBottle = ({ cards, eyebrow }) => {
  const cx = classNames.bind(styles)

  return (
    <>
      {cards?.length > 0 && (
        <div className={cx("wrapper")}>
          <div className={cx("container")}>
            <div className={cx("content")}>
              <div className={cx("label")}>
                <p>{eyebrow}</p>
              </div>
              <div className={cx("cardsWrapper")}>
                {cards.map((card, index) => {
                  return <ContentCard {...card} key={`card-${index}`} />
                })}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

FavoriteBottle.propTypes = {
  eyebrow: PropTypes.string,
  cards: PropTypes.array,
}

FavoriteBottle.defaultProps = {
  eyebrow: "",
  cards: [],
}

export default FavoriteBottle
