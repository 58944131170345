import React, { useEffect, useRef, useState } from "react"
import PropTypes from "prop-types"
import classNames from "classnames/bind"
import { gsap } from "gsap"
import { Draggable } from "gsap/Draggable"
import DeviceInfo from "../../utils/device-info"
import { EVENTS, reports } from "../../utils/reports"
import * as styles from "./parallaxLockupA.module.scss"
import ContentCard from "../../molecules/ContentCard/ContentCard"
import { navigate } from "@reach/router"
import { withPrefix } from "../../utils/with-prefix"
import { Link } from "gatsby"
import { isNonEmptyArray } from "@apollo/client/utilities"

const ParallaxLockupA = ({
  deviceInfo,
  title,
  eyebrow,
  subcopy,
  slides,
  hasDots,
  variant,
}) => {
  const [currentSlide, setCurrentSlide] = useState(0)
  const [dragStart, setDragStart] = useState(0)
  const [dragEnd, setDragEnd] = useState(0)
  const [scrollProgress, setScrollProgress] = useState(0)
  const containerRef = useRef(null)
  const carouselBlockRef = useRef(null)
  const carouselWrapperRef = useRef(null)

  const cx = classNames.bind(styles)

  useEffect(() => {
    updateSlideSize()
    window.addEventListener("resize", updateSlideSize)
    updateSlideSize()
    return () => window.removeEventListener("resize", updateSlideSize)
  }, [])

  useEffect(() => {
    updateSlideSize()
    window.addEventListener("orientationchange", updateSlideSize)
    updateSlideSize()
    return () =>
      window.removeEventListener("orientationchange", updateSlideSize)
  }, [])

  useEffect(() => {
    updateSlideSize()
    prepareAnimation()
    if (variant === "carousel-A" && !deviceInfo.isMobile) {
      prepareScrollTrigger()
    }
  }, [carouselBlockRef.current])

  useEffect(() => {
    if (dragStart > dragEnd) {
      // move foward
      if (currentSlide + 1 < slides.length) {
        setCurrentSlide(currentSlide + 1)
        goToSlide(currentSlide + 1, "left")
      } else {
        setCurrentSlide(0)
        goToSlide(0, "left")
      }
    } else if (dragStart < dragEnd) {
      // move back
      if (currentSlide - 1 >= 0) {
        setCurrentSlide(currentSlide - 1)
        goToSlide(currentSlide - 1, "right")
      } else {
        setCurrentSlide(currentSlide - 1)
        goToSlide(slides.length - 1, "right")
      }
    }
  }, [dragEnd])

  const prepareScrollTrigger = () => {
    setTimeout(() => {
      let tl = gsap.timeline({
        scrollTrigger: {
          trigger: containerRef.current,
          start: "bottom bottom",
          pin: true,
          end: () => containerRef.current ? containerRef.current.offsetHeight * 2 + "px" : 0,
          pinSpacing: "margin",
          // markers: true,
          onUpdate: self => {
            setScrollProgress(self.progress)
          },
        },
      })
      tl.scrollTrigger.refresh()
    }, 1000)
  }

  useEffect(() => {
    if (scrollProgress < 0.33 && currentSlide !== 0) {
      setCurrentSlide(0)
      prepareAnimation(0)
    } else if (
      scrollProgress > 0.33 &&
      scrollProgress < 0.66 &&
      currentSlide !== 1
    ) {
      setCurrentSlide(1)
      prepareAnimation(1)
    } else if (scrollProgress > 0.66 && currentSlide !== 2) {
      setCurrentSlide(2)
      prepareAnimation(2)
    }
  }, [scrollProgress])

  const prepareAnimation = (newCurrentSlide = currentSlide, direction = "") => {
    if (deviceInfo.isMobile) {
      Draggable.create(carouselWrapperRef.current, {
        type: "x",
        edgeResistance: 0.5,
        dragResistance: 0.95,
        bounds: {
          minX: 0,
          maxX: 0,
        },
        lockAxis: true,
        onDragStart: args => {
          setDragStart(args.clientX)
        },
        onDragEnd: args => {
          setDragEnd(args.clientX)
        },
      })
      if (direction) {
        const imagesWrapper = carouselWrapperRef.current
        const children = imagesWrapper.children
        const transitionDuration = 1
        slides.map((slide, index) => {
          if (currentSlide === index) {
            gsap.to(children[index], {
              duration: transitionDuration,
              ease: "sine.out",
              zIndex: 0,
              xPercent: direction === "left" ? -100 : 100,
            })
          }
          if (index !== newCurrentSlide && index !== currentSlide) {
            gsap.to(children[index], {
              duration: transitionDuration / 10,
              ease: "sine.out",
              zIndex: 0,
              xPercent: direction === "left" ? -100 : 100,
            })
          }
          if (newCurrentSlide === index) {
            gsap.to(children[index], {
              duration: 0,
              ease: "sine.out",
              zIndex: 0,
              display: 'block',
              xPercent: direction === "left" ? 100 : -100,
            })
            gsap.to(children[index], {
              duration: transitionDuration,
              ease: "sine.out",
              opacity: 1,
              display: "block",
              zIndex: 1,
              xPercent: 0,
            })
          }
        })
      }
    } else {
      if (
        containerRef.current &&
        carouselWrapperRef.current &&
        newCurrentSlide !== currentSlide
      ) {
        const imagesWrapper = carouselWrapperRef.current
        const children = imagesWrapper.children
        const transitionDuration = 0.8
        slides.map((slide, index) => {
          if (index !== currentSlide) {
            gsap.to(children[index], {
              duration: transitionDuration / 10,
              ease: "sine.out",
              opacity: 0,
              display:'none',
              zIndex: 0,
              yPercent: 40,
            })
          } else {
            gsap.to(children[index], {
              duration: transitionDuration,
              ease: "sine.out",
              opacity: 0.5,
              zIndex: 0,
              delay: transitionDuration / 10,
              yPercent: -40,
            })
          }
          if (newCurrentSlide === index) {
            gsap.to(children[index], {
              duration: transitionDuration,
              ease: "sine.out",
              opacity: 1,
              display: 'block',
              zIndex: 1,
              delay: transitionDuration / 10,
              yPercent: 0,
            })
          }
        })
      }
    }
  }

  const updateSlideSize = () => {
    const blockRef = carouselBlockRef.current
    if (blockRef) {
      const smallViewport = deviceInfo.viewport === "small"
      // set default height
      smallViewport
        ? gsap.to(blockRef, {
            height: `410px`,
            maxHeight: `410px`,
          })
        : gsap.to(blockRef, {
            maxHeight: `620px`,
          })
      if (!smallViewport) {
        Draggable.get(carouselWrapperRef.current)?.kill()
      }
    }
  }

  const goToSlide = (slide, direction) => {
    setCurrentSlide(slide)
    prepareAnimation(slide, direction)
  }

  return (
    <div
      className={cx("container", {
        pieceCarousel: variant === "related-content",
      })}
      ref={containerRef}
    >
      <div className={cx("grid")}>
        <div className={cx("textBlock")}>
          <Link to={slides ? slides[currentSlide].url : '#'}>
            {eyebrow && <span className={cx("eyebrowTag")}>{eyebrow}</span>}
            <h6 className={cx("titleTag")}>{title}</h6>
            {subcopy && <p className={cx("copyTag")}>{subcopy}</p>}
          </Link>
        </div>
        <div
          className={cx("carouselBlock", {
            ["piece"]: variant === "related-content",
          })}
          ref={carouselBlockRef}
        >
          <div className={cx("carouselContainer")}>
            <div className={cx("carouselWrapper")} ref={carouselWrapperRef}>
              {slides &&
                slides.map((slide, index) => {
                  if (variant === "carousel-A") {
                    return (
                      <div
                        key={`slide-${index}`}
                        className={cx("slide", "shadow")}
                        tabIndex={0}
                        onFocus={() => goToSlide(index)}
                        onClick={() => {
                          reports({
                            event: EVENTS.IMAGE_CLICK,
                            type: "features",
                            title: `${slide.eyebrow} ${slide.title}`,
                          })
                        }}
                      >
                        {slide.image && (
                          <img
                            src={slide.image.url}
                            alt={
                              slide.image.alt ? slide.image.alt : slide.title
                            }
                          />
                        )}

                        <div className={cx("content")}>
                          {slide.eyebrow && (
                            <span className={cx("eyebrowTag")}>
                              {slide.eyebrow}
                            </span>
                          )}
                          <h2 className={cx("titleTag")}>{slide.title}</h2>
                          {slide.subcopy && (
                            <p className={cx("copyTag")}>{slide.subcopy}</p>
                          )}
                        </div>
                      </div>
                    )
                  } else {
                    return (
                      <div
                        className={cx("slideContainer")}
                        onClick={
                          !deviceInfo.isMobile
                            ? () => {
                                navigate(withPrefix(slide.cta.url))
                              }
                            : () => {}
                        }
                      >
                        <ContentCard
                          eyebrow={''}
                          header={slide.title}
                          copy={slide.subcopy}
                          type={slide.type}
                          image={slide.image}
                          info={slide.features}
                          cta={slide.cta}
                          key={`slide-${index}`}
                          ctaBottom={slide.ctaBottom}
                          url={slide.url}
                        />
                      </div>
                    )
                  }
                })}
            </div>
          </div>
          {hasDots && (
            <div className={cx("dotsWrapper")}>
              {slides &&
                slides.map((slide, index) => {
                  return (
                    <button
                      key={`dot-${index}`}
                      className={cx("dot", {
                        ["active"]: currentSlide === index,
                      })}
                      onClick={() => goToSlide(index, "left")}
                    >
                      {index}
                    </button>
                  )
                })}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

ParallaxLockupA.propTypes = {
  title: PropTypes.string,
  eyebrow: PropTypes.string,
  subcopy: PropTypes.string,
  image: PropTypes.object,
  slides: PropTypes.array,
  variant: PropTypes.oneOf(["carousel-A", "related-content"]),
  hasDots: PropTypes.bool,
}

ParallaxLockupA.defaultProps = {
  image: null,
  hasDots: true,
  variant: "carousel-A",
}

const ParallaxLockupWithCarouselWrapper = props => (
  <DeviceInfo>
    {deviceInfo => <ParallaxLockupA deviceInfo={deviceInfo} {...props} />}
  </DeviceInfo>
)

export default ParallaxLockupWithCarouselWrapper
