import React, { useState, useEffect } from "react"
import classNames from "classnames/bind"
import { useQuery } from "@apollo/client"

import BrandLogo from "../../atoms/BrandLogo/BrandLogo"
import SaveButton from "../../molecules/SaveButton/SaveButton"
import ShareButton from "../../molecules/ShareButton/ShareButton"

import { GetUserInformation, GetSettings } from "../../utils/fragments"

import * as styles from "./resultsHeader.module.scss"

const ResultsHeader = ({ darkMode }) => {
  const cx = classNames.bind(styles)
  const [filled, setFilled] = useState(false)
  const { data } = useQuery(GetUserInformation)
  const { data : settings } = useQuery(GetSettings)



  useEffect(() => {
    if (darkMode) {
      setFilled(true)
    } else {
      setFilled(false)
    }
  }, [darkMode])


  return (
    <div className={cx("headerWrapper", filled ? "white" : "")}>
      <div className={cx("logo")}>
        <BrandLogo url={ settings?.flavorFinderSettings ? settings.flavorFinderSettings?.brandLink : "/" }/>
      </div>
      <div className={cx("actions")}>
        <ShareButton
          title="Share"
          linkUrl={window.location.href}
          isFull={true}
        />
        {!data?.currentUser && <SaveButton title="Save" />}
      </div>
    </div>
  )
}

export default ResultsHeader
