import React, { useCallback } from "react"
import PropTypes from "prop-types"
import classNames from "classnames/bind"
import * as styles from "./copyLink.module.scss"
import { EVENTS, reports } from "../../utils/reports"

const cx = classNames.bind(styles)

const CopyLink = ({ url }) => {
  const isQuiz = !url.includes("bourbon-u")
  const handleClick = useCallback(() => {
    isQuiz &&
      reports({
        event: EVENTS.QUIZ_SHARE,
        method: "Copy Link",
      })
    navigator.clipboard.writeText(url)
  }, [url])

  return (
    <div className={cx("container")}>
      <i className={cx("icon")} />
      <label>
        <p>{url}</p>
      </label>
      <button
        onClick={handleClick}
        data-gtm-social-share="url link"
        data-gtm-url={url}
      >
        <h4>Copy Link</h4>
      </button>
    </div>
  )
}

CopyLink.propTypes = {
  url: PropTypes.string,
}
CopyLink.defaultProps = {}

export default CopyLink
