import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames/bind"
import { useLocation } from "@reach/router"

import ShareButton from "../../molecules/ShareButton/ShareButton"
import SaveButton from "../../molecules/SaveButton/SaveButton"

import * as styles from "./videoCopyLockup.module.scss"

const cx = classNames.bind(styles)

const VideoCopyLockup = ({ category, title, description, pieceId }) => {
  const { href } = useLocation()

  return (
    <div className={cx("container")}>
      <h5>{category}</h5>
      <h2>{title}</h2>
      <p>{description}</p>
      <div className={cx("ctas")}>
        <SaveButton
          savedTitle="Remove from favorites"
          title="Add to Favorites"
          pieceId={pieceId}
          contentData={{ title, type: "video" }}
        />
        <ShareButton title="Share" linkUrl={href} />
      </div>
    </div>
  )
}

VideoCopyLockup.propTypes = {
  category: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  pieceId: PropTypes.number,
}
VideoCopyLockup.defaultProps = {}

export default VideoCopyLockup
