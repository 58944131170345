import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { GetUserInformation } from "./fragments"
import useCognitoUserData from "./cognito-user-data"
import { useLazyQuery } from "@apollo/client"
export const NavSession = ({ children }) => {
  const [getUserInformation, { data, loading, error }] = useLazyQuery(
    GetUserInformation
  )
  const { sessionChecked, updateSession } = useCognitoUserData()
  useEffect(() => {
    getUserInformation()
  }, [sessionChecked, updateSession])

  useEffect(() => {
    if (!window.beamFrontendCurrentUser) {
      return
    }
    const firstName = data?.currentUser?.firstname
    const lastName = data?.currentUser?.lastname
    const addressCity = data?.currentUser?.city
    const addressState = data?.currentUser?.state
    const addressCountry = data?.currentUser?.country

    if (firstName && lastName && addressState && addressCity && addressCountry ) {
      window.beamFrontendCurrentUser.update({ firstName, lastName , addressState , addressCity , addressCountry })
    } else {
      window.beamFrontendCurrentUser.update(null)
    }
  }, [data])

  return <>{children}</>
}

NavSession.propTypes = {
  children: PropTypes.node,
}
