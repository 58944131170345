import React, { useEffect, useRef, useState } from "react"
import PropTypes from "prop-types"
import { gsap } from "gsap"
import classNames from "classnames/bind"
import * as styles from "./questionBreaker.module.scss"
import darkBreaker from "./dark-breaker.svg"
import goldenBreaker from "./golden-breaker.svg"
import barrel from "./barrel-desktop.svg"
import { EVENTS, reports } from "../../utils/reports"
import FactsData from "./facts"
import Button from "../../atoms/Button/Button"

const QuestionBreaker = ({
  title,
  secondaryCopy,
  assistanceCopy,
  imageCopy,
  action,
  start,
  isFact,
  percent,
  facts,
}) => {
  const finalWavesRef = useRef(null)
  const initialWavesRef = useRef(null)
  const titleRef = useRef(null)
  const subtitleRef = useRef(null)
  const copyContainerRef = useRef(null)
  const mainContainerRef = useRef(null)
  const barrelRef = useRef(null)

  const [isReady, toggleReady] = useState(false)
  const [barrelCanUpdate, setBarrelCanUpdate] = useState(false)
  const [factInfo, setFactInfo] = useState(FactsData(facts?.WhiskeyFacts))

  useEffect(() => {
    let timer
    if (isReady) {
      timer = setTimeout(
        () => {
          action()
        },
        isFact ? 9500 : 4000
      )
    }
    return () => timer && clearTimeout(timer)
  }, [isReady])

  useEffect(() => {
    if (start) {
      // first animation
      gsap
        .timeline()
        .to(initialWavesRef.current, {
          duration: 0.1,
          opacity: 1,
        })
        .from(initialWavesRef.current, {
          duration: 1.4,
          y: "115vh",
          ease: "none",
        })
        .to(initialWavesRef.current, {
          duration: 1,
          opacity: 0,
          display: "none",
        })
        // second animation
        .to(finalWavesRef.current, { duration: 0.75, opacity: 1 }, "-=1.45")
        .to(finalWavesRef.current, {
          delay: isFact ? 4 : 0,
          duration: 2.2,
          y: "140vh",
          ease: "power1.in",
        })
        .to(
          [titleRef.current, subtitleRef?.current],
          {
            duration: 0.5,
            opacity: 1,
            ease: "power4.in",
          },
          isFact ? "-=7.4" : "-=3.4"
        )
        .to(
          copyContainerRef.current,
          {
            duration: 0.5,
            opacity: 1,
            ease: "power4.in",
          },
          isFact ? "-=6.4" : "-=2.4"
        )
        .to(finalWavesRef.current, { duration: 0.5, display: "none" })
        .to(
          [titleRef.current, subtitleRef?.current],
          {
            duration: 0.5,
            opacity: 0,
            ease: "power4.in",
            onComplete: handleBarrelPercent,
          },
          "-=2.4"
        )
      gsap.fromTo(
        [finalWavesRef.current, initialWavesRef.current],
        {
          x: "-50vw",
        },
        {
          duration: 0.5,
          ease: "none",
          repeat: 24,
          x: "50vw",
        }
      )
      gsap.to(mainContainerRef.current, {
        duration: 0.5,
        backgroundColor: "#f6f6f3",
        delay: 1.4,
      })
      setTimeout(() => {
        toggleReady(true)
      }, 3000)
    }
  }, [start])

  useEffect(() => {
    if (barrelCanUpdate) {
      handleBarrelPercent()
    }
  }, [percent])

  useEffect(() => {
    reports({
      event: EVENTS.PAGEVIEW_INTERSTITIAL,
      interstitial: title || factInfo.title.join(" "),
    })
    //fix scroll during breaker animation
    document.body.style.overflow = "hidden"
    document.body.style.height = "100%"
    //required for iphone safari
    document.documentElement.style.overflow = "hidden"
    document.documentElement.style.height = "100%"
    return () => {
      document.documentElement.style.overflow = "unset"
      document.body.style.overflow = "unset"
    }
  }, [])

  const handleBarrelPercent = () => {
    if (barrelRef.current) {
      gsap.to(barrelRef.current, {
        opacity: percent / 100,
        ease: "power4.in",
        duration: 3,
        onComplete: () => {
          if (!barrelCanUpdate) {
            setBarrelCanUpdate(true)
          }
        },
      })
    }
  }

  const handleOnclick = () => {
    isReady && action()
  }

  const cx = classNames.bind(styles)

  const copyContainerClassName = cx({
    ["copyContainer"]: true,
    ["hasImage"]: imageCopy,
  })

  return start ? (
    <div
      className={cx("container", {
        ["hasFact"]: isFact,
      })}
      onClick={() => handleOnclick()}
      ref={mainContainerRef}
    >
      <img src={goldenBreaker} ref={initialWavesRef} alt={"Waves"} />
      <div className={cx(isFact ? "" : "factWrapper")}>
        {isFact && (
          <>
            <h1 className={cx("factHeader")} ref={titleRef}>
              {factInfo.title[0]}
              <br />
              {factInfo.title[1]}
            </h1>
            <p className={cx("factSubHeader")} ref={subtitleRef}>
              {factInfo.subtitle}
            </p>
          </>
        )}
        {!isFact && <h1 ref={titleRef}>{title}</h1>}
        <div className={copyContainerClassName} ref={copyContainerRef}>
          {isFact && (
            <>
              <h1 className={cx("factTitle")}>{factInfo.fact.title}</h1>
              <h2 className={cx("factSubTitle")}>{factInfo.fact.subtitle}</h2>
              <p className={cx("factCopy")}>{factInfo.fact.copy}</p>
              <Button {...factInfo.cta} onClick={() => handleOnclick()} />
              <img
                src={factInfo.fact.image ? factInfo.fact.image : barrel}
                alt={factInfo.fact.title}
                ref={barrelRef}
                className={cx("factImage")}
              />
            </>
          )}
          {!isFact && (
            <>
              <h2>{secondaryCopy}</h2>
              {assistanceCopy && <p>{assistanceCopy}</p>}
              {imageCopy && <img src={imageCopy} alt={"Image copy"} />}
            </>
          )}
        </div>
      </div>
      <img src={darkBreaker} ref={finalWavesRef} alt={"Waves"} />
    </div>
  ) : (
    <></>
  )
}

QuestionBreaker.propTypes = {
  title: PropTypes.string,
  secondaryCopy: PropTypes.string,
  assistanceCopy: PropTypes.string,
  imageCopy: PropTypes.string,
  action: PropTypes.func,
  start: PropTypes.bool,
  isFact: PropTypes.bool,
  percent: PropTypes.number,
  facts: PropTypes.object,
}

QuestionBreaker.defaultProps = {
  title: "",
  secondaryCopy: "",
  assistanceCopy: "",
  imageCopy: "",
  start: false,
  action: () => {},
  isFact: false,
  percent: 0,
  facts: {},
}

export default QuestionBreaker
