import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames/bind"

import ImageSwitcher from "../../atoms/ImageSwitcher/ImageSwitcher"

import * as styles from "./contentAnyRoadTicketing.module.scss"
import Button from "../../atoms/Button/Button"

const cx = classNames.bind(styles)

const ContentAnyRoadTicketing = ({
  eyebrow,
  title,
  body,
  id,
  guests,
  time,
  cta,
  image,
  mobileImage,
}) => {
  return (
    <div className={cx("container")}>
      <div className={cx("contentContainer")}>
        {eyebrow && <h4>{eyebrow}</h4>}
        <h2>{title}</h2>
        <p>{body}</p>
        {id && (
          <div className={cx("fields")}>
            <div className={cx("idField")}>
              <h4>booking id</h4>
              <h3>{id}</h3>
            </div>
            <div className={cx("timeField")}>
              <h4>tour date</h4>
              <h3>{time}</h3>
            </div>
            <div className={cx("guestsField")}>
              <h4>guests</h4>
              <h3>{guests}</h3>
            </div>
          </div>
        )}
        {cta && <Button {...cta} />}
      </div>
      <div className={cx("imageContainer")}>
        <ImageSwitcher
          img={image}
          smallImg={mobileImage}
          alt={title}
          breakpoint="md"
        />
      </div>
    </div>
  )
}

ContentAnyRoadTicketing.propTypes = {
  eyebrow: PropTypes.string,
  title: PropTypes.string,
  body: PropTypes.string,
  id: PropTypes.string,
  guests: PropTypes.string,
  time: PropTypes.string,
  image: PropTypes.object,
  mobileImage: PropTypes.object,
}
ContentAnyRoadTicketing.defaultProps = {}

export default ContentAnyRoadTicketing
