import React from "react"
import classNames from "classnames/bind"
import ContentCard from "./../ContentCard/ContentCard"
import { Link } from "gatsby"
import { withPrefix } from "../../utils/with-prefix"

import * as styles from "./categoryResults.module.scss"

const CategoryResults = ({ results }) => {
  const cx = classNames.bind(styles)

  return (
    <div className={cx("categoryResults")}>
      {results.map(
        ({
          type,
          pieceId,
          id,
          videofeatured,
          articlefeatured,
          url,
          eyebrow,
          title,
          description,
          iconlevel,
          level,
          iconduration,
          duration,
          iconkind,
          kind,
        }) => {
          const isNotRecipe = type === "recipe" ? false : true
          const thumbnail = videofeatured ? videofeatured : articlefeatured

          return (
            <Link className={cx("link")} to={withPrefix(url)}>
              <ContentCard
                pieceId={pieceId || id}
                eyebrow={eyebrow}
                header={title}
                copy={isNotRecipe ? description : ""}
                hasShare={isNotRecipe}
                hasSave={isNotRecipe}
                type={type}
                image={{ url: thumbnail }}
                url={withPrefix(url)}
                info={[
                  {
                    icon: iconlevel,
                    text: level,
                  },
                  {
                    icon: iconduration,
                    text: duration,
                  },
                  {
                    icon: iconkind,
                    text: kind,
                  },
                ]}
                cta={
                  type === "article"
                    ? {
                        text: "Read now",
                        type: "tertiary",
                        icon: true,
                        iconAlignment: "right",
                        variant: "positive",
                        url: withPrefix(url),
                      }
                    : type === "recipe"
                    ? {
                        text: "View Recipe",
                        type: "tertiary",
                        icon: true,
                        iconAlignment: "right",
                        variant: "positive",
                        url: withPrefix(url),
                      }
                    : null
                }
              />
            </Link>
          )
        }
      )}
    </div>
  )
}

export default CategoryResults
