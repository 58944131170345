import React, { useContext, useEffect } from "react"
import { navigate } from "@reach/router"
import LoadingPage from "../loadingPage/loadingPage"

import useCognitoUserData from "../cognito-user-data"
import { withPrefix } from "../with-prefix"
import { lastRegistrationSource } from "../session-variable"

const FillAndFollowPage = ({ location }) => {
  const { isLoggedIn, sessionChecked } = useCognitoUserData()

  const params = new URLSearchParams(location?.search)
  const ffybLocation = params.get("location")

  useEffect(() => {
    lastRegistrationSource().set("FRONTEND_FFYB")
  }, [])

  useEffect(() => {
    if (sessionChecked && isLoggedIn()) {
      const baseUrl = withPrefix("profile")
      if (ffybLocation) {
        lastRegistrationSource().set("FRONTEND_FFYB")
        return navigate(`${baseUrl}?location=${ffybLocation}`)
      }
      navigate(baseUrl)
    } else if (sessionChecked) {
      const baseUrl = withPrefix("sign-in")
      if (ffybLocation) {
        lastRegistrationSource().set("FRONTEND_FFYB")
        return navigate(`${baseUrl}?location=${ffybLocation}`)
      }
      navigate(baseUrl)
    }
  }, [sessionChecked])

  return !sessionChecked && <LoadingPage />
}

export default FillAndFollowPage
