// extracted by mini-css-extract-plugin
export var container = "question-module--container--3ZikS";
export var grid = "question-module--grid--3McyN";
export var textCol = "question-module--textCol--2Dytl";
export var auxText = "question-module--auxText--3Nm1w";
export var dividerCol = "question-module--dividerCol--dMYB8";
export var optionsCol = "question-module--optionsCol--1G2lK";
export var noFlex = "question-module--noFlex--1FZuD";
export var onFadeOut = "question-module--onFadeOut--7DORe";
export var fadeOut = "question-module--fadeOut--3mgB4";
export var onFadeIn = "question-module--onFadeIn--3JnX8";
export var fadeIn = "question-module--fadeIn--13rqZ";