import React, { createContext, useState } from "react"
import PropTypes from "prop-types"

export const CognitoSessionContext = createContext()

export const CognitoSessionProvider = ({ children }) => {
  const [userAttributes, setUserAttributes] = useState(null)
  const [tempUserData, setTempUserData] = useState(null)
  const [jwt, setJwt] = useState("")
  const [sessionChecked, setSessionChecked] = useState(false)
  const [updateSession, setUpdateSession] = useState(false)

  return (
    <CognitoSessionContext.Provider
      value={{
        userAttributes,
        setUserAttributes,
        tempUserData,
        setTempUserData,
        jwt,
        setJwt,
        sessionChecked,
        setSessionChecked,
        updateSession,
        setUpdateSession
      }}
    >
      {children}
    </CognitoSessionContext.Provider>
  )
}

CognitoSessionProvider.propTypes = {
  children: PropTypes.node,
}
