import React, { useState, useEffect, useRef } from "react"
import PropTypes from "prop-types"
import classNames from "classnames/bind"
import * as styles from "./multipleSelectContainer.module.scss"
import DeviceInfo from "../../utils/device-info"
import MultipleOption from "../../molecules/MultipleOption/MultipleOption"

const MultipleSelectContainer = ({
  deviceInfo,
  selectedOption,
  onSelect,
  options,
  image,
  isFollowUp,
  max,
  min,
  followUpData,
  selectedFavorite,
}) => {
  const [selection, setSelection] = useState([])
  const [hasOptionImage, setHasOptionImage] = useState(false)
  const [favorite, setFavorite] = useState(selectedFavorite)
  const [favoriteSelection, setFavoriteSelection] = useState(
    followUpData && isFollowUp
  )
  const [maxOptions, setMaxOptions] = useState(max)
  const [optionsHTML, setOptionsHTML] = useState([])
  const multiSelectContainer = useRef()
  const optionsContainer = useRef()
  const cx = classNames.bind(styles)

  useEffect(() => {
    let selectedOptions = selectedOption !== "" ? selectedOption.split(",") : []
    let optionIds = []
    options.map(optionGroup => {
      optionGroup.options.map(option => {
        optionIds.push(option.id)
      })
    })
    selectedOptions = selectedOptions.filter(option =>
      optionIds.some(id => id === option)
    )
    setSelection(selectedOptions)
  }, [selectedOption])

  useEffect(() => {
    setMaxOptions(followUpData ? followUpData.max : max)
    setFavoriteSelection(followUpData && isFollowUp)
    updateHTMLOptions(max)
    if (favorite === null && followUpData && followUpData.favorite !== null) {
      setFavorite(followUpData.favorite)
    }
  }, [options, selection, max, followUpData, selectedFavorite])

  useEffect(() => {
    const container = multiSelectContainer.current
    const overFlowState = hasOptionImage || image ? "auto" : "unset"
    // set initial overflow
    container.style.overflow = overFlowState

    // add breathing space between options and scrollbar to prevent overlapping
    if (
      deviceInfo.viewport !== "small" &&
      container.clientHeight < container.scrollHeight
    ) {
      container.style.width = `${container.clientWidth + 24}px`
      container.style.paddingRight = "24px"
      container.style.marginRight = "-24px"
      // always show scrollbar when scroll is posible
      container.style.overflow = "hidden scroll"
    } else {
      container.style.width = ""
      container.style.paddingRight = ""
      container.style.marginRight = ""
      container.style.overflow = overFlowState
    }
  }, [hasOptionImage])

  const updateHTMLOptions = max => {
    let lastRow = null
    const firstLoad = selection.length === 0
    const maxOpt = max

    const htmlOptions = (followUpData ? followUpData.options : options).map(
      element => {
        const followUpEnabled = followUpData !== null
        return element.options.map((option, index) => {
          setHasOptionImage(option?.image)
          const key = `option-${index}`
          let selected = selection.some(e => {
            return e === option.id
          })

          if (followUpEnabled) {
            selected =
              option.id === favorite || option.id === followUpData.favorite
          }

          const currentState = selected ? "selected" : "unselected"
          const disabled =
            maxOpt && !selected && maxOpt > 0 && selection.length === maxOpt

          let itemOptions = {
            ...option,
            state: firstLoad || disabled ? "default" : currentState,
            defaultImage: option?.image,
            selectedImage: option?.selectedImage,
            onClick: handleSelect,
            disabled: followUpEnabled ? false : disabled,
          }

          if (
            element.options.length - 1 === index &&
            element.options.length > 1 &&
            element.options.length / 2 > 0
          ) {
            lastRow = <MultipleOption {...itemOptions} key={key} />
            return <div className={cx("extraRow")} key={`${key}-extra`}></div>
          }
          return <MultipleOption {...itemOptions} key={key} />
        })
      }
    )

    if (lastRow) {
      htmlOptions.push(lastRow)
    }

    setOptionsHTML(htmlOptions)
  }

  const handleSelect = (optionId, selected) => {
    updateSelectionData(optionId, selected)
  }

  const updateSelectionData = (optionId, selected) => {
    const followUpEnabled = followUpData !== null
    // unset selection
    let newSelection = [...selection]
    let newFavorite = favorite
    // update selection
    if (!selected) {
      if (favoriteSelection) {
        if (optionId === favorite) {
          setFavorite(null)
          newFavorite = null
        }
      } else {
        // remove
        newSelection = newSelection.filter(item => {
          return item !== optionId
        })
      }
    } else {
      // add
      if (favoriteSelection || followUpEnabled) {
        setFavorite(optionId)
        newFavorite = optionId
      } else {
        newSelection.push(optionId)
      }
    }
    if (newSelection.length >= min || newSelection.length === 0) {
      onSelect({
        ids: newSelection.join(","),
        favorite: newFavorite,
      })
    }
    setSelection(newSelection)
  }

  return (
    <div className={cx("container")} ref={multiSelectContainer}>
      {image && <img className={cx("image")} src={image.url} alt={image.alt} />}
      <div
        className={cx("grid", {
          ["imageGrid"]: !image,
          ["buttonGrid"]: image,
        })}
        ref={optionsContainer}
      >
        {optionsHTML}
      </div>
    </div>
  )
}

MultipleSelectContainer.propTypes = {
  selectedOption: PropTypes.string,
  onSelect: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.object),
  image: PropTypes.object,
  isFollowUp: PropTypes.bool,
  max: PropTypes.number,
  min: PropTypes.number,
  followUpData: PropTypes.object,
  selectedFavorite: PropTypes.string,
}

MultipleSelectContainer.defaultProps = {
  selectedOption: "",
  onSelect: () => {},
  options: [],
  image: null,
  isFollowUp: false,
  followUpData: null,
  max: null,
  min: 1,
  selectedFavorite: null,
}

const MultipleSelectContainerWrapper = props => (
  <DeviceInfo>
    {deviceInfo => (
      <MultipleSelectContainer deviceInfo={deviceInfo} {...props} />
    )}
  </DeviceInfo>
)

export default MultipleSelectContainerWrapper
