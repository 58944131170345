import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import classNames from "classnames/bind"

import * as styles from "./breadCrumbs.module.scss"

const BreadCrumbs = ({ breadcrumbs, isWhiteFont, video }) => {
  const cx = classNames.bind(styles)

  return (
    <ul
      className={cx(
        "breadcrumb",
        isWhiteFont ? "white" : "",
        video ? "video" : ""
      )}
    >
      {breadcrumbs.map((breadcrumb, idx) => {
        return (
          breadcrumb.label && (
            <React.Fragment key={idx}>
              <li>
                <Link to={breadcrumb.url}> {breadcrumb.label} </Link>
              </li>
              <li>
                <i>|</i>
              </li>
            </React.Fragment>
          )
        )
      })}
    </ul>
  )
}

BreadCrumbs.propTypes = {
  breadcrumbs: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  isWhiteFont: PropTypes.bool,
}
BreadCrumbs.defaultProps = {}

export default BreadCrumbs
