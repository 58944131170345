// extracted by mini-css-extract-plugin
export var textCol = "slideCard-module--textCol--3go-d";
export var carouselNavigationController = "slideCard-module--carouselNavigationController--XCgEk";
export var iconPrev = "slideCard-module--iconPrev--2-FXE";
export var iconNext = "slideCard-module--iconNext--2X13l";
export var bottleName = "slideCard-module--bottleName--JzvZD";
export var keyFeatures = "slideCard-module--keyFeatures--pCE6O";
export var flavorNotes = "slideCard-module--flavorNotes--2SoPd";
export var dividerCol = "slideCard-module--dividerCol--3IvK-";
export var imageCol = "slideCard-module--imageCol--2jj0C";
export var container = "slideCard-module--container--8pvau";
export var ctasContainer = "slideCard-module--ctasContainer--QKXzy";