import React, { useRef, useEffect, useState } from "react"
import PropTypes from "prop-types"
import classNames from "classnames/bind"
import * as styles from "./resultsHero.module.scss"
import { gsap } from "gsap"

const ResultsHero = ({
  resultImage,
  resultImageActive,
  backgroundImage,
  copy,
  onAnimationStart,
  onAnimationEnd
}) => {
  const cx = classNames.bind(styles)

  const [filled, setFilled] = useState(false)
  const [show, setShow] = useState(false)

  const staticElements = useRef(null)
  const scrollButton = useRef(null)
  const panelOne = useRef(null)
  const panelTwo = useRef(null)
  const containerRef = useRef(null)

  const goToSection = () => {
    if (window) {
      gsap.to(window, {
        scrollTo: {
          y: containerRef.current.clientHeight + 2000,
          autoKill: false,
        },
        duration: 1.5,
      })
    }
  }

  useEffect(() => {
    const logoImage = new Image();
    logoImage.src = resultImage.url;

    logoImage.onload = () => {
      setShow(true)
    }
  }, [])


  useEffect(() => {
    if (containerRef.current) {
      // add scroll timeline
      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: containerRef.current,
          pin: true,
          start: "top top",
          scrub: true,
          end: () => containerRef.current?.offsetHeight ? `+=${containerRef.current.offsetHeight}` : 0,
          toggleActions: "play none none reverse",
          //markers: true,
          onEnter: () => {
            onAnimationStart()
          },
          onLeave: () => {
            gsap.fromTo(staticElements.current,
              {
                opacity: 1,
              },
              {
                opacity: 0,
                duration: 3
              })
              onAnimationEnd()
          },
          onLeaveBack: () => {
            onAnimationEnd()
          },
          onEnterBack: () => {
            onAnimationStart()
            fadeInBackground()
          }
        },
      }).fromTo(panelOne.current,
        {
          opacity: 1,
        },
        {
          opacity: 0,
          y: '-20px',
          duration: 0.8,
        }).fromTo(panelTwo.current,
          {
            opacity: 0
          },
          {
            opacity: 1,
            duration: 0.8,
            height: "100vh",
            position: "absolute",
            top: "unset",
            bottom: 0,
          }).to(panelTwo.current, {
            opacity: 0,
            duration: 0.8,
            delay: 3,
            top: "-50%"
          });


      //Swap logos
      gsap.fromTo(panelOne.current.firstChild,
        {
          opacity: 1
        },
        {
          opacity: 0,
          duration: 0.5,
          delay: 2,
          onComplete: () => {
            fadeInBackground()
          }
        })

      gsap.to(panelOne.current.lastChild, {
        opacity: 1,
        duration: 0.5,
        delay: 2,
        onComplete: () => {
          setFilled(true)
        }
      })
    }
  }, [staticElements])

  const fadeInBackground = () => {
    gsap.to(staticElements.current,
      {
        opacity: 1,
        duration: 1,
        top: 0,
      })
  }

  return (
    <div className={cx("root")} ref={containerRef}>
      <div className={cx("content")}>
        <div className={cx("staticElements")} ref={staticElements}>
          <img src={backgroundImage.url} alt={backgroundImage.alt} />
        </div>
        <section className={cx("panel")} ref={panelOne}>
          <img src={resultImage.url} alt={resultImage.alt} />
          <img src={resultImageActive.url} alt={resultImageActive.alt} />
        </section>
        <section className={cx("panel")} ref={panelTwo}>
          <div className={cx("grid")}>
            <div className={cx("colEight")}>
              <p>{copy}</p>
            </div>
          </div>
        </section>
        <div className={cx("scrollControl")} ref={scrollButton}>
          <div
            className={cx(filled ? "filled" : "")}
            onClick={goToSection}
            aria-hidden="true"
          >
            <h4>Scroll</h4>
            <span />
          </div>
        </div>
      </div>
    </div>
  )
}

ResultsHero.propTypes = {
  resultImageActive: PropTypes.object,
  resultImage: PropTypes.object,
  backgroundImage: PropTypes.object,
  copy: PropTypes.string,
}

ResultsHero.defaultProps = {
  resultImageActive: {
    url: ``,
    alt: ``,
  },
  resultImage: {
    url: ``,
    alt: ``,
  },
  backgroundImage: {
    url: ``,
    alt: ``,
  },
  copy: ``,
}

export default ResultsHero
