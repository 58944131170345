import * as React from "react"
import Layout from "../atoms/layout/Layout"
import RecipePage from "../utils/recipePage/recipePage"

const RecipePiece = ({ piece }) => {
  return (
    <Layout includeHeaderOffset>
      <RecipePage recipeData={piece} />
    </Layout>
  )
}

export default RecipePiece
