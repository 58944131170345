import { useState, useEffect } from "react"

const queryDeviceInfo = () => {
  if (typeof window === "undefined") {
    // Dummy device information during build.
    return {
      isIos: false,
      isAndroid: false,
      screenSize: 1024,
      isMobile: false,
      isMobileBrowser: false,
      viewport: "big",
      isSafari: false,
    }
  }

  /*
   * Note: replace screen.width with window.innerWidth to follow the window size
   * instead of the device screen size.
   */

  const isIos = /iPhone;|iPad;/.test(navigator.userAgent)
  const isAndroid = /Android/.test(navigator.userAgent)
  const isSafari =
    /Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent)
  const screenSize = window.screen.width < 1024
  const currentViewport =
    window.innerWidth <= 768
      ? "small"
      : window.innerWidth > 768 && window.innerWidth <= 1023
      ? "medium"
      : window.innerWidth >= 1920
      ? "large"
      : "big"

  return {
    isIos,
    isAndroid,
    screenSize,
    isMobile: isIos || isAndroid || screenSize,
    isMobileBrowser: isIos || isAndroid,
    viewport: currentViewport,
    isSafari: isSafari,
  }
}

const DeviceInfo = ({ children }) => {
  const [info, setInfo] = useState(queryDeviceInfo)

  useEffect(() => {
    const onResize = event => setInfo(queryDeviceInfo())

    window.addEventListener("resize", onResize)

    return () => {
      window.removeEventListener("resize", onResize)
    }
  })

  return children(info)
}

export default DeviceInfo
