import React from "react"
import PropTypes from "prop-types"
import { normalizeFields } from "../../utils/helpers"

import CopyCenter from "../CopyCenter/CopyCenter"
import LPImageHero from "../LPImageHero/LPImageHero"
import ReactiveImageLockup from "../ReactiveImageLockup/ReactiveImageLockup"
import ArticleHero from "../ArticleHero/ArticleHero"
import ArticleDetail from "../ArticleDetail/ArticleDetail"
import ContentCardsContainer from "../../organisms/ContentCardsContainer/ContentCardsContainer"
import ContentCopyHeader from "../../molecules/ContentCopyHeader/ContentCopyHeader"
import RelatedContent from "../../organisms/RelatedContent/RelatedContent"
import SearchApiLayout from "../../organisms/SearchCategories/SearchApiLayout"

const Blocks = ({ items, components, favoritePieces }) => {
  const newItems = items.map(item => item)
  const isSearch = items.find(
    item =>
      item?.entity?.__typename === "ParagraphBeamBourbonuContentCategories"
  )
  if (favoritePieces?.length > 0 && !isSearch) {
    const newItem = {
      entity: {
        __typename: "ParagraphBeamBourbonuCopyCenter",
        title: "Your favorites",
      },
    }
    newItems.splice(2, 0, newItem)

    const newItem2 = {
      entity: {
        __typename: "ParagraphBeamBourbonuCardsCarousel",
        cards: favoritePieces,
      },
    }
    newItems.splice(3, 0, newItem2)
  }

  return newItems.map((item, i) => {
    if (item?.entity) {
      const block = normalizeFields(item.entity)
      const slug = item.entity.__typename
      const Component = components[slug]

      if (!Component) {
        return "Component Not Found."
      }

      return <Component key={i} {...block} userFavorites={favoritePieces} />
    }
  })
}

Blocks.propTypes = {
  items: PropTypes.array,
  components: PropTypes.object,
}

Blocks.defaultProps = {
  items: [],
  components: {
    ParagraphBeamBourbonuCopyCenter: CopyCenter,
    ParagraphBeamBourbonuLpImageHero: LPImageHero,
    ParagraphBeamBourbonuReactiveImgLu: ReactiveImageLockup,
    BeamBourbonuPieceArticle: ArticleHero,
    ParagraphBeamBourbonuTextOnly: ArticleDetail,
    ParagraphBeamBourbonuTextWhithImage: ArticleDetail,
    ParagraphBeamBourbonuCopyCenterSimple: ContentCopyHeader,
    ParagraphBeamBourbonuFeatureContent: ContentCardsContainer,
    ParagraphBeamBourbonuCardHighlight2: ContentCardsContainer,
    ParagraphBeamBourbonuCardHighlight3: ContentCardsContainer,
    ParagraphBeamBourbonuCardsCarousel: ContentCardsContainer,
    ParagraphBeamBourbonuContentCategories: SearchApiLayout,
    BeamBourbonuPieceArticleRelatedContent: RelatedContent,
  },
}

export default Blocks
