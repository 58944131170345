// extracted by mini-css-extract-plugin
export var container = "shareModal-module--container--xSF1-";
export var imgCol = "shareModal-module--imgCol--7DAfd";
export var img = "shareModal-module--img--3hHO_";
export var dotsWrapper = "shareModal-module--dotsWrapper--1Qyqk";
export var dot = "shareModal-module--dot--12Kwp";
export var active = "shareModal-module--active--3lT0s";
export var clickable = "shareModal-module--clickable--1kb20";
export var textCol = "shareModal-module--textCol--v0bpT";
export var media = "shareModal-module--media--1nqhZ";
export var socialMedia = "shareModal-module--socialMedia--1HvfQ";
export var mobileHeader = "shareModal-module--mobileHeader--1dljI";