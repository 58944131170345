import React, { useCallback, useEffect, useState } from "react"
import classNames from "classnames/bind"
import Input from "../../atoms/Input/Input"
import Button from "../../atoms/Button/Button"
import * as styles from "./forgotPasswordPage.module.scss"
import Password from "../../atoms/Password/Password"
import * as AmazonCognitoIdentity from "amazon-cognito-identity-js"
import { withPrefix } from "../with-prefix"
import { navigate } from "@reach/router"
import useCognitoUserData from "../cognito-user-data"

const ForgotPasswordPage = () => {
  const [requiredFieldNames, setRequiredFieldNames] = useState(["email"])
  const [formValues, setFormValues] = useState({})
  const [formErrors, setFormErrors] = useState({})
  const [codeSent, setCodeSent] = useState(false)
  const [errorMsg, setErrorMsg] = useState("")
  const {
    forgotPassword,
    confirmResetPassword,
    isLoggedIn,
    sessionChecked,
  } = useCognitoUserData()

  const cx = classNames.bind(styles)

  const isEnabled = () => {
    let isEnabled = true
    requiredFieldNames.forEach(key => {
      if (!formValues[key]) {
        isEnabled = false
      }
    })
    for (const key in formErrors) {
      if (formErrors[key]) {
        isEnabled = false
      }
    }
    return isEnabled
  }

  const handleChange = (value, name) => {
    setFormValues({ ...formValues, [name]: value })
    if (name === "newPassword" && formValues.confirmPassword) {
      if (value !== formValues.confirmPassword) {
        setFormErrors({
          ...formErrors,
          confirmPassword: "Passwords don't match.",
        })
      } else {
        setFormErrors({
          ...formErrors,
          confirmPassword: false,
          newPassword: false,
        })
      }
    }
  }

  const handleError = (value, name) => {
    setFormErrors({ ...formErrors, [name]: value })
  }

  const handleSendCode = useCallback(() => {
    const email = formValues["email"]
    forgotPassword(email)
      .then(() => {
        setCodeSent(true)
      })
      .catch(err => setErrorMsg(err.message))
  }, [formValues])

  const handleConfirmPassword = useCallback(() => {
    const email = formValues?.email
    const verificationCode = formValues?.verificationCode
    const newPassword = formValues?.newPassword
    const confirmPassword = formValues?.confirmPassword
    if (newPassword !== confirmPassword) {
      return setErrorMsg("Passwords don't match.")
    }
    confirmResetPassword(email, verificationCode, newPassword)
      .then(() => {
        navigate(withPrefix("/sign-in"))
      })
      .catch(err => setErrorMsg(err.message))
  }, [formValues])

  const handleSubmit = e => {
    if (!isEnabled()) {
      return
    }
    e.preventDefault()
    if (codeSent) {
      handleConfirmPassword()
    } else {
      handleSendCode()
    }
  }

  useEffect(() => {
    if (codeSent) {
      setRequiredFieldNames([
        "email",
        "newPassword",
        "confirmPassword",
        "verificationCode",
      ])
    }
  }, [codeSent])

  useEffect(() => {
    if (sessionChecked && isLoggedIn()) {
      navigate(withPrefix("/profile"))
    }
  }, [sessionChecked])

  return (
    <div className={cx("wrapper")}>
      <div className={cx("container")}>
        <form onSubmit={e => handleSubmit(e)} className={cx("formContainer")}>
          <div className={cx("formHeader")}>
            <h1>Forgot Password?</h1>
            <p>
              We got you, remind us of your email and we’ll shoot you a link to
              reset it.
            </p>
          </div>
          <div className={cx("formFields")}>
            <Input
              type={"email"}
              name={"email"}
              label={"Email"}
              placeholder={"Email"}
              maxLength={0}
              isRequired={false}
              onChange={e => handleChange(e, "email")}
              onError={e => handleError(e, "email")}
            />
            {codeSent && (
              <>
                <Input
                  type={"text"}
                  name={"verificationCode"}
                  label={"Verification Code"}
                  placeholder={"Verification Code"}
                  maxLength={0}
                  isRequired={false}
                  onChange={e => handleChange(e, "verificationCode")}
                  onError={e => handleError(e, "verificationCode")}
                />
                <Password
                  name={"newPassword"}
                  label={"New Password"}
                  placeholder={"Password"}
                  maxLength={0}
                  isRequired={false}
                  helperText={
                    "Min. 8 characters. Mix of numbers, letters and upper and lower cases."
                  }
                  onChange={e => handleChange(e, "newPassword")}
                  onError={e => handleError(e, "newPassword")}
                />
                <Password
                  name={"confirmPassword"}
                  label={"Confirm Password"}
                  placeholder={"Password"}
                  maxLength={0}
                  isRequired={false}
                  onChange={e => handleChange(e, "confirmPassword")}
                  onError={e => handleError(e, "confirmPassword")}
                  valueToConfirm={formValues.newPassword}
                  defaultError={formErrors.confirmPassword}
                />
              </>
            )}
            {errorMsg && (
              <div>
                <p>{errorMsg}</p>
              </div>
            )}
          </div>
          <div className={cx("formFooter")}>
            {!codeSent && (
              <Button
                type={"primary"}
                text={"Send verification code"}
                icon={false}
                state={isEnabled() ? "active" : "disabled"}
              />
            )}
            {codeSent && (
              <Button
                type={"primary"}
                text={"Reset password"}
                icon={false}
                state={isEnabled() ? "active" : "disabled"}
              />
            )}
            <Button
              type={"secondary"}
              text={"Back to sign in"}
              icon={false}
              state={"active"}
              url={withPrefix("/sign-in")}
            />
            <p>Don’t have an account?</p>
            <Button
              type={"tertiary"}
              text={"Create account"}
              iconAlignment={"right"}
              icon={true}
              url={withPrefix("/sign-up")}
            />
          </div>
        </form>
      </div>
    </div>
  )
}

export default ForgotPasswordPage
