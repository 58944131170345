import React, { useCallback, useEffect, useState } from "react"
import PropTypes from "prop-types"
import classNames from "classnames/bind"

import { useEventListener } from "../../../hooks/useEventListener"

import DeviceInfo from "../../utils/device-info"

import * as styles from "./tabContainer.module.scss"

const cx = classNames.bind(styles)

const TabContainer = ({ children, deviceInfo }) => {
  const [names, setNames] = useState([])
  const [tabs, setTabs] = useState([])
  const [currentTabName, setCurrentTabName] = useState("")

  useEffect(() => {
    if (!children) return
    const names = children
      .slice(0, 5)
      .filter(item => !!item)
      .map(item => ({
        name: item.props.name,
      }))
    setNames(names)
    setCurrentTabName(names[0].name)
  }, [children])

  useEffect(() => {
    const tabs = children
      .slice(0, 5)
      .filter(item => !!item)
      .map(item => ({
        ...item,
        props: { ...item.props, closed: item.props.name !== currentTabName },
      }))
    setTabs(tabs)
  }, [currentTabName])

  const handlerOnKeyDown = useCallback(
    ({ keyCode }) => {
      if (keyCode === 39) {
        setCurrentTabName(oldName => {
          const index = tabs.findIndex(tab => tab.props.name === oldName)
          const newIndex = tabs.length > index + 1 ? index + 1 : index
          return tabs[newIndex].props.name
        })
      } else if (keyCode === 37) {
        setCurrentTabName(oldName => {
          const index = tabs.findIndex(tab => tab.props.name === oldName)
          const newIndex = index > 0 ? index - 1 : 0
          return tabs[newIndex].props.name
        })
      }
    },
    [tabs, setCurrentTabName]
  )

  useEventListener("keydown", handlerOnKeyDown)

  const handlerOnChangeTab = e => {
    setCurrentTabName(e.target.value)
  }

  return (
    <div className={cx("container")}>
      {deviceInfo.viewport !== "small" && (
        <div className={cx("navigator")}>
          {names.map(tab => {
            const className = cx({
              ["tabSelector"]: true,
              ["selected"]: tab.name === currentTabName,
            })
            return (
              <button
                className={className}
                onClick={handlerOnChangeTab}
                value={tab.name}
              >
                {tab.name}
              </button>
            )
          })}
        </div>
      )}
      <div className={cx("content")}>{tabs}</div>
    </div>
  )
}

TabContainer.propTypes = {
  children: PropTypes.node,
  deviceInfo: PropTypes.object,
}

TabContainer.defaultProps = {}

const TabContainerWrapper = props => (
  <DeviceInfo>
    {deviceInfo => <TabContainer deviceInfo={deviceInfo} {...props} />}
  </DeviceInfo>
)
export default TabContainerWrapper
