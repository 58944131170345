import React from "react"
import PropTypes from "prop-types"

import classNames from "classnames/bind"

import * as styles from "./progressBar.module.scss"

const ProgressBar = ({ steps, currentStep }) => {
  const progress = (currentStep * 100) / steps
  const cx = classNames.bind(styles)

  return (
    <div className={cx("progressbar")}>
      <div style={{ width: `${progress}%` }} />
    </div>
  )
}

ProgressBar.propTypes = {
  stpes: PropTypes.number,
  currentStep: PropTypes.number,
}

ProgressBar.defaultProps = {
  steps: 100,
  currentStep: 0,
}

export default ProgressBar
